import request from '@/utils/request'

// get theatre
export function getTheatreList (params) {
  return request({
    url: '/api/theatre',
    method: 'GET',
    params
  })
}
// get theatre
export function getTheatreList2 (data) {
  return request({
    url: `/api/theatre?${data}`,
    method: 'GET',
  })
}
// add theatre
export function addTheatre (data) {
  return request({
    url: '/api/theatre',
    method: 'POST',
    data
  })
}

// get theatreDetail
export function getTheatreDetail (id) {
  return request({
    url: `/api/theatre/${id}`,
    method: 'GET',
  })
}

//edit theatre
export function editTheatre (id, data) {
  return request({
    url: `/api/theatre/${id}`,
    method: 'PUT',
    data
  })
}
//delect theatre
export function delectTheatre (id) {
  return request({
    url: `/api/theatre/${id}`,
    method: 'DELETE',
  })
}