<template>
  <div>
    <div class="title d-flex">
      <p>{{ title }}</p>
      <p v-if="!val1">
        電影記錄內之「出產年份」查閱：[{{ val }}年] 計至12月31日
      </p>
      <p v-else>電影記錄內之「出產年份」查閱：計至影片落畫 / 昨日</p>
    </div>
    <b-button
      size="sm"
      @click="$router.push({ name: 'check-upload-record' })"
      class="mb-1"
      style="margin-left: 8px"
      variant="outline-dark"
      >回目錄</b-button
    >
    <div style="display: flex; align-items: center"></div>
    <div class="mt-1 mb-1">
      <p class="d-flex">
        <b style="color: #0e72bc" class="mr-4">
          Report Period :<span style="color: red">{{
            report_start ? $moment(report_start).format() : ""
          }}</span>
          ~
          <span style="color: red">{{
            report_end || report_end !== "0000-00-00"
              ? $moment(report_end).format()
              : "00-00-0000"
          }}</span
          >首輪
        </b>
        <b-form-checkbox
          id="checkbox-showHiddenMovie"
          v-model="showHiddenMovie"
          @change="checkShow()"
        >
          不顯示隱藏戲院記錄
        </b-form-checkbox>
      </p>
      <p><b>All Films marked with 'No_Show' will not display here.</b></p>
      <div class="get-excel" v-show="tableData.length > 0">
        <b-button size="sm" @click="exportExcel()"
          >Get a Detail Excel file of
          {{ $moment(val).format("YYYY") }}</b-button
        >
      </div>
      <div v-loading="loading">
        <b-table
          bordered
          class="mt-1"
          striped
          hover
          responsive
          :items="tableData"
          :fields="
            $store.state.highlightCol.firTable
              ? $store.state.newTableFields
              : tableFields
          "
          @head-clicked="
            commonHighLightCol(
              $event,
              tableFields,
              $store.state.newTableFields,
              'firTable'
            )
          "
        >
          <template #thead-top="data">
            <b-tr>
              <b-th colspan="6"></b-th>
              <b-th> total SHOWS :{{ totalVal("shows", hasItem) }}</b-th>
              <b-th> total ADM :{{ totalVal("TA", hasItem) }}</b-th>
              <b-th> total B.O. :{{ totalVal("TG", hasItem) }}</b-th>
            </b-tr>
          </template>
          <template #cell(date_release)="data">
            <div v-if="data.item.status === 'No_Show'" style="color: red">
              {{ isValidDate(data.item.date_release) }}
            </div>
            <div v-else>
              {{ isValidDate(data.item.date_release) }}
            </div>
          </template>
          <template #cell(film_master.country)="data">
            <div class="text-nowrap">
              {{ data.value || "-" }}
            </div>
          </template>
          <template #cell(LastShow)="data">
            <div class="text-nowrap">
              {{
                isNaN(new Date(data.item.LastShow))
                  ? data.item.LastShow
                  : $moment(data.item.LastShow).format()
              }}
            </div>
          </template>
          <template #cell(TA)="data">
            <div
              :style="`color:${
                Number(val) < $moment(data.item.date_on).format('YYYY') ||
                Number(val) < $moment(data.item.date_off).format('YYYY') ||
                (data.item.LastShow === '上映中' &&
                  $moment().format('YYYY') > Number(val))
                  ? 'red'
                  : ''
              }`"
            >
              {{ parseFloat(data.item.TA).toLocaleString() }}
            </div>
          </template>
          <template #cell(TG)="data">
            <div
              :style="`color:${
                Number(val) < $moment(data.item.date_on).format('YYYY') ||
                Number(val) < $moment(data.item.date_off).format('YYYY') ||
                (data.item.LastShow === '上映中' &&
                  $moment().format('YYYY') > Number(val))
                  ? 'red'
                  : ''
              }`"
            >
              {{ parseFloat(data.item.TG).toLocaleString() }}
            </div>
          </template>
          <template #cell(shows)="data">
            <div
              :style="`color:${
                Number(val) < $moment(data.item.date_on).format('YYYY') ||
                Number(val) < $moment(data.item.date_off).format('YYYY') ||
                (data.item.LastShow === '上映中' &&
                  $moment().format('YYYY') > Number(val))
                  ? 'red'
                  : ''
              }`"
            >
              {{ parseFloat(data.item.shows).toLocaleString() }}
            </div>
          </template>
        </b-table>
        <p>紅色highlight代表有跨年數據</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getRecordByYear } from "@/api/record.js"
// import XLSX from 'xlsx'
import XLSX from "xlsx-js-style"
export default {
  props: {
    hasItem: {
      type: String,
      default: null,
    },
    title: {
      type: String,
    },
    title_num: {
      type: String,
    },
    val: {
      type: String,
    },
    val1: {
      type: String,
    },
    titleText: {
      type: String,
    },
    uploadDate: {
      type: String,
    },
    filmIDList: {
      type: String,
    },
    searchType: {
      type: String,
    },
  },
  data() {
    return {
      tableData: [],
      loading: false,
      select: "All Location",
      selectOptions: [
        { value: "All Location", text: "全部地區" },
        { value: "Hong Kong", text: "香港" },
        { value: "Macau", text: "澳門" },
      ],
      showHiddenMovie: true,
      report_start: "",
      report_end: "",
      filmInfoList: [],
    }
  },
  computed: {
    isDateOff() {
      return function (date) {
        if (isNaN(new Date(date))) {
          return date
        } else {
          if (
            Number(this.$moment(date).format("YYYY")) > Number(this.val) &&
            !this.val1
          ) {
            return "上映中"
          } else {
            return date
          }
        }
      }
    },
    totalVal() {
      return function (val, hasItem) {
        if (hasItem) {
          return parseFloat(
            this.tableData[hasItem].reduce(
              (sum, item) => sum + Number(item[val]),
              0
            )
          ).toLocaleString()
        } else {
          return parseFloat(
            this.tableData.reduce((sum, item) => sum + Number(item[val]), 0)
          ).toLocaleString()
        }
      }
    },
    tableFields() {
      return [
        { key: "film_master.id", label: "F_M ID", sortable: true },
        { key: "film_master.name_english", label: "Name", sortable: true },
        { key: "film_master.name_chinese", label: "Film Name", sortable: true },
        { key: "film_master.country", label: "Country", sortable: true },
        { key: "date_release", label: "Release Date", sortable: true },
        { key: "LastShow", label: "Date.Off", sortable: true },
        { key: "shows", label: "Shows", sortable: true },
        { key: "TA", label: "Total Adm.", sortable: true },
        { key: "TG", label: "Total B.O.", sortable: true },
      ]
    },
  },
  methods: {
    checkShow() {
      this.isShowAllFilm = !this.isShowAllFilm
      this.getRecordByYear()
    },
    getRecordByYear() {
      this.loading = true

      const data = {
        get_all: this.val1 ? 1 : 0,
        rpt_year: this.val,
        no_hide_theatre: this.showHiddenMovie ? 1 : 0,
        area: sessionStorage.getItem("area") || "",
      }
      getRecordByYear(data)
        .then((res) => {
          console.log(res)
          this.report_start = res.report_start
          this.report_end = res.report_end
          this.tableData = res.FM_ID
          this.filmInfoList = res.FM_ID
          this.tableData.map((el) => {
            el.LastShow = this.isDateOff(
              this.dateOffFormat(el.date_off, el.date_release)
            )
            if (new Date(el.date_on).getFullYear() < Number(this.val)) {
              el._rowVariant = "danger"
            }
          })
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    exportExcel() {
      //tabel list
      const header = [
        "FM ID",
        "Name",
        "戲名",
        "產地",
        "發 行",
        "片種",
        "首輪",
        "C. I",
        "C.IIA",
        "C.IIB",
        "C.III",
        "顯示票房日期",
        "Date.Release",
        "Date.Off",
        "R.2D",
        "R.3D",
        "IMAX",
        "DBox",
        "R.Others",
        "Shows",
        "Total Adm.",
        "Total B.O.",
      ]

      const dataList = []
      console.log(this.filmInfoList)
      this.filmInfoList.map((item) => {
        console.log(item.film_master)

        const list = []
        if (item.film_master) {
          const distributors = []
          item.distributors.map((distr) => {
            distributors.push(
              `${distr.distributor_name_zh_hk} ${distr.distributor_name_en}`
            )
          })
          console.log(distributors)

          let run = ""
          item.run.map((el) => {
            if (el === "1.0") {
              run = "首輪"
            } else {
              run = "+次輪+"
            }
          })
          const film_cat = []
          item.film_master.film_cat.map((el) => {
            film_cat.push(`${el.film_cat_name_zh_hk}*${el.film_cat_name_en}`)
          })
          list.push(
            item.film_master.id,
            item.film_master.name_english,
            item.film_master.name_chinese,
            item.film_master.country,
            distributors.length > 0 ? distributors.join("/") : "-", //發 行
            film_cat.length > 0 ? film_cat.join("/") : "-",
            run,
            item.rating && item.rating.I ? "Yes" : "", //C. I
            item.rating && item.rating.IIA ? "Yes" : "", //C.IIA
            item.rating && item.rating.IIB ? "Yes" : "", //C.IIB
            item.rating && item.rating.III ? "Yes" : "", //C.III
            this.excelDateFormat(item.date_to_report), //顯示票房日期
            this.excelDateFormat(item.date_release), //date on
            this.dateOffFormat(item.date_off, item.date_release), //date off
            item.effect && item.effect["2D"] ? "Yes" : "", //R.2D
            item.effect && item.effect["3D"] ? "Yes" : "", //R.3D
            item.effect && item.effect["IMAX"] ? "Yes" : "", //IMAX
            item.effect && item.effect["DBox"] ? "Yes" : "", //DBox
            item.effect && item.effect["Others"] ? "Yes" : "", //R.Others
            Number(item.shows),
            Number(item.TA),
            Number(item.TG)
          )
        }
        dataList.push(list)
      })
      //push data to excel
      dataList.unshift(header)
      //report period date
      let date1 = ""
      let date2 = ""
      if (!this.val1) {
        date1 = `${this.val}-01-01`
        date2 = `${this.val}-12-31`
      } else {
        date1 = `${this.val}-01-01`
        date2 = this.val1
      }
      dataList.unshift([`Report Period : ${date1} - ${date2}(首輪)`])
      //add total to tabel foot
      const total = [
        "",
        "",
        "",
        "",
        "", //發 行
        "",
        "",
        "", //C. I
        "", //C.IIA
        "", //C.IIB
        "", //C.III
        "", //顯示票房日期
        "", //date on
        "", //date off
        "", //R.2D
        "", //R.3D
        "", //IMAX
        "", //DBox
        "Total:", //R.Others
        this.filmInfoList.reduce((sum, item) => sum + Number(item.shows), 0),
        this.filmInfoList.reduce((sum, item) => sum + Number(item.TA), 0),
        this.filmInfoList.reduce((sum, item) => sum + Number(item.TG), 0),
      ]
      dataList.push(total)
      //report date
      dataList.push([
        `Report Date/Time : ${this.$moment().format("YYYY-MM-DD/HH:mm")}`,
      ])
      console.log(dataList)
      const excelInfo = {
        type: "16.10",
        merge: false,
        setDateValue: false,
        data: this.filmInfoList,
        dateCol: [],
        fileName: `Per_Movie_Report-${this.$moment(this.val).format(
          "YYYY"
        )}.xlsx`,
        header,
        excelData: dataList,
        numFmt: true,
        numFmtList: [
          { title: "T", index: 3 },
          { title: "U", index: 3 },
          { title: "V", index: 3 },
        ],
        hasTotal: true,
      }
      this.excelFun(excelInfo, "noapi")
    },
  },
  mounted() {
    this.getRecordByYear()
  },
}
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.sec-title {
  color: #0e72bc;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid #0e72bc;
}
.w300 {
  width: 250px;
}
</style>
