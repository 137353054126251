var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"uncertan-page"},[_c('div',{staticClass:"title d-flex"},[_c('p',[_vm._v(_vm._s(_vm.title))]),_c('p',[_vm._v(" 比較["+_vm._s(_vm.$moment(_vm.val).format())+"]之23:00(Hourly)數 與 Day_Final數之分別 "),(_vm.title_num === '16.9')?_c('span',[_vm._v("(包括午夜場票房)")]):_vm._e(),(_vm.title_num === '16.9.23.N')?_c('span',[_vm._v("(不包括午夜場票房)")]):_vm._e()])]),_c('b-button',{staticClass:"mb-1",staticStyle:{"margin-left":"8px"},attrs:{"size":"sm","variant":"outline-dark"},on:{"click":function($event){return _vm.$router.push({ name: 'check-upload-record' })}}},[_vm._v("回目錄")]),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('b-form-checkbox',{attrs:{"id":"checkbox-showHiddenMovie"},on:{"change":function($event){return _vm.checkShow()}},model:{value:(_vm.showHiddenMovie),callback:function ($$v) {_vm.showHiddenMovie=$$v},expression:"showHiddenMovie"}},[_vm._v(" 不顯示隱藏戲院記錄 ")])],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"mt-1 mb-1"},[_c('b',{staticClass:"mt-1",staticStyle:{"color":"#0e72bc"}},[_vm._v(" List Theatre B.O. Records in "),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.$moment(_vm.val).format()))]),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" 23:00")]),_c('span',[_vm._v("(UnOfficial Total on all records received "),(_vm.title_num === '16.9.23' || _vm.title_num === '16.9.23.N')?_c('span',{staticStyle:{"color":"red"}},[_vm._v("Without Gala B.O.")]):_vm._e(),_vm._v(" within in this period.)")])]),_c('b-table',{attrs:{"bordered":"","striped":"","hover":"","responsive":"","items":_vm.tableData,"fields":_vm.$store.state.highlightCol.firTable
          ? _vm.$store.state.newTableFields
          : _vm.tableFields},on:{"head-clicked":function($event){return _vm.commonHighLightCol(
          $event,
          _vm.tableFields,
          _vm.$store.state.newTableFields,
          'firTable'
        )}},scopedSlots:_vm._u([{key:"cell(theatre_name)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" \" "+_vm._s(data.item.theatre_name)+" ")])]}},{key:"cell(pre_adm_day_final)",fn:function(data){return [_c('div',[_vm._v(" "+_vm._s(parseFloat(data.item.pre_adm_day_final).toLocaleString())+" ")])]}},{key:"cell(pre_bo_day_final)",fn:function(data){return [_c('div',[_vm._v(" "+_vm._s(parseFloat(data.item.pre_bo_day_final).toLocaleString())+" ")])]}},{key:"cell(h23_adm_hourly_xml)",fn:function(data){return [_c('div',{style:(("color:" + (data.item.h23_adm_hourly_xml === data.item.adm_final_xml
              ? ''
              : 'red')))},[_vm._v(" "+_vm._s(parseFloat(data.item.h23_adm_hourly_xml).toLocaleString())+" ")])]}},{key:"cell(h23_bo_hourly_xml)",fn:function(data){return [_c('b',{style:(("color:" + (data.item.h23_bo_hourly_xml === data.item.bo_final_xml
              ? ''
              : 'red')))},[_vm._v(" "+_vm._s(parseFloat(data.item.h23_bo_hourly_xml).toLocaleString())+" ")])]}},{key:"cell(adm_final_xml)",fn:function(data){return [_c('b',[_vm._v(" "+_vm._s(parseFloat(data.item.adm_final_xml).toLocaleString())+" ")])]}},{key:"cell(bo_final_xml)",fn:function(data){return [_c('b',[_vm._v(" "+_vm._s(parseFloat(data.item.bo_final_xml).toLocaleString())+" ")])]}}])})],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"mt-3"},[_c('b',{staticClass:"mt-1",staticStyle:{"color":"#0e72bc"}},[_vm._v(" List Movie B.O. Records in "),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.$moment(_vm.val).format()))]),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" 23:00")]),_c('span',[_vm._v("(UnOfficial Total on all records received "),(_vm.title_num === '16.9.23' || _vm.title_num === '16.9.23.N')?_c('span',{staticStyle:{"color":"red"}},[_vm._v("Without Gala B.O.")]):_vm._e(),_vm._v(" within in this period.)")])]),_c('b-table',{staticClass:"mt-1",attrs:{"bordered":"","striped":"","hover":"","responsive":"","items":_vm.movieData,"fields":_vm.$store.state.highlightCol.secTable
          ? _vm.$store.state.newSecTableFields
          : _vm.movieFields},on:{"head-clicked":function($event){return _vm.commonHighLightCol(
          $event,
          _vm.movieFields,
          _vm.$store.state.newSecTableFields,
          'secTable'
        )}},scopedSlots:_vm._u([{key:"cell(film_title_name)",fn:function(data){return [_c('div',{staticStyle:{"width":"250px"},domProps:{"innerHTML":_vm._s(data.item.film_title_name)}})]}},{key:"cell(h23_adm_hourly_xml)",fn:function(data){return [_c('div',{style:(("color:" + (data.item.h23_adm_hourly_xml === data.item.adm_final_xml
              ? ''
              : 'red')))},[_vm._v(" "+_vm._s(parseFloat(data.item.h23_adm_hourly_xml).toLocaleString())+" ")])]}},{key:"cell(h23_bo_hourly_xml)",fn:function(data){return [_c('b',{style:(("color:" + (data.item.h23_bo_hourly_xml === data.item.bo_final_xml
              ? ''
              : 'red')))},[_vm._v(" "+_vm._s(parseFloat(data.item.h23_bo_hourly_xml).toLocaleString())+" ")])]}},{key:"cell(pre_adm_day_final)",fn:function(data){return [_c('div',[_vm._v(" "+_vm._s(parseFloat(data.item.pre_adm_day_final).toLocaleString())+" ")])]}},{key:"cell(pre_bo_day_final)",fn:function(data){return [_c('b',[_vm._v(" "+_vm._s(parseFloat(data.item.pre_bo_day_final).toLocaleString())+" ")])]}},{key:"cell(adm_final_xml)",fn:function(data){return [_c('b',[_vm._v(" "+_vm._s(parseFloat(data.item.adm_final_xml).toLocaleString())+" ")])]}},{key:"cell(bo_final_xml)",fn:function(data){return [_c('b',[_vm._v(" "+_vm._s(parseFloat(data.item.bo_final_xml).toLocaleString())+" ")])]}}])}),_c('p',[_vm._v("紅色字是代表數據有異差")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }