<template>
  <div>
    <b-card>
      <div class="title">
        <p>修改票房記錄 - 新增票房記錄</p>
      </div>
      <b-row v-for="(item,index) in info" :key="index" class="my-1">
        <b-col sm="2">
          {{ item.label }} :
        </b-col>
        <b-col sm="6">
          <b-form-input :id="`type-${index}`" v-model="theatreInfo[item.key]" :type="item.type||'text'" />
        </b-col>
      </b-row>
      <div>
        <div class="line" />
        <div
          class="d-flex align-items-center justify-content-between"
        >
          <div class="input-box">
            <label
              class="label"
            >放映時間 Show Time*</label>
            <b-form-input v-model="theatreInfo.show_time" />
          </div>
          <div class="input-box">
            <label
              class="label"
            >入場人數 Admissions*</label>
            <b-form-input  v-model="theatreInfo.admissions" />
          </div>
          <div class="input-box">
            <label
              class="label"
            >收入 Total Gross (HKD)*</label>
            <b-form-input v-model="theatreInfo.total_gross" />
          </div>
        </div>
      </div>
      <b-button size="sm" class="mt-2 next" variant="outline-primary" @click="next">
        下一步
      </b-button>
    </b-card>
  </div>
</template>

<script>

export default {
  data() {
    return {
      id: null,
      info: [
        { label: '戲院名稱 (Full Name)', key:'full_name' },
        { label: '電影名稱 Film Name', key: 'short_name' },
        { label: 'Flim Master ID', key: 'film_master_id' },
        { label: 'Flim ID', key: 'film_id' },
        { label: 'POS ID', key: 'pos_id' },
        { label: 'House no.', key: 'house_id' },
        { label: 'Date_of_Engagement', key: 'engagement_date' },
        { label: '上映日期 Show Date', key: 'show_date' }
      ],
      theatreInfo:{
        'id':'',
        'full_name': '',
        'short_name': '',
        'film_master_id': '',
        'film_id': '',
        'pos_id': '',
        'house_id': '',
        'date': '',
        'show_date': '',
        'show_time': '',
        'engagement_date':'',
        'admissions': '',
        'total_gross': ''
      }
    }
  },
  mounted() {
    this.theatreInfo.id = this.$route.query.id
  },
  methods:{
    next() {
      this.$router.push({name:'add-film-record', query:this.theatreInfo})

    }
    // addTheatre() {
    //   addTheatre(this.theatreInfo).then(res => {
    //     this.$router.push('/theatre/theatre')
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
.line{
  height: 1.5px;
  background: #0e72bc;
  width: 100%;
  margin: 20px 0;
}
.next{
    float: right;
}
.input-box{
    width: 27%;
  }
  .change-box{
    height: 80px;
    border-bottom: 2px solid #0e72bc;
    .change-btn{
    margin-top: 20px;
    float: right;
  }
}
.case-tank{
  width: 100%;
}
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
</style>

