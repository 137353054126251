var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}]},[_c('div',{staticClass:"title"},[_c('p',[_vm._v("院線資料")])]),_c('div',[_c('b-button',{staticClass:"mr-2 mb-1",attrs:{"size":"sm","variant":"outline-primary"},on:{"click":function($event){return _vm.goDetail('', 'create')}}},[_vm._v(" 開立新院線記錄 ")])],1),_c('div',{staticClass:"mb-1 d-flex"},[_c('b-form-input',{staticStyle:{"width":"30%"},attrs:{"placeholder":"Enter Circuit Name"},model:{value:(_vm.searchCircuit),callback:function ($$v) {_vm.searchCircuit=$$v},expression:"searchCircuit"}}),_c('b-button',{staticClass:"ml-2",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.getCircuitList()}}},[_vm._v(" Search ")])],1),_c('b-overlay',{attrs:{"rounded":"sm"}},[_c('b-table',{attrs:{"bordered":"","striped":"","hover":"","responsive":"","items":_vm.tableData,"fields":_vm.$store.state.highlightCol.firTable
            ? _vm.$store.state.newTableFields
            : _vm.tableFields},on:{"head-clicked":function($event){return _vm.commonHighLightCol(
            $event,
            _vm.tableFields,
            _vm.$store.state.newTableFields,
            'firTable'
          )}},scopedSlots:_vm._u([{key:"cell(full_name)",fn:function(row){return [_c('b-button',{staticClass:"mr-2",attrs:{"size":"sm","variant":"outline-primary"},on:{"click":function($event){return _vm.goDetail(row.item.id, 'view')}}},[_vm._v(" "+_vm._s(row.item.full_name)+" ")])]}},{key:"cell(action)",fn:function(row){return [_c('b-button',{staticClass:"mr-2",attrs:{"size":"sm"},on:{"click":function($event){return _vm.goDetail(row.item.id,'edit')}}},[_vm._v(" 作出修訂 ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }