<template>
  <div class="permissions-com" v-loading="loadingCom">
    <div v-if="showPermission">
      <div
        class="select-permission"
        v-for="(item, index) in permissionList"
        :key="index"
      >
        <p
          v-if="item.type !== 'time' && item.type !== 'dailyReport'"
          class="sub-title"
        >
          {{ item.title }}
        </p>
        <div v-else-if="item.type === 'dailyReport'" class="sub-title">
          <p>{{ item.title }}</p>
          <div class="set-xml">
            <el-checkbox
              @change="useXMLChange"
              class="mb-1"
              v-model="permissionValue.showXML"
            >
              <b style="color: #0e72bc">可使用XML Gateway</b>
            </el-checkbox>
          </div>
        </div>
        <div v-else>
          <div class="set-time-xml d-flex mb-1">
            <div class="set-time">
              <el-checkbox
                class="mb-1"
                :disabled="isGateway"
                @change="setTimeChange"
                v-model="permissionValue.setTime"
              >
                <b style="color: #0e72bc">時間設定</b>
              </el-checkbox>
              <br />
              <div class="d-flex">
                <datePickerCom
                  :key="datePickerKeyStart"
                  class="mr-1"
                  :isDisabled="!permissionValue.setTime || isGateway"
                  :pickTimeVal.sync="
                    userInfoValue.report_side_permissions_date_start
                  "
                  :inputWidth="'100%'"
                />
                <datePickerCom
                  :key="datePickerKeyEnd"
                  :isDisabled="!permissionValue.setTime || isGateway"
                  :pickTimeVal.sync="
                    userInfoValue.report_side_permissions_date_end
                  "
                  :inputWidth="'100%'"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="permission-box d-flex"
          v-for="(perItem, perIndex) in item.selectList"
          :key="perIndex"
        >
          <el-checkbox
            :disabled="isGateway"
            v-if="
              item.type !== 'area' &&
              item.type !== 'time' &&
              item.type !== 'dailyReport'
            "
            :indeterminate="perItem.isIndeterminate"
            v-model="perItem.checkAll"
            @change="
              handleCheckAllChange(
                perItem.checkAll,
                index,
                perIndex,
                item.title_val,
                perItem.checkList
              )
            "
          >
            <b class="mr-3">{{ perItem.sub_title }}</b>
          </el-checkbox>
          <el-checkbox-group
            v-if="item.type !== 'time'"
            v-model="permissionValue[item.title_val][perItem.checkList]"
            @change="
              handleCheckedChange(
                permissionValue[item.title_val][perItem.checkList],
                index,
                perIndex,
                perItem.title_name_list.length
              )
            "
          >
            <el-checkbox
              v-for="(liName, liIndex) in perItem.title_name_list"
              :label="liName.value"
              :key="liIndex"
              :disabled="disabledVal(item.type)"
              >{{ liName.name }}</el-checkbox
            >
          </el-checkbox-group>
          <div
            style="width: 70%"
            v-if="item.type === 'dailyReport'"
            class="d-flex ml-2 flex-wrap"
          >
            <el-checkbox
              :disabled="disabledVal(item.type)"
              :true-label="Number(1)"
              :false-label="Number(0)"
              class="mr-1"
              v-model="userInfoValue.xml_gateway_table_format"
              >列表模式</el-checkbox
            >
            <span class="text-nowrap">XML Gateway IP:</span>
            <b-form-input
              :disabled="disabledVal(item.type)"
              class="ml-2"
              style="width: 70%"
              v-model="userInfoValue.xml_gateway_ip"
              placeholder=""
            ></b-form-input>
          </div>
        </div>
        <span
          v-if="
            item.title === '報告地區設定' &&
            permissionValue.setArea.ar.length === 0
          "
          style="color: red; margin-right: 10px"
          >(please select Area *)</span
        >
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <b-button size="sm" @click="reset('cancel')" class="mr-1">
        取消
      </b-button>
      <!-- <b-button
        v-if="permissionKey === 'Customize settings' && savePerVal"
        variant="primary"
        class="mr-1"
        size="sm"
        @click="reset('reset')"
      >
        重置
      </b-button> -->
      <b-button
        :disabled="permissionValue.setArea.ar.length === 0"
        variant="primary"
        size="sm"
        @click="submit('confirm')"
      >
        確定
      </b-button>
    </div>
  </div>
</template>

<script>
import { checkRole, getUsersDetail } from "@/api/user.js";
import { permissionsList } from "./userPermissions";
import datePickerCom from "@/views/components/datePicker.vue";
export default {
  components: { datePickerCom },
  props: {
    userInfo: {
      type: Object
    },
    permissionsData: {
      type: Object
    },
    permissionKey: {
      type: String
    }
  },
  data() {
    return {
      datePickerKeyStart: 1,
      datePickerKeyEnd: 100,
      isGateway: false,
      showPermission: true,
      savePerVal: window.sessionStorage.getItem("permissionList") || null,
      loadingCom: false,
      permissionList: [
        {
          title: "票房紀錄",
          title_val: "boxofficeRecord",
          selectList: [
            {
              sub_title: "票房紀錄",
              title_name_list: [
                { name: "票房簡表", value: "bo_simple_table" },
                { name: "7日票房詳情", value: "bo_seven_day_detail" },
                {
                  name: "7日影片排名及戲院資料",
                  value: "bo_film_order_and_cinema_list"
                },
                { name: "7日戲院影片概況", value: "bo_cinema_film_overview" },
                { name: "戲院排名", value: "bo_cinema_order" },
                { name: "戲院詳情", value: "bo_cinema_detail" }
              ],
              checkList: "bo",
              isIndeterminate: false,
              checkAll: false
            },
            {
              sub_title: "上映詳情",
              title_name_list: [
                { name: "影片放映種類", value: "sh_film_category" },
                { name: "戲院上映詳情", value: "sh_cinema_showing_detail" }
              ],
              checkList: "sh",
              isIndeterminate: false,
              checkAll: false
            },
            {
              sub_title: "即時票房",
              title_name_list: [
                { name: "影片即時票房", value: "rt_film_box_office" },
                { name: "戲院即時票房", value: "rt_cinema_box_office" },
                { name: "影片比較-今天", value: "rt_film_compare_today" },
                { name: "戲院比較-今天", value: "rt_cinema_compare_today" },
                {
                  name: "昔日影片每小時比較",
                  value: "rt_past_film_hourly_compare"
                },
                {
                  name: "昔日戲院每小時票房",
                  value: "rt_past_cinema_hourly_compare"
                }
              ],
              checkList: "rt",
              isIndeterminate: false,
              checkAll: false
            },
            {
              sub_title: "每日票房報告",
              title_name_list: [
                {
                  name: "影片收入及人次",
                  value: "dr_film_gross_and_admission"
                },
                { name: "影片纍積結果", value: "dr_film_total" },
                { name: "票房每日詳情", value: "dr_box_office_daily_detail" }
              ],
              checkList: "dr",
              isIndeterminate: false,
              checkAll: false
            },
            {
              sub_title: "市場票房分析",
              title_name_list: [
                { name: "戲院占有率", value: "al_cinema_occupancy" },
                { name: "院線占有率", value: "al_theatre_occupancy" },
                { name: "影片占有率", value: "al_film_occupancy" },
                { name: "發行商占有率", value: "al_distributor_occupancy" },
                { name: "出品公司占有率", value: "al_production_occupancy" },
                { name: "分區占有率", value: "al_region_occupancy" },
                { name: "產地占有率", value: "al_area_occupancy" },
                { name: "片種占有率", value: "al_category_occupancy" },
                { name: "影片比較", value: "al_film_compare" },
                { name: "戲院比較", value: "al_cinema_compare" },
                { name: "院線比較", value: "al_theatre_compare" }
              ],
              checkList: "al",
              isIndeterminate: false,
              checkAll: false
            }
          ]
        },
        {
          title: "資料搜尋",
          title_val: "dataSearch",
          value: "sc_data",
          selectList: [
            {
              sub_title: "資料搜尋",
              title_name_list: [],
              checkList: "sc",
              isIndeterminate: false,
              checkAll: false
            }
          ]
        },
        {
          title: "影片排期",
          title_val: "filmSchedule",
          value: "sch_film",
          selectList: [
            {
              sub_title: "影片排期",
              title_name_list: [],
              checkList: "sch",
              isIndeterminate: false,
              checkAll: false
            }
          ]
        },
        {
          title: "票房簡報",
          title_val: "boxofficeReport",
          selectList: [
            {
              sub_title: "票房簡報",
              title_name_list: [
                { name: "正場", value: "bos_front" },
                { name: "午夜場", value: "bos_midnight" },
                { name: "每周十大", value: "bos_first_ten" },
                { name: "片名搜尋", value: "bos_search_film_name" },
                { name: "片名搜尋（昔日影片）", value: "bos_search_past_film" },
                { name: "每月落畫", value: "bos_monthly_date_off" },
                {
                  name: "戲院月結票房",
                  value: "bos_theatre_box_office_monthly"
                }
              ],
              checkList: "bos",
              isIndeterminate: false,
              checkAll: false
            }
          ]
        },
        {
          title: "上映中",
          title_val: "onShow",
          selectList: [
            {
              sub_title: "上映中",
              title_name_list: [
                { name: "票房簡表", value: "shi_box_office_simple_table" },
                { name: "院線占有率", value: "shi_theatre_occupancy" },
                { name: "戲院占有率", value: "shi_cinema_occupancy" },
                { name: "院線比較", value: "shi_theatre_company" },
                { name: "戲院排名", value: "shi_theatre_order" },
                { name: "每周票房報告", value: "shi_box_office_weekly_report" },
                { name: "影片纍積結果", value: "shi_film_total" },
                { name: "票房每日詳情", value: "shi_box_office_daily" },
                { name: "地區分析", value: "shi_area_analyze" },
                { name: "即時票房", value: "shi_realtime_box_office" }
              ],
              checkList: "shi",
              isIndeterminate: false,
              checkAll: false
            }
          ]
        },
        {
          title: "時間設定",
          type: "time",
          value: "noShowTine",
          time_value: "",
          use_xml: "",
          selectList: [
            {
              sub_title: "",
              title_name_list: [],
              checkList: [],
              isIndeterminate: false,
              checkAll: false
            }
          ]
        },
        {
          title: "Daily Report",
          title_val: "dailyReport",
          type: "dailyReport",
          selectList: [
            {
              sub_title: "",
              title_name_list: [
                {
                  name: "Daily 票房（7日數據）",
                  value: "dar_seven_day_box_office"
                },
                {
                  name: "Daily Top Ten（7日數據）",
                  value: "dar_seven_day_top_ten"
                }
              ],
              checkList: "dar",
              isIndeterminate: false,
              checkAll: false
            }
          ]
        },

        {
          title: "報告地區設定",
          title_val: "setArea",
          type: "area",
          selectList: [
            {
              sub_title: "",
              title_name_list: [
                { name: "香港", value: "ar_hong_kong" },
                { name: "澳門", value: "ar_macao" }
              ],
              checkList: "ar",
              isIndeterminate: false,
              checkAll: false
            }
          ]
        }
      ],
      perSame: {
        boxofficeRecord: {
          bo: [],
          sh: [],
          rt: [],
          dr: [],
          al: []
        },
        dataSearch: {
          sc: []
        },
        filmSchedule: {
          sch: []
        },
        boxofficeReport: {
          bos: []
        },
        onShow: {
          shi: []
        },
        setTime: false,
        useXml: { xml: [] },
        setArea: {
          ar: []
        },
        showXML: false,
        dailyReport: {
          dar: []
        }
      }
    };
  },
  computed: {
    userInfoValue: {
      get() {
        return this.userInfo;
      },
      set(value) {
        this.$emit("update:userInfo", value);
      }
    },
    permissionValue: {
      get() {
        return this.permissionsData;
      },
      set(value) {
        this.$emit("update:permissionsData", value);
      }
    },
    disabledVal() {
      return function (val) {
        console.log(val, "val");
        if (val === "dailyReport") {
          console.log(this.permissionValue, "6666");
          if (this.permissionValue && this.permissionValue.showXML) {
            return false;
          } else {
            return true;
          }
        } else if (val === "area") {
          return false;
        } else {
          return this.isGateway;
        }
      };
    }
  },
  methods: {
    setTimeChange(val) {
      if (!val) {
        this.userInfoValue.report_side_permissions_date_start = "";
        this.userInfoValue.report_side_permissions_date_end = "";
      }
    },
    reset(type) {
      this.showPermission = false;
      if (type === "cancel") {
        if (
          this.permissionKey === "Customize settings" &&
          sessionStorage.getItem("gatewayIp")
        ) {
          this.userInfoValue.xml_gateway_ip =
            sessionStorage.getItem("gatewayIp");
        }
        this.$emit("updatePermissionKey", type, "");
      } else {
        this.loadingCom = true;
        window.sessionStorage.removeItem("permissionList");
        if (this.$route.query.review !== "new") {
          this.permissionValue = JSON.parse(
            window.sessionStorage.getItem("oldPermissions")
          );
          this.checkDefaultAll();
        } else {
          this.loadingCom = false;
          this.permissionValue = this.perSame;
        }
      }
      this.showPermission = true;
    },
    submit(type) {
      const list = [];
      //將選擇列表變成一整個數組

      Object.keys(this.permissionValue).map((el) => {
        if (
          Object.keys(this.permissionValue[el]) &&
          Object.keys(this.permissionValue[el]).length > 0
        ) {
          Object.keys(this.permissionValue[el]).map((item) => {
            if (
              this.permissionValue[el][item] &&
              this.permissionValue[el][item].length > 0
            ) {
              if (this.permissionValue[el][item] === "xml_gateway") {
                list.push("xml_gateway");
              } else {
                this.permissionValue[el][item].map((val) => {
                  list.push(val);
                });
              }
            }
          });
        }
      });
      console.log(list);
      if (type === "confirm") {
        this.loadingCom = true;
        checkRole(list).then((res) => {
          if (res.data.name === "Customize settings") {
            window.sessionStorage.setItem(
              "permissionList",
              JSON.stringify(this.permissionValue)
            );
          }
          this.$emit(
            "updatePermissionKey",
            type,
            res.data.name,
            this.permissionValue
          );
          this.loadingCom = false;
        });
      } else {
        this.$emit("updatePermissionKey", type, "");
      }
    },
    useXMLChange(val) {
      if (!val) {
        this.isGateway = false;
        this.datePickerKeyStart++;
        this.datePickerKeyEnd++;
        this.permissionValue.dailyReport.dar = [];
        this.permissionValue.useXml.xml = [];
        this.userInfo.xml_gateway_ip = "";
      } else {
        this.useGatewayDefault(); //點擊後 除了地區  所有都禁用
        this.userInfoValue.report_side_permissions_date_start = "";
        this.userInfoValue.report_side_permissions_date_end = "";
        this.datePickerKeyStart++;
        this.datePickerKeyEnd++;
      }

      this.$emit("useXMLChange", val);
    },
    useGatewayDefault() {
      //點擊後 除了地區  所有都禁用
      this.isGateway = true;
      this.permissionList.map((el) => {
        if (
          el.type !== "time" &&
          el.type !== "dailyReport" &&
          el.type !== "area"
        ) {
          el.selectList.map((item) => {
            item.checkAll = false;
          });
        }
      });
      this.permissionValue = {
        boxofficeRecord: {
          bo: [],
          sh: [],
          rt: [],
          dr: [],
          al: []
        },
        dataSearch: {
          sc: []
        },
        filmSchedule: {
          sch: []
        },
        boxofficeReport: {
          bos: []
        },
        onShow: {
          shi: []
        },
        setTime: false,
        useXml: { xml: ["xml_gateway"] },
        setArea: {
          ar: ["ar_hong_kong", "ar_macao"]
        },
        showXML: true,
        dailyReport: {
          dar: []
        }
      };
    },
    handleCheckAllChange(val, index, subIndex, title_val, sub_name_val) {
      if (val) {
        this.permissionList[index].selectList[subIndex].title_name_list.map(
          (el) => {
            this.permissionValue[title_val][sub_name_val].push(el.value);
            this.permissionValue[title_val][sub_name_val] = [
              ...new Set(this.permissionValue[title_val][sub_name_val])
            ];
          }
        );
        this.permissionList[index].selectList[subIndex].isIndeterminate = false;
        this.permissionList[index].selectList[subIndex].checkAll = true;
        if (title_val === "filmSchedule") {
          this.permissionValue.filmSchedule.sch = ["sch_film"];
        } else if (title_val === "dataSearch") {
          this.permissionValue.dataSearch.sc = ["sc_data"];
        }
      } else {
        this.permissionValue[title_val][sub_name_val] = [];
        this.permissionList[index].selectList[subIndex].isIndeterminate = false;
        this.permissionList[index].selectList[subIndex].checkAll = false;
      }
    },
    handleCheckedChange(isvalue, index, subIndex, valLength) {
      const value = Array.from(new Set(isvalue));
      const checkedCount = value.length;
      this.permissionList[index].selectList[subIndex].checkAll =
        checkedCount ===
        this.permissionList[index].selectList[subIndex].title_name_list.length;
      this.permissionList[index].selectList[subIndex].isIndeterminate =
        checkedCount > 0 &&
        checkedCount <
          this.permissionList[index].selectList[subIndex].title_name_list
            .length;
    },
    checkDefaultAll() {
      // Customize settings 變爲舊設置
      if (window.sessionStorage.getItem("permissionList")) {
        this.permissionValue = JSON.parse(
          window.sessionStorage.getItem("permissionList")
        );
      } else {
        this.permissionValue = JSON.parse(
          window.sessionStorage.getItem("oldPermissions")
        );
      }
      if (this.permissionValue && this.permissionValue.showXML) {
        this.isGateway = true;
      }
      this.permissionList.map((el) => {
        if (
          el.title_val === "filmSchedule" &&
          this.permissionValue.filmSchedule &&
          this.permissionValue.filmSchedule.sch.length > 0
        ) {
          el.selectList[0].checkAll = true;
        } else if (
          el.title_val === "dataSearch" &&
          this.permissionValue.dataSearch &&
          this.permissionValue.dataSearch.sc.length > 0
        ) {
          el.selectList[0].checkAll = true;
        } else {
          el.selectList.map((item) => {
            console.log(this.permissionValue, "this.permissionValue");

            if (item.title_name_list && item.title_name_list.length > 0) {
              if (
                item.title_name_list.length ===
                this.permissionValue[el.title_val][item.checkList].length
              ) {
                item.checkAll = true;
              }
            }
          });
        }
      });
      console.log(this.permissionValue);
      this.loadingCom = false;
    },
    checkBoxDefualt() {
      //初始化
      if (this.permissionKey === "Normal User") {
        this.permissionList.map((el) => {
          if (el.title === "票房簡報") {
            console.log(el, "票房簡報");
            this.perSame.boxofficeReport.bos = [];
            el.selectList[0].title_name_list.map((item) => {
              this.perSame.boxofficeReport.bos.push(item.value);
            });
            el.selectList[0].isIndeterminate = false;
            el.selectList[0].checkAll = true;
          }
          if (el.title === "影片排期") {
            this.perSame.filmSchedule.sch.push("sch_film");
            el.selectList[0].isIndeterminate = false;
            el.selectList[0].checkAll = true;
          }
          if (el.title === "報告地區設定") {
            this.perSame.setArea.ar.push("ar_hong_kong", "ar_macao");
            el.selectList[0].isIndeterminate = false;
            el.selectList[0].checkAll = true;
          }
        });
        this.permissionValue = this.perSame;
        this.userInfoValue.xml_gateway_ip = "";
      }
      if (this.permissionKey === "Pro. User") {
        this.permissionList.map((el) => {
          if (el.type !== "time" && el.type !== "dailyReport") {
            el.selectList.map((item) => {
              item.title_name_list.map((li) => {
                this.perSame[el.title_val][item.checkList].push(li.value);
              });
              item.checkAll = true;
            });
          }
          if (el.title_val === "dataSearch") {
            this.perSame.dataSearch.sc = ["sc_data"];
          } else if (el.title_val === "filmSchedule") {
            this.perSame.filmSchedule.sch = ["sch_film"];
          }
        });
        this.permissionValue = this.perSame;
        this.userInfoValue.xml_gateway_ip = "";
      }

      if (this.permissionKey === "Customize settings") {
        this.checkDefaultAll();
      }
      if (!this.userInfoValue.setItem) {
        this.userInfoValue.report_side_permissions_date_start = "";
        this.userInfoValue.report_side_permissions_date_end = "";
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.checkBoxDefualt();
      console.log(this.userInfo);
    });
  }
};
</script>
<style lang="scss" scoped>
.sub-title {
  width: 100%;
  color: #0e72bc;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 5px;
}
.select-permission {
  margin-bottom: 5px;
}
</style>
