<template>
  <div class="uncertan-page">
    <div class="title d-flex">
      <p>{{ title }}</p>
      <p>[{{ $moment(val).format() }}]之所有未確認總數</p>
    </div>
    <b-button
      size="sm"
      @click="$router.push({ name: 'check-upload-record' })"
      class="mb-1"
      style="margin-left: 8px"
      variant="outline-dark"
      >回目錄</b-button
    >

    <div style="display: flex; align-items: center">
      <b-form-checkbox
        id="checkbox-showHiddenMovie"
        v-model="showHiddenMovie"
        @change="checkShow()"
      >
        不顯示隱藏戲院記錄
      </b-form-checkbox>
    </div>
    <div class="mt-1 mb-1" v-loading="loading">
      <b style="color: #0e72bc" class="mt-1">
        List Theatre B.O. Records in
        <span style="color: red">{{ $moment(val).format() }}</span>
        <span style="color: red"> (23:00)</span>
        <span
          >(UnOfficial Total on all records received
          <span v-if="title_num === '16.8.23'" style="color: red"
            >Without Gala B.O.</span
          >
          within in this period.)</span
        >
      </b>
      <b-table
        bordered
        responsive
        striped
        hover
        :items="tableData"
        :fields="
          $store.state.highlightCol.firTable
            ? $store.state.newTableFields
            : tableFields
        "
        @head-clicked="
          commonHighLightCol(
            $event,
            tableFields,
            $store.state.newTableFields,
            'firTable'
          )
        "
      >
        <template #cell(theatre_name)="data">
          <div class="text-nowrap">
            "
            {{ data.item.theatre_name }}
          </div>
        </template>
        <template #cell(date_upload_ref)="data">
          <div class="">
            <span v-if="data.item.date_upload_ref">{{
              $moment(data.item.date_upload_ref).format("DD-MM-YYYY HH:mm:ss")
            }}</span>
            <!--  -->
          </div>
        </template>
        <template #cell(pre_adm_day_final)="data">
          <div>
            {{ parseFloat(data.item.pre_adm_day_final).toLocaleString() }}
          </div>
        </template>
        <template #cell(h23_adm_hourly_xml)="data">
          <div>
            {{ parseFloat(data.item.h23_adm_hourly_xml).toLocaleString() }}
          </div>
        </template>
        <template #cell(total_adm)="data">
          <div>
            {{ parseFloat(data.item.total_adm).toLocaleString() }}
          </div>
        </template>
        <template #cell(pre_bo_day_final)="data">
          <b>
            {{ parseFloat(data.item.pre_bo_day_final).toLocaleString() }}
          </b>
        </template>
        <template #cell(h23_bo_hourly_xml)="data">
          <b>
            {{ parseFloat(data.item.h23_bo_hourly_xml).toLocaleString() }}
          </b>
        </template>
        <template #cell(last_bo_hourly_xml)="data">
          <b>
            {{ parseFloat(data.item.last_bo_hourly_xml).toLocaleString() }}
          </b>
        </template>
        <template #cell(total_bo)="data">
          <b>
            {{ parseFloat(data.item.total_bo).toLocaleString() }}
          </b>
        </template>
      </b-table>
    </div>
    <div class="mt-3" v-loading="loading">
      <b style="color: #0e72bc" class="mt-1">
        List Movie B.O. Records in
        <span style="color: red">{{ $moment(val).format() }}</span
        ><span v-if="title_num === '16.8.23'" style="color: red"> 23:00</span>
        <span
          >(UnOfficial Total on all records received
          <span v-if="title_num === '16.8.23'" style="color: red"
            >Without Gala B.O.</span
          >
          within in this period.)</span
        >
      </b>
      <b-table
        bordered
        class="mt-1"
        striped
        hover
        responsive
        :items="movieData"
        :fields="
          $store.state.highlightCol.secTable
            ? $store.state.newSecTableFields
            : movieFields
        "
        @head-clicked="
          commonHighLightCol(
            $event,
            movieFields,
            $store.state.newSecTableFields,
            'secTable'
          )
        "
      >
        <template #head()="data">
          <div v-html="data.label"></div>
        </template>
        <template #cell(film_title_name)="data">
          <div style="width: 140px" v-html="data.item.film_title_name"></div>
        </template>
        <template #cell(film_effect)="data">
          <div class="text-nowrap">
            {{ data.item.film_effect }}
          </div>
        </template>
        <template #cell(pre_adm_day_final)="data">
          <div>
            {{ parseFloat(data.item.pre_adm_day_final).toLocaleString() }}
          </div>
        </template>
        <template #cell(h23_adm_hourly_xml)="data">
          <div>
            {{ parseFloat(data.item.h23_adm_hourly_xml).toLocaleString() }}
          </div>
        </template>
        <template #cell(daily_total_adm)="data">
          <div>
            {{ parseFloat(data.item.daily_total_adm).toLocaleString() }}
          </div>
        </template>
        <template #cell(h23_theatre_count)="data">
          <div>
            {{ parseFloat(data.item.h23_theatre_count).toLocaleString() }}
          </div>
        </template>
        <template #cell(h23_house_count)="data">
          <div>
            {{ parseFloat(data.item.h23_house_count).toLocaleString() }}
          </div>
        </template>
        <template #cell(h23_shows_count)="data">
          <div>
            {{ parseFloat(data.item.h23_shows_count).toLocaleString() }}
          </div>
        </template>
        <template #cell(up_to_date_bo)="data">
          <b>
            {{ parseFloat(data.item.up_to_date_bo).toLocaleString() }}
          </b>
        </template>
        <template #cell(pre_bo_day_final)="data">
          <b>
            {{ parseFloat(data.item.pre_bo_day_final).toLocaleString() }}
          </b>
        </template>
        <template #cell(h23_bo_hourly_xml)="data">
          <b>
            {{ parseFloat(data.item.h23_bo_hourly_xml).toLocaleString() }}
          </b>
        </template>
        <template #cell(daily_total_bo)="data">
          <b>
            {{ parseFloat(data.item.daily_total_bo).toLocaleString() }}
          </b>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { getUncertainRecord } from "@/api/record.js"

export default {
  props: {
    title: {
      type: String,
    },
    title_num: {
      type: String,
    },
    val: {
      type: String,
    },
    val1: {
      type: String,
    },
    titleText: {
      type: String,
    },
    uploadDate: {
      type: String,
    },
    filmIDList: {
      type: String,
    },
    searchType: {
      type: String,
    },
  },
  data() {
    return {
      movieData: [],
      tableData: [],
      loading: false,
      select: "All Location",
      selectOptions: [
        { value: "All Location", text: "全部地區" },
        { value: "Hong Kong", text: "香港" },
        { value: "Macau", text: "澳門" },
      ],
      showHiddenMovie: true,
    }
  },
  computed: {
    movieFields() {
      return [
        { key: "film_title_name", label: "Movie Name" },
        // { key: 'film_effect', label: '' },
        {
          key: "up_to_date_bo",
          label: `Up to Date B.O. Until ${this.$moment(
            this.val
          ).format()} <span style="color:red">(UnOfficial !!)</span>`,
        },
        {
          key: "pre_adm_day_final",
          label: "Pre-Sale Adm. In Day Final",
        },
        {
          key: "pre_bo_day_final",
          label: "Pre-Sale B.O. In Day Final",
        },
        {
          key: "h23_adm_hourly_xml",
          label: "H23 Adm. In hourly XML",
        },
        {
          key: "h23_bo_hourly_xml",
          label: "H23 B.O. In hourly XML",
        },
        {
          key: "daily_total_adm",
          label: `Daily Total Adm.<span style="color:red">(UnOfficial !)</span>`,
        },
        {
          key: "daily_total_bo",
          label: `Daily Total B.O.<span style="color:red">(UnOfficial !)</span>`,
        },
        { key: "h23_theatre_count", label: "H23 Theatre" },
        { key: "h23_house_count", label: "H23 House" },
        { key: "h23_shows_count", label: "H23 Shows" },
      ]
    },
    tableFields() {
      return [
        { key: "theatre", label: "Theatre Name", sortable: true },
        { key: "date_upload_ref", label: "Date Upload Ref.", sortable: true },
        {
          key: "pre_adm_day_final",
          label: "Pre-Sale Adm. In Day Final",
          sortable: true,
        },
        {
          key: "pre_bo_day_final",
          label: "Pre-Sale B.O. In Day Final",
          sortable: true,
        },
        {
          key: "h23_adm_hourly_xml",
          label: "H23 Adm. In hourly XML",
          sortable: true,
        },
        {
          key: "h23_bo_hourly_xml",
          label: "H23 B.O. In hourly XML",
          sortable: true,
        },
        { key: "total_adm", label: "Total Adm.", sortable: true },
        { key: "total_bo", label: "Total B.O.", sortable: true },
      ]
    },
  },
  methods: {
    checkShow() {
      this.isShowAllFilm = !this.isShowAllFilm
      this.getUncertainRecord()
    },
    //get 16.8 data
    getUncertainRecord() {
      this.loading = true
      const data = {
        date: this.val,
        without_gala_bo: this.title_num === "16.8" ? 0 : 1,
        without_hidden: !this.isShowAllFilm,
      }
      console.log(data)
      getUncertainRecord(data).then((res) => {
        console.log(res)
        this.tableData = res.data.theatreTable
        this.tableData.map((el) => {
          el.total_adm =
            Number(el.pre_adm_day_final) + Number(el.h23_adm_hourly_xml)
          el.total_bo =
            Number(el.pre_bo_day_final) + Number(el.h23_bo_hourly_xml)
        })
        const list = []
        const movieList = res.data.movieTable
        console.log(movieList)
        movieList.map((el) => {
          console.log(el)
          el.film_effect = ""
          let isreleaseRound = ""
          if (
            new Date(el[0].film_date_release) > new Date(el[0].film_date_on)
          ) {
            if (
              new Date(el[0].film_date_on) <= new Date(this.val) &&
              new Date(this.val) < new Date(el[0].film_date_release)
            ) {
              isreleaseRound = `<span style="color:red">(優先場)</span>`
            }
          }
          if (el.length === 1) {
            el[0]._rowVariant = "warning"
            const releaseRound =
              new Date(el[0].film_date_release) > new Date(el[0].film_date_on)
                ? `<span style="color:red">(優先場)</span>`
                : ""
            const remark = el[0].film_remark_2
              ? `${el[0].film_remark_1} (${el[0].film_remark_2})`
              : el[0].film_remark_1
            el[0].film_title_name = `${el[0].film_title_name}${isreleaseRound} <br/> <div style="font-size:10px">${remark} ${el[0].film_language}  [${el[0].film_run_ver}]</div>`
            list.push(el[0])
          } else {
            const totalList = new Object()
            totalList.film_title_name = `${el[0].film_title_name}${isreleaseRound}`
            totalList.film_date_release = el[0].film_date_release
            totalList._rowVariant = "warning"
            totalList.up_to_date_bo = el.reduce(
              (sum, item) => sum + Number(item.up_to_date_bo),
              0
            )
            totalList.pre_adm_day_final = el.reduce(
              (sum, item) => sum + Number(item.pre_adm_day_final),
              0
            )
            totalList.pre_bo_day_final = el.reduce(
              (sum, item) => sum + Number(item.pre_bo_day_final),
              0
            )
            totalList.h23_adm_hourly_xml = el.reduce(
              (sum, item) => sum + Number(item.h23_adm_hourly_xml),
              0
            )
            totalList.h23_bo_hourly_xml = el.reduce(
              (sum, item) => sum + Number(item.h23_bo_hourly_xml),
              0
            )
            totalList.adm_final_xml = el.reduce(
              (sum, item) => sum + Number(item.adm_final_xml),
              0
            )
            totalList.bo_final_xml = el.reduce(
              (sum, item) => sum + Number(item.bo_final_xml),
              0
            )
            totalList.daily_total_adm = el.reduce(
              (sum, item) => sum + Number(item.daily_total_adm),
              0
            )
            totalList.daily_total_bo = el.reduce(
              (sum, item) => sum + Number(item.daily_total_bo),
              0
            )
            totalList.h23_theatre_count = el.reduce(
              (sum, item) => sum + Number(item.h23_theatre_count),
              0
            )
            totalList.h23_house_count = el.reduce(
              (sum, item) => sum + Number(item.h23_house_count),
              0
            )
            totalList.h23_shows_count = el.reduce(
              (sum, item) => sum + Number(item.h23_shows_count),
              0
            )
            list.push(totalList)
            el.map((li) => {
              const effect = li.film_remark_2
                ? `${li.film_remark_1} (${li.film_remark_2})`
                : li.film_remark_1
              li.film_title_name = `${effect} ${li.film_language} [${li.film_run_ver}]`

              list.push(li)
            })
          }
        })
        this.movieData = list
        this.loading = false
      })
    },
  },
  mounted() {
    this.getUncertainRecord()
  },
}
</script>
<style lang="scss">
.uncertan-page {
  .b-table {
    thead {
      tr {
        th {
          div {
            white-space: normal;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.sec-title {
  color: #0e72bc;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid #0e72bc;
}
.w300 {
  width: 250px;
}
</style>
