import request from '@/utils/request'
export function getOccupancyByFilm (params) {
  return request({
    url: '/api/box-offices/occupancy-by-film',
    method: 'GET',
    params
  })
}
export function getOccupancyByHouse (params) {
  return request({
    url: '/api/box-offices/occupancy-by-house',
    method: 'GET',
    params
  })
}
export function getOccupancyByTheatre (params) {
  return request({
    url: '/api/box-offices/occupancy-by-theatre',
    method: 'GET',
    params
  })
}

export function selectShowFilms (params) {
  return request({
    url: '/api/box-offices/show-films',
    method: 'GET',
    params
  })
}
export function getAreas () {
  return request({
    url: '/api/areas',
    method: 'GET',
    
  })
}
export function getCircuitTheatres (params) {
  return request({
    url: '/api/box-offices/circuit-theatres',
    method: 'GET',
    params
  })
}
export function getFilmPengding () {
  return request({
    url: '/api/films/showing-pending',
    method: 'GET'
  })
}
export function getFilmListOfFilm (params) {
  return request({
    url: '/api/film-master/key-search',
    method: 'GET',
    params
  })
}
