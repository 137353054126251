<template>
  <div>
    <b-card>
      <div class="title">
        <p>22.1 B.O. Report For the year ( By Version )</p>
      </div>
      <div class="m-2">
        <b-row>
          <b-col
            cols="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button size="sm" variant="outline-primary" @click="goBackReport">
              回目錄
            </b-button>
          </b-col>
        </b-row>
        <div v-if="!hasArea || hasArea === 'Hong Kong'">
          <b-row class="my-1">
            <p class="sub-title">香港</p>
            <b-button
              size="sm m-1"
              variant="outline-primary"
              @click="godetails('Hong Kong')"
            >
              Details
            </b-button>
          </b-row>
          <b-row>
            <b-table
              bordered
              v-loading="loading"
              striped
              hover
              responsive
              :items="items['HongKong']"
              :fields="$store.state.highlightCol.firTable ? $store.state.newTableFields : fields"
          @head-clicked="
            commonHighLightCol(
              $event,
              fields,
              $store.state.newTableFields,
              'firTable'
            )
          "
            >
              >
            </b-table>
          </b-row>
        </div>
        <div v-if="!hasArea || hasArea === 'macau'">
          <b-row class="my-1">
            <p class="sub-title">澳門</p>
            <b-button
              size="sm m-1"
              variant="outline-primary"
              @click="godetails('Macau')"
            >
              Details
            </b-button>
          </b-row>
          <b-row>
            <b-table
              bordered
              v-loading="loading"
              striped
              hover
              responsive
              :items="items['Macau']"
              :fields="$store.state.highlightCol.firTable ? $store.state.newTableFields : fields"
          @head-clicked="
            commonHighLightCol(
              $event,
              fields,
              $store.state.newTableFields,
              'firTable'
            )
          "
            >
              >
            </b-table>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import { getReportYear } from '@/api/specialRecord'
export default {
  components: {},
  data() {
    return {
      hasArea: window.sessionStorage.getItem('area'),
      loading: false,
      year: '',
      fields: [
        {
          key: 'year',
          label: `${this.$route.query.data}`
        },
        {
          key: 'total',
          label: 'Total'
        },
        {
          key: 'hk',
          label: 'Hong Kong'
        },
        {
          key: 'non',
          label: 'Non Hong Kong'
        }
      ],
      items: {
        Macau: [],
        HongKong: []
      }
    }
  },
  methods: {
    goBackReport() {
      this.$router.push('/dashboard/special-film-record')
    },
    godetails(type) {
      this.$router.push({
        name: 'report-year-version-detail',
        query: { date: this.year, area: type }
      })
    },
    getReport() {
      this.loading = true
      const area = this.hasArea ? this.hasArea : ''
      const data = `year=${this.year}&type=version&area=${area}`

      getReportYear(data).then((res) => {
        const itemstemp = res.data
        const list = {
          Macau: [],
          HongKong: []
        }
        const keysTitle = {
          no_of_released: 'No. of Films released',
          no_of_3D: 'No. of Films of 3D visual Effect',
          no_of_imax: 'No. of Films of iMAX Visual Effect',
          bo_of_run: 'Total B.O. of films of Run 1',
          bo_of_thea: 'Total B.O. of theatres'
        }
        if (!area) {
          Object.keys(itemstemp).map((keys) => {
            Object.keys(itemstemp[keys]).map((el) => {
              console.log(itemstemp[keys][el].non)
              if (el === 'bo_of_run' || el === 'bo_of_thea') {
                list[keys].push({
                  year: keysTitle[el],
                  total:
                    Number(itemstemp[keys][el].hk) +
                    Number(itemstemp[keys][el].non)
                      ? `HKD$ ${parseFloat(
                        Number(itemstemp[keys][el].hk) +
                            Number(itemstemp[keys][el].non)
                      ).toLocaleString()}`
                      : '-',
                  hk: itemstemp[keys][el].hk
                    ? `HKD$ ${parseFloat(
                      Number(itemstemp[keys][el].hk)
                    ).toLocaleString()}`
                    : '-',
                  non: itemstemp[keys][el].non
                    ? `HKD$ ${parseFloat(
                      Number(itemstemp[keys][el].non)
                    ).toLocaleString()}`
                    : '-'
                })
              } else {
                list[keys].push({
                  year: keysTitle[el],
                  total:
                    Number(itemstemp[keys][el].hk) +
                    Number(itemstemp[keys][el].non)
                      ? parseFloat(
                        Number(itemstemp[keys][el].hk) +
                            Number(itemstemp[keys][el].non)
                      ).toLocaleString()
                      : '-',
                  hk: itemstemp[keys][el].hk
                    ? parseFloat(
                      Number(itemstemp[keys][el].hk)
                    ).toLocaleString()
                    : '-',
                  non: itemstemp[keys][el].non
                    ? parseFloat(
                      Number(itemstemp[keys][el].non)
                    ).toLocaleString()
                    : '-'
                })
              }
            })
          })
        } else {
          let isArea = ''
          if (area === 'Hong Kong') {
            isArea = 'HongKong'
          } else if (area === 'macau') {
            isArea = 'Macau'
          }
          Object.keys(itemstemp).map((keys) => {
            if (keys === 'bo_of_run' || keys === 'bo_of_thea') {
              list[isArea].push({
                year: keysTitle[keys],
                total:
                  Number(itemstemp[keys].hk) + Number(itemstemp[keys].non)
                    ? `HKD$ ${parseFloat(
                      Number(itemstemp[keys].hk) + Number(itemstemp[keys].non)
                    ).toLocaleString()}`
                    : '-',
                hk: itemstemp[keys].hk
                  ? `HKD$ ${parseFloat(
                    Number(itemstemp[keys].hk)
                  ).toLocaleString()}`
                  : '-',
                non: itemstemp[keys].non
                  ? `HKD$ ${parseFloat(
                    Number(itemstemp[keys].non)
                  ).toLocaleString()}`
                  : '-'
              })
            } else {
              list[isArea].push({
                year: keysTitle[keys],
                total:
                  Number(itemstemp[keys].hk) + Number(itemstemp[keys].non)
                    ? parseFloat(
                      Number(itemstemp[keys].hk) + Number(itemstemp[keys].non)
                    ).toLocaleString()
                    : '-',
                hk: itemstemp[keys].hk
                  ? parseFloat(Number(itemstemp[keys].hk)).toLocaleString()
                  : '-',
                non: itemstemp[keys].non
                  ? parseFloat(Number(itemstemp[keys].non)).toLocaleString()
                  : '-'
              })
            }
          })
        }

        this.items = list
        console.log(list)
        this.loading = false
      })
    }
  },
  mounted() {},
  created() {
    // this.fields[0].label = this.$route.query.data;
    this.year = this.$route.query.data
    if (this.year) {
      this.getReport()
    }
  }
}
</script>

<style lang="scss" scoped>
.line {
  height: 1.5px;
  background: #0e72bc;
  width: 100%;
  margin: 20px 0;
}
.sub-title {
  color: #0e72bc;
  width: auto;
  font-size: 16px;
  font-weight: 500;
  margin: 20px 0;
}
</style>
