var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('subTitle',{attrs:{"info":_vm.info,"title":_vm.title,"date":_vm.queryVal.date,"type":_vm.routeType}}),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('b-table',{attrs:{"bordered":"","striped":"","hover":"","responsive":"","items":_vm.tableData,"fields":_vm.$store.state.highlightCol.firTable
            ? _vm.$store.state.newTableFields
            : _vm.tableFields},on:{"head-clicked":function($event){return _vm.commonHighLightCol(
            $event,
            _vm.tableFields,
            _vm.$store.state.newTableFields,
            'firTable'
          )}},scopedSlots:_vm._u([{key:"thead-top",fn:function(data){return [_c('b-tr',[_c('b-th',{attrs:{"colspan":"5"}}),_c('b-th',[_vm._v(" total SHOWS :"+_vm._s(parseFloat(_vm.tableTotal.showings).toLocaleString()))]),_c('b-th',[_vm._v(" total ADM :"+_vm._s(parseFloat(_vm.tableTotal["TA"]).toLocaleString()))]),_c('b-th',[_vm._v(" total B.O. :"+_vm._s(parseFloat(_vm.tableTotal["TG"]).toLocaleString()))]),_c('b-th')],1)]}},{key:"cell(TA)",fn:function(data){return [_c('div',[_vm._v(" "+_vm._s(parseFloat(data.item.TA).toLocaleString())+" ")])]}},{key:"cell(TG)",fn:function(data){return [_c('div',[_vm._v(" "+_vm._s(parseFloat(data.item.TG).toLocaleString())+" ")])]}},{key:"cell(showings)",fn:function(data){return [_c('div',[_vm._v(" "+_vm._s(parseFloat(data.item.showings).toLocaleString())+" ")])]}},{key:"cell(Action)",fn:function(data){return [(data.item.show_time !== 'Total:')?_c('b-button',{staticStyle:{"width":"130px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.checkxml(
                data.item.film_id || '',
                data.item.theatre_id || '',
                data.item.show_time || ''
              )}}},[_vm._v(" 按放映時間列出 ")]):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }