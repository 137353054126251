<template>
  <section id="management-center">
    <b-card v-loading="textLoading">
      <h2 class="app-color">新增XML原始記錄檔</h2>
      <hr class="line" />
      <el-upload
        class="upload-demo"
        drag
        :action="`${env}/api/upload-xml`"
        name="xml_file"
        :headers="{
          Authorization: 'Bearer ' + loginCode,
          Accept: 'application/json'
        }"
        v-if="queryInfo.type === 'upload'"
        :on-error="uploadError"
        :on-success="uploadFileSuccess"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">將文件拖到此處，或<em>點擊上傳</em></div>
      </el-upload>
      <div v-if="queryInfo.type === 'copy'">
        <b-button @click="getLatestXml()"> 複製記錄 </b-button>
        <b-modal id="modal-center-copy" title="" hide-footer centered>
          <p class="my-4">
            確定複製Original XML Content [
            {{ xml_name }} ] - [XML_File_ID:{{ xml_id }}]-{{ xml_time }}檔案？
          </p>
          <div class="d-flex justify-content-end">
            <b-button
              size="sm"
              @click="$bvModal.hide('modal-center-copy')"
              class="mr-1"
            >
              取消
            </b-button>
            <b-button @click="uploadXML" variant="primary" size="sm">
              確定
            </b-button>
          </div>
        </b-modal>
        <b-modal
          size="lg"
          centered
          id="modal-xml-copy-text"
          title=""
          hide-footer
          class="copy-text-modal"
        >
          <xmlTextModalCom
            v-loading="xmlLoading"
            :xml-text.sync="xml_text"
            :theatre-id="$route.query.theatre_id"
            :theatre-no="$route.query.theatre_no"
          />

          <div class="d-flex justify-content-end copy-text-btn">
            <b-button
              size="sm"
              @click="$bvModal.hide('modal-xml-copy-text')"
              class="mr-1"
            >
              取消
            </b-button>
            <b-button @click="createXmlFinal()" variant="primary" size="sm">
              新增記錄
            </b-button>
          </div>
        </b-modal>
      </div>
    </b-card>
  </section>
</template>
<script>
import { BButton, BCard } from "bootstrap-vue";
import {
  uploadXML,
  getXmlFinal,
  getLatestXml,
  uploadXMLfile
} from "@/api/management";
import xmlTextModalCom from "./xmlTextModalCom.vue";
import { json } from "vkbeautify";

export default {
  components: {
    BCard,
    BButton,
    xmlTextModalCom
  },
  data() {
    return {
      loginCode: "",
      env: process.env.VUE_APP_BASE_API,
      xmlLoading: false,
      textLoading: false,
      fileData: "",
      queryInfo: "",
      fileList: [
        {
          name: "F-MCL-HW-2023-03-06.xml",
          url: "../../../public/F-MCL-HW-2023-03-06.xml"
        }
      ],
      xml_text: "",
      xml_name: "",
      xml_id: "",
      xml_time: ""
    };
  },
  methods: {
    uploadXml(file) {
      console.log(file);
    },
    getLatestXml() {
      this.textLoading = true;
      getLatestXml(this.$route.query.theatre_id, this.$route.query.date)
        .then((res) => {
          console.log(res);
          this.xml_text = res.xml_content;
          this.xml_name = res.xml_file_name;
          this.xml_id = res.xml_file_id;
          const list = this.xml_name.split("-");
          const time = list[list.length - 1].slice(0, 2);
          this.xml_time = time;
          this.textLoading = false;
          this.$bvModal.show("modal-center-copy");
        })
        .catch((error) => {
          this.textLoading = false;
        });
    },
    uploadFileSuccess(response) {
      console.log(response);
      if (response.result === "success") {
        this.$message({
          message: "文件上傳成功",
          type: "success"
        });
        this.$router.push({
          name: "original-record-file",
          query: { id: response.data.xml_id }
        });
      }
    },
    uploadError(error) {
      const { message } = error;
      const errorM = JSON.parse(message);
      console.log(error.exception, "response");
      if (error) {
        this.$message.error(errorM.message);
      }
    },
    // uploadFile(file) {
    //   console.log(file);
    //   if (file.status === "fail") {
    //     // this.$message.error("文件上傳失敗，請重新上傳");
    //   } else if (file.status === "success") {
    //   }
    // },
    uploadXML() {
      this.$bvModal.show("modal-xml-copy-text");
    },
    createXmlFinal() {
      this.xmlLoading = true;
      const data = this.xml_text;
      console.log(data);
      getXmlFinal(data)
        .then((res) => {
          console.log(res);
          this.xmlLoading = false;
          // this.$message({
          //   message: '文件新增成功',
          //   type: 'success'
          // })
          this.$router.push({
            name: "original-record-file",
            query: { id: res.data.xml_id }
          });
        })
        .catch((error) => {
          console.log(error);
          this.textLoading = false;
          // this.$message.error('文件新增失敗，請重新上傳')
        });
    }
  },
  created() {
    this.queryInfo = this.$route.query;
    this.loginCode = this.getCookie("hk_box_key");
  }
};
</script>
<style lang="scss">
.app-color {
  font-weight: bold;
  color: #0e72bc;
}
.line {
  height: 3px;
  color: #0e72bc;
}
.warning-color {
  color: red;
}
.handle-button {
  margin-right: 20px;
}
#modal-xml-copy-text {
  .xml-text {
    height: 650px !important;
    overflow-y: auto;
  }
}
</style>
<style lang="scss" scoped>
.date_of_report {
  color: red;
}
</style>
