var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-card',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"pre-sale"},[_c('div',{staticClass:"title"},[_c('p',[_vm._v(_vm._s(_vm.title))])]),_c('div',[_c('b-row',[_c('b-col',[_c('el-select',{staticClass:"per-page-selector d-inline-block mr-1",staticStyle:{"width":"30%"},attrs:{"clearable":"","filterable":"","placeholder":"請選擇戲院"},model:{value:(_vm.searchQuery.theatre),callback:function ($$v) {_vm.$set(_vm.searchQuery, "theatre", $$v)},expression:"searchQuery.theatre"}},_vm._l((_vm.selectTheatres),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.text,"value":item.value}})}),1),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.search()}}},[_vm._v(" Search ")])],1)],1),_c('b-row',[_c('b-col',[_c('p',{staticClass:"sub-title"},[_vm._v(" 由現在起查閱14天後的預售數：("+_vm._s(_vm.preSaleDate)+"後) ")])])],1)],1),_c('div',[_c('b-table',{attrs:{"bordered":"","hover":"","responsive":"","items":_vm.items,"fields":_vm.$store.state.highlightCol.firTable
            ? _vm.$store.state.newTableFields
            : _vm.fields},on:{"head-clicked":function($event){return _vm.commonHighLightCol(
            $event,
            _vm.fields,
            _vm.$store.state.newTableFields,
            'firTable'
          )}},scopedSlots:_vm._u([{key:"cell(theatre_name)",fn:function(data){return [_c('div',{staticStyle:{"width":"100px"}},[_vm._v(" "+_vm._s(data.item.theatre.theatre_name)+" ")])]}},{key:"cell(title_name)",fn:function(data){return [_c('div',{staticStyle:{"width":"150px"},on:{"click":function($event){return _vm.goPage(data.item, 'checkXML')}}},[_vm._v(" "+_vm._s(data.item.title_name)+" ")])]}},{key:"cell(show_time)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$moment(data.item.show_time).format("DD-MM-YYYY HH:mm:ss"))+" ")])]}},{key:"cell(date_of_engagement)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$moment(data.item.date_of_engagement).format())+" ")])]}},{key:"cell(total_gross)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(parseFloat(data.item.total_gross).toLocaleString())+" ")])]}},{key:"cell(total_admissions)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(parseFloat(data.item.total_admissions).toLocaleString())+" ")])]}},{key:"cell(date_to_report)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(data.item.date_to_report === "Invalid date" ? "-" : _vm.$moment(data.item.date_to_report).format( "DD-MM-YYYY HH:mm:ss" ))+" ")])]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }