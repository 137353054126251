<template>
  <section id="management-center">
    <b-card v-loading="loading">
      <h2 class="app-color" v-if="filmInfo.type !== 'search-not-film'">
        訂正電影記錄
      </h2>
      <h2 class="app-color" v-else>指派電影記錄</h2>
      <div style="border-bottom: 2px solid #0e72bc; margin-bottom: 20px"></div>
      <div>
        <b-button
          size="sm"
          variant="outline-secondary"
          class="handle-button"
          @click="back"
        >
          回上頁
        </b-button>
      </div>
      <hr class="is-line" />
      <div v-if="filmInfo.type !== 'search-not-film'">
        <b-row>
          <b-col cols="2"> 院線： </b-col>
          <b-col cols="10">{{ filmInfo.circuit }}</b-col>
          <b-col cols="2"> Circuit POS ID: </b-col>
          <b-col cols="2"> {{ $route.query.pos_id }} </b-col>
          <b-col cols="8">
            <b-button
              size="sm"
              variant="outline-secondary"
              class="handle-button mr-2"
              @click="goPage"
              >修改POS ID</b-button
            >
          </b-col>
          <b-col cols="2"> 院線提供之電影名稱: </b-col>
          <b-col cols="10">{{ filmInfo.title_name }}</b-col>
        </b-row>
        <div class="my-1">
          <b-button
            style="width: 182px"
            @click="toAddFilmRecord(filmInfo.title_name)"
            size="sm"
            >以此名字為藍本開設新紀錄</b-button
          >
        </div>
      </div>
      <div v-else v-loading="filmLoading">
        <b-row v-for="(item, index) in filmFields" :key="index">
          <b-col cols="2"> {{ item.label }}： </b-col>
          <b-col
            cols="10"
            :style="item.textColor ? `color:#0e72bc;font-weight:600` : ''"
            >{{ filmDetails[item.key] }}</b-col
          >
        </b-row>
        <hr class="is-line" />
        <CodeUseByCircuit
          :masterId="master_id"
          :filmId="filmInfo.id"
          :noEdit="true"
        />
      </div>
      <hr class="is-line" />
      <div>
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-input
              type="search"
              placeholder="Search For Film"
              v-model="filmInfo.search_name"
            ></b-form-input>
            <b-button variant="primary" class="ml-2" @click="Search">
              Search
            </b-button>
          </b-col>
        </b-row>
      </div>
      <hr class="is-line" />
      <p class="mb-1" v-if="items.length === 0 && unrecordedList.length === 0">
        No matching record on this name.
      </p>
      <div v-if="items && items.length > 0">
        <b style="color: #0e72bc">已在案的類似電影名稱</b>
        <div>
          <b-table
            bordered
            hover
            responsive
            :fields="
              $store.state.highlightCol.firTable
                ? $store.state.newTableFields
                : fields
            "
            @head-clicked="
              commonHighLightCol(
                $event,
                fields,
                $store.state.newTableFields,
                'firTable'
              )
            "
            :items="items"
          >
            <template v-slot:cell(effect)="rows">
              <span v-if="rows.item.remark_1">{{ rows.item.remark_1 }}</span>
              <span v-if="rows.item.remark_2">[{{ rows.item.remark_2 }}]</span>
              <span v-if="rows.item.remark_3">[{{ rows.item.remark_3 }}]</span>
            </template>
            <template v-slot:cell(date_off)="rows">
              <div class="text-nowrap">
                {{
                  isValidDate(
                    dateOffFormat(rows.item.date_off, rows.item.date_release)
                  )
                }}
              </div>
            </template>
            <template v-slot:cell(actions)="rows">
              <b-button
                size="sm"
                variant="outline-danger"
                class="handle-button"
                style="width: 236px"
                @click="setLinkToFilm(filmInfo, rows.item.id)"
              >
                把{{ $route.query.pos_id }} 連接至{{
                  rows.item.from_arch && rows.item.from_arch === 1 ? "" : "F-"
                }}{{ rows.item.id }}號電影版本
              </b-button>
            </template>
          </b-table>
        </div>
      </div>
      <div v-if="unrecordedList && unrecordedList.length > 0">
        <b class="mt-1" style="color: #0e72bc">未在案的類似電影名稱</b>
        <div>
          <b-table
            bordered
            hover
            responsive
            :fields="
              $store.state.highlightCol.secTable
                ? $store.state.newSecTableFields
                : unrecordedFields
            "
            @head-clicked="
              commonHighLightCol(
                $event,
                unrecordedFields,
                $store.state.newSecTableFields,
                'firTable'
              )
            "
            :items="unrecordedList"
          >
            <template v-slot:cell(actions)="rows">
              <b-button
                style="width: 182px"
                v-if="filmInfo.type !== 'search-not-film'"
                @click="toAddFilmRecord(rows.item.title_name)"
                size="sm"
                >以此名字為藍本開設新紀錄</b-button
              >
              <b-button
                v-else
                size="sm"
                variant="outline-danger"
                class="handle-button"
                style="width: 236px"
                @click="setLinkToFilm(filmInfo, rows.item.id)"
              >
                把{{ rows.item.pos_id }} 連接至{{
                  rows.item.from_arch && rows.item.from_arch === 1 ? "" : "F-"
                }}{{ filmInfo.id }}號電影版本
              </b-button>
            </template>
          </b-table>
        </div>
      </div>

      <div
        class="d-flex justify-content-end mt-1"
        v-if="filmInfo.type !== 'search-not-film'"
      >
        <div>
          <p>
            <b-button
              size="sm"
              variant="outline-secondary"
              @click="goPage"
              v-if="isADD"
              style="width: 120px"
            >
              新增POS ID
            </b-button>
          </p>
          <p>
            <b-button
              style="width: 120px"
              size="sm"
              @click="deletePosId"
              variant="outline-primary"
              >刪除此電影記錄</b-button
            >
          </p>
        </div>
      </div>
      <div class="d-flex justify-content-start mt-1" v-else>
        <b-button size="sm" variant="outline-secondary" @click="$router.go(-1)">
          回到電影記錄管理
        </b-button>
      </div>
    </b-card>
  </section>
</template>
<script>
import {
  getFilmByPosid,
  setLinkToFilm,
  getTheSameFilmName,
  getUnrecordedFilmName,
  deletePosId,
} from "@/api/management.js"
import { getFilmDetail } from "@/api/film-material-management/film"
import CodeUseByCircuit from "./component/codeUseByCircuit.vue"
export default {
  components: { CodeUseByCircuit },
  data() {
    return {
      filmLoading: false,
      master_id: "",
      loading: false,
      isADD: false,
      filmDetails: {},
      filmFields: [
        { label: "Film Mater ID", key: "master_id", textColor: true },
        { label: "Film ID", key: "id", textColor: true },
        { label: "Film Name(in English)", key: "name_en", textColor: true },
        { label: "電影名稱(中文名)", key: "name_cn", textColor: true },
        { label: "Distributor", key: "distributor" },
        { label: "出產年份(Year)", key: "ver_year" },
        { label: "Visual Effect", key: "remark", textColor: true },
        { label: "音軌語言", key: "language" },
        { label: "字幕語言", key: "sub_title" },
      ],
      fields: [
        { key: "master_id", label: "Film Master id", sortable: true },
        { key: "id", label: "Film ID", sortable: true },
        { key: "title_name", label: "Recorded Of Film Name", sortable: true },
        {
          key: "effect",
          label: "Effect",
          sortable: true,
        },
        { key: "format", label: "Format", sortable: true },
        { key: "language", label: "Lang.", sortable: true },
        { key: "run_ver.run_ver", label: "Ver.", sortable: true },
        { key: "date_off", label: "Date Off", sortable: true },
        { key: "actions", label: "" },
      ],
      unrecordedList: [],
      unrecordedFields: [
        { key: "title_name", label: "院線提供之電影名稱", sortable: true },
        { key: "pos_id", label: "Circuit POS ID", sortable: true },
        { key: "circuit_name", label: "院線", sortable: true },
        { key: "actions", label: "" },
      ],
      items: [],
      pos_id: "",
      filmInfo: {
        circuit: "",
        pos_id: "",
        title_name: "",
        full_name: "",
      },
    }
  },
  methods: {
    deletePosId() {
      this.loading = true
      const id = this.filmInfo.film_circuit_id
        ? this.filmInfo.film_circuit_id
        : this.filmInfo.id
      deletePosId(id).then((res) => {
        this.$router.go(-1)
      })
    },
    getFilmDetail() {
      this.filmLoading = true
      getFilmDetail(this.filmInfo.id)
        .then((res) => {
          console.log(res, "res")
          this.filmDetails = res.data
          this.filmDetails.name_en = res.data.film_master.name_english
          this.filmDetails.name_cn = res.data.film_master.name_chinese
          this.filmDetails.remark = res.data.remark_2
            ? `${res.data.remark_1}[${res.data.remark_2}]`
            : res.data.remark_1
          const distributorList = []
          if (
            res.data.film_master.distributor_code_and_detail &&
            res.data.film_master.distributor_code_and_detail.length > 0
          ) {
            res.data.film_master.distributor_code_and_detail.map((el) => {
              distributorList.push(
                `${el.distributor_name_en} ${el.distributor_name_zh_hk}`
              )
            })
          }

          this.filmDetails.distributor =
            distributorList && distributorList.length > 0
              ? distributorList.join(", ")
              : ""
          this.filmLoading = false
        })
        .catch((error) => {
          this.filmLoading = false
          console.log(error)
        })
    },
    Search() {
      this.getUnrecordedFilmName()
      if (this.filmInfo.type !== "search-not-film") {
        this.getTheSameFilmName()
      }
    },
    toAddFilmRecord(val) {
      this.$router.push({
        name: "master-record",
        query: { type: "create", film_name: val },
      })
    },
    setLinkToFilm(filmInfo, film_id) {
      console.log(filmInfo, "filmInfo")

      this.loading = true
      // let id = ""
      // if (filmInfo.type === "abnormal") {
      //   id = filmInfo.film_circuit_id
      // } else if (filmInfo.type === "notfound") {
      //   id = filmInfo.id
      // } else {
      //   id = filmInfo
      // }
      const data = {
        pos_id: this.$route.query.pos_id,
        link_to_film_id: film_id,
        film_id: filmInfo.film_id,
        circuit_id: filmInfo.circuit_id,
      }
      setLinkToFilm(data).then((res) => {
        this.$router.push(this.filmInfo.goPage)
        console.log(res)
      })
    },
    getUnrecordedFilmName() {
      //未在案的類似電影名稱 Unrecorded film title
      const data = {
        pos_id: this.$route.query.pos_id,
        search: this.filmInfo.search_name,
      }
      getUnrecordedFilmName(data).then((res) => {
        this.unrecordedList = res.data
      })
    },
    getTheSameFilmName() {
      //已在案的類似電影名稱 recorded film title
      //circuit_id, pos_id, search
      this.loading = true
      getTheSameFilmName(
        this.filmInfo.circuit_id,
        this.$route.query.pos_id,
        this.filmInfo.search_name
      )
        .then((res) => {
          console.log(res)
          this.items = res.data
          this.items.map((el) => {
            el.language = el.language || "-"
            el.format = el.format || "-"
          })
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    editID() {
      this.isADD = true
    },
    goPage() {
      this.$router.push({
        name: "revise-record-save",
        query: {
          pos_id: this.$route.query.pos_id,
          id: this.$route.query.id,
          circuit_id: this.filmInfo.circuit_id,
        },
      })
    },
    back() {
      this.$router.push({ path: this.filmInfo.goPage }) //返回上一层
    },
  },
  created() {
    this.master_id = this.$route.query.master_id
    const filmInfo = JSON.parse(sessionStorage.getItem("filmInfo"))
    console.log(filmInfo)
    this.filmInfo = filmInfo
    if (this.filmInfo.type !== "search-not-film") {
      this.getTheSameFilmName()
    } else {
      this.getFilmDetail()
    }
    this.getUnrecordedFilmName()
  },
}
</script>
<style lang="scss" scope="scoped">
.app-color {
  font-weight: 500;
  color: #0e72bc;
  font-size: 20px;
}
.is-line {
  height: 1px;
}
.lh30 {
  width: 250px;
}
.table-scroll {
  white-space: nowrap;
}
.font-color {
  color: blue;
}
.btn-group {
  .btn {
    margin-top: 10px;
    border-radius: 5px !important;
  }
}
</style>
