<template>
  <section>
    <b-card v-loading="loading">
      <div class="title">
        <p>{{ title }}</p>
      </div>
      <div>
        <b-row>
          <b-col>
            <b style="color: red">過往負值/0元人次票房紀錄</b>
            <p>顯示最近500條記錄</p>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-table
          bordered
          hover
          responsive
          :items="items"
          :fields="
            $store.state.highlightCol.firTable
              ? $store.state.newTableFields
              : fields
          "
          @head-clicked="
            commonHighLightCol(
              $event,
              fields,
              $store.state.newTableFields,
              'firTable'
            )
          "
        >
          <template #cell(date_of_engagement)="data">
            <div class="text-nowrap">
              {{ $moment(data.item.date_of_engagement).format() }}
            </div>
          </template>
          <template #cell(show_time)="data">
            <div class="text-nowrap">
              {{ $moment(data.item.show_time).format("DD-MM-YYYY HH:mm:ss") }}
            </div>
          </template>
          <template #cell(total_gross)="data">
            <div class="text-nowrap">
              {{ parseFloat(data.item.total_gross).toLocaleString() }}
            </div>
          </template>
        </b-table>
      </div>
    </b-card>
  </section>
</template>
<script>
import { getBeforeEng } from '@/api/record'

export default {
  components: {},

  data() {
    return {
      loading: false,
      perPage: 10,
      filter: null,
      title: '16.3 查閱 調整及修改類記錄',
      filterOn: [],
      selectValue: '全部地區',
      selectOptions: ['全部地區', '香港', '非香港'],
      searchQuery: {
        theatre: '',
        location: '全部地區'
      },
      location: '',
      theatre_id: '',
      selectTheatres: [],
      // date: new Date().toISOString().split('T')[0],
      date: '',
      fields: [
        { key: 'date_of_engagement', label: 'Upload Date', sortable: true },
        { key: 'theatre.full_name', label: 'Theatre', sortable: true },
        { key: 'show_time', label: 'Show Time', sortable: true },
        { key: 'film.title_name', label: 'Film Name', sortable: true },
        { key: 'screen_id', label: 'Screen Id', sortable: true },
        { key: 'total_admissions', label: 'Total ADM.', sortable: true },
        { key: 'total_gross', label: 'Total B.O.', sortable: true }
      ],
      addButtonTitle: '',
      editButtonTitle: '',
      items: [],
      currentPage: 1,
      pageTotal: 3,
      rows: 100
    }
  },
  computed: {
    dataListStart() {
      if (this.rows === 5) {
        return (this.currentPage - 1) * 5 + 1
      } else {
        return (this.currentPage - 1) * 2 * (this.rows / 2) + 1
      }
    },
    dataListStop() {
      if (this.currentPage === Math.ceil(this.pageTotal / this.rows)) {
        return this.pageTotal
      } else {
        return this.rows * this.currentPage
      }
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    // this.date = this.$route.query.date
    this.getBeforeEng()
  },
  methods: {
    getBeforeEng() {
      this.loading = true
      getBeforeEng(this.$route.query.id, this.currentPage, 500).then((res) => {
        this.items = res.data
        this.loading = false
      })
    },
    handleSizeChange(val) {
      this.rows = val
      this.getPreSale()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getPreSale()
    },
    handleSearch() {
      this.currentPage = 1
      this.getPreSale()
    },
    handleLocationChange() {
      let areaFilter = ''
      if (this.searchQuery.location === '香港') {
        areaFilter = 'Hong Kong'
      } else if (this.searchQuery.location === '非香港') {
        areaFilter = 'Non Hong Kong'
      } else {
        areaFilter = ''
      }
      this.location = areaFilter
    },
    handleTheatreChange() {
      this.theatre_id = this.searchQuery.theatre
      console.log(this.searchQuery)
    },
    search() {}
  }
}
</script>
<style lang="scss">
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.search {
  display: flex;
  margin: 15px 0;
}
.table-name:hover {
  text-decoration: underline;
}
</style>
