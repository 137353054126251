<template>
  <div>
    <tableCom
      v-loading="loading"
      :items="items"
      :fields="fields"
      :title="title"
      :goPath="goPath"
      @search="search"
    />
  </div>
</template>

<script>
import tableCom from './component/tableCom.vue'
import { getNews } from '@/api/pressRelease'

export default {
  components: { tableCom },
  data() {
    return {
      title: '新聞發佈',
      goPath: 'pressRelease-detail',
      loading: false,
      selectYear: '',
      items: [],
      fields: [
        {
          key: 'id',
          label: 'E_ID',
          sortable: true
        },
        {
          key: 'date',
          label: 'Date',
          sortable: true
        },
        {
          key: 'title',
          label: 'Title',
          sortable: true
        },
        {
          key: 'action',
          label: 'Action'
        }
      ]
    }
  },
  mounted() {
    this.getNews()
  },
  methods: {
    search(val) {
      this.selectYear = val
      this.getNews()
    },
    getNews() {
      this.loading = true
      const data = {
        year: this.selectYear
      }
      getNews(data)
        .then((res) => {
          console.log(res)
          this.items = res.data.data
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    }
  }
}
</script>

<style></style>
