<template>
  <MovieGenreForm
    :title="title"
    :type="type"
  />
</template>
<script>
import MovieGenreForm from '../components/movieGenreForm.vue'

export default {
  components: {
    MovieGenreForm,
  },
  data() {
    return {
      title: '片種',
      type: 'create',
    }
  },
}
</script>
