<template>
  <ReportTable
    v-loading="loading"
    :type="type"
    :title="title"
    :fields="fields"
    :items="items"
    :pageTotal="pageTotal"
    :currentPage="currentPage"
    :rows="rows"
    :path="path"
    :showTitleText="true"
    :titleVal="titleVal"
    :placeholderVal="placeholderVal"
    :showSearchInput="true"
    :showExportText.sync="showExportText"
    @searchChange="searchChange"
    @exportToExcel="excelExport"
    @handleSearch="getReport()"
  />
</template>
<script>
import ReportTable from "./components/reportTable.vue";
import { getReportYear, getYearExport } from "@/api/specialRecord";
// import XLSX from 'xlsx'
import XLSX from "xlsx-js-style";
export default {
  name: "ReportProductionCompany",
  components: {
    ReportTable
  },
  data() {
    return {
      showExportText:false,
      titleVal: {
        year: "",
        num_film: "",
        bo_total: ""
      },
      placeholderVal: "Search Country Name",
      searchVal: "",
      loading: false,
      type: "version",
      title: "22.2 B.O. Report For the year ( By Production Country )",
      currentPage: 1,
      selectDropdown: false,
      path: "special-film-record",
      year: "",
      location: "",
      fields: [
        {
          key: "source",
          label: "2021",
          sortable: true
        },
        {
          key: "filmno",
          label: "No. of Films",
          sortable: true
        },
        {
          key: "filmno3D",
          label: "No. of 3D Films",
          sortable: true
        },
        {
          key: "filmnoIMAX",
          label: "No. of IMAX Films",
          sortable: true
        },
        {
          key: "box_office",
          label: "Production Country B.O.",
          sortable: true
        }
      ],
      items: [],
      pageTotal: 10,
      perPage: 5,
      rows: 5,
      search: "",
      exportData: []
    };
  },
  methods: {
    exportExcel() {
      console.log(this.exportData);
      //tabel list
      const header = [
        "Title(Eng.)",
        "Title(Chi.)",
        "Production Country",
        "Version",
        "Date On",
        "Date Release",
        "Date Off",
        "Days",
        "Version B.O.",
        "Accom. B.O."
      ];
      const dataList = [];
      this.exportData.map((item) => {
        const list = [];
        list.push(
          item.title_eng,
          item.title_chi,
          item.country,
          item.version,
          this.excelDateFormat(item.date_on),
          this.excelDateFormat(item.date_release),
          this.dateOffFormat(item.date_off, item.date_release),
          Number(item.days),
          Number(item.version_bo),
          Number(item.accum_bo)
        );
        dataList.push(list);
      });
      dataList.unshift(header);
      console.log(dataList);
      const numList = [
        { title: "H", index: 2 },
        { title: "I", index: 2 },
        { title: "J", index: 2 }
      ];
      const excelInfo = {
        type: "22.2",
        merge: false,
        setDateValue: false,
        data: this.exportData,
        dateCol: [],
        fileName: `B.O.Movie_Report-${this.year}.xlsx`,
        header,
        excelData: dataList,
        numFmt: true,
        numFmtList: numList,
        hasTotal: false
      };
      return excelInfo;
    },
    searchChange(val) {
      this.searchVal = val;
    },
    getReport() {
      this.loading = true;
      const data = `year=${this.year}&type=country&country=${this.searchVal}`;
      getReportYear(data).then((res) => {
        this.pageTotal = 0;
        const arr = [];
        this.items = arr;
        Object.keys(res.data).map((el) => {
          const obj = new Object();
          obj.source = res.data[el].country;
          obj.filmno = res.data[el].num_of_film;
          obj.filmno3D = res.data[el].num_of_3D_film;
          obj.filmnoIMAX = res.data[el].num_of_imax_film;
          obj.box_office = res.data[el].box_office;
          arr.push(obj);
        });

        this.items = arr;
        this.titleVal = {
          year: this.year,
          num_film: this.items.reduce((sum, item) => sum + item.filmno, 0),
          bo_total: parseFloat(
            this.items.reduce((sum, item) => sum + Number(item.box_office), 0)
          ).toLocaleString()
        };
        console.log(this.titleVal);
        this.loading = false;
      });
    }
  },
  mounted() {
    if (this.year) {
      this.getReport();
    }
  },
  created() {
    this.fields[0].label = this.$route.query.data.toString();
    this.year = this.$route.query.data;
  }
};
</script>
