<template>
  <div>
    <b-card
      ><div class="title">
        <p>人手輸入票房記錄</p>
      </div>
      <div>
        <DetailBoxofficeRcord />
      </div>
      <b-button
        v-show="!showAdd"
        class="mt-1"
        variant="outline-primary"
        @click="showAdd = true"
        >增加一個人手輸入票房記錄</b-button
      >
      <AddBoxofficeRecord v-show="showAdd" />
    </b-card>
  </div>
</template>

<script>
import AddBoxofficeRecord from "./addBoxofficeRecord.vue";
import DetailBoxofficeRcord from "./detailBoxofficeRecord.vue";

export default {
  name: "",
  data() {
    return { showAdd: false };
  },
  components: { AddBoxofficeRecord, DetailBoxofficeRcord },
  created() {},
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped></style>
