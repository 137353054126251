export default [
  {
    title: "管理中心",
    icon: "HomeIcon",
    route: "management-center"
  },
  // {
  //   title: '異常及未有記錄電影',
  //   icon: 'FilmIcon',
  //   route: 'unusual-film-record'
  // },
  // {
  //   title: '查閱14日後預售數',
  //   icon: 'FilmIcon',
  //   route: 'pre-sale',
  // },
  {
    title: "電影資料管理",
    icon: "FilmIcon",
    route: "data-management",
    children: [
      // {
      //   title: '電影記錄(add)',
      //   route: 'master-record',
      // },
      {
        title: "電影記錄",
        route: "film-record"
      },
      {
        title: "訂正票房資料庫記錄",
        route: "revise-record"
      },
      {
        title: "電影發行商",
        route: "film-distributor"
      },
      {
        title: "電影出品公司資料",
        route: "production-company"
      },
      {
        title: "電影片種資料",
        route: "genre"
      },
      {
        title: "導演資料",
        route: "director"
      },
      {
        title: "演員資料",
        route: "actor"
      },
      {
        title: "電影監製資料",
        route: "producer"
      },
      {
        title: "編劇資料",
        route: "scriptwriter"
      }
    ]
  },
  {
    title: "院線/戲院管理",
    icon: "FilmIcon",
    route: "theater-management",
    children: [
      {
        title: "入座率資料",
        route: "attendance-data"
      },
      {
        title: "院線資料管理",
        route: "theater-data"
      },
      {
        title: "戲院資料管理",
        route: "theatre"
      }
    ]
  },
  {
    title: "票房記錄管理",
    icon: "FileTextIcon",
    children: [
      {
        title: "人手鍵入票房記錄",
        route: "hand-type"
      },
      {
        title: "分類查閱上載記錄",
        route: "check-upload-record"
      },
      {
        title: "查閱XML上傳檔",
        route: "check-xml-upload"
      }
    ]
  },
  {
    title: "管理屬性選項",
    route: "admin-properties-options",
    icon: "UserIcon"
  },
  {
    title: "管理用戶",
    route: "manage-users",
    icon: "UserIcon"
  },
  {
    title: "修改票房記錄",
    route: "edit-film-record",
    icon: "Edit3Icon"
  },
  {
    title: "特別電影記錄",
    route: "special-film-record",
    icon: "InfoIcon"
  },
  {
    title: "最新消息",
    route: "latestNews",
    icon: "BellIcon"
  },
  {
    title: "新聞發佈",
    route: "pressRelease",
    icon: "GlobeIcon"
  },
  {
    title: "舊電影票房數據",
    route: "searchFilmName",
    icon: "FilmIcon"
  }
];
