import request from "@/utils/request";

export function getOldFilm(data) {
  return request({
    url: `/api/archived-film/search/${data}`,
    method: "GET"
  });
}

export function getOldFilmDetails(id) {
  return request({
    url: `/api/archived-film/detail/${id}`,
    method: "GET"
  });
}

export function editOldFilm(data) {
  return request({
    url: `/api/archived-film/edit`,
    method: "PUT",
    data
  });
}
