<template>
  <div>
    <b-card>
      <subTitle
        :info="info"
        :title="title"
        :date="queryVal.date"
        :type="routeType"
      />
      <div v-loading="loading">
        <b-table
          bordered
          striped
          hover
          responsive
          :items="tableData"
          :fields="
            $store.state.highlightCol.firTable
              ? $store.state.newTableFields
              : tableFields
          "
          @head-clicked="
            commonHighLightCol(
              $event,
              tableFields,
              $store.state.newTableFields,
              'firTable'
            )
          "
        >
          <template #thead-top="data">
            <b-tr>
              <b-th colspan="5"></b-th>
              <b-th> total SHOWS :{{ parseFloat(tableTotal.showings).toLocaleString()  }}</b-th>
              <b-th> total ADM :{{ parseFloat(tableTotal["TA"]).toLocaleString() }}</b-th>
              <b-th> total B.O. :{{ parseFloat(tableTotal["TG"]).toLocaleString() }}</b-th>
              <b-th></b-th>
            </b-tr>
          </template>
          <template #cell(TA)="data">
            <div>
              {{ parseFloat(data.item.TA).toLocaleString() }}
            </div>
          </template>
          <template #cell(TG)="data">
            <div>
              {{ parseFloat(data.item.TG).toLocaleString() }}
            </div>
          </template>
          <template #cell(showings)="data">
            <div>
              {{ parseFloat(data.item.showings).toLocaleString() }}
            </div>
          </template>
          <template v-slot:cell(Action)="data">
            <b-button
              v-if="data.item.show_time !== 'Total:'"
              size="sm"
              style="width:130px"
              @click="
                checkxml(
                  data.item.film_id || '',
                  data.item.theatre_id || '',
                  data.item.show_time || ''
                )
              "
            >
              按放映時間列出
            </b-button>
          </template>
        </b-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import { getAllversionByFilm } from '@/api/record.js'
import subTitle from './component/subTitles.vue'

export default {
  props: {
    hasItem: {
      type: String,
      default: null
    }
  },
  components: { subTitle },
  data() {
    return {
      title:'按日顯示票房記錄',
      loading: false,
      queryVal: '',
      tableData: [],
      info: [
        { label: 'Film Title', val: '' },
        { label: 'Film ID Listed', val: '' },
        { label: 'Date', val: '' }
      ],
      routeType: '',
      tableFields: [
        { key: 'film_id', label: 'Film ID', sortable: true },
        { key: 'effect', label: 'Effect', sortable: true },
        { key: 'run_ver', label: 'Ver.', sortable: true },
        { key: 'theatre_name', label: 'Theatre', sortable: true },
        { key: 'language', label: 'Lang.', sortable: true },
        { key: 'showings', label: 'Shows', sortable: true },
        { key: 'TA', label: 'Total Adm.', sortable: true },
        { key: 'TG', label: 'Total B.O.', sortable: true },
        { key: 'Action', label: 'Action' }
      ],
      tableTotal: {}
    }
  },
  methods: {
    getAllversionByFilm() {
      this.loading = true
      const data = {
        type: this.queryVal.mode,
        id: this.queryVal.id,
        date: this.queryVal.date
      }
      getAllversionByFilm(data).then((res) => {
        this.loading = false
        this.tableTotal = res
        const list = []
        res.data.map((el) => {
          list.push(el.film_id)
          if (el.film) {
            this.tableData.push({
              film_id: el.film.id,
              effect: el.film.remark_2
                ? `${el.film.remark_1}[${el.film.remark_2}]`
                : el.film.remark_1,
              run_ver: el.film.run_ver.run_ver,
              theatre_name: el.theatre.full_name,
              language: el.film.language,
              showings: el.showings,
              TA: el.TA,
              TG: el.TG,
              title_name: el.film.title_name,
              theatre_id: el.theatre.id
            })
          }
        })
        const film_id_list = [...new Set(list)].join(', ')
        console.log(film_id_list)
        this.info = [
          {
            label: 'Film Title',
            val:
              this.tableData && this.tableData.length > 0
                ? this.tableData[0].title_name
                : ''
          },
          {
            label: 'Film ID Listed',
            val: this.queryVal.mode === 'm' ? film_id_list : this.queryVal.id
          },
          { label: 'Date', val: this.queryVal.date }
        ]
        console.log(res)
      })
    },
    checkxml(film_id, theatre_id) {
      const query = {
        type: 'duration',
        date: this.queryVal.date,
        film_id,
        theatre_id
      }
      this.$router.push({
        path: '/record/version-film-record-by-showTime',
        query
      })
    }
  },
  created() {
    this.queryVal = this.$route.query
    this.routeType = this.queryVal.type
    this.getAllversionByFilm()
  }
}
</script>

<style lang="scss" scoped>
</style>
