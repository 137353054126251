var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-card',{staticClass:"news"},[_c('div',{staticClass:"title"},[_c('p',[_vm._v("舊電影票房數據")])]),_c('div',{staticClass:"search-input"},[_c('div',{staticClass:"d-flex"},[_c('el-input',{staticClass:"mr-2",staticStyle:{"width":"60%"},attrs:{"clearable":"","size":"small","placeholder":"Search Old Film Title"},on:{"change":_vm.Search},model:{value:(_vm.searchVal),callback:function ($$v) {_vm.searchVal=$$v},expression:"searchVal"}}),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.Search}},[_vm._v("Search")])],1),_c('div',{staticClass:"my-1",staticStyle:{"color":"red","font-size":"12px"}},[_vm._v(" *此版面只能搜尋2012或之前的舊電影 ")])]),(_vm.showSearchResult)?_c('div',{staticClass:"search-result"},[_c('div',{staticStyle:{"color":"#0e72bc"}},[_vm._v("片名搜尋："+_vm._s(_vm.searchVal))]),_c('div',[_vm._v("搜尋結果共："+_vm._s(_vm.tableData.length))]),_c('b-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"items":_vm.tableData,"fields":_vm.$store.state.highlightCol.firTable
            ? _vm.$store.state.newTableFields
            : _vm.tableFields},on:{"head-clicked":function($event){return _vm.commonHighLightCol(
            $event,
            _vm.tableFields,
            _vm.$store.state.newTableFields,
            'firTable'
          )}},scopedSlots:_vm._u([{key:"cell(name_english)",fn:function(data){return [_c('div',{staticClass:"link-text",on:{"click":function($event){return _vm.goPage(data.item, data.item.name_english)}}},[_vm._v(" "+_vm._s(data.item.name_english)+" ")])]}},{key:"cell(name_chinese)",fn:function(data){return [_c('div',{staticClass:"link-text",on:{"click":function($event){return _vm.goPage(data.item, data.item.name_chinese)}}},[_vm._v(" "+_vm._s(data.item.name_chinese)+" ")])]}},{key:"cell(date_release)",fn:function(data){return [_c('div',[_vm._v(_vm._s(_vm.isValidDate(data.item.date_release)))])]}}],null,false,3386138422)})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }