import request from '@/utils/request'

// get DistributorList API
export function getDistributorApi (params) {
  return request({
    url: '/api/distributor',
    method: 'GET',
    params
  })
}
//get Distributor Detail
export function getDistributorDetail(id) {
  return request({
    url: `/api/distributor/${id}`,
    method: 'GET',
  })
}
//create Distributor
export function createDistributor(data) {
  return request({
    url: '/api/distributor',
    method: 'POST',
    data
  })
}
//edit Distributor
export function editDistributor(id, data) {
  return request({
    url: `/api/distributor/${id}`,
    method: 'PUT',
    data
  })
}
//delete Distributor
export function deleteDistributor(id) {
  return request({
    url: `/api/distributor/${id}`,
    method: 'DELETE',
  })
}
//delete Distributor History
export function deleteDistributorHistory(id) {
  return request({
    url: `/api/distributor/record/${id}`,
    method: 'DELETE',
  })
}

//edit Distributor History
export function editDistributorHistory(id, data) {
  return request({
    url: `/api/distributor/record/${id}`,
    method: 'PUT',
    data
  })
}