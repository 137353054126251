<template>
  <ul v-if="reload">
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    />
    <div class="logout-btn" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span style="margin-left: 10px; color: #625f6e; line-height: 1.45"
        >登出</span
      >
    </div>
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { getSelfProfile } from '@/api/login'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      reload: true
    }
  },
  mounted() {
    // this.getSelfProfile()
    this.isReload()
  },
  setup() {
    provide('openGroups', ref([]))
    return {
      resolveNavItemComponent
    }
  },
  methods: {
    isReload() {
      this.reload = false
      this.$nextTick(() => {
        const userData = JSON.parse(localStorage.getItem('userData'))
        this.items.map((el, index) => {
          if (
            userData.role !== 'Super Admin' &&
            userData.role !== 'Admin Supervisor'
          ) {
            console.log(userData.role, 'delete 修改票房紀錄')
            if (el.title === '修改票房記錄') {
              this.items.splice(index, 1)
            }
          }
          if (userData.role === 'Admin Staff') {
            if (el.title === '管理用戶') {
              this.items.splice(index, 1)
            }
          }
        })
        this.reload = true
      })
    },
    // getSelfProfile() {
    //   getSelfProfile().then((res) => {
    //     console.log(res)
    //     if (
    //       res.data.role !== 'Super Admin' ||
    //       res.data.role !== 'Admin Supervisor'
    //     ) {
    //       this.items.map((el, index) => {
    //         if (el.title === '修改票房記錄') {
    //           this.items.splice(index, 1)
    //         }
    //       })
    //     }
    //   })
    // },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      // localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      // localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')
      // Reset ability
      this.$ability.update(initialAbility)
      // Redirect to login page
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="scss" scoped>
.logout-btn {
  // padding: 10px 15px 10px 15px;
  padding: 1px 15px;
  margin: 0 15px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.logout-btn:hover {
  transform: translateX(10px);
}
</style>
