<template>
  <div>
    <div class="title">
      <p>{{ title }}</p>
    </div>
    <b-button
      size="sm"
      @click="$router.push({ name: 'check-upload-record' })"
      class="mb-1"
      style="margin-left: 8px"
      variant="outline-dark"
      >回目錄</b-button
    >
    <b-row
      class="d-flex align-items-center"
      style="margin-left: 8px"
      v-if="title_num !== '16.2.3'"
    >
      <b-col sm="1.5"> Search For Film:</b-col>
      <b-col sm="6"
        ><b-form-input size="sm" v-model="val"></b-form-input
      ></b-col>
      <b-col sm="3"
        ><b-button size="sm" @click="search()">Search / 搜尋</b-button></b-col
      >
    </b-row>
    <div class="mt-1 mb-1">
      <b style="color: #0e72bc" v-if="title_num === '16.2.3'">
        List of Day Final Data as of <span style="color: red">{{ val }}</span
        ><span v-if="val1" style="color: red">~{{ val1 }}</span> (Total on all
        records within in this period.)
      </b>
      <b style="color: #0e72bc" v-else>電影記錄-搜尋條件符合 [{{ val }}]</b>
      <div class="mt-1" v-if="title_num == '16.2.3'">
        <p>
          <b>Film Title : </b> <b style="color: red">{{ val }}</b>
        </p>
        <p>
          <b>Film ID Listed : </b> <b style="color: red">{{ 3600 }}</b>
        </p>
        <p>
          <b>Date : </b> <b style="color: red">{{ 2023 - 5 - 22 }}</b>
        </p>
      </div>
      <div v-loading="loading">
        <b-table
          bordered
          class="mt-1"
          striped
          hover
          responsive
          :items="tableData"
          :fields="
            $store.state.highlightCol.firTable
              ? $store.state.newTableFields
              : tableFields
          "
          @head-clicked="
            commonHighLightCol(
              $event,
              tableFields,
              $store.state.newTableFields,
              'firTable'
            )
          "
        >
          <template #cell(theatre_name)="data">
            <div class="text-nowrap">
              "
              {{ data.item.theatre_name }}
            </div>
          </template>
          <template #cell(name_english)="data">
            <div style="width: 250px">
              {{ data.item.name_english }}
            </div>
          </template>
          <template #cell(name_chinese)="data">
            <div style="width: 250px">
              {{ data.item.name_chinese }}
            </div>
          </template>
          <template v-slot:cell(action)="data">
            <div>
              <b-button
                v-if="title_num === '16.2.1' || title_num === '16.2.2'"
                size="sm"
                class="mr-2"
                @click="
                  goFilmRecord(
                    '16.2.2',
                    data.item.name_chinese,
                    data.item.film_master_id
                  )
                "
              >
                檢閱票房紀錄(不分版本)
              </b-button>
              <b-button
                v-if="title_num === '16.2.1' || title_num === '16.2.5'"
                size="sm"
                class="mr-2"
                @click="
                  goFilmRecord(
                    '16.2.5',
                    data.item.name_chinese,
                    data.item.film_master_id
                  )
                "
              >
                檢閱票房紀錄(按版本分)
              </b-button>
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { getCheckFilmUploadRecord, getEngByFilmRecords } from "@/api/record.js";
import XLSX from "xlsx";

export default {
  props: {
    title: {
      type: String
    },
    title_num: {
      type: String
    },
    val: {
      type: String
    },
    val1: {
      type: String
    },
    titleText: {
      type: String
    },
    uploadDate: {
      type: String
    },
    filmIDList: {
      type: String
    },
    searchType: {
      type: String
    }
  },
  data() {
    return {
      tableData: [],
      loading: false,
      select: "All Location",
      selectOptions: [
        { value: "All Location", text: "全部地區" },
        { value: "Hong Kong", text: "香港" },
        { value: "Macau", text: "澳門" }
      ]
    };
  },
  computed: {
    tableFields() {
      if (
        this.title_num === "16.2.1" ||
        this.title_num === "16.2.2" ||
        this.title_num === "16.2.5"
      ) {
        return [
          { key: "film_master_id", label: "F_M_ID", sortable: true },
          { key: "name_english", label: "Film Name", sortable: true },
          { key: "name_chinese", label: "電影名稱", sortable: true },
          { key: "action", label: "" }
        ];
      } else {
        const list = [
          { key: "film_master_id", label: "F_M ID", sortable: true },
          { key: "film_id", label: "Film ID", sortable: true },
          { key: "film_title", label: "Film Title", sortable: true },
          { key: "country", label: "Production Country", sortable: true },
          { key: "effect", label: "Effect", sortable: true },
          { key: "lang", label: "Lang.", sortable: true },
          { key: "run", label: "Run", sortable: true },
          { key: "showings", label: "Shows", sortable: true },
          { key: "TA", label: "Total Adm.", sortable: true },
          { key: "TG", label: "Total B.O.", sortable: true },
          { key: "dateaction", label: "" }
        ];
        return list;
      }
    }
  },
  methods: {
    search() {
      this.$router.push({
        name: "upload-record-detail",
        query: {
          type: this.$route.query.type,
          val: this.val,
          val1: "",
          title: this.title,
          titleText: this.titleText,
          uploadDate: "",
          showLocation: "",
          filmIDList: "",
          title_num: this.title_num
        }
      });
      this.getCheckFilmUploadRecord()
    },
    //16.2.5
    getEngByFilmRecords() {
      this.loading = true;
      const data = {
        page: 1,
        count: 10000,
        search: this.val
      };
      getEngByFilmRecords(data)
        .then((res) => {
          const list = [];
          res.data.map((el) => {
            el.name_english = el.title_name.split("*")[1];
            el.name_chinese = el.title_name.split("*")[0];
            list.push({
              film_master_id: el.master_id,
              name_english: el.name_english,
              name_chinese: el.name_chinese,
              id: el.id
            });
          });
          this.tableData = list;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    goFilmRecord(type, value, id) {
      if (type === "16.2.2") {
        console.log(value);
        this.$router.push({
          name: "search-film-upload-record",
          query: {
            type: "film_name",
            title: "16.2.2 按電影查閱上載記錄(不分版本)",
            val: value,
            title_num: "16.2.2",
            titleText: "list-of-day&film-title",
            showLocation: true,
            search_id: id
          }
        });
      } else if (type === "16.2.5") {
        this.$router.push({
          name: "search-film-upload-record",
          query: {
            type: "film_name",
            title: "16.2.5 按電影查閱上載記錄(按版本分)",
            val: value,
            title_num: "16.2.5",
            titleText: "list-of-day&film-title",
            showLocation: true,
            search_id: id
          }
        });
      } else {
        this.$router.push("/boxoffice/film-box-record");
      }
    },
    //get 16.2.1 16.2.2 data
    getCheckFilmUploadRecord() {
      this.loading = true;
      this.rows = 10000;
      this.currentPage = 1;
      getCheckFilmUploadRecord(this.rows, this.currentPage, this.val)
        .then((res) => {
          console.log(res);
          const list = [];
          res.data.map((el) => {
            list.push({
              film_master_id: el.id,
              name_english: el.name_english,
              name_chinese: el.name_chinese
            });
          });
          this.tableData = list;
          this.pageTotal = res.total;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    }
  },
  mounted() {
    if (this.title_num === "16.2.5") {
      this.getEngByFilmRecords();
    } else {
      this.getCheckFilmUploadRecord();
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.sec-title {
  color: #0e72bc;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid #0e72bc;
}
.w300 {
  width: 250px;
}
</style>
