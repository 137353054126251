<template>
  <section>
    <b-card v-loading="loading">
      <div class="title">
        <p>{{ title }}</p>
      </div>
      <div class="">
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justif-content-start mb-1 mb-md-0"
          >
            <b-button size="sm" variant="outline-primary" @click="goAddPage()">
              {{ addButtonTitle }}
            </b-button>
            <b-button-group v-if="type !== 'genre'" size="sm" class="ml-1">
              <b-button
                @click="selectFilmType(0)"
                :variant="isOldFilm ? '' : 'primary'"
                >新{{ title }}記錄</b-button
              >
              <b-button
                @click="selectFilmType(1)"
                :variant="isOldFilm ? 'primary' : ''"
                >舊{{ title }}記錄</b-button
              >
            </b-button-group>
          </b-col>
        </b-row>
        <b-row class="search">
          <b-form-input
            class="mr-1"
            style="width: 25%"
            type="text"
            :placeholder="placeHolder"
            v-model="searchQuery.search"
            @change="search"
          />
          <b-form-select
            ref="blurSelect"
            v-if="showArea"
            style="width: 15%"
            class="mr-1"
            v-model="searchQuery.selectArea"
            :options="areaOption"
            @change="enterSearch"
          ></b-form-select>
          <b-button ref="enterBtn" variant="primary" @click="search()">
            Search
          </b-button>
        </b-row>
      </div>
      <div>
        <b-table
          bordered
          hover
          striped
          responsive
          :items="items"
          :fields="
            $store.state.highlightCol.firTable
              ? $store.state.newTableFields
              : fields
          "
          @head-clicked="
            commonHighLightCol(
              $event,
              fields,
              $store.state.newTableFields,
              'firTable'
            )
          "
        >
          <template #head()="data">
            <div
              class="cursor-pointer"
              v-if="data.field.sortKey"
              @click="orderHead(data.field.sortKey)"
            >
              {{ data.field.label }}
            </div>
            <div v-else>{{ data.field.label }}</div>
          </template>
          <template #cell(name_en)="data">
            <div @click="goPage(data.item.id, 'review')">
              <span size="sm" class="mr-2 table-name">
                {{ data.item.name_en }}
              </span>
            </div>
          </template>
          <template #cell(name_ch)="data">
            <div @click="goPage(data.item.id, 'review')">
              <span size="sm" class="mr-2 table-name">
                {{ data.item.name_ch }}
              </span>
            </div>
          </template>
          <template #cell(actions)="data">
            <b-button
              size="sm"
              variant="secondary"
              @click="goPage(data.item.id, 'edit')"
            >
              <span class="text-nowrap">{{ editButtonTitle }}</span>
            </b-button>
          </template>
        </b-table>
      </div>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-start justify-content-sm-end"
          >
            <div class="pagination mt-3">
              <el-pagination
                class=""
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[20, 50, 100]"
                :page-size="rows"
                layout=" sizes, prev, pager, next"
                :total="pageTotal"
              >
              </el-pagination>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </section>
</template>
<script>
export default {
  name: "TableDataListing",
  components: {},
  props: {
    placeHolder: {
      type: String,
      default: "",
    },
    showArea: {
      type: Boolean,
      default: true,
    },
    direct: {
      type: String,
      default: "desc",
    },
    sort: {
      type: String,
      default: "id",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
    fields: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    addButtonTitle: {
      type: String,
      default: "",
    },
    editButtonTitle: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
    selectDropdown: {
      type: Boolean,
      default: false,
    },
    pageTotal: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    rows: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isOldFilm: 0,
      areaOption: [
        { text: "全部地區", value: "" },
        { text: "香港", value: "Hong Kong" },
        { text: "非香港", value: "Non Hong Kong" },
      ],
      perPage: 10,
      filter: null,
      filterOn: [],
      selectValue: "",
      selectOptions: ["香港", "非香港"],
      searchQuery: {
        search: "",
        location: "",
        selectArea: "",
      },
    }
  },
  computed: {
    dataListStart() {
      if (this.rows === 5) {
        return (this.currentPage - 1) * 5 + 1
      } else {
        return (this.currentPage - 1) * 2 * (this.rows / 2) + 1
      }
    },
    dataListStop() {
      if (this.currentPage === Math.ceil(this.pageTotal / this.rows)) {
        return this.pageTotal
      } else {
        return this.rows * this.currentPage
      }
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.searchQuery.location = this.selectValue
  },
  methods: {
    enterSearch() {
      this.$nextTick(() => {
        this.$refs.blurSelect.blur()
        this.$refs.enterBtn.focus()
      })
    },
    selectFilmType(val) {
      this.isOldFilm = val
      this.$emit("selectFilmType", val)
    },
    orderHead(key) {
      let direct = ""
      if (this.direct === "desc") {
        direct = "asc"
      } else {
        direct = "desc"
      }
      this.$emit("orderHead", key, direct)
    },
    handleSizeChange(val) {
      this.$emit("handleSizeChange", val)
    },
    handleCurrentChange(val) {
      this.$emit("handleCurrentChange", val)
    },
    search() {
      const data = {
        rows: this.rows,
        selectArea: this.searchQuery.selectArea,
        search: this.searchQuery.search,
        sort: this.sort,
        direct: this.direct,
      }
      this.$emit("handleSearch", data)
    },
    goPage(value, type) {
      if (type === "review") {
        if (value) {
          this.$router.push({
            name: `edit-${this.type}`,
            query: { type: "review", id: value },
          })
        }
      } else if (type === "edit") {
        if (value) {
          this.$router.push({
            name: `edit-${this.type}`,
            query: { type: "edit", id: value },
          })
        }
      }
    },
    goAddPage() {
      this.$router.push({
        name: `add-${this.type}`,
        query: { type: "create" },
      })
    },
  },
}
</script>
<style lang="scss">
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.search {
  display: flex;
  margin: 15px 0;
}
.table-name:hover {
  text-decoration: underline;
}
</style>
