<template>
  <div class="latest-news-detail">
    <infoCom
      v-loading="loading"
      :id="query.id"
      :pageType="query.type"
      :title="headeTitle"
      :name="'新聞發佈'"
      :infoVal.sync="info"
      :fileList.sync="fileList"
      @submit="submit"
    />
  </div>
</template>

<script>
import infoCom from "./component/infoCom.vue";
import { getNewsDetail, createNews, editNews } from "@/api/pressRelease";
export default {
  components: { infoCom },
  data() {
    return {
      query: "",
      info: {
        banner_path: "",
        title: "",
        linked_at: "",
        news_file_id: "",
        date: "",
        paragraph: "",
        news_file: {
          file_name: "",
          path: ""
        }
      },
      loading: false,
      fileList: []
    };
  },
  mounted() {
    this.query = this.$route.query;
    if (this.query.type !== "create") {
      this.getNewsDetail();
    }
  },
  computed: {
    headeTitle() {
      if (this.query.type === "edit") {
        return `修訂${this.query.title}`;
      } else if (this.query.type === "create") {
        return `開立${this.query.title}`;
      } else {
        return `檢閲${this.query.title}`;
      }
    }
  },
  methods: {
    submit(val) {
      this.loading = true;
      if (this.query.type === "edit") {
        editNews(this.query.id, val)
          .then((res) => {
            console.log(res);
            this.getNewsDetail();
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      } else if (this.query.type === "create") {
        createNews(val)
          .then((res) => {
            console.log(res);
            this.loading = false;
            const path = this.$route.meta.parent;
            this.$router.push({ path });
          })
          .catch((error) => {
            this.loading = false;
          });
      }
    },
    getNewsDetail() {
      this.loading = true;
      getNewsDetail(this.query.id)
        .then((res) => {
          this.info = res.data;
          if (this.info.news_file) {
            const name = this.info.news_file.path.split("?")[0].split("/");
            this.info.news_file.file_name = decodeURIComponent(
              name[name.length - 1]
            );
          }
          this.loading = false;
          console.log(this.info);
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    }
  }
};
</script>

<style></style>
