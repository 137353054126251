<template>
  <section
    id="management-center"
    ref="managementCenterSticky"
    class="management-center"
  >
    <b-card
      :style="`padding:${showFilmRecord ? '10px' : ''}`"
      v-show="showFilmRecord"
      class="film-record"
    >
      <div class="top-to-page">
        <p class="mr-2">
          <span class="app-color">
            在資料庫內未有記錄之電影
            <span>共</span><span class="box">{{ UnfoundRecordTotal }}</span
            ><span>項</span>
          </span>
          <b-button
            size="sm"
            variant="outline-primary"
            class="handle-button"
            @click="view('not-found-records')"
          >
            處理相關項目
          </b-button>
        </p>
        <p class="mr-2">
          <span class="app-color">
            在資料庫內發現有問題的記錄
            <span>共</span><span class="box">{{ AbnormalRecordTotal }}</span
            ><span>項</span>
          </span>
          <b-button
            size="sm"
            variant="outline-primary"
            class="handle-button"
            @click="view('abnormal-records')"
          >
            處理相關項目
          </b-button>
        </p>
        <p>
          <b-button
            size="sm"
            variant="outline-success"
            class="handle-button"
            @click="$router.push({ path: '/dashboard/pre-sale' })"
          >
            查閱14日後預售數
          </b-button>
        </p>
      </div>

      <span class="app-color"
        >由午夜至現在收到的XML票房上載記錄：({{ time }})</span
      >
    </b-card>
    <b-card
      class="table-header-sticky"
      :style="`width:${stickyWidth};padding:${
        showFilmRecord ? '10px' : ''
      };padding-top:0`"
      v-show="isSticky"
      :class="{ sticky: isSticky }"
    >
      <div>
        <b-table bordered responsive hover :items="[]" striped :fields="fields">
          <template #head(name)="data">
            <div
              style="min-height: 40px"
              class="cursor-pointer"
              @click="sortChange(sortBy)"
            >
              {{ data.label }}
            </div>
          </template>
        </b-table>
      </div>
    </b-card>
    <b-card
      :style="`padding:${showFilmRecord ? '10px' : ''}`"
      v-loading="tableLoading"
    >
      <div class="" :key="tableKey">
        <b-table
          bordered
          responsive
          hover
          :items="items"
          :fields="fields"
          :tbody-tr-class="rowClass"
        >
          <template #head(name)="data">
            <div
              style="min-height: 40px"
              class="cursor-pointer"
              @click="sortChange(sortBy)"
            >
              {{ data.label }}
            </div>
          </template>
          <template #cell(name)="data">
            <p class="name">{{ data.item.name }}</p>
          </template>
          <template #cell(Day_Final)="data">
            <div v-if="data.item.is_open" style="padding: 3px">
              <span
                class="text-nowrap data-text"
                @click="goPage(data.item, data.value, 'dayfinal')"
              >
                <b-avatar
                  size="20"
                  :variant="
                    xmlDayfinalColor(data.value.status, data.value.from_hourly)
                  "
                >
                  {{ data.value.status }}
                </b-avatar>
              </span>
            </div>
          </template>
          <template #cell()="data">
            <span
              v-if="data.item.is_open"
              class="text-nowrap data-text"
              @click="goPage(data.item, data.value, 'hourly')"
            >
              <b-avatar
                style="font-size: 10px"
                size="20"
                :variant="xmlStatusColor(data.value.status)"
              >
                {{ data.value.status }}
              </b-avatar>
            </span>
          </template>
        </b-table>
      </div>
      <div>
        <p>
          此列表乃按院商上傳之XML檔案編號排列。("OK-"為無售票記錄之上傳檔案。)
        </p>
        <p>藍色為前一天的Copy XML文檔或人手輸入數據</p>
      </div>
    </b-card>
  </section>
</template>

<script>
import {
  getMainList,
  getUnfoundRecord,
  getAbnormalRecord,
} from "@/api/management.js"

export default {
  props: {
    isOrgXML: {
      type: Boolean,
      default: false,
    },
    showFilmRecord: {
      type: Boolean,
      default: true,
    },
    scroll: {
      type: Number,
      default: 150,
    },
    datePick: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tableKey: 100,
      sortBy: "asc",
      stickyWidth: "",
      isSticky: false,
      tableLoading: false,
      rows: 15,
      perPage: 1,
      currentPage: 1,
      select: "Hong Kong",
      selectOptions: ["Hong Kong", "Macau"],
      time: this.$moment().format("DD-MM-YYYY HH:mm:ss"),
      UnfoundRecordTotal: 0,
      AbnormalRecordTotal: 0,
      fields: [
        {
          key: "name",
          label: "Theatre Name",
          stickyColumn: true,
        },
        {
          key: "Day_Final",
          label: "Day Final",
          variant: "warning",
        },
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ],
      items: [],
    }
  },
  computed: {
    xmlDayfinalColor() {
      return function (val, type) {
        if (type) {
          return "primary"
        } else if (!type && val === "?") {
          return "danger"
        } else if (!type && val === "OK") {
          return "success"
        } else {
          return ""
        }
      }
    },
    xmlStatusColor() {
      return function (val) {
        if (val === "OK") {
          return "success"
        } else if (val === "OK-") {
          return "warning"
        } else if (val === "?") {
          return "danger"
        } else {
          return ""
        }
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll)
    window.addEventListener("resize", this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll)
    window.addEventListener("resize", this.handleScroll)
  },
  created() {
    this.getMainList(this.datePick)
    this.getAbnormalRecord()
    this.getUnfoundRecord()
  },
  methods: {
    sortChange(sort) {
      this.sortBy = sort === "desc" ? "asc" : "desc"
      this.getMainList(this.datePick, this.sortBy)
    },
    rowClass(item, index) {
      console.log(item, index, "item, index")

      if (item.boldBorder) {
        return "bold-border" // 將背景色設置為警告色
      } else {
        return ""
      }
    },
    handleScroll() {
      const scrollY = window.scrollY
      this.isSticky = scrollY > this.scroll // 设置一个滚动阈值，控制何时导航栏变为悬浮状态
      const element = this.$refs.managementCenterSticky
      if (element) {
        this.stickyWidth = `${element.offsetWidth}px`
      }
    },
    getMainList(dateVal, sort) {
      this.tableLoading = true
      const data = {
        area: window.sessionStorage.getItem("area") || "",
        type: "form",
        file_id: "",
        date: dateVal,
        direct: sort ? sort : "asc",
        simple: this.isOrgXML ? 0 : 1,
      }
      getMainList(data)
        .then((res) => {
          this.items = []
          const handtypeList = []
          let showHandType = false
          res.data.mainList.map((el) => {
            el.map((item) => {
              this.items.push(item)
            })
          })
          this.items.map((item, index) => {
            if (index !== this.items.length - 1) {
              if (item.circuit_id !== this.items[index + 1].circuit_id) {
                item.boldBorder = true
              }
            }
            if (item.Day_Final.from_hourly) {
              handtypeList.push(item.name)
              showHandType = true
            }
            const bgObj = {
              Day_Final: !item.is_open ? "secondary" : "",
              name: sort ? "info" : "",
            }
            item["_cellVariants"] = bgObj
          })
          if (
            showHandType &&
            this.$route.path === "/dashboard/management-center"
          ) {
            const text = handtypeList.join("<br/>")
            this.$alert(`${text}`, "戲院DayFinal有人手輸入數據", {
              dangerouslyUseHTMLString: true,
            })
          }
          this.tableKey++

          this.tableLoading = false
        })
        .catch((error) => {
          console.log(error)
          this.tableLoading = false
        })
    },
    getAbnormalRecord() {
      getAbnormalRecord(this.listParams).then((res) => {
        this.AbnormalRecordTotal = res.total
      })
    },
    getUnfoundRecord() {
      getUnfoundRecord(this.listParams).then((res) => {
        this.UnfoundRecordTotal = res.total
      })
    },
    goPage(data, value, type) {
      if (type === "dayfinal") {
        const d = this.datePick ? new Date(this.datePick) : new Date()
        const date = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
        if (!value.xml_id) {
          this.$router.push({
            name: "dayend-file",
            query: {
              xml_file_id: value.xml_fileid,
              xml_file_name: value.xml_filename,
              theatre_id: data.theatre_id,
              theatre_no: data.theatre_no,
              date,
              theatre_name: data.short_name,
            },
          })
        } else {
          this.$router.push({
            name: "original-record-file",
            query: {
              id: value.xml_id,
            },
          })
        }
      } else if (value.xml_id) {
        this.$router.push({
          name: "original-record-file",
          query: {
            id: value.xml_id,
          },
        })
      }

      // this.$router.push({ name: 'dayend-file' })
    },
    view(router) {
      this.$router.push({ name: router })
    },
  },
}
</script>
<style lang="scss">
.management-center {
  .card-body {
    padding: 0px;
  }
  .film-record {
    margin-bottom: 10px;
  }
  .b-table {
    table-layout: fixed;
    font-size: 12px !important;
    thead {
      tr {
        th {
          padding: 2px 0px !important;
          width: 20px;
          div {
            text-align: center;
            white-space: normal;
            font-size: 10px !important;
          }
        }
        th:first-child {
          width: 200px !important;
          div {
            text-align: left;
            width: 100%;
          }
        }
        th:nth-child(2) {
          width: 26px !important;
          padding: 5px 0 !important;
          div {
            width: 100%;
          }
        }
      }
    }
    tbody {
      tr {
        padding: 0px !important;
        td {
          padding: 0px !important;
          // width: 40px;
          p {
            margin-bottom: 0;
          }
          .name {
            width: 100% !important;
          }
          span {
            display: block;
            margin: 0 auto;
            line-height: 20px;
          }
        }
        td:first-child {
          padding: 0px !important;
          div {
            width: 100%;
          }
        }
        td:nth-child(2) {
          // background: rgba(248, 243, 137, 0.292);
          div {
            width: 100%;
          }
        }
      }
      .bold-border {
        border-bottom: 8px solid rgb(231, 228, 228);
      }
    }
  }
  @media screen and (max-width: 455px) {
    .card {
      margin-bottom: 10px;
    }
    .card-body {
      padding: 5px;
      p {
        -webkit-text-size-adjust: none;
        font-size: 10px;
        height: auto;
      }
      .b-table {
        -webkit-text-size-adjust: none;
        font-size: 10px;
        thead {
          tr {
            th {
              width: 27px;
              div {
                font-size: 10px;
              }
            }
            th:first-child {
              width: 100px !important;
              div {
                width: 100%;
              }
            }
          }
        }
        tbody {
          tr {
            td {
              span {
                display: block;
                margin: 0 auto;
                line-height: 20px;
                .b-avatar {
                  width: 20px !important;
                  height: 20px !important;
                }
              }
            }
          }
        }
      }
      p {
        margin-bottom: 0px;
        line-height: 15px;
      }
    }
  }
  .table-header-sticky {
    background-color: rgba(255, 255, 255, 0);
    box-shadow: 5px 5px 5px #0e71bc00;
    .card-body {
      background-color: rgba(255, 255, 255, 0);
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
}
</style>
<style lang="scss" scope="scoped">
.sticky {
  position: fixed;
  top: 80px;
  // width: 79%;
  z-index: 100;
  /* 悬浮时的样式 */
}
.top-to-page {
  display: flex;
  flex-wrap: wrap;
}
.app-color {
  color: #0e72bc;
  // height: 30px;
  // display: inline-block;
}
.box {
  display: inline-block;
  width: auto;
  height: 22px;
  box-sizing: content-box;
  padding: 0 5px;
  margin: 0 5px;
}
.handle-button {
  margin-left: 20px;
}
.table-scroll {
  overflow: scroll;
}
</style>
