<template>
  <div>
    <b-card v-loading="isLoading">
      <div class="title">
        <p>院線資料</p>
      </div>
      <div>
        <b-button
          size="sm"
          class="mr-2 mb-1"
          variant="outline-primary"
          @click="goDetail('', 'create')"
        >
          開立新院線記錄
        </b-button>
      </div>
      <div class="mb-1 d-flex">
        <b-form-input style="width:30%" v-model="searchCircuit" placeholder="Enter Circuit Name"></b-form-input>
        <b-button class="ml-2" variant="primary" @click="getCircuitList()">
          Search
        </b-button>
      </div>
      <b-overlay rounded="sm">
        <b-table
          bordered
          striped
          hover
          responsive
          :items="tableData"
          :fields="
            $store.state.highlightCol.firTable
              ? $store.state.newTableFields
              : tableFields
          "
          @head-clicked="
            commonHighLightCol(
              $event,
              tableFields,
              $store.state.newTableFields,
              'firTable'
            )
          "
        >
          <template v-slot:cell(full_name)="row">
            <b-button
              size="sm"
              class="mr-2"
              variant="outline-primary"
              @click="goDetail(row.item.id, 'view')"
            >
              {{ row.item.full_name }}
            </b-button>
          </template>
          <template v-slot:cell(action)="row">
            <b-button size="sm" class="mr-2" @click="goDetail(row.item.id,'edit')">
              作出修訂
            </b-button>
          </template>
        </b-table>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import vSelect from 'vue-select'
// import { getTheatreList } from '@/api/theatre.js'
import { getCircuitList } from '@/api/circuit.js'

export default {
  components: {
    vSelect
  },
  data() {
    return {
      searchCircuit:'',
      rows: 1,
      perPage: 1,
      currentPage: 1,
      select: '全部地區',
      selectOptions: [
        { label: '全部地區', id: '' },
        { label: '香港', id: 'Hong Kong' },
        { label: '澳門', id: 'Macau' }
      ],
      tableData: [],
      tableFields: [
        { key: 'id', label: 'Circuit ID ', sortable: true },
        { key: 'full_name', label: 'Circuit Name', sortable: true },
        { key: 'circuit_name', label: 'XML 標記', sortable: true },
        { key: 'circuit_key', label: 'XML ID', sortable: true },
        { key: 'action', label: '' }
      ],
      isLoading: false
    }
  },
  methods: {
    getCircuitList() {
      this.isLoading = true
      const listParams = {
        page: 1,
        count: 1000,
        area: sessionStorage.getItem('area'),
        search:this.searchCircuit
      }
      getCircuitList(listParams).then((res) => {
        this.tableData = res.data
        this.isLoading = false
      })
    },
    goDetail(id, type) {
      const query = {
        id: id || null,
        type
      }
      this.$router.push({ path: '/dashboard/circuit-detail', query })
    }
  },
  created() {
    this.getCircuitList()
  }
}
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
</style>
