var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"uncertan-page"},[_c('div',{staticClass:"title d-flex"},[_c('p',[_vm._v(_vm._s(_vm.title))]),_c('p',[_vm._v("查閲：最近一小時之所有未確認總數")])]),_c('b-button',{staticClass:"mb-1",staticStyle:{"margin-left":"8px"},attrs:{"size":"sm","variant":"outline-dark"},on:{"click":function($event){return _vm.$router.push({ name: 'check-upload-record' })}}},[_vm._v("回目錄")]),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('b-form-checkbox',{attrs:{"id":"checkbox-showHiddenMovie-16.12"},on:{"change":function($event){return _vm.getLastHourUncertainRecord(_vm.showHiddenMovie)}},model:{value:(_vm.showHiddenMovie),callback:function ($$v) {_vm.showHiddenMovie=$$v},expression:"showHiddenMovie"}},[_vm._v(" 不顯示隱藏戲院記錄 ")])],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"mt-1 mb-1"},[_c('b',{staticClass:"mt-1",staticStyle:{"color":"#0e72bc"}},[_vm._v(" List Theatre B.O. Records in "),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.$moment(_vm.val).format()))]),_c('span',{staticStyle:{"color":"red"}},[_vm._v("(Engt.Date:"+_vm._s(_vm.$moment(_vm.engt_date).format())+")")]),_c('span',[_vm._v("(UnOfficial Total on all records received within in this day,until the last hour.)")])]),_c('b-table',{attrs:{"bordered":"","striped":"","hover":"","responsive":"","items":_vm.tableData,"fields":_vm.$store.state.highlightCol.firTable
          ? _vm.$store.state.newTableFields
          : _vm.tableFields},on:{"head-clicked":function($event){return _vm.commonHighLightCol(
          $event,
          _vm.tableFields,
          _vm.$store.state.newTableFields,
          'firTable'
        )}},scopedSlots:_vm._u([{key:"cell()",fn:function(data){return [(data.field.numType)?_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(parseFloat(Number(data.value)).toLocaleString())+" ")]):_c('div',[_vm._v(_vm._s(data.value))])]}},{key:"cell(theatre)",fn:function(data){return [_c('div',{staticStyle:{"width":"220px"}},[_vm._v(" "+_vm._s(data.item.theatre)+" ")])]}},{key:"cell(date_upload_ref)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$moment(data.item.date_upload_ref).format( "DD-MM-YYYY HH:mm:ss" ) === "Invalid date" ? "-" : _vm.$moment(data.item.date_upload_ref).format( "DD-MM-YYYY HH:mm:ss" ))+" ")])]}}])})],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"mt-3"},[_c('b',{staticClass:"mt-1",staticStyle:{"color":"#0e72bc"}},[_vm._v(" List Movie B.O. Records in "),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.$moment(_vm.val).format()))]),_c('span',{staticStyle:{"color":"red"}},[_vm._v("(Engt.Date:"+_vm._s(_vm.$moment(_vm.engt_date).format())+")")]),_c('span',[_vm._v("(UnOfficial Total on all records received within in this day,until the last hour.)")])]),_c('b-table',{staticClass:"mt-1",attrs:{"bordered":"","striped":"","hover":"","responsive":"","items":_vm.movieData,"fields":_vm.$store.state.highlightCol.secTable
          ? _vm.$store.state.newSecTableFields
          : _vm.movieFields},on:{"head-clicked":function($event){return _vm.commonHighLightCol(
          $event,
          _vm.movieFields,
          _vm.$store.state.newSecTableFields,
          'secTable'
        )}},scopedSlots:_vm._u([{key:"head()",fn:function(data){return [_c('div',{domProps:{"innerHTML":_vm._s(data.label)}})]}},{key:"cell()",fn:function(data){return [(data.field.numType)?_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(parseFloat(Number(data.value)).toLocaleString())+" ")]):_c('div',[_vm._v(_vm._s(data.value))])]}},{key:"cell(film_title_name)",fn:function(data){return [_c('div',{staticStyle:{"width":"250px"},domProps:{"innerHTML":_vm._s(data.item.film_title_name)}})]}},{key:"cell(film_effect)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(data.item.film_effect)+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }