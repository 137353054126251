var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('div',{staticClass:"title"},[_c('p',[_vm._v("XML原始記錄檔")])]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.jsonInfoLoading),expression:"jsonInfoLoading"}]},[_c('p',{staticClass:"sec-title"},[_vm._v(" Original XML Content ["+_vm._s(_vm.xmlJsonInfo.file_name)+"]-[XML_File_ID："+_vm._s(_vm.$route.query.id)+"] ")]),(_vm.xmlJsonInfo)?_c('div',{staticClass:"mb-1",staticStyle:{"font-size":"13px"}},[_vm._v(" From "),_c('b',[_vm._v("["+_vm._s(_vm.xmlJsonInfo.circuit_name)+"]")]),_vm._v(" "),_c('br'),_vm._v("Time of this XML uploaded in FTP storage ： "),(
            _vm.$moment(_vm.xmlJsonInfo.date_of_report).format('HH:mm') <=
            _vm.$moment('2021-01-28 07:30').format('HH:mm')
          )?_c('span',[_vm._v(" "+_vm._s(_vm.xmlJsonInfo.date_of_report)+" ")]):_c('span',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.xmlJsonInfo.date_of_report)+" (Submit after 07:30am.)")]),(
            _vm.xmlJsonInfo.struct_info && _vm.xmlJsonInfo.struct_info.status === 'ok'
          )?_c('div',[_vm._v(" There are "),_c('b',[_vm._v(_vm._s(_vm.xmlJsonInfo.struct_info.theatre_tags))]),_vm._v(" set of "+_vm._s(_vm.threatreText)+" tag， and BoxOffice data on "),_c('b',[_vm._v(_vm._s(_vm.xmlJsonInfo.struct_info.engagement_tags))]),_vm._v(" films in this XMLrecord. (Tags Count= "),_c('span',[_vm._v(_vm._s(_vm.xmlJsonInfo.struct_info.tags_count))]),_vm._v(" ) ")]):(
            _vm.xmlJsonInfo.struct_info && _vm.xmlJsonInfo.struct_info.status !== 'ok'
          )?_c('div',[_vm._v(" "+_vm._s(_vm.xmlJsonInfo.struct_info.status)+" ")]):_vm._e()]):_vm._e()]),_c('b-button',{staticClass:"mb-1",attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){return _vm.showDelModel()}}},[_vm._v(" Delete XML："+_vm._s(_vm.$route.query.id)+" and all Related records. ")]),_c('div',{staticClass:"line"}),_c('b-button',{staticClass:"mb-1 mt-1",staticStyle:{"width":"194px"},attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":_vm.checkRelated}},[_vm._v(" 查閱此XML檔之資料庫記錄 ")]),_c('pre',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.xmlCodeLoading),expression:"xmlCodeLoading"}]},[_vm._v("      "+_vm._s(_vm.xml)+"\n    ")]),_c('b-modal',{attrs:{"centered":"","hide-footer":"","size":"lg","id":"modal-delete-xml-file","title":"Delete XML"}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.delLoading),expression:"delLoading"}]},[_c('div',[_c('p',[_vm._v(" Original XML Content ["+_vm._s(_vm.xmlJsonInfo.file_name)+"]-[XML_File_ID："+_vm._s(_vm.$route.query.id)+"] ")])]),_c('b-row',{staticClass:"my-1"},[_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-input',{attrs:{"trim":"","type":"number","placeholder":"Enter XML ID"},model:{value:(_vm.confirmXMLid),callback:function ($$v) {_vm.confirmXMLid=$$v},expression:"confirmXMLid"}})],1)],1),_c('div',{staticClass:"d-flex justify-content-end copy-text-btn"},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm"},on:{"click":function($event){return _vm.$bvModal.hide('modal-delete-xml-file')}}},[_vm._v(" 取消 ")]),_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.delXML(_vm.confirmXMLid)}}},[_vm._v(" 確定 ")])],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }