<template>
  <section id="management-center">
    <b-card v-loading="loading">
      <h2 class="app-color">修訂電影之票房聯繫記錄</h2>
      <div style="border-bottom: 2px solid #0e72bc; margin-bottom: 20px"></div>
      <div>
        <b-button
          size="sm"
          variant="outline-secondary"
          class="handle-button"
          @click="back"
        >
          回目錄
        </b-button>
      </div>
      <hr class="is-line" />
      <div>
        <b-row>
          <b-col cols="2"> Film_Master_ID: </b-col>
          <b-col cols="10">
            <span style="color: #0e72bc">{{ filmData.id }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2"> Film Name: </b-col>
          <b-col cols="10">
            <span style="color: #0e72bc">{{ filmData.name_english }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2"> 電影名稱: </b-col>
          <b-col cols="10">
            <span style="color: #0e72bc">{{
              filmData.name_chinese
            }}</span></b-col
          >
        </b-row>
      </div>
      <hr class="is-line" />
      <div>此影片之各種版本記錄</div>
      <div class="mt-1" v-for="(item, index) in filmData.film" :key="index">
        <b-row style="color: #0e72bc">
          <b-col cols="6" md="4">
            影片Film_ID :
            {{ item.from_arch && item.from_arch === 1 ? "" : "F-" }}-{{
              item.id
            }}
          </b-col>
          <b-col cols="6" md="4">
            {{ item.language }} ({{ item.remark_1 }})
            <span v-if="item.remark_2">{{
              item.remark_2 === "[無影院特效]" ? "" : `[${item.remark_2}]`
            }}</span>
            <span v-if="item.remark_3">{{ item.remark_3 }}</span></b-col
          >
          <b-col cols="6" md="4">
            <b style="color: red">{{ item.status }}</b>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6" md="4" class="mt-1">
            <b-form-group
              class="d-flex align-items-center"
              label="影片輪次"
              label-for="item-name"
              label-cols-md="4"
            >
              <b-form-input
                disabled
                v-model="item.run_ver.run_ver"
                id="item-name"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6" md="4" class="mt-1">
            <b-form-group
              class="d-flex align-items-center"
              label="視像效果"
              label-for="cost"
              label-cols-md="4"
            >
              <b-form-input
                disabled
                v-model="item.remark_1"
                id="effect"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6" md="4" class="mt-1">
            <b-form-group
              class="d-flex align-items-center"
              label="電影評級"
              label-for="rat"
              label-cols-md="4"
            >
              <b-form-input
                disabled
                v-model="item.rating"
                id="rat"
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" md="4" class="mt-1">
            <b-form-group
              class="d-flex align-items-center"
              label="音軌語言"
              label-for="item-lanuage"
              label-cols-md="4"
            >
              <b-form-input
                disabled
                v-model="item.language"
                id="item-lanuage"
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" md="4" class="mt-1">
            <b-form-group
              class="d-flex align-items-center"
              label="Theatre Effect"
              label-for="item-effect"
              label-cols-md="4"
            >
              <b-form-input
                disabled
                v-model="item.remark_2"
                id="item-effect"
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" md="4" class="mt-1">
            <b-form-group
              class="d-flex align-items-center"
              label="片長(分鐘)"
              label-for="item-time"
              label-cols-md="4"
            >
              <b-form-input
                disabled
                id="item-time"
                v-model="item.film_duration"
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" md="4" class="mt-1">
            <b-form-group
              class="d-flex align-items-center"
              label="字幕語言"
              label-for="item-lanuage1"
              label-cols-md="4"
            >
              <b-form-input
                disabled
                id="item-lanuage1"
                v-model="item.sub_title"
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" md="4" class="mt-1">
            <b-form-group
              class="d-flex align-items-center"
              label="上映日期"
              label-for="item-date"
              label-cols-md="4"
            >
              <b-form-input
                disabled
                v-model="item.date_release"
                type="text"
                id="item-date"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6" md="4" class="mt-1">
            <b-form-group
              class="d-flex align-items-center"
              label="優先場日期"
              label-for="item-date1"
              label-cols-md="4"
            >
              <b-form-input
                disabled
                v-model="item.date_release"
                type="text"
                id="item-date1"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6" md="4" class="mt-1">
            <b-form-group
              class="d-flex align-items-center"
              label="影片格式"
              label-for="item-format"
              label-cols-md="4"
            >
              <b-form-input
                disabled
                v-model="item.format"
                id="item-format"
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" md="4" class="mt-1">
            <b-form-group
              class="d-flex align-items-center"
              label="落畫日期"
              label-for="item-date3"
              label-cols-md="4"
            >
              <b-form-input
                disabled
                v-model="item.date_off"
                type="text"
                id="item-date3"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6" md="4" class="mt-1">
            <b-form-group
              class="d-flex align-items-center"
              label="顯示票房日期"
              label-for="item-date4"
              label-cols-md="4"
            >
              <b-form-input
                disabled
                v-model="item.date_to_report"
                type="text"
                id="item-date4"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <hr class="is-line" />
      <CodeUseByCircuit :masterId="$route.query.id" />
    </b-card>
  </section>
</template>
<script>
import { getFilmMasterDetail } from "@/api/film-material-management/filmMaster";
import CodeUseByCircuit from "./component/codeUseByCircuit.vue";
export default {
  components: { CodeUseByCircuit },
  data() {
    return {
      filmData: {},
      loading: false
    };
  },
  methods: {
    getFilmMasterDetail() {
      this.loading = true;
      getFilmMasterDetail(this.$route.query.id)
        .then((res) => {
          this.filmData = res.data;
          this.filmData.film.map((el) => {
            el.date_off =
              el.date_off === "0000-00-00" ? "00-00-0000" : el.date_off;
            el.date_on = this.isValidDate(el.date_on);
            el.date_release = this.isValidDate(el.date_release);
            el.date_to_report = this.isValidDate(el.date_to_report);
            el.remark_2 = el.remark_2 ? el.remark_2 : "[無影院特效]";
          });
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    back() {
      this.$router.push({ name: "revise-record" }); //返回上一层
    },
    goPage(info, pos_id, master_id, item) {
      console.log(item);
      if (info === "revise-record-add") {
        const search_split = this.getFilmSearchName(item);
        const filmInfo = {
          cinema_id: item.cinema_id || "",
          circuit: item.circuit_name,
          circuit_id: item.circuit_id,
          pos_id: item.pos_id,
          title_name: item.title_name,
          search_name: search_split,
          id: item.id,
          goPage: this.$route.fullPath,
          film_id: item.film_id,
          type: "notfound"
        };
        console.log(filmInfo);

        sessionStorage.setItem("filmInfo", JSON.stringify(filmInfo));
        this.$nextTick(() => {
          this.$router.push({
            name: info,
            query: {
              pos_id,
              master_id,
              search_name: search_split,
              circuit_id: item.circuit_id,
              id: item.id,
              cinema_id: item.cinema_id || ""
            }
          });
        });
      } else if (info === "revise-record-info") {
        this.$router.push({
          name: info
          // query: {
          //   pos_id,
          //   master_id,
          //   search_name: search[0],
          //   circuit_id: item.circuit_id,
          //   id: item.id
          // }
        });
      }
    }
  },
  created() {
    this.getFilmMasterDetail();
  }
};
</script>
<style lang="scss" scope="scoped">
.app-color {
  font-weight: 500;
  color: #0e72bc;
  font-size: 20px;
}
.is-line {
  height: 1px;
}
.lh30 {
  width: 250px;
}
.table-scroll {
  white-space: nowrap;
}
</style>
