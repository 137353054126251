<template>
  <div>
    <b-card class="admin-card">
      <div class="title">
        <p>資料</p>
      </div>
      <div class="admin-section">
        <b-col sm="5">
          <p class="sub-title">管理屬性</p>
          <b-form-select
            v-model="selected"
            :options="options"
            @change="optionChange"
          ></b-form-select>
        </b-col>
      </div>
      <div class="line"></div>
      <div class="admin-content" v-loading="optionsLoding">
        <div class="title-box">
          <p class="select-name">{{ selectTitle }}</p>
          <b-button
            size="sm"
            class="mr-2"
            @click="createSelect"
            variant="outline-primary"
            v-if="isDisable"
          >
            開立新選項
          </b-button>
        </div>
        <table width="100%">
          <tbody>
            <tr v-for="(cateItem, cateIndex) in selectList" :key="cateIndex">
              <td>
                <span
                  v-show="cateItem.is_default == 1 ? true : false"
                  class="default"
                  >默認選項</span
                >
              </td>
              <td>{{ cateItem.name_en }}</td>
              <td>{{ cateItem.name_zh_hk }}</td>
              <td class="btn-priority">
                <div>
                  <b-button
                    v-show="
                      cateIndex == 0
                        ? false
                        : true && selected !== 'system_language'
                    "
                    @click="orderChange('up', cateItem.id)"
                    class="p-0"
                    size="sm"
                    variant="outline-secondary"
                  >
                    <feather-icon
                      style="color: #000"
                      size="1.5x"
                      icon="ChevronUpIcon"
                    ></feather-icon>
                  </b-button>
                </div>
                <div>
                  <b-button
                    v-show="
                      cateIndex == selectList.length - 1
                        ? false
                        : true && selected !== 'system_language'
                    "
                    @click="orderChange('down', cateItem.id)"
                    class="p-0"
                    size="sm"
                    variant="outline-secondary"
                  >
                    <feather-icon
                      style="color: #000"
                      size="1.5x"
                      icon="ChevronDownIcon"
                    ></feather-icon>
                  </b-button>
                </div>
              </td>
              <td v-if="isDisable">
                <b-button
                  size="sm"
                  class="mr-2"
                  @click="editSelect(cateItem.type, cateItem.id, cateIndex)"
                  variant="outline-primary"
                >
                  修訂此選項
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-card>
  </div>
</template>

<script>
import { getManageProperties, sortManageProperties } from "@/api/properties"

export default {
  data() {
    return {
      optionsLoding: false,
      selected: "user_type",
      options: [
        { text: "使用者類別", value: "user_type" },
        { text: "影片格式", value: "film_format" },
        { text: "音軌語言", value: "film_language" },
        { text: "月份", value: "month" },
        { text: "開拍國家", value: "film_country" },
        { text: "電影評級", value: "film_rating" },
        { text: "視像效果", value: "film_visual_effect" },
        { text: "分區", value: "film_region" },
        { text: "字幕語言", value: "film_title_language" },
        { text: "系統語言", value: "system_language" },
        { text: "影片輪次", value: "film_version" },
      ],
      selectList: [],
      selectTitle: "使用者類別",
      systemLanguages: [
        {
          id: "1001",
          is_default: 1,
          name: "中文",
          name_en: "Chinese",
          name_zh_hk: "中文",
          remarks: "test",
          sort: "1",
          type: "system_language",
        },
        {
          id: "1002",
          is_default: 0,
          name: "英文",
          name_en: "English",
          name_zh_hk: "英文",
          remarks: "test",
          sort: "2",
          type: "system_language",
        },
      ],
    }
  },
  computed: {
    isDisable() {
      return !(
        this.selected === "user_type" || this.selected === "system_language"
      )
    },
  },
  methods: {
    optionChange() {
      this.optionsLoding = true
      if (this.selected !== "system_language") {
        this.getManageProperties()
      } else {
        setTimeout(() => {
          this.selectList = this.systemLanguages
          this.selectTitle = "系統語言"
          this.optionsLoding = false
        }, 500)
      }
      this.$router.replace(
        `/dashboard/admin-properties-options?type=${this.selected}`
      )
    },
    orderChange(type, id) {
      const data = {
        action: type,
        amount: 1,
      }
      sortManageProperties(id, data)
        .then(() => {
          this.getManageProperties()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    createSelect() {
      this.$router.push({
        path: "/dashboard/create-admin-properties-options",
        query: { type: this.selected },
      })
    },
    editSelect(type, id, index) {
      this.$router.push({
        path: "/dashboard/edit-admin-properties-options",
        query: { type, id, index },
      })
    },
    getManageProperties() {
      this.optionsLoding = true
      getManageProperties(this.selected)
        .then((res) => {
          console.log(res.data)
          const list = []
          res.data.map((el) => {
            if (el.status !== "1") {
              list.push(el)
            }
          })
          this.selectList = list
          const myselect = document.getElementsByClassName("custom-select")
          this.selectIndex = myselect[0].selectedIndex
          this.selectTitle = this.options[this.selectIndex].text
          this.optionsLoding = false
        })
        .catch((error) => {
          console.log(error)
          this.optionsLoding = false
        })
    },
  },

  created() {
    if (this.$route.query.type) {
      this.selected = this.$route.query.type
      if (this.selected === "system_language") {
        this.selectList = this.systemLanguages
        this.selectTitle = "系統語言"
      }
    }
    if (this.selected !== "system_language") {
      this.getManageProperties()
    }
  },
}
</script>

<style lang="scss" scoped>
.admin-card {
  min-height: 500px;
}
table {
  tr {
    td {
      width: 20%;
    }
  }
  tr:hover {
    background: #f0f3f56c;
  }
}
.admin-section {
  .sub-title {
    width: 100%;
    color: #0e72bc;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
  }
}
.line {
  height: 1.5px;
  background: #0e72bc;
  width: 100%;
  margin: 20px 0;
}
.admin-content {
  width: 100%;
  .title-box {
    justify-content: space-between;
    display: flex;
  }
  .select-name {
    color: #0e72bc;
    font-size: 17px;
    font-weight: 500;
  }
  .default {
    color: #fa0606;
    display: block;
    text-align: center;
  }
  .btn-priority {
    display: flex;
    width: 50px;
    justify-content: space-between;
  }
}
</style>
