var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"id":"management-center"}},[_c('b-card',[_c('div',{staticClass:"title"},[_c('p',[_vm._v("訂正票房資料庫記錄")])]),_c('div',{staticClass:"d-flex mb-1"},[_c('b-button-group',{staticClass:"mr-2",attrs:{"size":"sm"}},[_c('b-button',{attrs:{"variant":_vm.isOldFilm ? '' : 'primary'},on:{"click":function($event){return _vm.selectFilmType(0)}}},[_vm._v("新電影記錄")]),_c('b-button',{attrs:{"variant":_vm.isOldFilm ? 'primary' : ''},on:{"click":function($event){return _vm.selectFilmType(1)}}},[_vm._v("舊電影記錄")])],1),_c('b-form-input',{staticStyle:{"width":"35%"},attrs:{"type":"text","placeholder":"Search Film (e.g.: M-film master id ,F-film id)"},on:{"change":function($event){return _vm.getFilmMasterApi()}},model:{value:(_vm.searchFilm),callback:function ($$v) {_vm.searchFilm=$$v},expression:"searchFilm"}}),_c('span',{staticClass:"ml-2"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.getFilmMasterApi()}}},[_vm._v(" Search ")])],1)],1),_c('b-table',{attrs:{"bordered":"","striped":"","hover":"","items":_vm.items,"fields":_vm.$store.state.highlightCol.firTable
          ? _vm.$store.state.newTableFields
          : _vm.fields},on:{"head-clicked":function($event){return _vm.commonHighLightCol(
          $event,
          _vm.fields,
          _vm.$store.state.newTableFields,
          'firTable'
        )}},scopedSlots:_vm._u([{key:"head()",fn:function(data){return [(data.field.sortKey)?_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.orderHead(data.field.sortKey)}}},[_vm._v(" "+_vm._s(data.field.label)+" ")]):_c('div',[_vm._v(_vm._s(data.field.label))])]}},{key:"cell(name_en)",fn:function(data){return [_c('div',{on:{"click":function($event){return _vm.editRecord(data.item, 'review')}}},[_c('span',{staticClass:"mr-2 table-name",attrs:{"size":"sm"}},[_vm._v(" "+_vm._s(data.item.name_en)+" ")])])]}},{key:"cell(name_cn)",fn:function(data){return [_c('div',{on:{"click":function($event){return _vm.editRecord(data.item, 'review')}}},[_c('span',{staticClass:"mr-2 table-name",attrs:{"size":"sm"}},[_vm._v(" "+_vm._s(data.item.name_cn)+" ")])])]}},{key:"cell(action)",fn:function(data){return [_c('b-button',{staticClass:"mr-2",attrs:{"size":"sm"},on:{"click":function($event){return _vm.editRecord(data.item, 'edit')}}},[_vm._v(" 作出修訂 ")]),_vm._v(" ("+_vm._s(data.item.film_count)+") ")]}}])}),_c('div',{staticClass:"pagination mt-3"},[_c('el-pagination',{attrs:{"background":"","current-page":_vm.currentPage,"page-sizes":[20, 50, 100],"page-size":_vm.rows,"layout":" sizes, prev, pager, next","total":_vm.pageTotal},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1),_c('p',[_vm._v("紅色highligh代表此電影為刪除狀態")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }