var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('div',{staticClass:"title"},[_c('p',[_vm._v("22.1 B.O. Report For the year ( By Version )")])]),_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"6"}},[_c('b-button',{attrs:{"size":"sm","variant":"outline-primary"},on:{"click":_vm.goBackReport}},[_vm._v(" 回目錄 ")])],1)],1),(!_vm.hasArea || _vm.hasArea === 'Hong Kong')?_c('div',[_c('b-row',{staticClass:"my-1"},[_c('p',{staticClass:"sub-title"},[_vm._v("香港")]),_c('b-button',{attrs:{"size":"sm m-1","variant":"outline-primary"},on:{"click":function($event){return _vm.godetails('Hong Kong')}}},[_vm._v(" Details ")])],1),_c('b-row',[_c('b-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"bordered":"","striped":"","hover":"","responsive":"","items":_vm.items['HongKong'],"fields":_vm.$store.state.highlightCol.firTable ? _vm.$store.state.newTableFields : _vm.fields},on:{"head-clicked":function($event){return _vm.commonHighLightCol(
            $event,
            _vm.fields,
            _vm.$store.state.newTableFields,
            'firTable'
          )}}},[_vm._v(" > ")])],1)],1):_vm._e(),(!_vm.hasArea || _vm.hasArea === 'macau')?_c('div',[_c('b-row',{staticClass:"my-1"},[_c('p',{staticClass:"sub-title"},[_vm._v("澳門")]),_c('b-button',{attrs:{"size":"sm m-1","variant":"outline-primary"},on:{"click":function($event){return _vm.godetails('Macau')}}},[_vm._v(" Details ")])],1),_c('b-row',[_c('b-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"bordered":"","striped":"","hover":"","responsive":"","items":_vm.items['Macau'],"fields":_vm.$store.state.highlightCol.firTable ? _vm.$store.state.newTableFields : _vm.fields},on:{"head-clicked":function($event){return _vm.commonHighLightCol(
            $event,
            _vm.fields,
            _vm.$store.state.newTableFields,
            'firTable'
          )}}},[_vm._v(" > ")])],1)],1):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }