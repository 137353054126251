<template>
  <section>
    <div v-loading="loading">
      <div class="no-data" v-if="items.length === 0">
        <b>No Data</b>
      </div>
      <b-table
        v-if="items.length > 0"
        bordered
        hover
        responsive
        :items="items"
        :fields="
          $store.state.highlightCol.firTable
            ? $store.state.newTableFields
            : fields
        "
        @head-clicked="
          commonHighLightCol(
            $event,
            fields,
            $store.state.newTableFields,
            'firTable'
          )
        "
      >
        <template #cell(theatre_name)="data">
          <div style="width: 100px">
            {{ data.item.theatre.theatre_name }}
          </div>
        </template>
        <template #cell(title_name)="data">
          <div @click="goPage(data.item, 'checkXML')" style="width: 150px">
            {{ data.item.title_name }}
          </div>
        </template>
        <template #cell(date_to_report)="data">
          <div class="text-nowrap">
            {{ data.item.film.date_to_report }}
          </div>
        </template>
      </b-table>
    </div>
  </section>
</template>
<script>
export default {
  props: ['items'],
  components: {},
  data() {
    return {
      loading: false,
      title: '查閱14日後預售數',
      fields: [
        {
          key: 'id',
          label: 'E_ID',
          sortable: true
        },
        {
          key: 'theatre_name',
          label: 'Theatre Name',
          sortable: true
        },
        {
          key: 'screen_id',
          label: 'House',
          sortable: true
        },
        {
          key: 'film_master_id',
          label: 'F_M ID',
          sortable: true
        },
        {
          key: 'film_id',
          label: 'Film ID',
          sortable: true
        },
        {
          key: 'cinema_id',
          label: 'POS ID',
          sortable: true
        },
        {
          key: 'title_name',
          label: 'Film Name',
          sortable: true
        },
        {
          key: 'date_of_engagement',
          label: 'Date of Eng.',
          sortable: true
        },
        {
          key: 'show_time',
          label: 'Show Time',
          sortable: true
        },
        {
          key: 'total_admissions',
          label: 'Total ADM.',
          sortable: true
        },
        {
          key: 'total_gross',
          label: 'Total B.O.',
          sortable: true
        },
        {
          key: 'date_to_report',
          label: 'Upload Date',
          sortable: true
        }
      ]
    }
  },
  watch: {},
  computed: {
    // preSaleDate() {
    //   const date = moment()
    //   const fut = date.add(14, 'days').format('YYYY-MM-DD')
    //   return fut
    // }
  },
  methods: {
    goPage(item, type) {
      if (type === 'checkXML') {
        this.$router.push({
          name: 'xml-related-record',
          query: {
            file_id: item.xml_file_id,
            xml_id: item.theatre.theatre_no,
            e_id: item.id
          }
        })
      }
    }
  }
}
</script>
<style lang="scss"></style>
<style lang="scss" scoped>
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.sub-title {
  width: 100%;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin: 20px 0;
}
.search {
  display: flex;
  margin: 15px 0;
}
.table-name:hover {
  text-decoration: underline;
}
.no-data {
  width: 100%;
  text-align: center;
  border: 1px solid #eee;
  padding: 20px;
}
</style>
