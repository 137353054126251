<template>
  <div class="date-picker-com" :key="dateKey" :style="`width: ${inputWidth}`">
    <el-input
      @input="inputDate"
      @change="inputDateChange"
      :disabled="isDisabled"
      style="width: 100%;"
      placeholder="00-00-0000"
      v-model="pickTimeInput"
      :tabindex="String(tabIndexNum)"
    >
      <el-date-picker
        @change="datePickerChange"
        :editable="false"
        class="is-date-picker"
        :clearable="false"
        slot="suffix"
        :picker-options="pickerOptions"
        :disabled="isDisabled"
        v-model="pickTime"
        format="dd-MM-yyyy"
        value-format="yyyy-MM-dd"
        type="date"
        placeholder=""
      >
      </el-date-picker>
    </el-input>
  </div>
</template>

<script>
export default {
  props: {
    tabIndexNum: {
      type: Number,
      default: null
    },
    pickTimeVal: {
      type: [String, Date]
    },
    inputWidth: {
      type: String
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    pickerOptions: {
      type: Object
    }
  },
  data() {
    return {
      dateKey: 1,
      pickTimeInput: ""
    };
  },
  watch: {
    pickTimeVal(val) {
      if (val) {
        this.reload = false;
        this.$nextTick(() => {
          this.defaultDate();
        });
      }
    }
  },
  computed: {
    pickTime: {
      get() {
        return this.pickTimeVal;
      },
      set(val) {
        this.$emit("update:pickTimeVal", val);
      }
    }
  },
  created() {
    this.reloadCom = false;
  },
  mounted() {
    this.$nextTick(() => {
      this.defaultDate();
    });
  },
  methods: {
    defaultDate() {
      console.log(this.pickTimeVal, "this.pickTimeVal");

      if (this.pickTimeVal) {
        if (
          this.pickTimeVal === "0000-00-00" ||
          this.pickTimeVal === "0001-01-01"
        ) {
          this.pickTimeInput = "";
        } else {
          this.pickTimeInput = this.$moment(this.pickTimeVal).format();
        }
      } else {
        this.pickTimeInput = "";
      }
    },
    datePickerChange(val) {
      this.pickTimeInput = this.$moment(val).format();
      this.$emit("getRecords", this.pickTimeInput, "changeDate");
    },
    inputDateChange(val) {
      if (val.length < 8 && val.length !== 0) {
        this.$message.error("請輸入正確日期");
      } else if (val.length === 10) {
        this.$emit(
          "getRecords",
          this.$moment(this.pickTimeInput).format("YYYY-MM-DD")
        );
      }
    },
    inputDate(val) {
      const value = val.replace(/-/g, "");
      if (value.length === 8 || value.length === 10) {
        const year = value.slice(4, 8);
        const month = value.slice(2, 4);
        const date = value.slice(0, 2);
        const InvalidDate = new Date(`${year}-${month}-${date}`);
        if (isNaN(InvalidDate)) {
          this.$message.error("請輸入正確日期");
          this.pickTime = "";
        } else {
          this.pickTimeInput = `${date}-${month}-${year}`;
          this.pickTime = `${year}-${month}-${date}`;
        }
        console.log(this.pickTime);
      } else if (value.length > 10) {
        this.$message.error("請輸入正確日期");
      } else if (value.length === 0) {
        this.pickTimeInput = "";
        this.pickTime = "";
      }
    }
  }
};
</script>

<style lang="scss">
.is-date-picker {
  width: 40px !important;
  height: 36px;
  margin: 2px 0;
  cursor: pointer;
  // border: 2px solid rebeccapurple;
  .el-input__inner {
    height: 36px;
    padding: 0 !important;
    border: none;
    color: rgba(255, 255, 255, 0) !important;
  }
}
</style>
