var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('div',{staticClass:"title"},[_c('p',[_vm._v("請選擇戲院")])]),_c('div',[_c('b-button',{staticClass:"handle-button mb-2",attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){return _vm.returnRecord()}}},[_vm._v(" 回上頁 ")])],1),_c('div',[_c('p',[_c('b',{staticStyle:{"color":"#0e72bc"}},[_vm._v("已選擇的日期:")]),_c('b',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.$moment(_vm.$route.query.date).format()))])]),_c('div',[_c('el-input',{staticStyle:{"width":"220px"},attrs:{"placeholder":"請輸入戲院名稱"},on:{"change":_vm.getList},model:{value:(_vm.theatreVal),callback:function ($$v) {_vm.theatreVal=$$v},expression:"theatreVal"}})],1)]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('b-table',{staticClass:"mt-2",attrs:{"bordered":"","striped":"","hover":"","responsive":"","items":_vm.tableData,"fields":_vm.$store.state.highlightCol.firTable
            ? _vm.$store.state.newTableFields
            : _vm.tableFields},on:{"head-clicked":function($event){return _vm.commonHighLightCol(
            $event,
            _vm.tableFields,
            _vm.$store.state.newTableFields,
            'firTable'
          )}},scopedSlots:_vm._u([{key:"cell(action)",fn:function(row){return [_c('b-button',{staticClass:"mr-2",attrs:{"size":"sm"},on:{"click":function($event){return _vm.goDetail(row.item.id, row.item.full_name)}}},[_vm._v(" 作出修訂 ")])]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }