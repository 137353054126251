var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('div',{staticClass:"title"},[_c('p',[_vm._v("分類查閱上載記錄")])]),_vm._l((_vm.categoryList),function(item,index){return _c('div',{key:index,staticClass:"category-tank mb-2 pb-1"},[_c('p',{staticClass:"category-title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('b-row',{staticClass:"my-1"},[_c('b-col',{attrs:{"sm":"8"}},[(item.type !== 'date')?_c('b-form-input',{directives:[{name:"show",rawName:"v-show",value:(!item.noInput),expression:"!item.noInput"}],attrs:{"type":item.type,"tabindex":item.tabNum},model:{value:(item.val),callback:function ($$v) {_vm.$set(item, "val", $$v)},expression:"item.val"}}):((item.type === 'date') & (item.title === '16.9'))?_c('datePickerCom',{directives:[{name:"show",rawName:"v-show",value:(!item.noInput),expression:"!item.noInput"}],attrs:{"pickTimeVal":item.val,"inputWidth":'100%',"tabIndexNum":item.tabNum},on:{"update:pickTimeVal":function($event){return _vm.$set(item, "val", $event)},"update:pick-time-val":function($event){return _vm.$set(item, "val", $event)}}}):_c('datePickerCom',{directives:[{name:"show",rawName:"v-show",value:(!item.noInput),expression:"!item.noInput"}],attrs:{"pickTimeVal":item.val,"inputWidth":'100%',"tabIndexNum":item.tabNum},on:{"update:pickTimeVal":function($event){return _vm.$set(item, "val", $event)},"update:pick-time-val":function($event){return _vm.$set(item, "val", $event)}}}),(
              (item.search_type == 'film' ||
                item.search_type == 'film_master' ||
                item.search_type == 'cinema') &
              (item.type !== 'date')
            )?_c('b-form-input',{directives:[{name:"show",rawName:"v-show",value:(!item.noInput),expression:"!item.noInput"}],staticClass:"mt-1",attrs:{"type":item.type,"tabindex":item.tabNum2},model:{value:(item.val1),callback:function ($$v) {_vm.$set(item, "val1", $$v)},expression:"item.val1"}}):_vm._e(),(
              (item.search_type == 'film' ||
                item.search_type == 'film_master' ||
                item.search_type == 'cinema') &
              (item.type === 'date')
            )?_c('datePickerCom',{directives:[{name:"show",rawName:"v-show",value:(!item.noInput),expression:"!item.noInput"}],staticClass:"mt-1",attrs:{"pickTimeVal":item.val1,"inputWidth":'100%',"tabIndexNum":item.tabNum2},on:{"update:pickTimeVal":function($event){return _vm.$set(item, "val1", $event)},"update:pick-time-val":function($event){return _vm.$set(item, "val1", $event)}}}):_vm._e(),(item.title === '16.10')?_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"value-format":"yyyy","type":"year","tabindex":item.tabNum},model:{value:(item.val),callback:function ($$v) {_vm.$set(item, "val", $$v)},expression:"item.val"}}):_vm._e(),(item.hasCheckBox)?_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"id":("checkbox-" + (item.hasCheckBox)),"name":("checkbox-" + (item.hasCheckBox)),"value":true,"unchecked-value":false},model:{value:(item.val1),callback:function ($$v) {_vm.$set(item, "val1", $$v)},expression:"item.val1"}},[_vm._v(" "+_vm._s(item.hasCheckBox)+" ")]):_vm._e(),_vm._l((item.tips),function(tip){return _c('div',{key:'tip' + tip,staticClass:"mt-1",domProps:{"innerHTML":_vm._s(tip)}})})],2),_c('b-col',{attrs:{"sm":"4"}},[_c('b-button',{attrs:{"variant":"outline-secondary","tabindex":item.btnNum},on:{"click":function($event){return _vm.checkDetail(item)}}},[_vm._v(" "+_vm._s(item.btn)+" ")])],1)],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }