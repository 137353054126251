var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[(
        _vm.title_num === '16.1' ||
        _vm.title_num === '16.1.R' ||
        _vm.title_num === '16.11.R' ||
        _vm.title_num === '16.1.C'
      )?_c('UploadOneRecord',{attrs:{"title":_vm.title,"title_num":_vm.title_num,"val":_vm.val || '',"val1":_vm.val1 || '',"searchType":_vm.searchType,"titleText":_vm.titleText || '',"showLocation":_vm.showLocation,"uploadDate":_vm.uploadDate,"filmIDList":_vm.filmIDList}}):_vm._e(),(
        _vm.title_num === '16.2.1' ||
        _vm.title_num === '16.2.2' ||
        _vm.title_num === '16.2.3' ||
        _vm.title_num === '16.2.4' ||
        _vm.title_num === '16.2.5'
      )?_c('UploadTwoRecord',{attrs:{"title":_vm.title,"title_num":_vm.title_num,"val":_vm.val || '',"val1":_vm.val1 || '',"searchType":_vm.searchType,"titleText":_vm.titleText || '',"showLocation":_vm.showLocation,"uploadDate":_vm.uploadDate,"filmIDList":_vm.filmIDList}}):_vm._e(),(_vm.title_num === '16.3')?_c('UploadThreeRecord',{attrs:{"title":_vm.title,"title_num":_vm.title_num,"val":_vm.val || '',"val1":_vm.val1 || '',"searchType":_vm.searchType,"titleText":_vm.titleText || '',"showLocation":_vm.showLocation,"uploadDate":_vm.uploadDate,"filmIDList":_vm.filmIDList}}):_vm._e(),(_vm.title_num === '16.8' || _vm.title_num === '16.8.23')?_c('UploadEightRecord',{attrs:{"title":_vm.title,"title_num":_vm.title_num,"val":_vm.val || '',"val1":_vm.val1 || '',"searchType":_vm.searchType,"titleText":_vm.titleText || '',"showLocation":_vm.showLocation,"uploadDate":_vm.uploadDate,"filmIDList":_vm.filmIDList}}):_vm._e(),(_vm.title_num === '16.9' || _vm.title_num === '16.9.23' || _vm.title_num === '16.9.23.N')?_c('UploadNineRecord',{attrs:{"title":_vm.title,"title_num":_vm.title_num,"val":_vm.val || '',"val1":_vm.val1 || '',"searchType":_vm.searchType,"titleText":_vm.titleText || '',"showLocation":_vm.showLocation,"uploadDate":_vm.uploadDate,"filmIDList":_vm.filmIDList}}):_vm._e(),(_vm.title_num === '16.10')?_c('UploadTenRecord',{attrs:{"title":_vm.title,"title_num":_vm.title_num,"val":_vm.val || '',"val1":_vm.val1 || '',"searchType":_vm.searchType,"titleText":_vm.titleText || '',"showLocation":_vm.showLocation,"uploadDate":_vm.uploadDate,"filmIDList":_vm.filmIDList}}):_vm._e(),(_vm.title_num === '16.12')?_c('UploadTwelveRecord',{attrs:{"title":_vm.title,"title_num":_vm.title_num,"val":_vm.val || '',"val1":_vm.val1 || '',"searchType":_vm.searchType,"titleText":_vm.titleText || '',"showLocation":_vm.showLocation,"uploadDate":_vm.uploadDate,"filmIDList":_vm.filmIDList}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }