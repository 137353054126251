<template>
  <section>
    <b-card>
      <div class="title">
        <p>{{ infoPageTitle }}</p>
      </div>

      <div>
        <b-button variant="outline-secondary" @click="goBacGenreInfo()">
          回目錄
        </b-button>
      </div>
      <div>
        <b-form ref="form" @submit.prevent="submitForm">
          <b-row v-for="item in items" :id="item.id" :key="item.id" ref="row">
            <b-col cols="12" class="mt-2">
              <p class="sub-title">
                Genre ID : {{ pageIdVal == "edit" ? item.id : "Create New ID" }}
              </p>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Genre Name"
                label-for="genre-name"
                label-cols-md="3"
              >
                <b-form-input
                  id="genre-name"
                  label="Genre Name"
                  v-model="item.filmcat_name_en"
                  name="filmcat_name_en"
                  :disabled="disableChange"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="片種名稱"
                label-for="genre-name-ch"
                label-cols-md="3"
              >
                <b-form-input
                  id="genre-name-ch"
                  label="片種名稱"
                  v-model="item.filmcat_name_zh_hk"
                  name="filmcat_name_zh_hk"
                  :disabled="disableChange"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!-- <b-row>
            <b-col cols="12">
              <hr />
            </b-col>
            <b-col cols="12" v-if="disableChange === true">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="outline-primary"
                class="mr-1 float-right"
                @click="toEdit"
              >
                <span> 作出修訂 </span>
              </b-button>
            </b-col>
          </b-row> -->
          <b-row>
            <b-col offset-md="5">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                :disabled="disableChange"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
      <!-- submit and reset -->
    </b-card>
  </section>
</template>
<script>
import {
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { createGenre, editGenre } from "@/api/film-material-management/genre";

export default {
  name: "DirectorInformationForm",
  components: {
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BRow,
    BCol
  },
  directives: {
    Ripple
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    },
    items: {
      type: Array,
      default: () => [
        {
          id: "",
          filmcat_name_en: "",
          filmcat_name_zh_hk: ""
        }
      ]
    }
  },
  data() {
    return {
      disableChange: false,
      borderClass: "",
      pageId: "",
      pagePath: "",
      pageType: "",
      formdata: {
        filmcat_name_en: "",
        filmcat_name_zh_hk: ""
      }
    };
  },
  watch: {
    $route(to, from) {
      if (to.query.type !== from.query.type) {
        this.pageType = to.query.type;

        this.toEdit();
      }
    }
  },
  created() {
    this.pageType = this.$route.query.type;
    this.pageId = this.$route.query.id;
    this.pagePath = this.$route.path;
    if (this.pageType === "review") {
      this.disableChange = true;
    } else if (this.pageType === "edit") {
      this.borderClass = "redborder";
    }
  },
  computed: {
    infoPageTitle() {
      console.log(this.pageType);
      if (this.pageType === "review") {
        return `${this.title}記錄`;
      } else if (this.pageType === "edit") {
        return `修訂${this.title}記錄`;
      } else {
        return `開立新${this.title}記錄`;
      }
    },
    pageIdVal() {
      if (this.pageType === "review") {
        return "edit";
      } else if (this.pageType === "edit") {
        return "edit";
      } else {
        return "Create New ID";
      }
    }
  },
  methods: {
    goBacGenreInfo() {
      this.$router.push({ name: "genre" });
    },
    toEdit() {
      this.$router.push({
        path: this.pagePath,
        query: { type: "edit", id: this.pageId }
      });
      this.disableChange = false;
      this.borderClass = "redborder";
    },
    submitForm() {
      const data = {
        filmcat_name_en: this.items[0].filmcat_name_en,
        filmcat_name_zh_hk: this.items[0].filmcat_name_zh_hk
      };
      console.log(data);
      if (this.type === "create") {
        createGenre(data)
          .then((res) => {
            console.log(res);
            this.formdata.filmcat_name_en = res.data.filmcat_name_en;
            this.formdata.filmcat_name_zh_hk = res.data.filmcat_name_zh_hk;
            this.$router.push({
              name: "edit-genre",
              query: {
                type: "review",
                id: res.data.id
              }
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.type === "edit") {
        editGenre(this.items[0].id, data)
          .then((res) => {
            console.log(res);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      // this.$router.push({ name: 'genre' })
    }
  }
};
</script>
<style lang="scss">
.sub-title {
  width: 100%;
  color: #0e72bc;
  font-size: 16px;
  font-weight: 500;
  margin: 20px 0;
}
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.line {
  height: 3px;
  color: #0e72bc;
}
.redborder {
  border-color: red;
}
</style>
