<template>
  <section id="management-center">
    <b-card>
      <h2 class="app-color">
        修訂票房資料聯繫
      </h2>
     
      <div  style="border-bottom: 2px solid #0e72bc; margin-bottom:20px;"></div>
      <div>
        <b-row >
          <b-col cols="2">
            Film_Master_ID:
          </b-col>
          <b-col cols="10" class="font-color">3140</b-col>
          <b-col cols="2">
            Film_ID:
          </b-col>
          <b-col cols="10"  class="font-color">F-4049</b-col>
          <b-col cols="2">
            Film Name:
          </b-col>
          <b-col cols="10"  class="font-color">Kamen Rider Saber + Kikai Sentai Zenkaiger Super Hero Senki </b-col>
          <b-col cols="2">
            電影名稱
          </b-col>
          <b-col cols="10"  class="font-color">幪面超人聖刃 + 機界戰隊全開者Super Hero 戰記 </b-col>
          <b-col cols="2">
            院線
          </b-col>
          <b-col cols="10">Cinema City </b-col>
          <b-col cols="2">
            院線 POS ID
          </b-col>
          <b-col cols="10">3664 </b-col>
        </b-row>
      </div>
      <hr class="line">
      <div>此影片之相關票房記錄</div>
      <hr class="line">
      <div>
        Records Updated in Final Reports Database - <br/>
        Records Updated in Hourly Reports Database -
      </div>
      <hr class="line">
       <div>
        <b-button
          size="sm"
          variant="outline-secondary"
          class="handle-button"
           @click="back"
        >
          返回檢視及修訂
        </b-button>
      
      </div>
    </b-card>
  </section>
</template>
<script>
import {BTable, BButton, BCard, BPagination, BAvatar,
  BRow,
  BCol,BForm, BFormGroup, BFormInput,BFormDatepicker
} from 'bootstrap-vue'
import vSelect from 'vue-select'
export default {
  components: {BTable, BButton, BCard, BPagination, BAvatar,BFormDatepicker,
  BRow,
  BCol,BForm, BFormGroup, BFormInput,
  },
  data() {
    return{
      rows: 5,
      perPage: 1,
      currentPage: 1,
      fields: [ { key: 'Film_Id', label: 'Film ID' },
       { key: 'Circuit', label: ' Circuit' },
       { key: 'Pos_Id', label: ' POS ID' },
       {
         key:'Title_Circuit',
         label:'Title stated by Circuit'
       },
       { key: 'F_R', label: 'F-R' },
       { key: 'H_R', label: ' H-R' },
       { key: 'Status', label: 'Status' },
       { key: 'actions', label: '' },
       ],
       
      items:[
        {
          Film_Id: 'F-4016',
          Circuit:'Chinachem Circuit',
          Pos_Id:1449,
          Title_Circuit:'我和我的父輩*My Country,My Parents(2D)',
          F_R:'',
          H_R:'',
          Status:'',
        },
        {
          Film_Id: 'F-4016',
          Circuit:'Cinema City',
          Pos_Id:3641,
          Title_Circuit:'我和我的父輩*My Country,My Parents(2D)',
          F_R:'',
          H_R:'',
          Status:'',
        }
      ]
    }
  },
  methods: {
    back(){
        this.$router.go(-1);//返回上一层
    }

  },
}
</script>
<style lang="scss" scope="scoped">
.app-color{
    font-size: 20px;
    font-weight: 500;
    color: #0e72bc;
}
.line{
    height: 2px;

}
.lh30{
  width: 250px;
}
.table-scroll{
    white-space: nowrap;
 }
 .font-color{
   color: blue;
 }
</style>
