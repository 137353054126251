<template>
  <div class="xml-text-com">
    <codemirror
      ref="cmEditor"
      class="xml-text"
      v-model="xmlTextVal"
      :options="cmOptions"
    ></codemirror>
  </div>
</template>

<script>
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/idea.css'
import 'codemirror/mode/xml/xml.js'
import 'codemirror/mode/htmlmixed/htmlmixed'
import 'codemirror/mode/htmlembedded/htmlembedded'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/addon/fold/foldcode.js'
import 'codemirror/addon/fold/foldgutter.css'
import 'codemirror/addon/fold/foldgutter.js'
import 'codemirror/addon/fold/brace-fold.js'
import 'codemirror/addon/fold/xml-fold.js'
import 'codemirror/addon/fold/indent-fold.js'
import 'codemirror/addon/fold/markdown-fold.js'
import 'codemirror/addon/fold/comment-fold.js'

import 'codemirror/addon/edit/closebrackets.js'
import 'codemirror/addon/edit/closetag.js'
import 'codemirror/keymap/sublime.js'

import 'codemirror/addon/hint/show-hint.css'
import 'codemirror/addon/hint/show-hint.js'
import 'codemirror/addon/hint/css-hint.js'
import 'codemirror/addon/hint/html-hint.js'
import 'codemirror/addon/hint/javascript-hint.js'
import 'codemirror/addon/display/autorefresh'

export default {
  props: {
    xmlText: {
      type: String
    },
  },
  components: {},
  data() {
    return {
      cmOptions: {
        mode: 'xml',
        theme: 'idea',
        line: true,
        lineNumbers: true,
        lineWrapping: true,
        tabSize: 4,
        styleActiveLine: true,
        autoRefresh: true
      }
    }
  },
  computed: {
    xmlTextVal: {
      get() {
        return this.xmlText
      },
      set(value) {
        this.$emit('update:xmlText', value)
      }
    }
  },
  methods: {},
  mounted() {
    // const parser = new DOMParser()
    // const xmlDoc = parser.parseFromString(this.xmlTextVal, 'application/xml')
    // const tagElement = xmlDoc.querySelector('date_of_report')
    // console.log(tagElement)
    // if (tagElement) {
    //   // 更新标签内容
    //   tagElement.textContent = '新内容'

    //   // 将更新后的XML转换回字符串
    //   const serializer = new XMLSerializer()
    //   this.xmlTextVal = serializer.serializeToString(xmlDoc)
    // }
    const editor = this.$refs.cmEditor.codemirror
    const lastLine = editor.lastLine() + 1
    const hightlightNum = [1, 7, 8, 9, lastLine]
    console.log(this.xmlTextVal)
    if (this.xmlTextVal) {
      this.$nextTick(() => {
        setTimeout(() => {
          hightlightNum.map((el) => {
            editor.addLineClass(el, 'background', 'xml-hightlight-color')
          })
        }, 500)
      })
    }
  },
  created() {}
}
</script>

<style lang="scss">
.xml-text-com {
  .CodeMirror {
    height: 650px;
  }
}
.xml-hightlight-color {
  background: rgba(255, 0, 0, 0.176);
}
</style>
