<template>
  <div class="check-xml-upload">
    <b-card v-loading="loading">
      <div class="title">
        <p>查閱XML上傳檔</p>
      </div>
      <div class="d-flex align-items-center">
        <b-button variant="primary" class="mb-2 mr-2" @click="byTheatre()">
          By Theatre
        </b-button>
        <p>XML檔案之在案記錄</p>
      </div>
      <span>可查閱由15-09-2012開始 - 至 {{ time }}為止的在案記錄</span>
      <b-row class="my-1">
        <b-col sm="3">
          <datePickerCom :pickTimeVal.sync="selectDate" :inputWidth="'100%'" />
        </b-col>
        <b-col>
          <b-button
            @click="getXmlList(active == 1 ? 'form' : 'list')"
            variant="outline-secondary"
          >
            觀看這天的XML原檔記錄
          </b-button>
        </b-col>
      </b-row>
      <b-row class="my-1">
        <b-col sm="3">
          <b-form-input style="width: 100%" type="text" v-model="xmlId" />
        </b-col>
        <b-col>
          <b-button variant="outline-secondary" @click="checkOrigin(xmlId)">
            觀看編號的XML原檔記錄
          </b-button>
        </b-col>
      </b-row>

      <b-button variant="outline-secondary" class="mb-1" @click="changeView">
        {{ active === 0 ? "以全日列表模式觀看" : "以正常列表模式觀看" }}
      </b-button>
      <div v-show="active === 0">
        <b-table
          bordered
          striped
          hover
          :items="boxoffice"
          :fields="
            $store.state.highlightCol.secTable
              ? $store.state.newSecTableFields
              : boxofficeFields
          "
          @head-clicked="
            commonHighLightCol(
              $event,
              boxofficeFields,
              $store.state.newSecTableFields,
              'secTable'
            )
          "
        >
          <template v-slot:cell(action)="row">
            <b-button size="sm" class="mr-2" @click="checkOrigin(row.item.id)">
              觀看XML原檔
            </b-button>
          </template>
        </b-table>
      </div>
      <div v-show="active === 1" class="table-center-data" v-loading="loading">
        <centerDataOfXML
          :isOrgXML="true"
          ref="isCenterDataOfXML"
          :datePick="selectDate"
          :showFilmRecord="false"
          :scroll="300"
        />
      </div>
      <div class="pagination mt-3" v-if="active === 0">
        <el-pagination
          class=""
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[20, 50, 100]"
          :page-size="rows"
          layout="sizes, prev, pager, next"
          :total="pageTotal"
        >
        </el-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import { getXmlList } from "@/api/checkXmlUpload.js"
import centerDataOfXML from "@/views/management/managementCenter.vue"
import store from "@/store"
import datePickerCom from "@/views/components/datePicker.vue"
export default {
  components: { centerDataOfXML, datePickerCom },
  data() {
    return {
      selectDate: "",
      time: "",
      loading: false,
      pageTotal: 10,
      xmlId: null,
      rows: 20,
      perPage: 1,
      currentPage: 1,
      select: "Hong Kong",
      selectOptions: ["Hong Kong", "Macau"],
      items: [],
      active: 1,
      boxofficeFields: [
        { key: "circuit_name", label: "Circuit", sortable: true },
        { key: "src_type", label: "Report Type", sortable: true },
        { key: "time", label: "Time Stamp", sortable: true },
        { key: "id", label: "XML ID", sortable: true },
        { key: "file_name", label: "XML File Name", sortable: true },
        { key: "action", label: "" },
      ],
      boxoffice: [],
      listType: "form",
    }
  },
  computed: {
    dataListStart() {
      if (this.rows === 5) {
        return (this.currentPage - 1) * 5 + 1
      } else {
        return (this.currentPage - 1) * 2 * (this.rows / 2) + 1
      }
    },
    dataListStop() {
      if (this.currentPage === Math.ceil(this.pageTotal / this.rows)) {
        return this.pageTotal
      } else {
        return this.rows * this.currentPage
      }
    },
  },
  mounted() {
    this.getXmlList(this.listType)
    this.getTime()
  },
  methods: {
    getTime() {
      const d = new Date()
      this.time = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`
    },
    goPage(data, value, type) {
      console.log(data)
      console.log(value)
      if (type === "dayfinal") {
        if (value.status === "?" || value.status === "") {
          this.$router.push({
            name: "dayend-file",
            query: {
              xml_file_id: value.xml_fileid,
              xml_file_name: value.xml_filename,
              theatre_id: data.theatre_id,
              theatre_no: data.theatre_no,
            },
          })
        } else {
          this.$router.push({
            name: "original-record-file",
            query: {
              id: value.xml_id,
            },
          })
        }
      } else if (value.status === "OK" || value.status === "OK-") {
        this.$router.push({
          name: "original-record-file",
          query: {
            id: value.xml_id,
          },
        })
      }

      // this.$router.push({ name: 'dayend-file' })
    },
    changeView() {
      this.active === 0 ? (this.active = 1) : (this.active = 0)
      this.active === 0 ? this.getXmlList("list") : this.getXmlList("form")
    },
    handleSizeChange(val) {
      this.rows = val
      this.active === 0 ? this.getXmlList("list") : this.getXmlList("form")
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.active === 0 ? this.getXmlList("list") : this.getXmlList("form")
    },
    getXmlList(listType) {
      store.commit("updateStateData")

      if (listType === "list") {
        this.loading = true
        const data = {
          type: listType,
          page: this.currentPage,
          count: this.rows,
          file_id: "",
          date: this.selectDate,
          area: window.sessionStorage.getItem("area") || "",
        }
        getXmlList(data)
          .then((res) => {
            if (listType === "form") {
              this.boxoffice = res.data.mainLis
            } else {
              this.boxoffice = res.data.data
              this.pageTotal = res.data.total
            }

            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            console.log(error)
          })
      } else {
        this.$refs.isCenterDataOfXML.getMainList(this.selectDate)
      }
    },

    checkOrigin(id) {
      this.$router.push({
        path: "/boxoffice/original-record-file",
        query: { id },
      })
    },
    byTheatre() {
      this.$router.push("/boxoffice/check-xml-theatre")
    },
  },
}
</script>
<style lang="scss">
.is-date-picker {
  width: 40px !important;
  height: 36px;
  margin: 2px 0;
  // border: 2px solid rebeccapurple;
  .el-input__inner {
    height: 36px;
    padding: 0 !important;
    border: none;
    color: rgba(255, 255, 255, 0);
  }
}
.check-xml-upload {
  @media screen and (max-width: 455px) {
    .card {
      margin-bottom: 10px;
    }
    .card-body {
      padding: 5px;
      p {
        -webkit-text-size-adjust: none;
        font-size: 10px;
        height: auto;
      }
      .b-table {
        -webkit-text-size-adjust: none;
        font-size: 10px;
        thead {
          tr {
            th {
              width: 27px;
              div {
                font-size: 10px;
              }
            }
            th:first-child {
              width: 100px !important;
              div {
                width: 100%;
              }
            }
          }
        }
        tbody {
          tr {
            td {
              span {
                display: block;
                margin: 0 auto;
                line-height: 22px;
                .b-avatar {
                  width: 22px !important;
                  height: 22px !important;
                }
              }
            }
          }
        }
      }
      p {
        margin-bottom: 0px;
        line-height: 15px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
</style>
