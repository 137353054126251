<template>
  <section id="management-center">
    <b-card>
      <div class="title">
        <p>修訂電影版本記錄(Film_ID)</p>
      </div>
      <div v-loading="verLoading">
        <div class="header-title mb-1">
          <p>Film_Master_ID: {{ master_id }}</p>
          <p>Film Name：{{ $route.query.film_name }}</p>
        </div>
        <div class="line"></div>
        <div class="film-ver-record mt-1">
          <div class="ver-box">
            <div class="ver-content">
              <p class="sub-title" v-if="type === 'edit'">
                影片Film_ID：{{
                  filmInfo.from_arch && filmInfo.from_arch === 1 ? "" : "F-"
                }}{{ film_id }}
              </p>
              <p class="sub-title" v-if="type === 'create'">
                影片Film_ID：Create New Film_ID
              </p>
            </div>
            <div class="ver-content">
              <p class="sub-title" v-if="type === 'edit'">
                {{ filmInfo.film_language }} ({{ filmInfo.film_visual_effect }})
                {{ filmInfo.remark_2 ? `[${filmInfo.remark_2}]` : "" }}
              </p>
            </div>
            <div class="ver-content mb-1"></div>
            <div
              class="ver-content"
              v-for="(item, index) in itemVerCat"
              :key="index"
            >
              <b-form-group
                label-cols="4"
                label-cols-lg="3"
                :label="item.label"
                label-for="input-default-edit"
              >
                <b-form-input
                  v-if="item.type === 'input'"
                  v-model="filmInfo[item.key]"
                  :id="'input-ver-edit' + item.key"
                ></b-form-input>
                <el-select
                  v-else-if="item.type === 'select'"
                  filterable
                  style="width: 100%"
                  placeholder=" "
                  v-model="filmInfo[item.key]"
                >
                  <el-option
                    v-for="(item, index) in optionsList[item.key]"
                    :key="index"
                    :label="item.text"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <datePickerCom
                  v-else-if="item.type === 'date'"
                  :pickTimeVal.sync="filmInfo[item.key]"
                  :inputWidth="'100%'"
                />
              </b-form-group>
            </div>
          </div>
          <div class="d-flex justify-content-end mt-1 mr-1">
            <b-button
              size="sm"
              @click="saveFilm(type)"
              variant="outline-primary"
              >Save/存檔</b-button
            >
          </div>
          <div class="line"></div>
        </div>
      </div>

      <div class="film-ver-record mt-1">
        <p class="sub-title">此影片之各種版本記錄：</p>
        <div class="line"></div>
        <div v-loading="otherLoading">
          <div
            class="ver-box"
            v-for="(otherItem, otherIndex) in otherVerList"
            :key="otherIndex"
          >
            <div class="ver-content">
              <p class="sub-title">
                影片Film_ID：{{
                  otherItem.from_arch && otherItem.from_arch === 1 ? "" : "F-"
                }}{{ otherItem.id }}
              </p>
            </div>
            <div class="ver-content">
              <p class="sub-title">
                {{ otherItem.language }} ({{ otherItem.remark_1 }})
                {{ otherItem.remark_2 ? `[${otherItem.remark_2}]` : "" }}
              </p>
            </div>
            <div class="ver-content mb-1"></div>
            <div
              class="ver-content"
              v-for="(item, index) in itemVerCat"
              :key="index"
            >
              <b-row>
                <b-col>
                  <div class="d-flex">
                    <p class="mr-1" style="width: 30%">{{ item.label }} :</p>
                    <p
                      class="film-ver-val"
                      v-if="item.type === 'date' && item.key !== 'date_off'"
                    >
                      {{
                        isValidDate(otherItem[item.key]) === "0000-00-00"
                          ? "00-00-0000"
                          : isValidDate(otherItem[item.key])
                      }}
                    </p>
                    <p
                      class="film-ver-val"
                      v-else-if="
                        item.type === 'date' && item.key === 'date_off'
                      "
                    >
                      {{
                        otherItem.date_off === "0000-00-00"
                          ? "00-00-0000"
                          : $moment(otherItem.date_off).format()
                      }}
                    </p>
                    <p class="film-ver-val" v-else>
                      {{ otherItem[item.key] }}
                    </p>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="line"></div>
          </div>
        </div>
      </div>
    </b-card>
  </section>
</template>
<script>
import { getFilmMasterDetail } from "@/api/film-material-management/filmMaster";
import {
  getFilmDetail,
  createFilm,
  editFilm
} from "@/api/film-material-management/film";
import datePickerCom from "@/views/components/datePicker.vue";
import { getManageProperties } from "@/api/properties";

export default {
  components: { datePickerCom },

  data() {
    return {
      otherVerList: [],
      otherLoading: false,
      verLoading: false,
      film_id: "",
      optionsList: {
        status: [
          { text: "", value: "" },
          { text: "deleted", value: "deleted" },
          { text: "No_show", value: "No_show" }
        ],
        film_version: [],
        film_visual_effect: [],
        house_effect: [],
        film_rating: [],
        film_language: [],
        film_title_language: [],
        film_format: []
      },
      loading: false,
      master_id: "",
      film_name: "",
      itemVerCat: [
        {
          label: "影片輪次*",
          key: "film_version",
          type: "select",
          required: true
        },
        {
          label: "視像效果*",
          key: "film_visual_effect",
          type: "select",
          required: true
        },
        {
          label: "電影評級",
          key: "film_rating",
          type: "select"
        },
        {
          label: "音軌語言",
          key: "film_language",
          type: "select"
        },
        {
          label: "Theatre Effect",
          key: "house_effect",
          type: "select"
        },
        {
          label: "片長(分鐘)",
          key: "film_duration",
          type: "input"
        },
        {
          label: "字幕語言",
          key: "film_title_language",
          type: "select"
        },
        {
          label: "上映日期",
          key: "date_release",
          type: "date"
        },
        {
          label: "優先場日期",
          key: "date_on",
          type: "date"
        },
        {
          label: "影片格式",
          key: "film_format",
          type: "select"
        },
        {
          label: "落畫日期",
          key: "date_off",
          type: "date"
        },
        {
          label: "顯示票房日期",
          key: "date_to_report",
          type: "date"
        },
        {
          label: "Status",
          key: "status",
          type: "select"
        }
      ],
      filmInfo: {
        film_master_id: "",
        title_name: "",
        film_version: "",
        film_rating: "",
        film_title_language: "",
        film_language: "",
        film_duration: "",
        year: "",
        film_visual_effect: "",
        house_effect: "",
        date_to_report: "",
        date_on: "",
        date_off: "",
        date_release: "",
        film_format: "",
        status: ""
      },
      type: "",
      canSave: false
    };
  },
  computed: {},
  watch: {
    "filmInfo.date_to_report"(to) {
      console.log(to, this.$moment().format("YYYY-MM-DD"));

      console.log(to === this.$moment().format("YYYY-MM-DD"));
      if (
        to === this.$moment().format("YYYY-MM-DD") ||
        to > this.$moment().format("YYYY-MM-DD")
      ) {
        this.filmInfo.status = "No_Show";
      } else if (to < this.$moment().format("YYYY-MM-DD")) {
        this.filmInfo.status = "";
      }
    }
  },
  mounted() {},
  methods: {
    //get options list
    getManageProperties() {
      getManageProperties("").then((res) => {
        const obj = new Object();
        Object.keys(this.optionsList).map((key) => {
          if (key !== "status") {
            obj[key] = [];
          }
        });
        res.data.map((el) => {
          Object.keys(obj).map((op) => {
            let title = "";
            if (el.type === op) {
              if (el.name_en === el.name_zh_hk) {
                title = el.name_en;
                obj[op].push({
                  text: title,
                  value: el.name_en
                });
              } else {
                title = `${el.name_en} [${el.name_zh_hk}]`;
                obj[op].push({
                  text: title,
                  value: el.name_en
                });
              }
            }
            this.optionsList[op] = obj[op];
            if (
              el.is_default === 1 &&
              this.type === "create" &&
              el.type === op
            ) {
              this.filmInfo[op] = el.name_en;
            }
          });
        });
      });
    },
    saveFilm(type) {
      let canSave = "";
      const requiredList = [];
      this.itemVerCat.map((el) => {
        if (el.required) {
          if (this.filmInfo[el.key] !== "") {
            console.log(this.filmInfo[el.key], el.key);

            canSave = true;
          } else {
            requiredList.push(`${el.label} field is required.`);
            canSave = false;
          }
        }
      });
      if (requiredList && requiredList.length > 0) {
        this.$message({
          type: "warning",
          dangerouslyUseHTMLString: true,
          message: requiredList.join("<br/>")
        });
      } else {
        this.verLoading = true;
        this.filmInfo.year = new Date(this.filmInfo.date_on).getFullYear();
        const filmInfo = {
          ...this.filmInfo,
          format: this.filmInfo.film_format,
          language: this.filmInfo.film_language,
          rating: this.filmInfo.film_rating,
          run_ver: this.filmInfo.film_version,
          sub_title: this.filmInfo.film_title_language,
          film_master_id: this.master_id,
          title_name: this.film_name,
          remark_1: this.filmInfo.film_visual_effect,
          remark_2: this.filmInfo.house_effect
        };
        if (type === "edit") {
          editFilm(this.film_id, filmInfo).then((res) => {
            this.getFilmDetail();
            this.$router.go(-1);
          });
        } else {
          createFilm(filmInfo).then((res) => {
            this.verLoading = false;
            this.$router.go(-1);
          });
        }
      }
    },
    getFilmDetail() {
      this.verLoading = true;
      getFilmDetail(this.film_id)
        .then((res) => {
          console.log(res);
          this.filmInfo.film_master_id = this.master_id;
          this.filmInfo.title_name = res.data.title_name;
          this.filmInfo.film_version = res.data.run_ver.run_ver;
          this.filmInfo.film_rating = res.data.rating;
          this.filmInfo.year = res.data.year;
          this.filmInfo.film_title_language = res.data.sub_title;
          this.filmInfo.film_language = res.data.language;
          this.filmInfo.film_duration = res.data.film_duration;
          this.filmInfo.film_visual_effect = res.data.remark_1;
          this.filmInfo.house_effect = res.data.remark_2;
          this.filmInfo.date_to_report = res.data.date_to_report;
          this.filmInfo.date_on = res.data.date_on;
          this.filmInfo.date_off = res.data.date_off;
          this.filmInfo.date_release = res.data.date_release;
          this.filmInfo.film_format = res.data.format;
          this.filmInfo.status = res.data.status;
          this.filmInfo.from_arch = res.data.from_arch;
          if (
            this.$moment(this.filmInfo.date_to_report).diff(
              this.$moment(),
              "days"
            ) > 0
          ) {
            this.filmInfo.status = "No_show";
          }
          this.verLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.verLoading = false;
        });
    },
    getFilmMasterDetail() {
      this.otherLoading = true;
      getFilmMasterDetail(this.master_id)
        .then((res) => {
          const list = [];
          res.data.film.map((el) => {
            el.film_version = el.run_ver.run_ver;
            el.house_effect =
              el.remark_2 === "" || !el.remark_2 ? "[無影院特效]" : el.remark_2;
            el.film_format = el.format;
            el.film_rating = el.rating;
            el.film_title_language = el.sub_title;
            el.film_language = el.language;
            el.film_visual_effect = el.remark_1;
            if (el.id !== this.film_id) {
              list.push(el);
            }
          });
          this.otherVerList = list;
          console.log(this.otherVerList);
          this.otherLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.otherLoading = false;
        });
    },
    returnRecord() {
      // this.$router.push({ name: 'add-xml-record' })
      this.$router.go(-1);
    }
  },
  created() {
    this.film_id = this.$route.query.id;
    this.master_id = this.$route.query.master_id;
    this.film_name = this.$route.query.film_name;
    this.type = this.$route.query.type;
    if (this.type === "edit") {
      this.getFilmDetail();
    }
    this.getFilmMasterDetail();
    this.getManageProperties();
  }
};
</script>
<style lang="scss" scope="scoped">
.line {
  width: 100%;
  height: 1px;
  background: #0e72bc;
  margin: 10px 0;
}
.lh30 {
  width: 250px;
}
.sub-title {
  width: 90%;
  color: #0e72bc;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.film-ver-record {
  width: 100%;
  .ver-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    .ver-content {
      width: 31%;
      margin-left: 20px;
    }
  }
  .film-ver-val {
    background: #eee;
    width: 70%;
    padding: 3px;
  }
}
</style>
