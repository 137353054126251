<template>
  <div v-loading="loading">
    <div class="title">
      <p>{{ title }}</p>
    </div>
    <b-button
      size="sm"
      @click="$router.push({ name: 'check-upload-record' })"
      class="mb-1"
      style="margin-left: 8px"
      variant="outline-dark"
      >回目錄</b-button
    >

    <div
      style="display: flex; align-items: center"
      v-if="title_num === '16.1.C'"
    >
      <el-select
        v-model="areaSelect"
        popper-append-to-body
        popper-class="option-group"
        class="mr-1 search-select"
        style="width: 25%"
        placeholder="Select districts"
      >
        <el-option
          v-for="(item, index) in areaList"
          :key="index"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="selectTheatre"
        clearable
        filterable
        popper-append-to-body
        popper-class="option-group"
        class="mr-1 search-select"
        style="width: 45%"
        @change="selectCircuitChange"
        placeholder="選擇院線或戲院"
      >
        <el-option
          v-for="(item, index) in newFilmList"
          :key="index"
          @click.native="selectOption(item)"
          :label="item.full_name"
          :value="item.type + item.id"
        >
        </el-option>
      </el-select>
      <b-button variant="primary" @click="search">Search</b-button>
      <b-form-checkbox
        class="ml-4"
        id="checkbox-showHiddenMovie"
        v-model="isShowAllFilm"
        v-if="title_num === '16.1.C'"
        @change="search()"
      >
        隱藏電影
      </b-form-checkbox>
    </div>
    <div class="mt-1 mb-1">
      <b style="color: #0e72bc">
        List of Day Final Data as of
        <span style="color: red">{{ $moment(val).format() }}</span
        ><span v-if="val1" style="color: red"
          >~{{ $moment(val1).format() }}</span
        >
        (Total on all records within in this period.)
      </b>
      <div class="get-excel" v-if="title_num !== '16.11.R'">
        <b-button
          size="sm"
          @click="
            excelExport(
              title_num === '16.1' ? 'day' : 'month',
              val,
              val1,
              showExportText
            )
          "
          >Get a Detail Excel file of all shows on
          {{
            title_num !== "16.1"
              ? $moment(val).format("YYYY-MM")
              : $moment(val).format()
          }}</b-button
        >
        <span style="color: red" v-show="showExportText">
          (Excel數據加載中...)
        </span>
      </div>
      <div>
        <b-table
          bordered
          class="mt-1"
          striped
          hover
          responsive
          :items="tableData"
          :fields="
            $store.state.highlightCol.firTable
              ? $store.state.newTableFields
              : tableFields
          "
          @head-clicked="
            commonHighLightCol(
              $event,
              tableFields,
              $store.state.newTableFields,
              'firTable'
            )
          "
        >
          <template #thead-top="data">
            <b-tr>
              <b-th
                :colspan="
                  title_num === '16.1'
                    ? 7
                    : title_num === '16.1.R'
                    ? 7
                    : title_num === '16.11.R'
                    ? 6
                    : title_num === '16.1.C'
                    ? 5
                    : ''
                "
              ></b-th>
              <b-th> total SHOWS :{{ totalVal("showings", hasItem) }}</b-th>
              <b-th> total ADM :{{ totalVal("TA", hasItem) }}</b-th>
              <b-th> total B.O. :{{ totalVal("TG", hasItem) }}</b-th>
              <b-th v-if="title_num !== '16.1.C'" colspan="2"></b-th>
            </b-tr>
          </template>
          <template #head(country)>
            <div style="width: 82px; text-wrap: wrap">Production COUNTRY</div>
          </template>
          <template #cell(theatre_name)="data">
            <div class="text-nowrap">
              "
              {{ data.item.theatre_name }}
            </div>
          </template>
          <template #cell(film_title_c)="data">
            <div style="width: 180px">
              {{ data.item.film_title_c }}
            </div>
          </template>
          <template #cell(film_title_e)="data">
            <div style="width: 180px">
              {{ data.item.film_title_e }}
            </div>
          </template>
          <template #cell(film_title)="data">
            <div style="width: 180px">
              {{ data.item.film_title }}
            </div>
          </template>
          <template #cell(date_release)="data">
            <div>
              <span
                :style="`color:${setRedText(
                  data.item.date_release,
                  data.item.date_on
                )}`"
                >{{
                  data.item.date_release === "0000-00-00"
                    ? data.item.date_release
                    : $moment(data.item.date_release).format()
                }}</span
              >
            </div>
          </template>
          <template #cell(date)="data">
            <div class="text-nowrap">
              {{ data.item.date }}
            </div>
          </template>
          <template #cell(show_time)="data">
            <div class="text-nowrap">
              {{ data.item.show_time }}
            </div>
          </template>
          <template #cell(off_date)="data">
            <div class="text-nowrap">
              {{
                isValidDate(
                  dateOffFormat(data.item.off_date, data.item.date_release)
                )
              }}
            </div>
          </template>
          <template #cell(TA)="data">
            <div>
              {{ parseFloat(data.item.TA).toLocaleString() }}
            </div>
          </template>
          <template #cell(TG)="data">
            <div>
              {{ BoCurrency }} {{ parseFloat(data.item.TG).toLocaleString() }}
            </div>
          </template>
          <template #cell(showings)="data">
            <div>
              {{ parseFloat(data.item.showings).toLocaleString() }}
            </div>
          </template>
          <template v-if="title_num === '16.1.R'"> </template>
          <template v-if="title_num === '16.1'" v-slot:cell(dateaction)="data">
            <div class="d-flex">
              <b-button
                size="sm"
                style="width: 88px; padding: 6px"
                @click="goAllVersionrecord('m', data.item.film_master_id)"
              >
                所有版本
              </b-button>
              <b-button
                style="width: 98px; padding: 6px"
                size="sm"
                class="ml-1"
                @click="goAllVersionrecord('f', data.item.film_id)"
              >
                指定之版本
              </b-button>
            </div>
          </template>
        </b-table>
      </div>
    </div>
    <p v-if="title_num === '16.1.R' || title_num === '16.11.R'">
      紅色highlight代表優先場
    </p>
  </div>
</template>

<script>
import { getEngByDateRecord } from "@/api/record.js"
import { getAreas, getCircuitTheatres } from "@/api/occupancy.js"
export default {
  props: {
    hasItem: {
      type: String,
      default: null,
    },
    title: {
      type: String,
    },
    title_num: {
      type: String,
    },
    val: {
      type: String,
    },
    val1: {
      type: String,
    },
    titleText: {
      type: String,
    },
    uploadDate: {
      type: String,
    },
    filmIDList: {
      type: String,
    },
    searchType: {
      type: String,
    },
  },
  data() {
    return {
      query: {},
      showExportText: false,
      theatreVal: "",
      circuitVal: "",
      newFilmList: [],
      selectTheatre: "",
      areaList: [],
      areaSelect: "",
      tableData: [],
      loading: false,
      select: "All Location",
      selectOptions: [
        { value: "All Location", text: "全部地區" },
        { value: "Hong Kong", text: "香港" },
        { value: "Macau", text: "澳門" },
      ],
      isShowAllFilm: true,
      filmInfoList: [],
      excelData: [],
      isArea: window.sessionStorage.getItem("area") || "",
    }
  },
  computed: {
    setRedText() {
      return function (date_release) {
        if (
          this.$moment(date_release, "YYYY-MM-DD") >
          this.$moment(this.val1, "YYYY-MM-DD")
        ) {
          return "red"
        } else {
          return ""
        }
      }
    },
    BoCurrency() {
      if (this.isArea === "Hong Kong") {
        return "HKD$"
      } else if (this.isArea === "macau") {
        return "MOP$"
      } else {
        return "$"
      }
    },
    totalVal() {
      return function (val, hasItem) {
        if (hasItem) {
          return parseFloat(
            this.tableData[hasItem].reduce(
              (sum, item) => sum + Number(item[val]),
              0
            )
          ).toLocaleString()
        } else {
          return parseFloat(
            this.tableData.reduce((sum, item) => sum + Number(item[val]), 0)
          ).toLocaleString()
        }
      }
    },
    tableFields() {
      if (this.title_num === "16.11.R") {
        return [
          { key: "film_master_id", label: "F_M ID", sortable: true },
          { key: "run", label: "Run" },
          { key: "film_title_c", label: "Film Title(C)", sortable: true },
          { key: "film_title_e", label: "Film Title(E)", sortable: true },
          { key: "country", label: "Country", sortable: true },
          { key: "p_year", label: "P.Year", sortable: true },
          { key: "showings", label: "Shows", sortable: true },
          { key: "TA", label: "Total Adm.", sortable: true },
          { key: "TG", label: "Total B.O.", sortable: true },
          { key: "date_release", label: "Release Date", sortable: true },
          { key: "off_date", label: "Off Date", sortable: true },
        ]
      } else if (this.title_num === "16.1.C") {
        return [
          { key: "circuit_id", label: "Circuit ID", sortable: true },
          { key: "circuit_name", label: "Circuit", sortable: true },
          { key: "theatre_id", label: "Theatre ID", sortable: true },
          { key: "theatre", label: "Theatre", sortable: true },
          { key: "film_no", label: "No. of film", sortable: true },
          { key: "showings", label: "No. of show", sortable: true },
          { key: "TG", label: "Total B.O.", sortable: true },
          { key: "TA", label: "Total Adm.", sortable: true },
        ]
      } else {
        const list = [
          { key: "film_master_id", label: "F_M ID", sortable: true },
          { key: "film_id", label: "Film ID", sortable: true },
          { key: "film_title", label: "Film Title", sortable: true },
          { key: "country", label: "Production Country", sortable: true },
          { key: "effect", label: "Effect", sortable: true },
          { key: "lang", label: "Lang.", sortable: true },
          { key: "run", label: "Run", sortable: true },
          { key: "showings", label: "Shows", sortable: true },
          { key: "TA", label: "Total Adm.", sortable: true },
          { key: "TG", label: "Total B.O.", sortable: true },
          { key: "dateaction", label: "" },
        ]
        if (this.title_num === "16.1.R") {
          list.map((el) => {
            if (el.key === "dateaction") {
              el.key = "date_release"
              el.label = "Release Date"
              el.sortable = true
            }
          })
          list.push({ key: "status", label: "status", sortable: true })
        }
        return list
      }
    },
  },
  methods: {
    search() {
      console.log(this.$route.query, "this.$router.query")
      this.$router.push({
        path: "/boxoffice/upload-record-detail",
        query: {
          ...this.$route.query,
          region: this.areaSelect || "",
          circuit: this.circuitVal || "",
          theatre: this.theatreVal || "",
          show_all_film: this.isShowAllFilm || "",
        },
      })
      this.getEngByDateRecord()
    },
    selectCircuitChange(val) {
      if (!val) {
        this.circuitVal = ""
        this.theatreVal = ""
      }
      console.log(val, "val")
    },
    selectOption(item) {
      console.log(item)
      if (item.type === "circuit") {
        this.circuitVal = item.id
        this.theatreVal = ""
      } else {
        this.circuitVal = ""
        this.theatreVal = item.id
      }
    },
    getCircuitTheatres() {
      getCircuitTheatres()
        .then((res) => {
          const newFilmList = []
          res.data.map((el) => {
            newFilmList.push({
              circuit_key: el.circuit_key,
              full_name: el.full_name,
              id: el.id,
              theatres: el.theatres,
              type: "circuit",
            })
            if (el.theatres.length > 0) {
              el.theatres.map((child) => {
                child.full_name = `- 戲院： ${child.full_name}`
                newFilmList.push({
                  circuit_key: el.circuit_key,
                  full_name: child.full_name,
                  id: child.id,
                  type: "theatre",
                })
              })
            }
          })
          const all = {
            full_name: "所有院線和戲院",
            circuit_key: "",
            id: "",
          }
          newFilmList.unshift(all)
          this.newFilmList = newFilmList
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getAreas() {
      getAreas()
        .then((res) => {
          const newList = []
          const hkList = res.data[0]
          const mcList = res.data[1]
          if (this.isArea === "Hong Kong") {
            hkList.states.map((child) => {
              // newList.push(child);
              child.districts.map((kid) => {
                kid.name = `- ${kid.name}`
                newList.push(kid)
              })
            })
          } else if (this.isArea === "macau") {
            mcList.states.map((child) => {
              // newList.push(child);
              child.districts.map((kid) => {
                kid.name = `- ${kid.name}`
                newList.push(kid)
              })
            })
          } else {
            res.data.map((el) => {
              el.states.map((child) => {
                newList.push(child)
                child.districts.map((kid) => {
                  kid.name = `- ${kid.name}`
                  newList.push(kid)
                })
              })
            })
          }

          const all = {
            name: "All districts",
            id: "",
          }
          newList.unshift(all)
          this.areaList = newList
        })
        .catch((error) => {
          console.log(error)
        })
    },
    checkShow() {
      this.getEngByDateRecord()
    },
    getEngByDateRecord() {
      this.loading = true

      let data = {
        date_start: this.val,
        date_end: this.title_num === "16.1" ? this.val : this.val1,
        type: this.searchType,
        area: this.isArea,
      }

      console.log(this.val)
      if (this.title_num === "16.1.C") {
        data = {
          date_start: this.val,
          date_end: this.val1,
          type: this.searchType,
          isShowAllFilm: !this.isShowAllFilm,
          theatre: this.theatreVal,
          circuit: this.circuitVal,
          area: sessionStorage.getItem("area") || "",
          region: this.areaSelect,
        }
      }
      console.log(data)
      getEngByDateRecord(data)
        .then((res) => {
          console.log(res)
          this.filmInfoList = res.data
          const tableList = []
          res.data.map((el) => {
            if (this.title_num === "16.1.C") {
              tableList.push({
                circuit_id:
                  el.theatre && el.theatre.circuit_id
                    ? el.theatre.circuit_id
                    : "-",
                circuit_name:
                  el.theatre && el.theatre.circuit ? el.theatre.circuit : "-",
                theatre_id: el.theatre ? el.theatre.id : "-",
                theatre:
                  el.theatre && el.theatre.full_name
                    ? el.theatre.full_name
                    : "-",
                film_no: el.film_no,
                showings: el.showings,
                TA: el.TA,
                TG: el.TG,
              })
            } else {
              let highlight = ""
              // if (this.title_num === "16.1.R" || this.title_num === "16.11.R") {
              //   highlight =
              //     new Date(el.film.date_on) < new Date(el.film.date_release)
              //       ? "danger"
              //       : "";
              // }
              tableList.push({
                film_master_id: el.film_master_id,
                film_id: el.film_id,
                film_title: el.film.title_name,
                effect: `${el.film.remark_1 || ""} ${el.film.remark_2 || ""} ${
                  el.film.remark_3 || ""
                }`,
                lang: el.film.language,
                run: el.film.run_ver.run_ver,
                showings: el.showings,
                TA: el.TA,
                TG: el.TG,
                dateaction: "",
                date_release: el.film.date_release,
                country:
                  el.film_master && el.film_master.country
                    ? el.film_master.country
                    : "",
                film_title_c:
                  el.film_master && el.film_master.name_chinese
                    ? el.film_master.name_chinese
                    : "",
                film_title_e:
                  el.film_master && el.film_master.name_english
                    ? el.film_master.name_english
                    : "",
                p_year:
                  el.film_master && el.film_master.ver_year
                    ? el.film_master.ver_year
                    : "",
                status: el.film.status,
                off_date: el.film.date_off,
                date_on: el.film.date_on,
                _rowVariant: highlight,
              })
            }
          })
          this.tableData = tableList
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
    goAllVersionrecord(mode, id) {
      const query = {
        type: "date",
        date: this.$route.query.val,
        mode,
        id,
      }
      this.$router.push({ path: "/record/version-film-record-by-date", query })
    },
    exportExcel() {
      //tabel list
      const dataList = []
      const header = [
        "Date_of_engagement",
        "film_master_id",
        "film_id",
        "POS_ID",
        "film_Title",
        "Run",
        "Production Country",
        "Film Info",
        "Cinema_id",
        "Cinema_Name",
        "Circuit",
        "Screen_id",
        "Show_time",
        "Adm.",
        "T.Gross",
      ]

      this.excelData.map((item) => {
        let effectVal = ""
        let film_info = "-"
        if (item.film) {
          effectVal = item.film.remark_2
            ? `${item.film.remark_1}[${item.film.remark_2}]`
            : item.film.remark_1
          film_info = `${effectVal} - ${item.film.language || ""}`
        } else {
          effectVal = ""
          film_info = "-"
        }

        const f_id =
          item.film.from_arch && item.film.from_arch === 1
            ? `OF-${item.film_id || 0}`
            : `F-${item.film_id || 0}`

        const list = []
        list.push(
          new Date(item.date_of_engagement),
          item.film_master_id,
          f_id,
          item.title_key, //POS_ID
          item.film && item.film.title_name ? item.film.title_name : "-",
          item.film && item.film.run_ver ? item.film.run_ver.run_ver : "-",
          item.film_master && item.film_master.country
            ? item.film_master.country
            : "-",
          film_info,
          item.theatre && item.theatre.id ? item.theatre.id : "-", //Cinema_id
          item.theatre && item.theatre.theatre_name
            ? item.theatre.theatre_name
            : "-", //Cinema_name
          item.theatre && item.theatre.circuit ? item.theatre.circuit : "-", //Circuit
          item.screen_id, //Screen_id
          new Date(item.show_time),
          Number(item.TA),
          Number(item.TG)
        )
        dataList.push(list)
      })
      dataList.unshift(header)

      console.log(dataList)
      dataList.push([
        `Report Area:${
          sessionStorage.getItem("area") || "All"
        }, Date/Time: ${this.$moment().format("YYYY-MM-DD/HH:mm")}`,
      ])
      const date =
        this.title_num !== "16.1"
          ? this.$moment(this.val).format("YYYY-MM")
          : this.val
      const excelInfo = {
        type: "16.1",
        merge: true,
        setDateValue: true,
        data: this.excelData,
        dateCol: [{ title: "M", index: 2 }],
        fileName: `BO_per_Film_Show-${date}.xlsx`,
        header,
        excelData: dataList,
      }
      return excelInfo
    },
  },
  created() {
    if (this.title_num === "16.1.C") {
      this.getAreas()
      this.getCircuitTheatres()
    }
  },
  mounted() {
    this.query = this.$route.query
    console.log(this.query, "this.query")

    this.isShowAllFilm =
      this.query.show_all_film && this.query.show_all_film === "true"
        ? true
        : false
    console.log(this.isShowAllFilm, "this.isShowAllFilm")

    this.areaSelect = Number(this.query.region) || ""
    this.circuitVal = this.query.circuit || ""
    this.theatreVal = this.query.theatre || ""
    if (this.circuitVal) {
      this.selectTheatre = `circuit${this.circuitVal}`
    }
    if (this.theatreVal) {
      this.selectTheatre = `theatre${this.theatreVal}`
    }
    this.getEngByDateRecord()
  },
}
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.sec-title {
  color: #0e72bc;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid #0e72bc;
}
.w300 {
  width: 250px;
}
</style>
