<template>
  <section id="management-center">
    <b-card v-loading="loading">
      <div class="title">
        <p>在資料庫內未有記錄之電影</p>
      </div>
      <div>
        <b-button
          size="sm"
          variant="outline-secondary"
          class="handle-button"
          @click="returnRecord()"
          >回目錄</b-button
        >
      </div>
      <div class="tit">
        <span>在資料庫內未有記錄之電影</span>
        <span>共 {{ tableData.length }} 項</span>
      </div>
      <b-table
        bordered
        striped
        hover
        responsive
        :items="tableData"
        :fields="
          $store.state.highlightCol.firTable
            ? $store.state.newTableFields
            : tableFields
        "
        @head-clicked="
          commonHighLightCol(
            $event,
            tableFields,
            $store.state.newTableFields,
            'firTable'
          )
        "
      >
        <template v-slot:cell(action)="row">
          <b-button
            size="sm"
            style="width: 84px"
            variant="outline-secondary"
            class="handle-button"
            @click="
              goPage(
                'revise-record-add',
                row.item.pos_id,
                row.item.master_id,
                row.item
              )
            "
          >
            作出修訂
          </b-button>
        </template>
      </b-table>
      <div class="">
        <b-button
          class="d-block"
          variant="link"
          style="text-decoration: underline"
          v-for="(item, index) in linkToPage"
          :key="index"
          @click="$router.push({ name: item.link })"
        >
          <i class="el-icon-caret-right"></i> {{ item.name }}</b-button
        >
      </div>
    </b-card>
  </section>
</template>
<script>
import { getUnfoundRecord } from '@/api/management.js'

export default {
  components: {},
  data() {
    return {
      loading: false,
      total: 0,
      linkToPage: [
        { name: '新增/修改 電影記錄 記錄', link: 'film-record' },
        { name: '訂正票房資料庫記錄', link: 'revise-record' },
        { name: '新增/修改 電影發行商 記錄', link: 'film-distributor' }
      ],
      tableData: [],
      tableFields: [
        { key: 'pos_id', label: 'Circuit POS ID ', sortable: true },
        {
          key: 'title_name',
          label: 'Film Name By Circuit Prov.',
          sortable: true
        },
        { key: 'circuit_name', label: 'Circuit', sortable: true },
        { key: 'remarks', label: 'Remarks', sortable: true },
        { key: 'action', label: '' }
      ]
    }
  },
  created() {
    this.getUnfoundRecord()
  },
  methods: {
    getUnfoundRecord() {
      this.loading = true
      const data = {
        page: 1,
        count: 10000
      }
      getUnfoundRecord(data).then((res) => {
        this.tableData = res.data
        this.total = res.total
        this.loading = false
      })
    },
    returnRecord() {
      this.$router.push({ name: 'management-center' })
    },
    goPage(info, pos_id, master_id, item) {
      const search_split = this.getFilmSearchName(item)
      const filmInfo = {
        cinema_id: item.cinema_id,
        circuit: item.circuit_name,
        circuit_id: item.circuit_id,
        pos_id: item.pos_id,
        title_name: item.title_name,
        search_name: search_split,
        id: item.id,
        goPage: this.$route.fullPath,
        film_id: item.film_id,
        type:'notfound'
      }
      sessionStorage.setItem('filmInfo', JSON.stringify(filmInfo))
      this.$nextTick(() => {
        this.$router.push({
          name: info,
          query: {
            pos_id,
            master_id,
            search_name: search_split,
            circuit_id: item.circuit_id,
            id: item.id,
            cinema_id: item.cinema_id
          }
        })
      })
    }
  }
}
</script>
<style lang="scss" scope="scoped">
.tit {
  width: 100%;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin: 20px 0;
}
</style>
