import request from '@/utils/request'

// login
export function login (data) {
  return request({
    url: '/api/login',
    method: 'POST',
    data
  })
}

// get self profile
export function getSelfProfile () {
  return request({
    url: '/api/self-profile',
    method: 'GET',
  })
}
