<template>
  <div>
    <b-card>
      <div class="title">
        <p>顯示電影票房記錄</p>
      </div>
      <v-select
        v-model="select"
        :options="selectOptions"
        :clearable="false"
        style="width: 200px"
        class="per-page-selector d-inline-block ml-50 mr-1"
      />
      <p class="mt-1">
        List of Day Final Data as of (Total on that single Engament Date)
      </p>
      <b-table
        :sticky-header="true"
        striped
        hover
        responsive
        :items="tableData"
        :fields="tableFields"
      />
      <p class="sec-title pb-1">輪次1</p>
      <b-table
        :sticky-header="true"
        striped
        hover
        responsive
        :items="tableData"
        :fields="roundFields"
      >
        <template v-slot:cell(action)>
          <b-button size="sm" class="mr-2" @click="goAllVersionrecord()">
            所有版本
          </b-button>
        </template>
      </b-table>
      <p class="sec-title pb-1">輪次2</p>
      <b-table
        :sticky-header="true"
        striped
        hover
        responsive
        :items="tableData"
        :fields="roundFields"
      >
        <template v-slot:cell(action)>
          <b-button size="sm" class="mr-2" @click="goAllVersionrecord()">
            所有版本
          </b-button>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import vSelect from "vue-select";

export default {
  components: {
    vSelect
  },
  data() {
    return {
      select: "All locations",
      selectOptions: ["All locations", "Hong Kong", "Macau"],
      tableData: [
        {
          e_id: "7909422",
          cinema: "acxhn_004",
          House: "acxhn_004",
          F_master_id: "3117",
          film_id: "4019",
          pos_id: "214",
          title: "假面之夜*Masquerade Night (2D) ",
          Date_of_engt: "2021-10-22",
          show_time: "2021-10-22 21：20",
          admissions: "0",
          total_gross: "0"
        },
        {
          e_id: "7909422",
          cinema: "acxhn_004",
          House: "acxhn_004",
          F_master_id: "3117",
          film_id: "4019",
          pos_id: "214",
          title: "假面之夜*Masquerade Night (2D) ",
          Date_of_engt: "2021-10-22",
          show_time: "2021-10-22 21：20",
          admissions: "0",
          total_gross: "0"
        },
        {
          e_id: "7909422",
          cinema: "acxhn_004",
          House: "acxhn_004",
          F_master_id: "3117",
          film_id: "4019",
          pos_id: "214",
          title: "假面之夜*Masquerade Night (2D) ",
          Date_of_engt: "2021-10-22",
          show_time: "2021-10-22 21：20",
          admissions: "0",
          total_gross: "0"
        }
      ],
      tableFields: [
        { key: "e_id", label: "Film ID LIsted" },
        { key: "cinema", label: "Run" },
        { key: "House", label: "Language" },
        { key: "admissions", label: "Effect" },
        { key: "Date_of_engt", label: "上書日期" },
        { key: "show_time", label: "落畫日期" }
      ],
      roundFields: [
        { key: "Date_of_engt", label: "Date Of Engt." },
        { key: "cinema", label: "Theaters" },
        { key: "pos_id", label: "Shows" },
        { key: "F_master_id", label: "Total B.O." },
        { key: "action", label: "" }
      ]
    };
  },
  methods: {
    goAllVersionrecord() {
      this.$router.push("/boxoffice/all-version-film-record");
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.sec-title {
  color: #0e72bc;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid #0e72bc;
}
</style>
