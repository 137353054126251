<template>
  <div class="latest-news-detail">
    <infoCom
      v-loading="loading"
      :id="query.id"
      :pageType="query.type"
      :title="headeTitle"
      :name="'最新消息'"
      :infoVal.sync="info"
      @submit="submit"
    />
  </div>
</template>

<script>
import infoCom from "./component/infoCom.vue";
import {
  getLatestNewsDetail,
  createLatestNews,
  editLatestNews
} from "@/api/latestNews";
export default {
  components: { infoCom },
  data() {
    return {
      query: "",
      info: {
        banner_path: "",
        title: "",
        paragraph: "",
        date: ""
      },
      loading: false
    };
  },
  mounted() {
    this.query = this.$route.query;
    if (this.query.type !== "create") {
      this.getLatestNewsDetail();
    }
  },
  computed: {
    headeTitle() {
      if (this.query.type === "edit") {
        return `修訂${this.query.title}`;
      } else if (this.query.type === "create") {
        return `開立${this.query.title}`;
      } else {
        return `檢閲${this.query.title}`;
      }
    }
  },
  methods: {
    submit(val) {
      this.loading = true;
      if (this.query.type === "edit") {
        editLatestNews(this.query.id, val)
          .then((res) => {
            console.log(res);
            this.getLatestNewsDetail();
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      } else if (this.query.type === "create") {
        createLatestNews(val)
          .then((res) => {
            console.log(res);
            this.loading = false;
            const path = this.$route.meta.parent;
            this.$router.push({ path });
          })
          .catch((error) => {
            this.loading = false;
          });
      }
    },
    getLatestNewsDetail() {
      this.loading = true;
      getLatestNewsDetail(this.query.id)
        .then((res) => {
          this.info = res.data;
          this.loading = false;
          console.log(this.info);
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    }
  }
};
</script>

<style></style>
