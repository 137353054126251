<template>
  <div>
    <b-card v-loading="loading">
      <div class="title">
        <p>修改票房記錄</p>
      </div>
      <div>
        <b-button
          size="sm"
          variant="outline-secondary"
          class="handle-button"
          @click="$router.go(-1)"
        >
          回上頁
        </b-button>
      </div>
      <h4 class="mt-1">
        <span style="color: #0e72bc">請核實以下輸入及確定儲存為正式記錄</span>
      </h4>
      <h5 class="">
        <span class="">(有*號 為必須輸入項目)</span>
      </h5>
      <b-row v-for="(item, index) in info" :key="index" class="my-1">
        <b-col sm="2"> {{ item.label }} : </b-col>
        <b-col sm="6">
          <b-form-select
            v-if="item.key === 'screen_id'"
            v-model="filmInfo[item.key]"
            :options="houseList"
          ></b-form-select>
          <p
            style="color: #0e72bc; font-size: 20px"
            v-else-if="item.key === 'theatre_name'"
          >
            {{ filmInfo[item.key] }}
          </p>
          <b-form-input
            v-else-if="item.key === 'circuit_film_id'"
            :disabled="item.disabled"
            @change="checkPosid(filmInfo[item.key])"
            :id="`edit-record-input-${index}`"
            v-model="filmInfo[item.key]"
            :type="item.type || 'text'"
          />
          <b-form-input
            v-else
            :disabled="item.disabled"
            :id="`edit-record-input-${index}`"
            v-model="filmInfo[item.key]"
            :type="item.type || 'text'"
          />
        </b-col>
      </b-row>
      <b-col offset-md="5">
        <b-button
          :disabled="showNoUseBtn"
          type="submit"
          variant="primary"
          class="mr-1"
          @click="editDetail()"
        >
          <span>Edit / 修改</span>
        </b-button>
      </b-col>
    </b-card>
    <div v-if="showTable">
      <XMLBoList
        :onlyShowTable="true"
        :filmId="filmInfo.film_id"
        :time="filmInfo.time"
        :date="filmInfo.date"
        :theatreId="theatreID"
        :screenId="filmInfo.screen_id"
      />
    </div>
    <b-modal
      size="lg"
      id="add-pos-id"
      hide-footer
      centered
      title="Change POS ID"
    >
      <div class="my-1" v-loading="loadingAddPosid">
        <div v-if="usedPosidItems && usedPosidItems.length > 0">
          <p>
            <b
              >查到此POS ID[<span style="color: #0e72bc">{{
                filmInfo.circuit_film_id
              }}</span>
              ]被以下數據使用，請重新輸入</b
            >
          </p>
          <b-table
            striped
            hover
            :items="usedPosidItems"
            :fields="usedPosidFields"
          ></b-table>
        </div>
        <div v-if="showNoUseText">
          <b
            >此POS ID[<span style="color: #0e72bc">{{
              filmInfo.circuit_film_id
            }}</span
            >]未曾被使用，可確認更改</b
          >
        </div>
      </div>
      <div class="float-right">
        <b-button class="mr-1" @click="cancelPosid" size="sm">返回</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { editDetail, getDetail } from "@/api/editBoxoffice.js"
import { getTheatreHouseList } from "@/api/theatrehouse.js"
import { checkPosid } from "@/api/management.js"
import XMLBoList from "../recordManagement/versionFilmRecord/versionFilmRecordByXML.vue"
export default {
  components: { XMLBoList },
  data() {
    return {
      showNoUseBtn: false,
      showNoUseText: false,
      loadingAddPosid: false,
      usedPosidItems: [],
      usedPosidFields: [
        { key: "film_id", label: "Film ID" },
        { key: "title_name", label: "Film Name" },
        { key: "film.ver_name", label: "Version" },
        { key: "circuit_name", label: "Circuit Name" },
      ],
      loading: false,
      houseList: [],
      info: [
        { label: "戲院名稱 Theatre Name", key: "theatre_name" },
        { label: "電影名稱 Film Name", key: "film_name", disabled: true },
        { label: "Flim Master ID*", key: "film_master_id", disabled: false },
        { label: "Flim ID*", key: "film_id", disabled: false },
        { label: "POS ID", key: "circuit_film_id", disabled: false },
        { label: "放影日期 Show Date", key: "date", disabled: true },
        { label: "放影時間 Show Time", key: "time", disabled: true },
        { label: "Screen ID", key: "screen_id", disabled: false },
        {
          label: "票房結算日期 Engagement Date",
          key: "date_of_engagement",
          disabled: true,
        },
        {
          label: "入場人數 Admissions*",
          key: "total_admissions",
          disabled: false,
        },
        {
          label: "收入 Total Gross (HK$)*",
          key: "total_gross",
          disabled: false,
        },
      ],
      filmInfo: {
        theatre_name: "",
        film_name: "",
        film_master_id: "",
        film_id: "",
        date: "",
        time: "",
        screen_id: "",
        date_of_engagement: "",
        total_admissions: "",
        total_gross: "",
        circuit_film_id: "",
      },
      recordID: "",
      theatreID: "",
      showTable: false,
    }
  },
  computed: {},
  mounted() {
    this.recordID = this.$route.query.id
    this.theatreID = this.$route.query.theatre_id
    this.getDetail()
    this.getTheatreHouseList()
  },
  methods: {
    cancelPosid() {
      this.add_pos_id = ""
      this.showNoUseText = false
      this.$bvModal.hide("add-pos-id")
    },
    checkPosid(val) {
      this.$bvModal.show("add-pos-id")
      this.loadingAddPosid = true
      const data = {
        pos_id: val,
        circui_id: "GHHK",
      }
      checkPosid(data).then((res) => {
        if (res.data && res.data.length > 0) {
          this.usedPosidItems = res.data
          this.showNoUseText = false
          this.showNoUseBtn = true
        } else {
          this.usedPosidItems = []
          this.showNoUseText = true
          this.showNoUseBtn = false
        }

        this.loadingAddPosid = false
      })
    },
    getTheatreHouseList() {
      const data = "page=1&count=100"
      getTheatreHouseList(this.theatreID, data).then((res) => {
        console.log(res)
        const list = []
        res.data.map((el) => {
          list.push({ text: el.house_name, value: el.screen_id })
        })
        this.houseList = list
      })
    },
    getDetail() {
      this.loading = true
      getDetail(this.recordID)
        .then((res) => {
          console.log(res)
          this.filmInfo = res.data
          this.filmInfo.circuit_film_id = res.data.circuit_film_id
          this.filmInfo.theatre_name = res.data.theatre.full_name
          this.filmInfo.film_name = res.data.film.title_name
          this.filmInfo.date = this.$moment(res.data.date).format()
          this.filmInfo.date_of_engagement = this.$moment(
            res.data.date_of_engagement
          ).format()
          this.showTable = true
          this.loading = false
        })
        .catch((error) => {
          console.log(error, "error")
          this.loading = false
        })
    },
    editDetail() {
      this.loading = true
      const data = {
        film_master_id: this.filmInfo.film_master_id,
        film_id: this.filmInfo.film_id,
        screen_id: this.filmInfo.screen_id,
        total_admissions: this.filmInfo.total_admissions,
        total_gross: this.filmInfo.total_gross,
        circuit_film_id: this.filmInfo.circuit_film_id,
      }
      console.log(data)
      editDetail(this.recordID, data)
        .then((res) => {
          // this.getDetail()
          this.loading = false
          this.$router.go(-1)
        })
        .catch((error) => {
          console.log("error", error)

          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.circuit_ID {
  margin-top: 20px;
}
</style>
