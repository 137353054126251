<template>
  <div>
    <b-card v-loading="loading">
      <div class="title">
        <p>{{ title }}資料</p>
      </div>
      <div class="tip">
        <b-button
          size="sm"
          class="mr-1"
          @click="newActor"
          variant="outline-primary"
        >
          {{ createBtn }}
        </b-button>
        <b-button-group size="sm">
          <b-button
            @click="selectFilmType(0)"
            :variant="isOldFilm ? '' : 'primary'"
            >新{{ title }}記錄</b-button
          >
          <b-button
            @click="selectFilmType(1)"
            :variant="isOldFilm ? 'primary' : ''"
            >舊{{ title }}記錄</b-button
          >
        </b-button-group>
      </div>
      <div class="search">
        <b-form-input
          style="width: 25%"
          type="text"
          class="mr-1"
          :placeholder="`Search For ${name}`"
          v-model="searchQuery.search"
          @change="search()"
        />
        <b-form-select
          ref="blurSelect"
          style="width: 15%"
          v-model="searchQuery.selectArea"
          :options="areaOption"
          @change="enterSearch"
        ></b-form-select>
        <b-col>
          <b-button ref="enterBtn" variant="primary" @click="search()">
            Search
          </b-button>
        </b-col>
      </div>
      <b-table
        bordered
        striped
        hover
        :items="items"
        :fields="
          $store.state.highlightCol.firTable
            ? $store.state.newTableFields
            : fields
        "
        @head-clicked="
          commonHighLightCol(
            $event,
            fields,
            $store.state.newTableFields,
            'firTable'
          )
        "
      >
        <template #head()="data">
          <div
            class="cursor-pointer"
            v-if="data.field.sortKey"
            @click="orderHead(data.field.sortKey)"
          >
            {{ data.field.label }}
          </div>
          <div v-else>{{ data.field.label }}</div>
        </template>
        <template #cell(name_en)="data">
          <div @click="editRecord(data.item, 'review')">
            <span size="sm" class="mr-2 table-name">
              {{ data.item.name_en }}
            </span>
          </div>
        </template>
        <template #cell(name_cn)="data">
          <div @click="editRecord(data.item, 'review')">
            <span size="sm" class="mr-2 table-name">
              {{ data.item.name_cn }}
            </span>
          </div>
        </template>
        <template #cell(action)="data">
          <b-button
            size="sm"
            class="mr-2"
            @click="editRecord(data.item, 'edit')"
          >
            作出修訂
          </b-button>
        </template>
      </b-table>
      <div class="pagination mt-3">
        <el-pagination
          class=""
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[20, 50, 100]"
          :page-size="rows"
          layout=" sizes, prev, pager, next"
          :total="pageTotal"
        >
        </el-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    direct: {
      type: String,
      default: "desc",
    },
    sort: {
      type: String,
      default: "id",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    path: {
      type: String,
      required: true,
    },
    createBtn: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    select: {
      type: String,
      required: true,
    },
    selectOptions: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    pageTotal: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    rows: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.searchQuery.location = this.select
  },
  data() {
    return {
      isOldFilm: 0,
      searchQuery: {
        search: "",
        location: "",
        selectArea: "",
      },
      areaOption: [
        { text: "全部地區", value: "" },
        { text: "香港", value: "Hong Kong" },
        { text: "非香港", value: "Non Hong Kong" },
      ],
    }
  },
  computed: {},
  methods: {
    enterSearch() {
      this.$nextTick(() => {
        this.$refs.blurSelect.blur()
        this.$refs.enterBtn.focus()
      })
    },
    selectFilmType(val) {
      this.isOldFilm = val
      this.$emit("selectFilmType", val)
    },
    orderHead(key) {
      let direct = ""
      if (this.direct === "desc") {
        direct = "asc"
      } else {
        direct = "desc"
      }
      this.$emit("orderHead", key, direct)
    },
    handleSizeChange(val) {
      this.$emit("handleSizeChange", val)
    },
    handleCurrentChange(val) {
      this.$emit("handleCurrentChange", val)
    },
    newActor() {
      this.$router.push({ path: this.path, query: { type: "create" } })
    },
    editRecord(item, istype) {
      this.isId = item.id
      if (istype === "edit") {
        this.$router.push({
          path: this.path,
          query: { type: "edit", id: this.isId },
        })
      } else {
        this.$router.push({
          path: this.path,
          query: { type: "review", id: this.isId },
        })
      }
    },
    search() {
      const data = {
        rows: this.rows,
        selectArea: this.searchQuery.selectArea,
        search: this.searchQuery.search,
        sort: this.sort,
        direct: this.direct,
      }
      this.$emit("handleSearch", data)
    },
  },
}
</script>
<style lang="scss" scoped>
.tip {
  color: #0e72bc;
}
.search {
  display: flex;
  margin: 15px 0;
}
.table-name:hover {
  text-decoration: underline;
}
</style>
