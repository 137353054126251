const AWS = require('aws-sdk')

// AWS_ACCESS_KEY_ID=AKIA5CLP6K6A53CCF3XX
// AWS_SECRET_ACCESS_KEY=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
// AWS_DEFAULT_REGION=ap-southeast-1
// AWS_BUCKET=hkbox-test
// AWS_USE_PATH_STYLE_ENDPOINT=false

AWS.config.update({
  accessKeyId: 'AKIA5CLP6K6A53CCF3XX',
  secretAccessKey: '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',
  region: 'ap-southeast-1',
})

module.exports = AWS