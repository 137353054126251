import request from '@/utils/request'

// get manual box office
export function getXmlList (params) {
  return request({
    url: 'api/get-xml-list',
    method: 'GET',
    params
  })
}
export function getXmlDetailOfXml (id) {
  return request({
    url: `api/get-xml-detail/${id}`,
    method: 'GET',
  })
}

export function getXmlDetailOfJson (id) {
  return request({
    url: `api/get-xml-detail-json/${id}`,
    method: 'GET',
  })
}

export function delXml(id) {
  return request({
    url: `api/xml-file/${id}`,
    method: 'DELETE',
  })
}

export function getXmlRecord(id, page, count) {
  return request({
    url: `api/get-xml-record/${id}?page=${page}&count=${count}`,
    method: 'GET',
  })
}

export function getXmlByTheatre(params) {
  return request({
    url: 'api/get-xml-list-by-theatre',
    method: 'GET',
    params
  })
}

export function getXmlByDelete(params) {
  return request({
    url: 'api/get-delete-xml-list',
    method: 'GET',
    params
  })
}
export function DeleteXML(params) {
  return request({
    url: 'api/xml-files-delete',
    method: 'DELETE',
    params
  })
}
export function restorXML(params) {
  return request({
    url: 'api/xml-files-restore',
    method: 'POST',
    params
  })
}

