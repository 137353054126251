<template>
  <section id="management-center">
    <b-card>
      <h2 class="app-color">XML原始記錄檔</h2>
      <hr class="line" />
      <h4 class="card-title">
        <span class="warning-color"> 暫時沒有記錄 </span>
      </h4>
      <div>
        <b-button
          size="sm"
          variant="outline-secondary"
          class="handle-button"
          @click="addXmlRecord('upload')"
        >
          上傳XML原始記錄檔
        </b-button>
        <b-button
          size="sm"
          variant="outline-secondary"
          class="handle-button"
          @click="addXmlRecord('copy')"
        >
          複製最新的XML原始記錄檔
        </b-button>
        <b-button
          v-if="
            userData.role === 'Admin Supervisor' ||
            userData.role === 'Super Admin'
          "
          size="sm"
          variant="outline-secondary"
          class="handle-button"
          @click="addBoxRecord()"
        >
          輸入票房記錄
        </b-button>
      </div>
    </b-card>
  </section>
</template>
<script>
export default {
  components: {
   
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData'))
    }
  },
  methods: {
    addXmlRecord(istype) {
      this.$router.push({
        name: 'add-xml-record',
        query: {
          type: istype,
          xml_file_id: this.$route.query.xml_file_id,
          xml_file_name: this.$route.query.xml_file_name,
          theatre_id: this.$route.query.theatre_id,
          theatre_no: this.$route.query.theatre_no,
          date:this.$route.query.date
        }
      })
    },
    addBoxRecord() {
      this.$router.push({
        name: 'add-boxoffice-record',
        query: {
          theatreid: this.$route.query.theatre_id,
          type: 'create',
          showResale: '1',
          date: this.$moment(this.$route.query.date).subtract(1,'days').format('YYYY-MM-DD'),
          is_temp:1,
          theatre_name:this.$route.query.theatre_name
        }
      })
    }
  }
}
</script>
<style lang="scss" scope="scoped">
.app-color {
  font-weight: bold;
  color: #0e72bc;
}
.line {
  height: 3px;
}
.warning-color {
  color: red;
}
.handle-button {
  margin-right: 20px;
}
</style>
