export const permissionsList = {
  proUser: [
    'bo_simple_table',
    'bo_seven_day_detail',
    'bo_film_order_and_cinema_list',
    'bo_cinema_film_overview',
    'bo_cinema_order',
    'bo_cinema_detail',
    'sh_film_category',
    'sh_cinema_showing_detail',
    'dr_film_gross_and_admission',
    'dr_film_total',
    'dr_box_office_daily_detail',
    'rt_film_box_office',
    'rt_cinema_box_office',
    'rt_film_compare_today',
    'rt_cinema_compare_today',
    'rt_past_film_hourly_compare',
    'rt_past_cinema_hourly_compare',
    'al_cinema_occupancy',
    'al_theatre_occupancy',
    'al_film_occupancy',
    'al_distributor_occupancy',
    'al_production_occupancy',
    'al_region_occupancy',
    'al_area_occupancy',
    'al_category_occupancy',
    'al_film_compare',
    'al_cinema_compare',
    'al_theatre_compare',
    'sc_data',
    'sch_film',
    'bos_front',
    'bos_midnight',
    'bos_first_ten',
    'bos_search_film_name',
    'bos_search_past_film',
    'bos_monthly_date_off',
    'bos_theatre_box_office_monthly',
    'shi_box_office_simple_table',
    'shi_theatre_occupancy',
    'shi_cinema_occupancy',
    'shi_theatre_company',
    'shi_theatre_order',
    'shi_box_office_weekly_report',
    'shi_film_total',
    'shi_box_office_daily',
    'shi_area_analyze',
    'shi_realtime_box_office',
    'ar_hong_kong',
    'ar_macao'
  ],
  normalUser: [
    'sch_film',
    'bos_front',
    'bos_midnight',
    'bos_first_ten',
    'bos_search_film_name',
    'bos_search_past_film',
    'bos_monthly_date_off',
    'bos_theatre_box_office_monthly',
    'ar_hong_kong',
    'ar_macao'
  ],
  adminUser: [
    'bo_simple_table',
    'bo_seven_day_detail',
    'bo_film_order_and_cinema_list',
    'bo_cinema_film_overview',
    'bo_cinema_order',
    'bo_cinema_detail',
    'sh_film_category',
    'sh_cinema_showing_detail',
    'dr_film_gross_and_admission',
    'dr_film_total',
    'dr_box_office_daily_detail',
    'rt_film_box_office',
    'rt_cinema_box_office',
    'rt_film_compare_today',
    'rt_cinema_compare_today',
    'rt_past_film_hourly_compare',
    'rt_past_cinema_hourly_compare',
    'al_cinema_occupancy',
    'al_theatre_occupancy',
    'al_film_occupancy',
    'al_distributor_occupancy',
    'al_production_occupancy',
    'al_region_occupancy',
    'al_area_occupancy',
    'al_category_occupancy',
    'al_film_compare',
    'al_cinema_compare',
    'al_theatre_compare',
    'sc_data',
    'sch_film',
    'bos_front',
    'bos_midnight',
    'bos_first_ten',
    'bos_search_film_name',
    'bos_search_past_film',
    'bos_monthly_date_off',
    'bos_theatre_box_office_monthly',
    'shi_box_office_simple_table',
    'shi_theatre_occupancy',
    'shi_cinema_occupancy',
    'shi_theatre_company',
    'shi_theatre_order',
    'shi_box_office_weekly_report',
    'shi_film_total',
    'shi_box_office_daily',
    'shi_area_analyze',
    'shi_realtime_box_office',
    'xml_gateway',
    'ar_hong_kong',
    'ar_macao',
    'dar_seven_day_box_office',
    'dar_seven_day_top_ten',
  ]
}

