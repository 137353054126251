<template>
  <section>
    <b-card class="news">
      <div class="title">
        <p>搜尋電影結果</p>
      </div>
      <div class="mb-1">
        <b-button size="sm" variant="outline-primary" @click="$router.go(-1)"
          >回上頁</b-button
        >
      </div>
      <div class="search-input">
        <div class="d-flex">
          <el-input
            clearable
            style="width: 60%"
            class="mr-2"
            size="small"
            v-model="searchVal"
            placeholder="Search Old Film Title"
            @change="Search"
          ></el-input>
          <b-button size="sm" variant="primary" @click="Search"
            >Search</b-button
          >
        </div>
        <div class="my-1" style="color: red; font-size: 12px">
          *此版面只能搜尋2012或之前的舊電影
        </div>
      </div>
      <div class="search-result">
        <div class="d-flex justify-content-between">
          <div>
            <div style="color: #0e72bc">
              片名搜尋：{{ $route.query.full_name }}
            </div>
            <div>搜尋結果共：{{ dataList.list.length }}</div>
          </div>
          <div class="d-flex align-items-center">
            <span>縂纍積票房：</span
            ><el-input
              v-loading="loading"
              v-if="showSaveBtn"
              class="mr-2"
              style="width: 130px"
              size="small"
              placeholder=" "
              v-model="dataList.accum_total"
              :disabled="!showSaveBtn"
            >
            </el-input>

            <div
              element-loading-spinner="el-icon-loading"
              v-loading="loading"
              v-else
              class="mr-1 total-text"
            >
              <b>{{ parseFloat(dataList.accum_total).toLocaleString() }}</b>
            </div>
            <b-button
              v-if="showSaveBtn"
              size="sm"
              variant="success"
              class="mr-1"
              @click="saveData"
              >Save</b-button
            >
            <b-button
              v-if="!showSaveBtn"
              size="sm"
              variant="primary"
              @click="fixData"
              >Edit</b-button
            >
            <b-button
              v-if="showSaveBtn"
              size="sm"
              variant="outline-primary"
              @click="cancel"
              >Cancel</b-button
            >
          </div>
        </div>
        <b-table
          v-loading="loading"
          :items="dataList.list"
          :fields="
            $store.state.highlightCol.firTable
              ? $store.state.newTableFields
              : tableFields
          "
          @head-clicked="
            commonHighLightCol(
              $event,
              tableFields,
              $store.state.newTableFields,
              'firTable'
            )
          "
        >
          <template #cell(main)="data">
            <el-input
              v-if="showSaveBtn"
              style="width: 130px"
              size="small"
              v-model="data.item.engagements.main.tg"
              placeholder=" "
            ></el-input>
            <div v-else>
              {{ parseFloat(data.item.engagements.main.tg).toLocaleString() }}
            </div>
          </template>
          <template #cell(mid_night)="data">
            <el-input
              v-if="showSaveBtn"
              style="width: 130px"
              size="small"
              v-model="data.item.engagements['mid-night'].tg"
              placeholder=" "
            ></el-input>
            <div v-else>
              {{
                parseFloat(
                  data.item.engagements["mid-night"].tg
                ).toLocaleString()
              }}
            </div>
          </template>
          <template #cell(date_of_engagement)="data">
            <div>{{ isValidDate(data.item.date_of_engagement) }}</div>
          </template>
          <template #cell(today_total)="data">
            <div>{{ parseFloat(data.item.today_total).toLocaleString() }}</div>
          </template>
          <template #cell(seven_day_accum_total)="data">
            <div>
              {{ parseFloat(data.item.seven_day_accum_total).toLocaleString() }}
            </div>
          </template>
        </b-table>
      </div>
    </b-card>
  </section>
</template>

<script>
import { getOldFilmDetails, editOldFilm } from "@/api/oldFilmRecord"
export default {
  name: "",
  data() {
    return {
      loading: false,
      showSaveBtn: false,
      searchVal: "",
      tableFields: [
        {
          key: "days",
          sortable: true,
          label: "上映日數",
        },
        {
          key: "date_of_engagement",
          sortable: true,
          label: "日期",
        },
        {
          key: "main",
          label: "正場",
          sortable: true,
        },
        {
          key: "mid_night",
          label: "午夜場",
          sortable: true,
        },
        {
          key: "today_total",
          label: "每日",
          sortable: true,
        },
        {
          key: "seven_day_accum_total",
          label: "纍積票房",
          sortable: true,
        },
      ],
      dataList: {
        accum_total: "0",
        film_id: "",
        list: [],
      },
    }
  },
  components: {},
  created() {
    if (this.$route.query.search) {
      this.searchVal = this.$route.query.search
    }
    if (this.$route.query.film_id) {
      this.getOldFilmDetails()
    }
  },
  mounted() {},
  methods: {
    cancel() {
      this.showSaveBtn = false
      this.getOldFilmDetails()
    },
    editOldFilm() {
      this.loading = true
      editOldFilm(this.dataList).then((res) => {
        this.getOldFilmDetails()
        this.showSaveBtn = false
      })
    },
    getOldFilmDetails() {
      this.loading = true
      getOldFilmDetails(this.$route.query.film_id)
        .then((res) => {
          this.dataList = res.data
          this.dataList.list.map((el) => {
            el.main = el.engagements.main.tg
            el.mid_night = el.engagements["mid-night"].tg
          })
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          console.log("error", error)
        })
    },
    saveData() {
      this.editOldFilm()
      console.log(this.dataList)
    },
    fixData() {
      this.showSaveBtn = true
    },
    Search() {
      this.$router.push({
        path: "/dashboard/searchFilmName",
        query: { search: this.searchVal },
      })
    },
  },
}
</script>
<style lang="scss">
.total-text {
  .el-loading-spinner {
    margin-top: -11px;
  }
}
</style>
<style lang="scss" scoped>
.link-text:hover {
  color: #0e72bc;
  text-decoration: underline;
  cursor: pointer;
}
</style>
