import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
// import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    newTableFields: [],
    newSecTableFields: [],
    newSecTableFields1: [],
    newSecTableFields2: [],
    newSecTableFields3: [],
    newSecTableFields4: [],
    newSecTableFields5: [],
    newSecTableFields6: [],
    newSecTableFields7: [],
    newSecTableFields8: [],
    newSecTableFields9: [],
    newSecTableFields10: [],
    highlightCol: {
      firTable: false,
      secTable: false,
      secTable1: false,
      secTable2: false,
      secTable3: false,
      secTable4: false,
      secTable5: false,
      secTable6: false,
      secTable7: false,
      secTable8: false,
      secTable9: false,
      secTable10: false,
    },
  },
  mutations: {updateStateData(state) {
    // 更新状态数据
    state.highlightCol.firTable = false //頁面中第一個表格
    state.highlightCol.secTable = false //頁面中第二個表格
    state.highlightCol.secTable1 = false
    state.highlightCol.secTable2 = false
    state.highlightCol.secTable3 = false
    state.highlightCol.secTable4 = false
    state.highlightCol.secTable5 = false
    state.highlightCol.secTable6 = false
    state.highlightCol.secTable7 = false
    state.highlightCol.secTable8 = false
    state.highlightCol.secTable9 = false
    state.highlightCol.secTable10 = false
    state.newTableFields = [] //預設空Title表格
    state.newSecTableFields = []
    state.newSecTableFields1 = []
    state.newSecTableFields2 = []
    state.newSecTableFields3 = []
    state.newSecTableFields4 = []
    state.newSecTableFields5 = []
    state.newSecTableFields6 = []
    state.newSecTableFields7 = []
    state.newSecTableFields8 = []
    state.newSecTableFields9 = []
    state.newSecTableFields10 = []
  }},  
  modules: {
    app,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
  // plugins: [createPersistedState()]
})
