<template>
  <section>
    <b-card>
      <div class="title-top">
        <p>{{ title }}</p>
      </div>
      <div class="theatre-box">
        <b-row class="my-1">
          <b-col sm="2">戲院名稱(Full Name):</b-col>
          <b-col sm="6">
            <b style="color: #0e72bc"> {{ $route.query.cinema }}</b></b-col
          >
        </b-row>
        <b-row class="my-1"
          ><b-col sm="2">Cinema ID:</b-col>
          <b-col sm="6">{{ theatreId }}</b-col></b-row
        >
        <div class="th-list">
          <table
            v-loading="loading"
            class="mt-1"
            width="100%"
            hover
            :items="houseList"
          >
            <thead>
              <tr>
                <th style="width: 50px"></th>
                <th v-for="(head, headIndex) in houseFields" :key="headIndex">
                  {{ head.label }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in houseList" :key="index">
                <td>{{ index + 1 }}</td>
                <td
                  v-for="(title, titleIndex) in houseFields"
                  :key="titleIndex"
                >
                  <b-form-select
                    v-if="title.key === 'status'"
                    v-model="item[title.key]"
                    :options="status"
                  ></b-form-select>
                  <b-form-input v-else v-model="item[title.key]"></b-form-input>
                </td>
                <td v-if="theatreId">
                  <b-button
                    @click="
                      historyChange = item.past_seats;
                      HouseName = item.house_name;
                      nowSeats = item.seats;
                    "
                    style="width: 140px"
                    size="sm"
                    v-b-modal.modal-1
                  >
                    查看過往座位修改
                  </b-button>
                </td>
                <td>
                  <b-button
                    @click="delectTheatreHouse(item.id)"
                    style="width: 60px"
                    variant="outline-primary"
                    size="sm"
                  >
                    刪除
                  </b-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-center mt-1">
          <b-button class="mr-1" variant="primary" @click="$router.go(-1)"
            >Back to Theatre Edit</b-button
          >
          <b-button
            class="mr-1"
            @click="theatreId ? multiTheatreHouse() : $router.go(-1)"
            variant="outline-primary"
            >Save Changes</b-button
          >
          <b-button
            class="mr-1"
            v-b-modal.modal-add-house
            @click="addHouse"
            variant="outline-primary"
            >Add a New House</b-button
          >
        </div>
        <b-modal
          id="modal-1"
          hide-footer
          size="lg"
          scrollable
          centered
          title="過往座位修改"
        >
          <b>House Name : </b> <b style="color: #0e72bc">{{ HouseName }}</b>

          <el-table
            border
            max-height="300"
            fit
            :data="historyChange"
            style="width: 100%"
          >
            <el-table-column prop="screen_id" label="House Id">
            </el-table-column>
            <el-table-column prop="seats" label="Seats">
              <template slot-scope="scope">
                <span>{{
                  nowSeats === scope.row.seats
                    ? "當前座位數量"
                    : scope.row.seats
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="change_date" label="Change Date">
            </el-table-column>
          </el-table>
        </b-modal>
        <b-modal
          ref="modal-add-house"
          id="modal-add-house"
          hide-footer
          size="lg"
          centered
          title="Add House"
        >
          <div v-loading="editLoading" class="d-flex">
            <div
              class="mr-1 mb-3"
              v-for="(item, index) in houseFields"
              :key="index"
            >
              <label>{{ item.label }}</label>
              <b-form-select
                v-if="item.key === 'status'"
                v-model="item.val"
                :options="status"
              ></b-form-select>
              <b-form-input
                v-else
                :disabled="item.disable"
                :id="'input' + item.key"
                v-model="item.val"
                trim
              ></b-form-input>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <b-button
              size="sm"
              class="mr-1"
              @click="$bvModal.hide('modal-add-house')"
              >Close</b-button
            >
            <b-button
              size="sm"
              variant="outline-primary"
              @click="addTheatreHouse()"
              >Add</b-button
            >
          </div>
        </b-modal>
      </div>
    </b-card>
  </section>
</template>

<script>
import {
  getTheatreHouseList,
  multiTheatreHouse,
  addTheatreHouse,
  delectTheatreHouse
} from "@/api/theatrehouse.js";

export default {
  data() {
    return {
      nowSeats: 0,
      HouseName: "",
      editLoading: false,
      loading: false,
      historyChange: [],
      status: [
        { text: "維修", value: "維修" }
      ],
      title: "修訂影廳資料",
      houseFields: [
        { label: "House ID*", key: "id", val: "", disable: true },
        { label: "House Name*", key: "house_name", val: "" },
        { label: "Screen_ID in XML", key: "screen_id", val: "" },
        { label: "Seats*", key: "seats", val: "" },
        { label: "Status", key: "status", val: "" }
      ],
      houseList: [],
      theatreId: "",
      next_id: ""
    };
  },
  created() {
    this.theatreId = this.$route.query.id;
    if (this.theatreId) {
      this.getTheatreHouseList();
    } else {
      this.houseList = JSON.parse(sessionStorage.getItem("houseInfo")) || [];
    }
  },
  methods: {
    delectTheatreHouse(house_id) {
      this.loading = true;
      delectTheatreHouse(this.theatreId, house_id)
        .then((res) => {
          console.log(res);
          this.getTheatreHouseList();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    addTheatreHouse() {
      const data = {
        cinema_id: this.theatreId || "",
        id: this.houseFields[0].val,
        screen_id: this.houseFields[2].val,
        house_name: this.houseFields[1].val,
        seats: this.houseFields[3].val,
        status: this.houseFields[4].val
      };
      console.log(data);
      if (this.theatreId) {
        this.editLoading = true;
        addTheatreHouse(this.theatreId, data)
          .then((res) => {
            console.log(res);
            this.editLoading = false;
            this.$refs["modal-add-house"].hide();
            this.getTheatreHouseList();
          })
          .catch((error) => {
            console.log(error);
            this.editLoading = false;
            this.$refs["modal-add-house"].hide();
          });
      } else {
        this.houseList.push(data);
        sessionStorage.setItem("houseInfo", JSON.stringify(this.houseList));
        this.$refs["modal-add-house"].hide();
      }
    },
    multiTheatreHouse() {
      this.loading = true;
      multiTheatreHouse(this.theatreId, this.houseList)
        .then((res) => {
          console.log(res);
          this.getTheatreHouseList();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTheatreHouseList() {
      const data = "count=1000&page=1";
      this.loading = true;
      getTheatreHouseList(this.theatreId, data).then((res) => {
        console.log(res);
        this.houseList = res.data;
        this.loading = false;
        this.next_id = res.next_id;
      });
    },
    addHouse() {
      this.houseFields.map((el) => {
        console.log(el);
        if (el.key === "id") {
          if (this.houseList.length === 0) {
            el.val = this.next_id;
          } else {
            el.val = Number(this.houseList[this.houseList.length - 1].id) + 1;
          }
        } else {
          el.val = "";
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.title-top {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.th-list {
  border-top: 2px solid #555;
  table {
    thead {
      tr {
        th {
          text-align: center;
          // background-color: #fff !important;
        }
      }
    }
  }
  tbody {
    tr {
      height: 70px;
      td {
        // border-top: none !important;
        text-align: center;
      }
    }
    tr:hover {
      background-color: #f6f6f9;
    }
  }
}
.popup-text {
  margin-top: 20px;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-around;
}
</style>
