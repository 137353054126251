<template>
  <div>
    <b-card>
      <subTitle
        v-if="!onlyShowTable"
        :info="info"
        :title="title"
        :date="queryVal.time"
        :type="routeType"
      />
      <div v-else><b>列出票房記錄之相關xml檔</b></div>
      <div v-loading="loading">
        <b-table
          bordered
          striped
          hover
          responsive
          :items="tableData"
          :fields="
            $store.state.highlightCol.firTable
              ? $store.state.newTableFields
              : tableFields
          "
          @head-clicked="
            commonHighLightCol(
              $event,
              tableFields,
              $store.state.newTableFields,
              'firTable'
            )
          "
        >
          <template #thead-top="data">
            <b-tr>
              <b-th
                :colspan="tableData[0].xml_file_id === 0 ? '3' : '4'"
              ></b-th>
              <b-th> total ADM :{{ totalVal("TA") }}</b-th>
              <b-th> total B.O. :{{ totalVal("TG") }}</b-th>
              <b-th
                v-if="tableFields[tableFields.length - 1].label === 'Action'"
              ></b-th>
            </b-tr>
          </template>
          <template #cell(TA)="data">
            <div>
              {{ parseFloat(data.item.TA).toLocaleString() }}
            </div>
          </template>
          <template #cell(TG)="data">
            <div>
              {{ parseFloat(data.item.TG).toLocaleString() }}
            </div>
          </template>
          <template v-slot:cell(Action)="data">
            <b-button
              style="width: 194px"
              v-if="data.item.show_time !== 'Total:'"
              size="sm"
              @click="
                checkxml(data.item.xml_file_id || null, data.item.id || null)
              "
            >
              查閱此XML檔之資料庫記錄
            </b-button>
          </template>
        </b-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import { getEngByTheareByXML } from "@/api/record.js"
import subTitle from "./component/subTitles.vue"

export default {
  props: {
    onlyShowTable: {
      type: Boolean,
      default: false,
    },
    filmId: {
      type: [String, Number],
      default: "",
    },
    time: {
      type: String,
      default: "",
    },
    date: {
      type: String,
      default: "",
    },
    theatreId: {
      type: [String, Number],
      default: "",
    },
    screenId: {
      type: [String, Number],
      default: "",
    },
  },
  components: { subTitle },
  data() {
    return {
      title: "列出票房記錄之相關xml檔",
      loading: false,
      queryVal: "",
      tableData: [],
      info: [],
      routeType: "",
      tableTotal: {},
    }
  },
  computed: {
    totalVal() {
      return function (val) {
        return parseFloat(
          this.tableData.reduce((sum, item) => sum + Number(item[val]), 0)
        ).toLocaleString()
      }
    },
    tableFields() {
      if (
        this.tableData &&
        this.tableData.length > 0 &&
        this.tableData[0].xml_file_id === 0
      ) {
        return [
          { key: "id", label: "E_ID", sortable: true },
          { key: "type", label: "Input Type", sortable: true },
          {
            key: "src_type",
            label: "UID = Input User",
            sortable: true,
          },
          { key: "TA", label: "Adm.", sortable: true },
          { key: "TG", label: "B.O.", sortable: true },
        ]
      } else {
        const list = [
          { key: "id", label: "E_ID", sortable: true },
          { key: "xml_file_id", label: "XML_File_ID", sortable: true },
          {
            key: "xml_file.date_of_report",
            label: "Upload Date/Time",
            sortable: true,
          },
          { key: "xml_file.file_name", label: "Film Name", sortable: true },
          { key: "TA", label: "Adm.", sortable: true },
          { key: "TG", label: "B.O.", sortable: true },
        ]
        if (!this.onlyShowTable) {
          list.push({ key: "Action", label: "Action" })
        }
        return list
      }
    },
  },
  methods: {
    getEngByTheareByXML() {
      this.loading = true
      const date = this.date.split("-")
      const dateVal = `${date[2]}-${date[1]}-${date[0]}`
      const data = {
        film_id: this.onlyShowTable ? this.filmId : this.queryVal.film_id,
        time: this.onlyShowTable
          ? `${dateVal} ${this.time}`
          : this.queryVal.time,
        theatre_id: this.onlyShowTable
          ? this.theatreId
          : this.queryVal.theatre_id,
        screen_id: this.onlyShowTable ? this.screenId : this.queryVal.screen_id,
      }
      getEngByTheareByXML(data).then((res) => {
        this.loading = false
        this.tableTotal = res
        res.data.map((el) => {
          el.TA = el.total_admissions
          el.TG = el.total_gross
        })
        console.log(this.tableData)
        this.tableData = res.data
      })
    },
    checkxml(xml_file_id, e_id) {
      const query = {
        file_id: xml_file_id,
        e_id,
      }
      this.$router.push({ path: "/boxoffice/xml-related-record", query })
    },
  },
  created() {
    this.queryVal = this.$route.query
    this.routeType = this.queryVal.type
    this.getEngByTheareByXML()
    this.info = [
      { label: "Film Title", val: this.queryVal.film_name },
      { label: "Film ID Listed", val: this.queryVal.film_id },
      { label: "Film Effect", val: this.queryVal.film_effect },
      { label: "Show Time", val: this.queryVal.time },
      { label: "Theatre", val: this.queryVal.theatre_name },
    ]
  },
}
</script>

<style lang="scss" scoped></style>
