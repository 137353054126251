var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"theatre-info"},[_c('div',{staticClass:"title"},[_c('p',[_vm._v("戲院資料")])]),_c('div',[_c('b-button',{staticClass:"mr-2 mb-2",attrs:{"size":"sm","variant":"outline-primary"},on:{"click":function($event){return _vm.goDetail()}}},[_vm._v(" 開立新戲院記錄 ")])],1),_c('div',{staticClass:"d-flex mb-1"},[_c('b-form-input',{staticClass:"mr-1",staticStyle:{"width":"220px"},attrs:{"placeholder":"Enter Theatre Name"},model:{value:(_vm.searchVal),callback:function ($$v) {_vm.searchVal=$$v},expression:"searchVal"}}),(this.area)?_c('b-form-select',{staticClass:"mr-1",staticStyle:{"width":"220px"},attrs:{"options":_vm.optionState},model:{value:(_vm.selecteState),callback:function ($$v) {_vm.selecteState=$$v},expression:"selecteState"}}):_vm._e(),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.getTheatreList}},[_vm._v("Search")])],1),_c('b-overlay',{attrs:{"rounded":"sm"}},[_c('b-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],attrs:{"bordered":"","striped":"","hover":"","responsive":"","items":_vm.tableData,"fields":_vm.$store.state.highlightCol.firTable
            ? _vm.$store.state.newTableFields
            : _vm.tableFields},on:{"head-clicked":function($event){return _vm.commonHighLightCol(
            $event,
            _vm.tableFields,
            _vm.$store.state.newTableFields,
            'firTable'
          )}},scopedSlots:_vm._u([{key:"head()",fn:function(data){return [(data.field.sortKey)?_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.orderHead(data.field.sortKey)}}},[_vm._v(" "+_vm._s(data.field.label)+" ")]):_c('div',[_vm._v(_vm._s(data.field.label))])]}},{key:"cell(full_name)",fn:function(data){return [_c('div',{on:{"click":function($event){return _vm.goLook(data.item.id)}}},[_c('span',{staticClass:"mr-2 table-name",attrs:{"size":"sm"}},[_vm._v(" "+_vm._s(data.item.full_name)+" ")])])]}},{key:"cell(action)",fn:function(row){return [_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"mr-2",staticStyle:{"width":"85px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.goDetail(row.item.id)}}},[_vm._v(" 作出修訂 ")])],1)]}}])}),_c('div',{staticClass:"pagination mt-3"},[_c('el-pagination',{attrs:{"background":"","current-page":_vm.currentPage,"page-sizes":[20, 50, 100],"page-size":_vm.rows,"layout":" sizes, prev, pager, next","total":_vm.pageTotal},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }