import request from '@/utils/request'

// get manual box office
export function getManualBoxoffice (params, id) {
  return request({
    url: `/api/theatres/${id}/manual-box-office`,
    method: 'GET',
    params
  })
}

// add manual box office
export function addManualBoxoffice (data, id) {
  return request({
    url: `/api/theatres/${id}/manual-box-office`,
    method: 'POST',
    data
  })
}

// check upload record
export function getUploadRecord (params) {
  return request({
    url: '/api/records/get-Eng-by-date',
    method: 'GET',
    params
  })
}

// get manual box officeDetail
export function getManualBoxofficeDetail (theatreid, id) {
  return request({
    url:`/api/theatres/${theatreid}/manual-box-office/${id}`,
    method: 'GET',
  })
}

//edit manual box office
export function editManualBoxoffice (theatreid, id, data) {
  return request({
    url: `/api/theatres/${theatreid}/manual-box-office/${id}`,
    method: 'PUT',
    data
  })
}
//delect manual box office
export function delectManualBoxoffice (theatreid, id) {
  return request({
    url: `/api/theatres/${theatreid}/manual-box-office/${id}`,
    method: 'DELETE',
  })
}
//get boxoffice pre-sale
export function getPreSale (data) {
  return request({
    url: `/api/box-offices/pre-sale?${data}`,
    method: 'GET',
  })
}