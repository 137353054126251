<template>
  <div>
    <profilesPage
      :loading="loading"
      :path="path"
      :title="title"
      :create-btn="createBtn"
      :select="select"
      :select-options="selectOptions"
      :fields="fields"
      :items="items"
      :name="name"
      :pageTotal="pageTotal"
      @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
      @handleSearch="handleSearch"
      :currentPage="currentPage"
      :rows="rows"
      :sort="sort"
      :direct="direct"
      @orderHead="orderHead"
      @selectFilmType="selectFilmType"
    />
  </div>
</template>

<script>
import profilesPage from "../profilespage/profilesPage"
import { getActorApi } from "@/api/film-material-management/actor"

export default {
  components: {
    profilesPage,
  },
  data() {
    return {
      sort: "id",
      direct: "desc",
      loading: false,
      title: "演員",
      name: "Actor",
      createBtn: "開立新演員記錄",
      select: "全部地區",
      path: "/dashboard/actor-info",
      selectOptions: ["全部地區", "香港", "非香港"],
      currentPage: 1,
      fields: [
        {
          key: "id",
          label: "Actor ID",
          sortKey: "id",
        },
        {
          key: "name_en",
          label: "Actor Name",
          sortKey: "actor_name_en",
        },
        {
          key: "name_cn",
          label: "演員名稱",
          sortKey: "actor_name_zh_hk",
        },
        {
          key: "sex",
          label: "演員性別",
          sortKey: "sex",
        },
        {
          key: "action",
          label: "",
        },
      ],
      items: [],
      pageTotal: 10,
      rows: 20,
      search: "",
      location: "",
      isOld: 0,
    }
  },
  methods: {
    selectFilmType(val) {
      this.isOld = val
      this.getActorList()
    },
    orderHead(key, direct) {
      this.sort = key
      this.direct = direct
      this.getActorList()
    },
    handleSizeChange(val) {
      this.rows = val
      this.getActorList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getActorList()
    },
    handleSearch(val) {
      this.currentPage = 1
      this.rows = val.rows
      this.location = val.selectArea
      this.search = val.search
      this.sort = val.sort
      this.direct = val.direct
      this.getActorList()
    },
    getActorList() {
      this.loading = true
      const data = {
        page: this.currentPage,
        count: this.rows,
        area: this.location,
        search: this.search,
        sort: this.sort,
        direct: this.direct,
        arch: this.isOld,
      }
      getActorApi(data)
        .then((res) => {
          this.items = res.data
          this.pageTotal = res.total
          const arr = []
          this.items.forEach((el) => {
            const obj = new Object()
            obj.id = el.id
            obj.name_cn = el.actor_name_zh_hk
            obj.name_en = el.actor_name_en
            obj.name_other = el.actor_name_other
            obj.area = el.area
            obj.sex = el.sex
            arr.push(obj)
          })
          this.items = arr
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    },
  },
  mounted() {
    this.getActorList()
  },
}
</script>

<style lang="scss" scoped></style>
