<template>
  <section>
    <b-card class="report-table">
      <div class="title">
        <p>{{ title }}</p>
      </div>
      <div class="mb-2">
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-start"
          >
            <b-button size="sm" variant="outline-primary" @click="goBackReport">
              回上頁
            </b-button>
            <div class="d-flex align-items-center" v-if="topExcel">
              <b-button
                @click="exportToExcel"
                class="ml-1"
                size="sm"
                variant="outline-primary"
              >
                Excel
              </b-button>
              <span style="color: red" v-show="showExportTextVal">
                (Excel數據加載中...)
              </span>
            </div>
          </b-col>
          <b-col class="d-flex align-items-center justify-content-start mt-1" v-if="type !== 'toprank'">
            <b-form-select
              v-if="showHKSelect"
              class="mr-1"
              style="width: 20%"
              @change="handleOptionChange"
              v-model="selectValueHighestOption"
              :options="selectHighestOptions"
            ></b-form-select>
            <b-form-input
              style="width: 20%"
              class="mr-1"
              :placeholder="placeholderVal"
              v-if="showSearchInput"
              v-model="searchInput"
              @input="searchChange"
            ></b-form-input>
            <datePickerCom
              v-if="type === 'highestsingleday'"
              :pickTimeVal.sync="from"
              :inputWidth="'200px'"
              class="mr-1"
              @getRecords="handleDateChange"
            />
            <datePickerCom
              v-if="type === 'highestsingleday'"
              :pickTimeVal.sync="to"
              :inputWidth="'200px'"
              class="mr-1"
              @getRecords="handleDateChange"
            />
            <b-button
              variant="primary"
              @click="search()"
            >
              Search
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-1" v-if="excel">
          <div class="d-flex align-items-center">
            <b-button
              @click="exportToExcel"
              class="ml-1"
              size="sm"
              variant="outline-primary"
            >
              Excel
            </b-button>
            <span style="color: red" v-show="showExportTextVal">
              (Excel數據加載中...)
            </span>
          </div>
        </b-row>
        <b-row v-if="type === 'toprank'">
          <b-col>
            <div class="d-flex align-items-center">
              <p class="sub-title mr-2 filter-text my-0">
                已選擇的日子:
                {{ $moment(from).format() }} 至
                {{ $moment(to).subtract(1, "days").format() }}
                <span v-if="selectedCriteriaShow">/</span>
                {{ selectedCriteriaShow }}
                <span v-if="selectedCriteriaShow">/</span>
                {{ selectedGenreShow }} {{ selectedLevelShow }}
                {{ selectedVersionShow }}
                {{ selectedOriginShow }}
                <span class="black-text">首100位（只有輪次1）</span>
              </p>
              <b-button variant="primary" @click="search()"> Search </b-button>
              <b-button
                variant="outline-primary"
                class="nowrap"
                style="white-space: nowrap; margin-left: 20px"
                v-b-toggle.collapse-1
              >
                選擇更多選項
              </b-button>
            </div>
            <div>
              <b-collapse id="collapse-1" class="mt-2">
                <div class="sub-title mb-1">選擇更多選項</div>
                <b-card class="border mb-0">
                  <p class="sub-title mt-0">選擇時間</p>
                  <div class="d-flex mt-2">
                    <datePickerCom
                      :pickTimeVal.sync="from"
                      :inputWidth="'200px'"
                      class="mr-1"
                      @getRecords="handleDateChange"
                    />
                    <datePickerCom
                      :pickTimeVal.sync="to"
                      :inputWidth="'200px'"
                      class="mr-1"
                      @getRecords="handleDateChange"
                    />
                  </div>
                  <p class="sub-title">選擇搜尋條件</p>
                  <b-form-group>
                    <b-form-radio-group
                      v-model="selectedCriteria"
                      :options="optionsCriteria"
                      class="demo-inline-spacing"
                      @change="handleCriteriaChange"
                    />
                  </b-form-group>
                  <p class="sub-title">按片種排序 (可多項選擇）</p>
                  <b-form-group>
                    <b-form-checkbox-group
                      v-model="selectedGenre"
                      :options="optionsGenre"
                      class="demo-inline-spacing"
                      @change="handleGenreChange"
                    />
                  </b-form-group>
                  <p class="sub-title">按級別排序 (可多項選擇）</p>
                  <b-form-group>
                    <b-form-checkbox-group
                      v-model="selectedLevel"
                      :options="optionsLevel"
                      class="demo-inline-spacing"
                      @change="handleLevelChange"
                    />
                  </b-form-group>
                  <p class="sub-title">按版本排序 (可多項選擇）</p>
                  <b-form-group>
                    <b-form-checkbox-group
                      v-model="selectedVersion"
                      :options="optionsVersion"
                      class="demo-inline-spacing"
                      @change="handleVersionChange"
                    />
                  </b-form-group>
                  <p class="sub-title">按產地排序 (可多項選擇）</p>
                  <b-form-input v-model="searchCountry" placeholder="搜尋產地" class="mt-1 search-country"></b-form-input>
                  <b-form-group>
                    <b-form-checkbox-group
                      v-model="selectedOrigin"
                      :options="countryOptions"
                      class="demo-inline-spacing"
                      @change="handleOriginChange"
                    />
                  </b-form-group>
                  <!-- <div class="d-flex d-flex justify-content-end">
                    <b-button variant="primary" @click="search()">
                      Search
                    </b-button>
                  </div> -->
                </b-card>
              </b-collapse>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="type === 'highestsingleday'">
          <b-col>
            <div class="d-flex">
              <p class="sub-title" style="width: 50%">
                <span
                  >已選擇的日子:{{ $moment(from).format() }} /
                  {{ $moment(to).subtract(1, "days").format() }}</span
                >
                <span class="black-text ml-2">首100位（只有輪次1）</span>
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="mb-2 d-flex align-items-center" v-if="showTitleText">
        <span class="mr-1" style="color: #0e72bc"
          >{{ titleVal.year }}年有{{ titleVal.num_film }}部影片,票房總計為{{
            titleVal.bo_total
          }}</span
        >
        <b-button size="sm" variant="outline-primary" @click="exportToExcel"
          >Detail</b-button
        >
        <span style="color: red" v-show="showExportText">
          (Excel數據加載中...)
        </span>
      </div>
      <div>
        <b-table
          bordered
          hover
          responsive
          :fields="
            $store.state.highlightCol.firTable
              ? $store.state.newTableFields
              : fields
          "
          @head-clicked="
            commonHighLightCol(
              $event,
              fields,
              $store.state.newTableFields,
              'firTable'
            )
          "
          :items="items"
        >
          <template #head()="row">
            <div
              class="text-nowrap"
              :style="`background:${highlightTopLabel(
                row.field.key
              )};min-width:50px`"
            >
              {{ row.label }}
            </div>
          </template>
          <template #cell(name)="row">
            <div style="width: 100px">{{ row.item.name }}</div>
          </template>
          <template #cell(date_on)="row">
            <div class="text-nowrap">
              {{ isValidDate(row.value) }}{{ showRelease(row.item) }}
            </div>
          </template>
          <template #cell(single_day_highest_date)="row">
            <div class="text-nowrap">
              {{ isValidDate(row.item.single_day_highest_date) }}
            </div>
          </template>
          <template #cell(date_release)="row">
            <div class="text-nowrap">
              {{
                isValidDate(row.item.date_release) === ""
                  ? ""
                  : isValidDate(row.item.date_release)
              }}
            </div>
          </template>
          <template #cell(date_off)="row">
            <div class="text-nowrap">
              {{
                isValidDate(
                  dateOffFormat(row.item.date_off, row.item.date_release)
                )
              }}
            </div>
          </template>
          <template #cell(accum_bo)="row">
            <div class="text-nowrap">
              {{ parseFloat(row.item.accum_bo).toLocaleString() }}
            </div>
          </template>
          <template #cell(box_office)="row">
            <div class="text-nowrap">
              {{ parseFloat(row.item.box_office).toLocaleString() }}
            </div>
          </template>
          <!-- highestSingelDay -->
          <template #cell(visit_cummulative)="row">
            <div class="text-nowrap">
              {{ parseFloat(row.item.visit_cummulative).toLocaleString() }}
            </div>
          </template>
          <template #cell(bo_cummulative)="row">
            <div class="text-nowrap">
              {{ parseFloat(row.item.bo_cummulative).toLocaleString() }}
            </div>
          </template>
          <template #cell(highest_number_visitors)="row">
            <div class="text-nowrap">
              {{
                parseFloat(row.item.highest_number_visitors).toLocaleString()
              }}
            </div>
          </template>
          <template #cell(single_day_highest)="row">
            <div class="text-nowrap">
              {{ parseFloat(row.item.single_day_highest).toLocaleString() }}
            </div>
          </template>
          <template #cell(max_number_session_in_day)="row">
            <div class="text-nowrap">
              {{
                parseFloat(row.item.max_number_session_in_day).toLocaleString()
              }}
            </div>
          </template>
          <template #cell(max_number_screens_in_day)="row">
            <div class="text-nowrap">
              {{
                parseFloat(row.item.max_number_screens_in_day).toLocaleString()
              }}
            </div>
          </template>
          <!-- top ranking -->
          <template #cell(highest_date_on_ad)="row">
            <div class="text-nowrap">
              {{
                row.item.highest_date_on_ad === 0
                  ? "-"
                  : parseFloat(row.item.highest_date_on_ad).toLocaleString()
              }}
            </div>
          </template>
          <template #cell(highest_date_on_bo)="row">
            <div class="text-nowrap">
              {{
                row.item.highest_date_on_bo === 0
                  ? "-"
                  : parseFloat(row.item.highest_date_on_bo).toLocaleString()
              }}
            </div>
          </template>
          <template #cell(visit_cummulative)="row">
            <div class="text-nowrap">
              {{ parseFloat(row.item.visit_cummulative).toLocaleString() }}
            </div>
          </template>
          <template #cell(single_day_highest_visitors)="row">
            <div class="text-nowrap">
              {{
                parseFloat(
                  row.item.single_day_highest_visitors
                ).toLocaleString()
              }}
            </div>
          </template>
          <template #cell(first_week_visitors)="row">
            <div class="text-nowrap">
              {{ parseFloat(row.item.first_week_visitors).toLocaleString() }}
            </div>
          </template>
          <template #cell(bo_first)="row">
            <div class="text-nowrap">
              {{ parseFloat(row.item.bo_first).toLocaleString() }}
            </div>
          </template>
          <template #cell(visitors)="row">
            <div class="text-nowrap">
              {{ parseFloat(row.item.visitors).toLocaleString() }}
            </div>
          </template>
          <template #cell(first_day_bo)="row">
            <div class="text-nowrap">
              {{ parseFloat(row.item.first_day_bo).toLocaleString() }}
            </div>
          </template>
        </b-table>
      </div>
      <p v-if="type === 'highestsingleday'">紅色highligh代表合拍電影</p>
      <p v-if="path === 'report-year-version'">紅色highligh代表有優先場</p>
    </b-card>
  </section>
</template>
<script>
import { BCollapse, VBToggle } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import datePickerCom from "@/views/components/datePicker.vue";
export default {
  name: "TableDataListing",
  components: {
    BCollapse,
    datePickerCom
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple
  },
  props: {
    topExcel: {
      type: Boolean,
      default: false
    },
    showExportText: {
      type: Boolean,
      default: false
    },
    showTitleText: { type: Boolean, default: false },
    titleVal: { type: Object },
    placeholderVal: { type: String, default: "" },
    showSearchInput: {
      type: Boolean,
      default: false
    },
    showHKSelect: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ""
    },
    fields: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ""
    },
    items: {
      type: Array,
      default: () => []
    },
    optionsCriteria: {
      type: Array,
      default: () => []
    },
    optionsGenre: {
      type: Array,
      default: () => []
    },
    optionsLevel: {
      type: Array,
      default: () => []
    },
    optionsVersion: {
      type: Array,
      default: () => []
    },
    optionsOrigin: {
      type: Array,
      default: () => []
    },
    pageTotal: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    rows: {
      type: Number,
      required: true
    },
    path: {
      type: String,
      default: ""
    },
    excel: {
      type: Boolean,
      default: false
    },
    returnData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      searchInput: "",
      filter: null,
      filterOn: [],
      selectValue: window.sessionStorage.getItem("area") || "",
      selectValueHighestOption: "",
      selectHighestOptions: [
        { value: "", text: "全部地區" },
        { value: "Hong Kong", text: "香港" },
        { value: "non Hong Kong", text: "非香港" }
      ],
      searchQuery: {
        search: "",
        location: ""
      },
      from: "",
      to: this.$moment().format("YYYY-MM-DD"),
      disabledDatesFrom: {
        to: "",
        from: null
      },
      disabledDatesTo: {
        to: ""
      },
      selectedCriteria: "total_bo",
      selectedCriteriaShow: "累積票房",
      selectedGenreShow: "",
      selectedLevelShow: "",
      selectedVersionShow: "",
      selectedOriginShow: "",
      selectedGenre: [],
      selectedLevel: [],
      selectedVersion: [],
      selectedOrigin: [],
      searchCountry: '',
    };
  },
  computed: {
    highlightTopLabel() {
      return function (val) {
        if (this.selectedGenre.length > 0 && val === "film") {
          return "#dcd355";
        }
        if (this.selectedLevel.length > 0 && val === "level") {
          return "#dcd355";
        }
        if (this.selectedOrigin.length > 0 && val === "origin") {
          return "#dcd355";
        }
        let key = "";
        this.optionsCriteria.map((el) => {
          if (this.selectedCriteria === el.value) {
            key = el.key;
          }
        });
        if (key === val) {
          return "#dcd355";
        }
        return "";
      };
    },
    showExportTextVal: {
      get() {
        return this.showExportText;
      },
      set(value) {
        return this.$emit("update:showExportText", value);
      }
    },
    showRelease() {
      return function (val) {
        if (
          val.date_on !== "0001-01-01" &&
          new Date(val.date_on) < new Date(val.date_release) &&
          this.type === "highestsingleday"
        ) {
          return "(優先場)";
        }
      };
    },
    dataListStart() {
      if (this.rows === 5) {
        return (this.currentPage - 1) * 5 + 1;
      } else {
        return (this.currentPage - 1) * 2 * (this.rows / 2) + 1;
      }
    },
    dataListStop() {
      if (this.currentPage === Math.ceil(this.pageTotal / this.rows)) {
        return this.pageTotal;
      } else {
        return this.rows * this.currentPage;
      }
    },
    countryOptions() {
      if (this.optionsOrigin.length > 0 && this.searchCountry) {
        return this.optionsOrigin.filter(item => item.text.toLowerCase().includes(this.searchCountry))
      } else if (this.optionsOrigin.length === 0 && this.searchCountry) {
        return []
      }
      return this.optionsOrigin
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.searchQuery.location = this.selectValue;
    const oneMonthAgoDate = new Date();
    oneMonthAgoDate.setMonth(oneMonthAgoDate.getMonth() - 1);
    this.from = oneMonthAgoDate.toISOString().slice(0, 10);
    // this.selectValueHighestOption = this.$route.query.area
    //   ? this.$route.query.area
    //   : ''
  },
  methods: {
    exportToExcel() {
      this.$emit("exportToExcel");
    },
    searchChange(val) {
      this.$emit("searchChange", val);
    },
    handleSearchChange() {
      this.$emit("handleSearchChange", this.searchQuery.search);
    },
    handleLocationChange() {
      let areaFilter = "";
      if (this.searchQuery.location === "全部地區") {
        areaFilter = "";
      } else if (this.searchQuery.location === "香港") {
        areaFilter = "Hong Kong";
      } else {
        areaFilter = "non Hong Kong";
      }
      this.$emit("handleLocationChange", areaFilter);
    },
    handleOptionChange() {
      this.$emit("handleOptionChange", this.selectValueHighestOption);
    },
    handleHighestChange() {
      this.$emit("handleHighestChange", this.selectValueHighestOption);
    },
    handleDateChange() {
      this.$emit("handleDateChange", this.from, this.to);
    },
    handleCriteriaChange(val) {
      const arrfinder = this.optionsCriteria.filter(function (ele) {
        return ele.value === val;
      });
      this.selectedCriteriaShow = arrfinder[0].text;
      const criteria = `&sort_by=${this.selectedCriteria}`;
      this.$emit("handleCriteriaChange", criteria);
    },
    handleGenreChange() {
      let genre = "";
      let genreshow = "";
      this.selectedGenre.forEach((element, index) => {
        genre += `&film_cat[${index}]=${element}`;
        const arrfinder = this.optionsGenre.filter(function (ele) {
          return ele.value === element;
        });
        genreshow += `${arrfinder[0].text} /`;
      });
      this.selectedGenreShow = genreshow;
      this.$emit("handleGenreChange", genre);
    },
    handleLevelChange() {
      let rating = "";
      let ratingshow = "";
      this.selectedLevel.forEach((element, index) => {
        rating += `&rating[${index}]=${element}`;
        const arrfinder = this.optionsLevel.filter(function (ele) {
          return ele.value === element;
        });
        ratingshow += `${arrfinder[0].text} /`;
      });
      this.selectedLevelShow = ratingshow;
      this.$emit("handleLevelChange", rating);
    },
    handleVersionChange() {
      let version = "";
      let versionshow = "";
      this.selectedVersion.forEach((element, index) => {
        version += `&version[${index}]=${element}`;
        const arrfinder = this.optionsVersion.filter(function (ele) {
          return ele.value === element;
        });
        versionshow += `${arrfinder[0].text} /`;
      });
      this.selectedVersionShow = versionshow;
      this.$emit("handleVersionChange", version);
    },
    handleOriginChange() {
      let origin = "";
      let originshow = "";
      this.selectedOrigin.forEach((element, index) => {
        origin += `&country[${index}]=${element}`;
        const arrfinder = this.optionsOrigin.filter(function (ele) {
          return ele.value === element;
        });
        originshow += `${arrfinder[0].text} /`;
      });
      this.selectedOriginShow = originshow;
      this.$emit("handleOriginChange", origin);
    },
    search() {
      this.$emit("handleSearch");
    },
    goBackReport() {
      this.$router.push({ name: this.path, query: this.returnData });
    },
    updateDDT(selected) {
      console.log(new Date(selected));
      const date = selected;
      this.disabledDatesTo.to = selected;
      console.log(this.disabledDatesTo.to);
      if (new Date(this.to) < new Date(this.disabledDatesTo.to)) {
        this.to = this.disabledDatesTo.to;
      }
    },
    updateDDF(selected) {
      this.disabledDatesFrom.from = this.$moment(selected).format("YYYY-MM-DD");

      if (new Date(this.from) < new Date(this.disabledDatesTo.from)) {
        this.from = this.disabledDatesTo.from;
      }
    },
    findfromarray() {}
  }
};
</script>
<style lang="scss">
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.table-name:hover {
  text-decoration: underline;
}
.line {
  height: 1.5px;
  background: #0e72bc;
  width: 100%;
  margin: 20px 0;
}
.sub-title {
  width: 100%;
  color: #0e72bc;
  font-size: 16px;
  font-weight: 500;
  margin: 20px 0 0;
}
.black-text {
  color: black;
}
.report-table {
  .b-table {
    thead {
      tr {
        th {
          div {
            white-space: normal;
          }
        }
      }
    }
  }
}
.filter-text {
  line-height: 1.5;
}
.search-country {
  width: 200px;
}
</style>
