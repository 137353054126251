import axios from "axios";
import moment from "moment-timezone";
// import store from '@/store'
import router from "@/router";
import md5 from "js-md5";
import Vue from "vue";
import { getToken } from "@/utils/auth";
import { Message } from "element-ui";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: false, // send cookies when cross-domain requests
  timeout: 300000, // request timeout
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    const time = moment
      .tz(moment(), "Asia/Hong_Kong")
      .format("YYYY-MM-DD HH:mm:ss");
    // const secret = md5(`106734cf-ed78-423c-8e42-f35e3dac2869:CUHK-API:${time}`)
    // config.headers['secret'] = secret
    config.headers["request-time"] = time;
    if (getToken()) {
      console.log(getToken());
      config.headers.Authorization = `Bearer ${getToken()}`;
    }
    return config;
  },
  (error) =>
    // do something with request error
    Promise.reject(error)
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code.
   */
  (response) => {
    const res = response;
    console.log(res, "res");

    if (
      (res.status === 200 || res.status === 201) &&
      (res.config.method === "put" ||
        res.config.method === "post" ||
        res.config.method === "delete")
    ) {
      if (
        res.config.url !== "/api/records/eng-by-date" &&
        res.config.url !== "/api/records/uncertain-list" &&
        res.config.url !== "/api/records/compare-night-and-final" &&
        res.config.url !== "/api/records/get-record-by-year" &&
        res.config.url !== "/api/login" &&
        res.config.url !== "/api/search-pos-id" &&
        res.config.url !== "/api/check-pos-id" &&
        res.config.url !== "/api/records/eng-by-date-export"
      ) {
        Message.success("操作成功！");
      } else if (res.config.url === "/api/login") {
        Message.success("登錄成功！");
      }
    }
    // if the custom code is not 20000, it is judged as an error.
    if (res.status > 399) {
      Message.error(res.data.error.message || "error");
      // Notify({ type: 'danger', message: res.data.error.message || 'error' })
      return Promise.reject(res.statusText || "error");
    }
    if (res.status === 200 || res.status === 201) {
      return res.data;
    }
  },
  (error) => {
    console.log({ error });
    if (axios.isCancel(error)) {
      // 为了终结promise链 就是实际请求 不会走到.catch(rej=>{});这样就不会触发错误提示之类了。
      return new Promise(() => {});
    }
    if (error.message.includes("timeout")) {
      Message.error("Network connection error, please try again");
    } else if (!error.response) {
      Message.error("Network connection failure");
    } else if (error.response.status === 500 || error.response.status === 404) {
      if (error.config.responseType === "blob") {
        const reader = new FileReader();
        reader.readAsText(error.response.data);
        reader.onload = function (e) {
          try {
            const jsonData = JSON.parse(e.target.result);
            Message.error(jsonData.message);
          } catch (error) {
            console.log("Error parsing JSON:", error);
            Message.error(`Error parsing JSON:${error}`);
          }
        };
      } else {
        Message.error(error.response.data.message);
      }
    } else if (error.response.status === 401) {
      Message.error(error.response.data.message);
      router.push("/login");
    } else if (error.response.status === 422) { 
      const messages = Object.keys(error.response.data.errors);
      if (messages && messages.length > 0) {
        const errList = [];
        messages.map((el) => {
          errList.push(error.response.data.errors[el]);
        });
        const errText = errList.join("<br/>");
        Message({
          dangerouslyUseHTMLString: true,
          type: "error",
          message: errText
        });
      }
    } else {
      console.log(error, "error");
      const messages = error.response.data.message;
      console.log(messages);
      // eslint-disable-next-line no-prototype-builtins
      if (messages) {
        Message({
          dangerouslyUseHTMLString: true,
          type: "error",
          message: messages
        });
      }
    }
    return Promise.reject(error);
  }
);

export default service;
