<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <img width="70%" src="../../../assets/images/logo/logo.png" alt="" />
      </b-link>
      <b-col lg="12" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="4" class="px-xl-2 mx-auto">
          <b-card-title
            style="text-align: center"
            class="mb-4 font-weight-bold"
            title-tag="h2"
          >
            Welcome
          </b-card-title>
          <b-card-text style="text-align: center" class="mb-2">
            <!-- Please sign-in to your account. -->
          </b-card-text>
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group label="Username" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group label-for="login-password" label="Password">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <el-button
                :loading="loginLoading"
                type="primary"
                block
                style="width: 100%"
                :disabled="invalid"
                @click="handleLogin"
              >
                Sign in
              </el-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate"
import VuexyLogo from "@core/layouts/components/Logo.vue"
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from "bootstrap-vue"
import { required, email } from "@validations"
import { togglePasswordVisibility } from "@core/mixins/ui/forms"
import store from "@/store/index"
import { login, getSelfProfile } from "@/api/login"
import { setToken } from "@/utils/auth"

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loginLoading: false,
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon"
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg")
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    handleLogin() {
      this.loginLoading = true

      if (!this.userEmail || !this.password) {
        return
      }
      const data = {
        // area_code: (this.username.split('-'))[0],
        username: this.userEmail,
        password: this.password,
      }
      console.log(data)
      login(data)
        .then((res) => {
          if (res.result === "success") {
            const token = res.data.access_token
            console.log("token::", token)
            setToken(token)
            sessionStorage.setItem("isLogin", "yes")
            this.getProfle()
          }
        })
        .catch((err) => {
          this.loginLoading = false
          this.$vs.notify({
            title: "error",
            text: err.response.data.error.detail
              ? `${err.response.data.error.message}:${err.response.data.error.detail}`
              : err.response.data.error.message,
            color: "danger",
            position: "top-center",
          })
        })
    },
    getProfle() {
      getSelfProfile().then((res) => {
        console.log(res)
        const userData = {
          fullName: res.data.user_name,
          role: res.data.role,
          name: res.data.name,
        }
        localStorage.setItem("userData", JSON.stringify(userData))
        if (res.data.expire_date !== '0000-00-00') {
          let expireDate = this.$moment(res.data.expire_date)
          const diffDate = expireDate.diff(this.$moment(), 'months') < 1
          if (diffDate) {
            this.$message({
              message: `當前賬號還有${expireDate.diff(this.$moment(), 'days')}天到期`,
              type: 'warning',
            })
          }
        }
        this.loginLoading = false
        this.$router.push("/dashboard/management-center")
      })
    },
  },
  created() {
    localStorage.clear()
    sessionStorage.clear()
    // 清除cookie
    const cookies = document.cookie.split(";")
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i]
      const eqPos = cookie.indexOf("=")
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT"
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
