<template>
  <div>
    <div class="title">
      <p>{{ title }}</p>
    </div>
    <b-button
      size="sm"
      @click="$router.push({ name: 'check-upload-record' })"
      class="mb-1"
      style="margin-left: 8px"
      variant="outline-dark"
      >回目錄</b-button
    >
    <div class="mb-1" v-loading="loading">
      <b style="color: red">負值/0元人次票房紀錄</b>
      <b-table bordered striped hover :items="tableData"  :fields="$store.state.highlightCol.firTable ? $store.state.newTableFields : tableFields"
          @head-clicked="
            commonHighLightCol(
              $event,
              tableFields,
              $store.state.newTableFields,
              'firTable'
            )
          ">
        <template #cell(theatre_name)="data">
          <div class="text-nowrap">
            "
            {{ data.item.theatre_name }}
          </div>
        </template>
        <template #cell(date)="data">
          <div class="text-nowrap">
            {{$moment(data.item.date).format()  }}
          </div>
        </template>
        <template #cell(show_time)="data">
          <div class="text-nowrap">
            {{$moment(data.item.show_time).format('DD-MM-YYYY HH:mm:ss')  }}
          </div>
        </template>
        <template #cell(total_admissions)="data">
          <div>
            {{ parseFloat(data.item.total_admissions).toLocaleString() }}
          </div>
        </template>
        <template #cell(total_gross)="data">
          <div>
            {{ parseFloat(data.item.total_gross).toLocaleString() }}
          </div>
        </template>
        <template v-slot:cell(action)="data">
          <b-button
            style="width: 130px"
            size="sm"
            class="mr-2"
            @click="goFilmRecord(data.item.id)"
          >
            檢閲過往記錄
          </b-button>
        </template>
      </b-table>
    </div>
    <!-- <div class="line"></div>
    <div class="mt-1 mb-1" v-loading="loading">
      <p class="sub-title">系統內經XML輸入之調整及修改類之票房記錄</p>
      <h6>
        即負值之記錄，及超過48小時之前之記錄調整或補充。(只列出最後之500個記錄)
      </h6>
      <div class="search d-flex mt-1 mb-1">
        <b-form-input
          style="width: 250px"
          class="mr-1"
          v-model="searchVal"
          placeholder="Search"
        ></b-form-input>
        <b-button variant="primary">Search</b-button>
      </div>
      <b-table
        bordered
        striped
        hover
        responsive
        :items="tableData"
        :fields="$store.state.highlightCol.firTable ? $store.state.newTableFields : tableFields"
          @head-clicked="
            commonHighLightCol(
              $event,
              tableFields,
              $store.state.newTableFields,
              'firTable'
            )
          "
      >
        <template #cell(theatre_name)="data">
          <div class="text-nowrap">
            "
            {{ data.item.theatre_name }}
          </div>
        </template>
        <template #cell(date)="data">
          <div class="text-nowrap">
            {{ data.item.date }}
          </div>
        </template>
        <template #cell(show_time)="data">
          <div class="text-nowrap">
            {{ data.item.show_time }}
          </div>
        </template>
      </b-table>
    </div> -->
  </div>
</template>

<script>
import { getModifyRecord } from "@/api/record.js";

export default {
  props: {
    title: {
      type: String
    },
    title_num: {
      type: String
    },
    val: {
      type: String
    },
    val1: {
      type: String
    },
    titleText: {
      type: String
    },
    uploadDate: {
      type: String
    },
    filmIDList: {
      type: String
    },
    searchType: {
      type: String
    }
  },
  data() {
    return {
      searchVal: "",
      tableData: [],
      loading: false
    };
  },
  computed: {
    tableFields() {
      return [
        { key: "date", label: "Upload Date", sortable: true },
        { key: "theatre", label: "Theatre", sortable: true },
        { key: "show_time", label: "Show Time", sortable: true },
        { key: "title_name", label: "Film Name", sortable: true },
        { key: "screen_id", label: "Screen Id", sortable: true },
        { key: "total_admissions", label: "Total ADM.", sortable: true },
        { key: "total_gross", label: "Total B.O.", sortable: true },
        { key: "action", label: "Action" }
      ];
    }
  },
  methods: {
    //get 16.3 data
    getModifyRecord() {
      this.loading = true;
      getModifyRecord(1, 10000)
        .then((res) => {
          console.log(res);
          const list = [];
          res.data.map((el) => {
            list.push({
              date: el.date_of_engagement,
              theatre: el.theatre.theatre_name,
              show_time: el.show_time,
              title_name: el.title_name,
              screen_id: el.screen_id,
              total_admissions: el.total_admissions,
              total_gross: el.total_gross,
              id: el.id
            });
          });
          this.tableData = list;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    goFilmRecord(value) {
      this.$router.push({
        path: "/boxoffice/upload-old-record",
        query: { id: value }
      });
    }
  },
  mounted() {
    this.getModifyRecord();
  }
};
</script>

<style lang="scss" scoped>
.line {
  width: 100%;
  height: 2px;
  background: #0e72bc;
}
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.sub-title {
  color: #0e72bc;
  font-size: 18px;
  font-weight: 500;
}
.w300 {
  width: 250px;
}
</style>
