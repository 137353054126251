<template>
  <div>
    <TableDataListing
      :loading="loading"
      :type="type"
      :title="title"
      :fields="fields"
      :items="items"
      :pageTotal="pageTotal"
      :add-button-title="addButtonTitle"
      :edit-button-title="editButtonTitle"
      @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
      @handleSearchChange="handleSearchChange"
      @handleLocationChange="handleLocationChange"
      @handleSearch="handleSearch"
      :select-dropdown="selectDropdown"
      :currentPage="currentPage"
      :rows="rows"
      :sort="sort"
      :direct="direct"
      :placeHolder="placeHolder"
      @orderHead="orderHead"
      @selectFilmType="selectFilmType"
    />
  </div>
</template>
<script>
import TableDataListing from "../components/tableDataListing.vue"
import { getProductionApi } from "@/api/film-material-management/productionCompany"

export default {
  name: "ProductionCompany",
  components: {
    TableDataListing,
  },
  data() {
    return {
      placeHolder: "Search Production company",
      loading: false,
      type: "production-company",
      title: "電影出品公司資料",
      addButtonTitle: "開立新出品公司記錄",
      editButtonTitle: "作出修訂",
      selectDropdown: true,
      sort: "id",
      direct: "desc",
      fields: [
        {
          key: "id",
          label: "Production company ID",
          sortKey: "id",
        },
        {
          key: "production_code",
          label: "Production company CODE",
          sortKey: "production_code",
        },
        {
          key: "name_en",
          label: "Production company Name",
          sortKey: "production_name_en",
        },
        {
          key: "name_ch",
          label: "出品公司名稱",
          sortKey: "production_name_zh_hk",
        },
        {
          key: "actions",
          label: "",
          tdClass: "d-flex justify-content-center",
        },
      ],
      items: [],
      currentPage: 1,
      pageTotal: 10,
      rows: 20,
      search: "",
      location: "",
      isOld: 0,
    }
  },
  methods: {
    selectFilmType(val) {
      this.isOld = val
      this.getProductionApi()
    },
    orderHead(key, direct) {
      this.sort = key
      this.direct = direct
      this.getProductionApi()
    },
    handleSizeChange(val) {
      this.rows = val
      this.getProductionApi()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getProductionApi()
    },
    handleSearchChange(val) {
      this.search = val
    },
    handleLocationChange(val) {
      this.location = val
      this.getProductionApi()
    },
    handleSearch(val) {
      this.currentPage = 1
      this.rows = val.rows
      this.location = val.selectArea
      this.search = val.search
      this.sort = val.sort
      this.direct = val.direct
      this.getProductionApi()
    },
    getProductionApi() {
      this.loading = true
      const data = {
        page: this.currentPage,
        count: this.rows,
        area: this.location,
        search: this.search,
        sort: this.sort,
        direct: this.direct,
        arch: this.isOld,
      }
      getProductionApi(data)
        .then((res) => {
          console.log(data)
          this.pageTotal = res.total
          const arr = []
          this.items = arr
          res.data.forEach((el) => {
            const obj = new Object()
            obj.id = el.id
            obj.name_ch = el.production_name_zh_hk
            obj.name_en = el.production_name_en
            obj.production_code = el.production_code
            arr.push(obj)
          })
          this.items = arr
          console.log(arr)
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
  },
  mounted() {
    this.location = window.sessionStorage.getItem("area") || ""
    this.getProductionApi()
  },
}
</script>
