<template>
  <div>
    <div
      v-loading="loading"
      class="film-different-circuit mt-1"
      v-if="dif_items && dif_items.length > 0"
    >
      <p style="color: #0e72bc">Code Using by different Circuit</p>
      <b-table
        :key="tabelKey"
        bordered
        striped
        hover
        :items="dif_items"
        :fields="
          $store.state.highlightCol.firTable
            ? $store.state.newTableFields
            : dif_fields
        "
        @head-clicked="
          commonHighLightCol(
            $event,
            dif_fields,
            $store.state.newTableFields,
            'firTable'
          )
        "
      >
        <template #cell(action)="data">
          <b-button
            size="sm"
            class="mr-1"
            @click="goPage('revise-record-add', data.item, 'notfound')"
            variant="outline-secondary"
            >作出修訂</b-button
          >
        </template></b-table
      >
      <div class="line"></div>
      <span style="font-size: 12px"
        >F-R = Engagament record Errors in Final Reports</span
      >
      <br />
      <span style="font-size: 12px"
        >H-R = Engagament record Errors in Hourly Reports</span
      >
    </div>
    <div class="mt-1" v-else>Film_ID not in use,No matching record found.</div>
  </div>
</template>

<script>
import { getCircuitFilms } from "@/api/film-material-management/filmMaster";
export default {
  props: {
    masterId: { type: [String, Number], default: "" },
    filmId: { type: [String, Number], default: "" },
    noEdit: { type: Boolean, default: false }
  },
  name: "",
  data() {
    return {
      tabelKey: 1,
      loading: false,
      dif_items: [],
      dif_fields: [
        { label: "Film ID", key: "film_id", variant: "warning" },
        { label: "Circuit", key: "circuit_name" },
        { label: "POS ID", key: "pos_id" },
        { label: "Title stated by Circuit", key: "title_name" },
        { label: "F-R", key: "engagement_final_count" },
        { label: "H-R", key: "engagement_hourly_count" },
        { label: "Status", key: "status" },
        { label: "", key: "action" }
      ]
    };
  },
  components: {},
  created() {
    this.getCircuitFilms();
  },
  mounted() {
    this.$nextTick(() => {
      console.log(this.noEdit, "noEdit");
      if (this.noEdit) {
        this.dif_fields.splice(this.dif_fields.length - 1, 1);
        this.tabelKey++;
      }
    });
  },
  methods: {
    goPage(info, item, type) {
      if (info === "revise-record-add") {
        const search_split = this.getFilmSearchName(item);
        const filmInfo = {
          cinema_id: item.cinema_id,
          circuit: item.circuit_name,
          circuit_id: item.circuit_id,
          pos_id: item.pos_id,
          title_name: item.title_name,
          search_name: search_split,
          film_circuit_id: item.film_circuit_id,
          id: item.id,
          goPage: this.$route.fullPath,
          film_id: item.film_id,
          type
        };
        sessionStorage.setItem("filmInfo", JSON.stringify(filmInfo));
        this.$nextTick(() => {
          this.$router.push({
            name: info,
            query: {
              pos_id: item.pos_id,
              master_id: item.master_id,
              search_name: search_split,
              circuit_id: item.circuit_id,
              id: item.id,
              cinema_id: item.cinema_id
            }
          });
        });
      } else if (info === "revise-record-info") {
        this.$router.push({
          name: info
        });
      }
    },
    getCircuitFilms() {
      this.loading = true;
      const data = {
        film_id: this.filmId
      };
      getCircuitFilms(this.masterId, data)
        .then((res) => {
          this.dif_items = res.data;
          this.loading = false;
          console.log(res);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
