<template>
  <div>
    <b-card>
      <div class="title">
        <p>修改票房記錄 - 新增票房記錄</p>
      </div>
      <b-row v-for="(item, index) in info" :key="index" class="my-1">
        <b-col sm="2"> {{ item.label }} : </b-col>
        <b-col sm="6">
          <b-form-input
            :id="`type-${index}`"
            v-model="theatreInfo[item.key]"
            :disabled="true"
            :type="item.type || 'text'"
          />
        </b-col>
      </b-row>
      <div>
        <div class="line" />
        <div class="d-flex align-items-center justify-content-between">
          <div class="input-box">
            <label class="label">票房結算日期 Engagement Date*</label>
            <b-form-input v-model="theatreInfo.engagement_date" />
          </div>
          <div class="input-box">
            <label class="label">放映時間 Show Time*</label>
            <b-form-input v-model="theatreInfo.show_time" />
          </div>
          <div class="input-box">
            <label class="label">Screen ID*</label>
            <b-form-input v-model="theatreInfo.scree_id" />
          </div>
          <div class="input-box">
            <label class="label">入場人數 Admissions*</label>
            <b-form-input v-model="theatreInfo.admissions" />
          </div>
          <div class="input-box">
            <label class="label">收入 Total Gross (HKD)*</label>
            <b-form-input v-model="theatreInfo.total_gross" />
          </div>
        </div>
        <div class="mt-1">
          <span>Remark:</span>
          <span
            >If "Engagement Date" filed are left empty,the system will not add
            the record to the database.</span
          >
        </div>
        <div class="line" />
      </div>
      <b-table
        :sticky-header="true"
        class="mt-2"
        striped
        hover
        responsive
        :items="tableData"
        :fields="tableFields"
      >
        <template v-slot:cell(action)> </template>
      </b-table>
      <b-button
        size="sm"
        class="mt-2 next"
        variant="outline-primary"
        @click="addBox()"
      >
        新增記錄
      </b-button>
    </b-card>
  </div>
</template>

<script>
import { addBoxOffices } from "@/api/editBoxoffice.js";

export default {
  data() {
    return {
      info: [
        { label: "戲院名稱 (Full Name)", key: "full_name" },
        { label: "電影名稱 Film Name", key: "short_name" },
        { label: "Flim Master ID", key: "theatre_name" },
        { label: "Flim ID", key: "" },
        { label: "POS ID", key: "" },
        { label: "House no.", key: "" },
        { label: "Date_of_Engagement", key: "" },
        { label: "上映日期 Show Date", key: "" }
      ],
      theatreInfo: {
        id: "",
        full_name: "",
        short_name: "",
        film_master_id: "",
        film_id: "",
        pos_id: "",
        house_id: "",
        date: "",
        show_date: "",
        show_time: "",
        engagement_date: "",
        admissions: "",
        total_gross: ""
      },
      tableData: [],
      tableFields: [
        { key: "circuit", label: "電影名稱" },
        { key: "theatre_no", label: "上映時間" },
        { key: "theatre_name", label: "片長" },
        { key: "a", label: "House" },
        { key: "b", label: "票房" },
        { key: "action", label: "人次" }
      ]
    };
  },
  computed: {},
  mounted() {
    this.theatreInfo = this.$route.query;
  },
  methods: {
    next() {
      this.$router.push({ name: "add-film-record" });
    },
    addBox() {
      const params = {
        film_master_id: this.theatreInfo.film_master_id,
        theatre_id: this.theatreInfo.theatre_id,
        house_id: this.theatreInfo.house_id,
        pos_id: this.theatreInfo.pos_id,
        film_id: this.theatreInfo.film_id,
        show_date: this.theatreInfo.show_date,
        show_time: this.theatreInfo.show_time,
        admissions: this.theatreInfo.admissions,
        total_gross: this.theatreInfo.total_gross
      };
      addBoxOffices(this.theatreInfo.id, params);
    }
  }
};
</script>

<style lang="scss" scoped>
.line {
  height: 1.5px;
  background: #0e72bc;
  width: 100%;
  margin: 20px 0;
}
.next {
  float: right;
}
.input-box {
  width: 22%;
  margin: 0 10px;
}
.change-box {
  height: 80px;
  border-bottom: 2px solid #0e72bc;
  .change-btn {
    margin-top: 20px;
    float: right;
  }
}
.case-tank {
  width: 100%;
}
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
</style>
