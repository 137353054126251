<template>
  <div>
    <b-card v-loading="loading">
      <div class="title d-flex justify-content-between">
        <p>{{ isEdit ? "修訂戲院" : "開立新戲院" }}</p>
        <b-form-checkbox
          v-if="isEdit"
          id="checkbox-page"
          v-model="showRecord"
          name="checkbox-page"
          :value="1"
          :unchecked-value="0"
          @change="edtiTheatre('hide')"
          >隱藏戲院記錄</b-form-checkbox
        >
      </div>
      <b-row v-for="(item, index) in info" :key="index" class="my-1">
        <b-col sm="2" v-if="item.type !== 'others'"> {{ item.label }} : </b-col>
        <b-col sm="10">
          <b-form-input
            v-if="item.type === 'input'"
            :id="`type-${index}`"
            v-model="theatreInfo[item.key]"
            type="text"
          />
          <div
            style="width: 100%"
            class="d-flex align-items-center"
            v-else-if="item.type === 'date'"
          >
            <datePickerCom
              :key="datePickerComKey"
              :pickTimeVal.sync="theatreInfo[item.key]"
              :inputWidth="'100%'"
              class="mr-1"
              :isDisabled="!theatreInfo[item.showEdit]"
            />
            <b-form-checkbox
              :id="`checkbox-${item.key}`"
              @change="dateDefualt"
              v-model="theatreInfo[item.showEdit]"
              :name="`checkbox-${item.key}`"
              :value="true"
              :unchecked-value="false"
            />
          </div>

          <b-form-select
            v-else-if="item.type == 'select'"
            v-model="theatreInfo[item.key]"
            :options="item.options"
            v-loading="item.loading"
            @change="selectChange(item.key, theatreInfo[item.key], index)"
            :clearable="false"
            style="width: 100%"
            class="per-page-selector d-inline-block mr-1 mb-1"
          />
          <b-form-select
            v-else-if="item.type == 'area_select'"
            v-model="theatreInfo[item.key]"
            :options="item.options"
            :clearable="false"
            style="width: 100%"
            class="per-page-selector d-inline-block mr-1 mb-1"
          />
          <b-form-textarea
            v-if="item.type === 'textarea'"
            id="textarea"
            v-model="theatreInfo[item.key]"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-col>
        <div class="d-flex theatreInfo" v-if="item.type === 'others'">
          <div
            v-for="(child, childIndex) in item.children"
            :key="childIndex"
            class="th-box my-1"
            :class="child.type === 'th-list' ? 'list-width' : ''"
          >
            <span class="th-label" v-if="child.type !== 'th-list'">
              {{ child.label }} :
            </span>
            <div class="th-input">
              <b-form-input
                v-if="child.type === 'input'"
                :disabled="isEdit && child.disable"
                :id="`ch-type-${childIndex}`"
                v-model="theatreInfo[child.key]"
              />
              <div class="d-flex" v-if="child.type === 'button'">
                <b-form-input
                  :disabled="child.disable"
                  style="width: 58%"
                  :id="`ch-type-${childIndex}`"
                  v-model="theatreInfo[child.key]"
                />
                <b-button
                  :to="`/theatre/edit-theatre-info?id=${
                    theatreId || ''
                  }&cinema=${theatreInfo.full_name || ''}`"
                  @click="toHousePage"
                  style="margin-left: 20px; width: 110px; padding: 10px 7px"
                  variant="outline-dark"
                  >修訂影廳資料</b-button
                >
              </div>
              <div
                class="th-list"
                v-if="
                  child.type === 'th-list' && houseList && houseList.length > 0
                "
              >
                <b>影廳資料</b>
                <b-table
                  bordered
                  class="mt-1"
                  hover
                  :items="houseList"
                  :fields="
                    $store.state.highlightCol.firTable
                      ? $store.state.newTableFields
                      : houseFields
                  "
                  @head-clicked="
                    commonHighLightCol(
                      $event,
                      houseFields,
                      $store.state.newTableFields,
                      'firTable'
                    )
                  "
                >
                </b-table>
              </div>
              <el-select
                style="width: 100%"
                class="per-page-selector d-inline-block mr-1 mb-1"
                v-if="child.type == 'circuit_select'"
                v-model="theatreInfo[child.key]"
                placeholder=" "
              >
                <el-option
                  v-for="item in child.options"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value"
                >
                  <div @click="selectCircuit(item)">{{ item.text }}</div>
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </b-row>
      <b-row>
        <b-col sm="12">
          <div class="d-flex justify-content-center align-items-center">
            <b-button variant="primary" @click="changeTheatreAction">
              Save / 存檔
            </b-button>
            <span v-if="theatreId" class="ml-1" style="color: red"
              >(最後更新時間:{{
                this.$moment(theatreInfo.updated_at).format("DD-MM-YYYY HH:mm:ss")
              }}
              )</span
            >
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { country, getState, getDistrict } from "@/api/country.js";
import { addTheatre, getTheatreDetail, editTheatre } from "@/api/theatre.js";
import { getTheatreHouseList } from "@/api/theatrehouse.js";
import { getCircuitList } from "@/api/circuit";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import datePickerCom from "@/views/components/datePicker.vue";
export default {
  components: { datePickerCom },
  data() {
    return {
      datePickerComKey: 1,
      dateReload: true,
      showRecord: 0,
      loading: false,
      houseFields: [
        { label: "House ID", key: "id" },
        { label: "House Name", key: "house_name" },
        { label: "Screen_ID in XML", key: "screen_id" },
        { label: "Seats", key: "seats" },
        { label: "Status", key: "status" }
      ],
      houseList: [],
      info: [
        { label: "戲院名稱 (Full Name)*", key: "full_name", type: "input" },
        { label: "戲院名稱 (Short Name)*", key: "short_name", type: "input" },
        {
          label: "",
          key: "",
          type: "others",
          children: [
            {
              label: "Theatre Name in XML*",
              key: "theatre_name",
              type: "input"
            },
            {
              label: "Theatre ID in XML*",
              key: "theatre_no",
              type: "input",
              disable: false
            },
            {
              label: "所屬院線*",
              key: "circuit_name",
              type: "circuit_select",
              options: []
            },
            {
              label: "院線XML ID*",
              key: "circuit_key",
              type: "input",
              disable: false
            },
            {
              label: "影廳數目 No. of House",
              key: "houses_count",
              type: "button",
              disable: true
            },
            {
              label: "XML Film Name ID*",
              key: "xml_fid",
              type: "input",
              disable: false
            },
            { label: "", key: "", type: "th-list" }
          ]
        },
        { label: "戲院聯絡人*", key: "contact_person", type: "input" },
        { label: "聯絡電話*", key: "contact_phone", type: "input" },
        { label: "聯絡電郵 (1)*", key: "alert_mail_1", type: "input" },
        // { label: '聯絡電郵 (2)', key: '', type: 'input' },
        { label: "地址*", key: "street_address_1", type: "input" },
        // { label: '城市 (City)', key: 'cyti', type: 'input' },

        {
          label: "國家 (Country)*",
          key: "country_id",
          type: "select",
          options: [
            { text: "", value: "" },
            { text: "Hong Kong", value: 1 },
            { text: "Macau", value: 2 }
          ]
        },
        {
          label: "區域分區 (state_or_province)*",
          key: "state_id",
          type: "select",
          options: []
        },
        {
          label: "地區 (district)*",
          key: "district_id",
          type: "area_select",
          options: []
        },
        {
          label: "關閉日期",
          key: "closing_date",
          type: "date",
          showEdit: "endShow"
        },
        {
          label: "開張日期",
          key: "opening_date",
          type: "date",
          showEdit: "starShow"
        },
        {
          label: "Status",
          key: "status",
          type: "select",
          options: ["XML", "XML_closed", "Manual"]
        },
        { label: "備註", key: "remarks", type: "textarea" }
      ],
      theatreInfo: {
        endShow: false,
        starShow: false,
        id: "",
        full_name: "",
        short_name: "",
        theatre_name: "",
        theatre_no: "",
        street_address_1: "",
        circuit_id: "",
        alert_mail_1: "",
        xml_fid: "",
        contact_person: "",
        contact_phone: "",
        opening_date: "",
        closing_date: "",
        is_hide: 1,
        remarks: "",
        country_id: "",
        state_id: 0,
        district_id: 0,
        country_name: "",
        state_name: "",
        district_name: "",
        circuit_name: "",
        circuit_key: "",
        houses_count: "",
        status: "XML"
      }
    };
  },
  computed: {
    isEdit() {
      if (this.$route.query.id) {
        return true;
      } else {
        return false;
      }
    },
    theatreId() {
      return this.$route.query.id || "";
    }
  },
  methods: {
    getChildrenIndex(sonKey) {
      const isIndex = {
        father: "",
        child: ""
      };
      this.info.map((el, index) => {
        if (el.children) {
          isIndex.father = index;
          el.children.map((son, sonIndex) => {
            if (son.key === sonKey) {
              isIndex.child = sonIndex;
            }
          });
        }
      });
      return isIndex;
    },
    getDefualtData() {
      if (sessionStorage.getItem("theatreInfo")) {
        this.theatreInfo =
          JSON.parse(sessionStorage.getItem("theatreInfo")) || this.theatreInfo;
      }
      if (sessionStorage.getItem("houseInfo")) {
        this.houseList = JSON.parse(sessionStorage.getItem("houseInfo")) || [];
      }
      this.theatreInfo.houses_count = this.houseList.length;
    },
    theatreData() {
      const data = {
        full_name: this.theatreInfo.full_name,
        theatre_name: this.theatreInfo.theatre_name,
        short_name: this.theatreInfo.short_name,
        contact_person: this.theatreInfo.contact_person,
        contact_phone: this.theatreInfo.contact_phone,
        alert_mail_1: this.theatreInfo.alert_mail_1,
        street_address_1: this.theatreInfo.street_address_1,
        area: this.theatreInfo.area,
        district: this.theatreInfo.district,
        sub_district: this.theatreInfo.sub_district,
        closing_date: this.theatreInfo.endShow
          ? this.theatreInfo.closing_date
          : "",
        opening_date: this.theatreInfo.starShow
          ? this.theatreInfo.opening_date
          : "",
        status: this.theatreInfo.status,
        remark: this.theatreInfo.remark,
        is_hide: this.theatreInfo.is_hide,
        remarks: this.theatreInfo.remarks,
        country_id: this.theatreInfo.country_id,
        state_id: this.theatreInfo.state_id,
        district_id: this.theatreInfo.district_id,
        circuit_key: this.theatreInfo.circuit_key,
        xml_fid: this.theatreInfo.xml_fid,
        theatre_no: this.theatreInfo.theatre_no,
        circuit_id: this.theatreInfo.circuit_key
      };
      return data;
    },
    dateDefualt() {
      this.datePickerComKey++;
    },
    selectCircuit(val) {
      this.theatreInfo.circuit_key = val.key;
      this.theatreInfo.circuit_id = val.value;
    },
    getCircuitList() {
      const data = {
        page: 1,
        count: 1000
      };
      getCircuitList(data).then((res) => {
        res.data.map((el) => {
          this.info[this.getChildrenIndex("circuit_name").father].children[
            this.getChildrenIndex("circuit_name").child
          ].options.push({
            value: el.id,
            text: el.full_name,
            key: el.circuit_key
          });
        });
      });
    },
    toHousePage() {
      this.$router.push({
        path: "/theatre/edit-theatre-info",
        query: {
          id: this.theatreId || "",
          cinema: this.theatreInfo.full_name || ""
        }
      });
      sessionStorage.setItem("theatreInfo", JSON.stringify(this.theatreInfo));
    },
    selectChange(key, id, index) {
      console.log(key, id);
      if (key === "country_id") {
        this.theatreInfo.country_id = id;
        this.getState();
      }
      if (key === "state_id") {
        this.theatreInfo.state_id = id;
        this.getDistrict();
      }
    },
    getDistrict() {
      getDistrict(this.theatreInfo.state_id).then((res) => {
        const list = [];
        res.data.map((el) => {
          list.push({ text: el.name, value: el.id });
        });

        this.info.map((item, index) => {
          if (item.key === "district_id") {
            this.info[index].options = list;
          }
        });
      });
    },
    getState(index) {
      getState(this.theatreInfo.country_id).then((res) => {
        const list = [];
        res.data.map((el) => {
          list.push({ text: el.name, value: el.id });
        });

        this.info.map((item, index) => {
          if (item.key === "state_id") {
            this.info[index].options = list;
          }
        });
      });
    },
    getTheatreHouseList() {
      const data = "count=1000&page=1";
      getTheatreHouseList(this.theatreId, data).then((res) => {
        console.log(res);
        this.houseList = res.data;
        this.getDefualtData();
      });
    },
    changeTheatreAction() {
      this.isEdit ? this.edtiTheatre() : this.addTheatre();
    },
    addTheatre() {
      const data = this.theatreData();
      addTheatre(data).then(() => {
        if (!this.theatreId) {
        }
        this.$router.push("/theatre/theatre");
      });
    },
    edtiTheatre(type) {
      this.loading = true;
      console.log(this.theatreInfo);
      // this.theatreInfo.status = 'XML'
      this.theatreInfo.is_hide = this.showRecord;
      let info = {};
      if (type === "hide") {
        const data = {
          is_hide: this.showRecord
        };
        info = data;
      } else {
        info = this.theatreData();
      }
      editTheatre(this.theatreId, info)
        .then(() => {
          this.loading = false;
          this.getTheatreDetail();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    getTheatreDetail() {
      this.loading = true;
      getTheatreDetail(this.theatreId)
        .then((res) => {
          console.log(this.datePickerComKey);

          console.log("ff check:::", res);
          this.theatreInfo = res.data;

          if (res.data.is_hide == null || res.data.is_hide == 1) {
            this.showRecord = 1;
          }

          this.theatreInfo.starShow =
            res.data.opening_date && res.data.opening_date !== "0000-00-00"
              ? true
              : false;

          this.theatreInfo.endShow =
            res.data.closing_date && res.data.closing_date !== "0000-00-00"
              ? true
              : false;

          console.log(this.theatreInfo.starShow, this.theatreInfo.endShow);

          this.getDefualtData();
          this.datePickerComKey++;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    }
  },
  created() {
    const area = window.sessionStorage.getItem("area") || "";
    this.getState();
    this.getDistrict();
    this.getCircuitList();
    if (this.theatreId) {
      this.getTheatreDetail();
      this.getTheatreHouseList();
    } else {
      this.info[this.getChildrenIndex("houses_count").father].children.splice(
        this.getChildrenIndex("houses_count").child,
        1
      );
    }
    this.getDefualtData();
    if (area === "macau") {
      this.theatreInfo.status = "XML";
    }
  }
};
</script>
<style lang="scss">
.th-list {
  table {
    thead {
      tr {
        th {
          background-color: #fff !important;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        border-top: none !important;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.theatreInfo {
  width: 100%;
  flex-wrap: wrap;
  margin: 0 15px;
  justify-content: space-between;
  .th-box {
    width: 48%;
    display: flex;
    .th-label {
      width: 35.5%;
    }
    .th-input {
      width: 64.5%;
    }
  }
  .list-width {
    width: 70%;
    margin-left: 15%;
    .th-input {
      width: 100%;
    }
  }
}
</style>
