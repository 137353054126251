<template>
  <DistributorForm
    :title="title"
    :type="type"
  />
</template>
<script>
import DistributorForm from '../components/filmDistributorForm.vue'

export default {
  components: {
    DistributorForm,
  },
  data() {
    return {
      title: '發行商',
      type: 'create',
    }
  },
}
</script>
