import request from '@/utils/request'

// get theatrehouse
export function getTheatreHouseList (theatreid, data) {
  return request({
    url: `/api/theatre/${theatreid}/house?${data}`,
    method: 'GET',
  })
}
// add theatrehouse
export function addTheatreHouse (theatreid, data) {
  return request({
    url: `/api/theatre/${theatreid}/house`,
    method: 'POST',
    data
  })
}

// get theatrehouseDetail
export function getTheatreHouseDetail (theatreid, id) {
  return request({
    url:`/api/theatre/${theatreid}/house/${id}`,
    method: 'GET',
  })
}

//edit theatrehouse
export function editTheatreHouse (theatreid, id, data) {
  return request({
    url: `/api/theatre/${theatreid}/house/${id}`,
    method: 'PUT',
    data
  })
}
//edit multiTheatrehouse
export function multiTheatreHouse (theatreid, data) {
  return request({
    url: `/api/theatre/${theatreid}/house-save`,
    method: 'PUT',
    data
  })
}
//delect theatrehouse
export function delectTheatreHouse (theatreid, id) {
  return request({
    url: `/api/theatre/${theatreid}/house/${id}`,
    method: 'DELETE',
  })
}