<template>
  <div>
    <profilesInfo
      :title="title"
      :title-en="titleEn"
      :select-options="selectOptions"
      :titles="infoList"
      :type="type"
      :pageId="pageId"
      :caseList="caseList"
      @saveInfo="saveInfo"
      :name="name"
      :select="select"
      :sex.sync="sex"
      :remarks="remarks"
      :pageTypeVal.sync="pageType"
      :loading.sync="loading"
      @getDetail="getProducerDetial"
    />
  </div>
</template>

<script>
import profilesInfo from '../profilespage/profilesInfo'
import {
  getProducerDetial,
  editProducer,
  createProducer
} from '@/api/film-material-management/producer'

export default {
  components: {
    profilesInfo
  },
  data() {
    return {
      loading: false,
      type: 'newInfo',
      title: '監製',
      name: 'producer',
      titleEn: 'Producer',
      select: {
        name: ''
      },
      sex: '',
      remarks: {
        name: '',
        status: false
      },
      selectOptions: [
        { text: '香港', value: 'Hong Kong' },
        { text: '非香港', value: 'Non Hong Kong' }
      ],
      pageId: '',
      caseList: [],
      infoList: [
        { title_name: 'Producer Name', val: '' },
        { title_name: '監製名稱', val: '' },
        { title_name: '別名', val: '' }
      ],
      createInfo: '',
      area: '',
      pageType: ''
    }
  },
  methods: {
    getProducerDetial() {
      this.loading = true
      getProducerDetial(this.pageId)
        .then((res) => {
          console.log(res)
          this.caseList = []
          res.data.old_record.forEach((item) => {
            this.caseList.push({
              id: item.id,
              name_en: item.name_en,
              name_zh_hk: item.name_zh_hk,
              change_date: item.change_date,
              area: item.area,
              other_name: item.other_name,
              disabled: true
            })
          })

          this.infoList = [
            { title_name: 'Producer Name', val: res.data.producer_name_en },
            { title_name: '監製名稱', val: res.data.producer_name_zh_hk },
            { title_name: '別名', val: res.data.producer_name_other }
          ]
          this.select.name = res.data.area
          this.remarks.name = ''
          if (this.select.name === 'Others') {
            this.remarks.status = true
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    },
    saveInfo(value, select, iscase, issex, remarks, revise) {
      this.createInfo = value
      this.area = select.name
      this.caseLists = iscase
      this.sex = issex
      this.remarks = remarks
      if (this.pageId) {
        this.editProducer(revise)
      } else {
        this.createProducer(revise)
      }
    },
    editProducer(revise) {
      const data = {
        producer_name_en: this.createInfo[0].val,
        producer_name_zh_hk: this.createInfo[1].val,
        producer_name_other: this.createInfo[2].val,
        remarks: this.remarks.name,
        area: this.area,
        revise
      }

      editProducer(this.pageId, data)
        .then((res) => {
          console.log(res)
          this.caseList = res.data.old_record
          this.getProducerDetial()
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    createProducer(revise) {
      const data = {
        producer_name_en: this.createInfo[0].val,
        producer_name_zh_hk: this.createInfo[1].val,
        producer_name_other: this.createInfo[2].val,
        area: this.area,
        remarks: this.remarks.name,
        revise
      }
      createProducer(data)
        .then((res) => {
          this.$router.push({
            name: 'producer-info',
            query: {
              type: 'review',
              id: res.data.id,
              pageId: res.data.id
            }
          })
          this.pageId = res.data.id
          this.pageType = 'review'
          this.getProducerDetial()
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  mounted() {
    if (this.pageId) {
      this.getProducerDetial()
    }
  },
  created() {
    this.pageId = this.$route.query.id
    this.pageType = this.$route.query.type
  }
}
</script>

<style lang="scss" scoped>
.title-top {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.title {
  width: 100%;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin: 20px 0;
}
</style>
