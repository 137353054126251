var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dif_items && _vm.dif_items.length > 0)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"film-different-circuit mt-1"},[_c('p',{staticStyle:{"color":"#0e72bc"}},[_vm._v("Code Using by different Circuit")]),_c('b-table',{key:_vm.tabelKey,attrs:{"bordered":"","striped":"","hover":"","items":_vm.dif_items,"fields":_vm.$store.state.highlightCol.firTable
          ? _vm.$store.state.newTableFields
          : _vm.dif_fields},on:{"head-clicked":function($event){return _vm.commonHighLightCol(
          $event,
          _vm.dif_fields,
          _vm.$store.state.newTableFields,
          'firTable'
        )}},scopedSlots:_vm._u([{key:"cell(action)",fn:function(data){return [_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){return _vm.goPage('revise-record-add', data.item, 'notfound')}}},[_vm._v("作出修訂")])]}}],null,false,2298228252)}),_c('div',{staticClass:"line"}),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("F-R = Engagament record Errors in Final Reports")]),_c('br'),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("H-R = Engagament record Errors in Hourly Reports")])],1):_c('div',{staticClass:"mt-1"},[_vm._v("Film_ID not in use,No matching record found.")])])}
var staticRenderFns = []

export { render, staticRenderFns }