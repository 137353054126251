<template>
  <div class="unusual-record">
    <b-card>
      <div class="title">
        <p>異常及未有記錄電影</p>
      </div>
      <div class="not-found" v-loading="loading">
        <div class="tit">
          <span>在資料庫內未有記錄之電影</span>
          <span>共 {{ tableDataNotFound.length }} 項</span>
        </div>
        <b-table
          bordered
          striped
          hover
          responsive
          :items="tableDataNotFound"
          :fields="
            $store.state.highlightCol.firTable
              ? $store.state.newTableFields
              : tableFieldsNotFound
          "
          @head-clicked="
            commonHighLightCol(
              $event,
              tableFieldsNotFound,
              $store.state.newTableFields,
              'firTable'
            )
          "
        >
          <template v-slot:cell(action)="row">
            <b-button
              size="sm"
              variant="outline-secondary"
              class="handle-button"
              style="width: 80px"
              @click="
                goPage(
                  'revise-record-add',
                  row.item.pos_id,
                  row.item.master_id,
                  row.item
                )
              "
            >
              作出修訂
            </b-button>
          </template>
        </b-table>
        <div class="d-flex justify-content-end">
          <el-pagination
            hide-on-single-page
            :current-page.sync="paramsVal.notPage"
            :page-size="paramsVal.notCount"
            @current-change="handleCurrentChangeNot"
            background
            layout="prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <div class="abnormal mt-4" v-loading="abnormalLoading">
        <div class="tit">
          <span>在資料庫內異常之電影</span>
          <span>共 {{ totalAbnormal }} 項</span>
          <span style="color: red" v-show="showPresale"
            >(有電影預售超過14天)</span
          >
        </div>
        <div>
          <b-table
            bordered
            striped
            hover
            responsive
            :items="tableDataAbnormal"
            :fields="
              $store.state.highlightCol.secTable
                ? $store.state.newSecTableFields
                : tableFieldsAbnormal
            "
            @head-clicked="
              commonHighLightCol(
                $event,
                tableFieldsAbnormal,
                $store.state.newSecTableFields,
                'secTable'
              )
            "
          >
            <template #head(title_name)>
              <div style="width: 130px; text-wrap: wrap">
                Film Name By Circuit Prov.
              </div>
            </template>
            <template #cell(title_name)="row">
              <div style="width: 130px">{{ row.item.title_name }}</div>
            </template>

            <template #cell(circuit)="row">
              <div style="width: 100px">{{ row.item.circuit }}</div>
            </template>
            <template #cell(full_name)="row">
              <div style="width: 100px">{{ row.item.full_name }}</div>
            </template>
            <template #cell(house)="row">
              <div class="text-nowrap">{{ row.item.house }}</div>
            </template>
            <template #cell(show_time)="row">
              <div class="text-nowrap">{{ row.item.show_time }}</div>
            </template>
            <template v-slot:cell(action1)="row">
              <div class="d-flex">
                <div>
                  <b-button
                    style="width: 130px"
                    variant="outline-secondary"
                    class="handle-button mb-1"
                    @click="setOvertime(row.item.film_id)"
                    size="sm"
                  >
                    延長落畫時間
                  </b-button>
                  <b-button
                    size="sm"
                    variant="outline-secondary"
                    class="handle-button"
                    style="width: 130px"
                    @click="
                      goPage(
                        'revise-record-add',
                        row.item.pos_id,
                        row.item.film_master_id,
                        row.item
                      )
                    "
                  >
                    指派到新Film ID
                  </b-button>
                </div>
                <div>
                  <b-button
                    size="sm"
                    style="width: 120px"
                    variant="outline-secondary"
                    class="handle-button mb-1"
                    v-b-modal.modal-delete-status
                    @click="actionChanges('delete', row.item.id)"
                  >
                    刪除這項記錄
                  </b-button>
                  <b-button
                    size="sm"
                    style="width: 120px"
                    variant="outline-secondary"
                    class="handle-button"
                    v-b-modal.modal-delete-status
                    @click="actionChanges('status', row.item.id)"
                  >
                    已完成處理
                  </b-button>
                </div>
              </div>
            </template>
            <template #head(action1)>
              <b-button
                size="sm"
                style="width: 110px"
                variant="primary"
                class="handle-button"
                v-b-modal.modal-all-complete
              >
                全部完成
              </b-button>
            </template>
          </b-table>

          <div class="d-flex justify-content-end">
            <el-pagination
              hide-on-single-page
              :current-page.sync="paramsVal.abPage"
              :page-size="paramsVal.abCount"
              @current-change="handleCurrentChange"
              background
              layout="prev, pager, next"
              :total="totalAbnormal"
            >
            </el-pagination>
          </div>
        </div>
        <b-modal id="modal-delete-status" centered hide-footer>
          <p>是否確認{{ actionStatus }}此條記錄？</p>
          <div class="float-right">
            <b-button
              class="mr-1"
              @click="$bvModal.hide('modal-delete-status')"
              size="sm"
              >取消</b-button
            >
            <b-button
              size="sm"
              @click="deletFilmRecord(itemId, actionStatusVal)"
              >確認</b-button
            >
          </div>
        </b-modal>
      </div>
      <b-modal id="modal-all-complete" hide-footer centered title="全部完成">
        <p class="my-3">是否確認全部完成處理？</p>
        <div class="float-right">
          <b-button
            class="mr-1"
            @click="$bvModal.hide('modal-all-complete')"
            size="sm"
            >取消</b-button
          >
          <b-button size="sm" @click="setAllStatus">確認</b-button>
        </div>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  getUnfoundRecord,
  getAbnormalRecord,
  deletFilmRecord,
  setOvertime,
  setAllStatus,
  setStatusMulti
} from '@/api/management.js'
export default {
  data() {
    return {
      showPresale: false,
      abnormalLoading: false,
      itemId: '',
      actionStatus: '',
      actionStatusVal: '',
      updateOverTime: '',
      total: 0,
      totalAbnormal: 0,
      tableDataAbnormal: [],
      tableFieldsAbnormal: [
        { key: 'id', label: 'EID', sortable: true },
        { key: 'film_master_id', label: 'FMID', sortable: true },
        { key: 'film_id', label: 'FID', sortable: true },
        { key: 'pos_id', label: 'POS', sortable: true },
        {
          key: 'title_name',
          label: 'Film Name By Circuit Prov.',
          sortable: true
        },
        { key: 'circuit_name', label: 'Circuit', sortable: true },
        { key: 'full_name', label: 'Theatre Name', sortable: true },
        { key: 'house', label: 'House', sortable: true },
        { key: 'show_time', label: 'Show Time', sortable: true },
        { key: 'action1', label: '' }
      ],
      loading: false,
      tableDataNotFound: [],
      tableFieldsNotFound: [
        { key: 'pos_id', label: 'Circuit POS ID ', sortable: true },
        {
          key: 'title_name',
          label: 'Film Name By Circuit Prov.',
          sortable: true
        },
        { key: 'circuit_name', label: 'Circuit', sortable: true },
        { key: 'remarks', label: 'Remarks', sortable: true },
        { key: 'action', label: '' }
      ],
      paramsVal: {
        notPage: 1,
        notCount: 1000,
        abPage: 1,
        abCount: 1000
      }
    }
  },
  computed: {},
  methods: {
    setAllStatus() {
      setAllStatus()
        .then((res) => {
          this.$bvModal.hide('modal-all-complete')
          this.getUnfoundRecord()
        })
        .catch((error) => {
          console.log(error)
          this.$bvModal.hide('modal-all-complete')
        })
    },
    handleCurrentChange(val) {
      console.log(val)
      this.paramsVal.abPage = val
      this.getAbnormalRecord()
    },
    handleCurrentChangeNot(val) {
      this.paramsVal.notPage = val
      this.getUnfoundRecord()
    },
    getAbnormalRecord() {
      this.abnormalLoading = true
      const data = {
        page: this.paramsVal.abPage,
        count: this.paramsVal.abCount
      }
      getAbnormalRecord(data)
        .then((res) => {
          this.totalAbnormal = res.total
          this.tableDataAbnormal = res.data
          this.tableDataAbnormal.map((el) => {
            console.log(
              this.$moment(el.show_time).diff(this.$moment(), 'days')
            )
            if (this.$moment(el.show_time).diff(this.$moment(), 'days') > 14) {
              this.showPresale = true
            }
          })
          this.abnormalLoading = false
        })
        .catch((error) => {
          console.log(error)
          this.abnormalLoading = false
        })
    },
    getUnfoundRecord() {
      this.loading = true
      const data = {
        page: this.paramsVal.notPage,
        count: this.paramsVal.notCount
      }
      getUnfoundRecord(data).then((res) => {
        this.tableDataNotFound = res.data
        this.total = res.total
        this.loading = false
      })
    },
    goPage(info, pos_id, master_id, item) {
      const search_split = this.getFilmSearchName(item)
      const filmInfo = {
        cinema_id: item.cinema_id,
        circuit: item.circuit_name,
        circuit_id: item.circuit_id,
        pos_id: item.pos_id,
        title_name: item.title_name,
        search_name: search_split,
        id: item.id,
        goPage: this.$route.fullPath
      }
      sessionStorage.setItem('filmInfo', JSON.stringify(filmInfo))
      this.$nextTick(() => {
        this.$router.push({
          name: info,
          query: {
            pos_id,
            master_id,
            search_name: search_split,
            circuit_id: item.circuit_id,
            id: item.id,
            cinema_id: item.cinema_id
          }
        })
      })
    },
    actionChanges(type, id) {
      this.itemId = id
      if (type === 'delete') {
        this.actionStatus = '刪除'
        this.actionStatusVal = 'D'
      } else {
        this.actionStatus = '更改'
        this.actionStatusVal = ''
      }
    },
    deletFilmRecord(id, status) {
      const data = {
        status
      }
      deletFilmRecord(id, data)
        .then((res) => {
          // this.$router.go(0)
          this.$bvModal.hide('modal-delete-status')
          this.getAbnormalRecord()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    setOvertime(id) {
      setOvertime(id)
        .then((res) => {
          console.log(res)
          this.getAbnormalRecord()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    returnRecord() {
      this.$router.push({ name: 'management-center' })
    }
  },
  created() {
    this.getUnfoundRecord()
    this.getAbnormalRecord()
  }
}
</script>
<style lang="scss"></style>
<style lang="scss" scoped>
.tip {
  color: #0e72bc;
}
.search {
  display: flex;
  margin: 15px 0;
}
.table-name:hover {
  text-decoration: underline;
}
.tit {
  width: 100%;
  color: #0e72bc;
  font-size: 17px;
  font-weight: 500;
  margin: 20px 0;
  justify-content: flex-start;
}
</style>
