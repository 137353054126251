<template>
  <form v-loading="updateLoading">
    <b-card :key="cardKey">
      <div class="title" style="width: 100%">
        <p>{{ infoPageTitle }}</p>
      </div>
      <div>
        <b-button
          variant="outline-primary"
          @click="$router.push({ path: `/dashboard/${name}` })"
        >
          回目錄
        </b-button>
        <p class="sub-title">
          {{ titleEn }}_ID :
          {{ pageType == "create" ? "Create New ID" : pageId }}
        </p>
      </div>
      <template>
        <b-container class="pl-0">
          <b-row v-for="(item, index) in titles" :key="index" class="my-1">
            <b-col sm="2">
              <label :for="`type-${item}`">{{ item.title_name }}:</label>
            </b-col>
            <b-col sm="10">
              <b-form-input
                :id="`type-${item}`"
                type="text"
                v-model="item.val"
                :disabled="disableChange"
              />
            </b-col>
          </b-row>
          <b-row class="my-1" v-if="pagePath === '/dashboard/actor-info'">
            <b-col sm="2">
              <label>演員性別:</label>
            </b-col>
            <b-col sm="10">
              <b-form-select
                v-model="sexVal"
                :options="sexOptions"
                :disabled="disableChange"
                selected
                style="color: black"
              />
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="2">
              <label>地區:</label>
            </b-col>
            <b-col sm="10">
              <b-form-select
                v-model="select.name"
                :options="selectOptions"
                :disabled="disableChange"
                @change="remarkShow"
                selected
                style="color: black"
              />
            </b-col>
          </b-row>
          <b-row class="my-1" v-if="remarks.status">
            <b-col sm="2">
              <label>Remarks:</label>
            </b-col>
            <b-col sm="10">
              <b-form-textarea
                id="remarks"
                label="Remarks"
                rows="3"
                v-model="remarks.name"
                name="remarks"
                :disabled="disableChange"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="pageType == 'edit'" sm="12">
              <div class="d-flex justify-content-end">
                <b-button
                  @click="disableChange = false"
                  v-if="disableChange == true"
                  variant="outline-primary"
                  >修改</b-button
                >
                <b-button
                  class="mr-1"
                  @click="submit('noAddRecord')"
                  v-if="disableChange == false"
                  variant="outline-primary"
                  >修正上方紀錄</b-button
                >
                <b-button
                  @click="submit('addRecord')"
                  v-if="disableChange == false"
                  variant="outline-primary"
                  >修訂紀錄並紀錄舊名</b-button
                >
              </div>
            </b-col>
          </b-row>
        </b-container>
      </template>
      <div v-if="pageId && caseList && caseList.length > 0">
        <b-row v-for="(item, index) in caseList" :key="index">
          <b-col cols="3">
            <b-form-group
              :label="title + '名稱 (英文舊名)'"
              label-for="publisher-english-old"
            >
              <div class="text-wrap:wrap input-box-style">
                {{ item.name_en }}
              </div>
              <!-- <b-form-input
                id="publisher-english-old"
                :label="title + '名稱 (英文舊名)'"
                rows="3"
                v-model="item.name_en"
                name="name_en"
                :disabled="true"
              /> -->
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              :label="title + '名稱 (中文舊名)'"
              label-for="publisher-chinese-old"
            >
              <div class="text-wrap:wrap input-box-style">
                {{ item.name_zh_hk }}
              </div>
              <!-- <b-form-input
                id="publisher-chinese-old"
                :label="title + '名稱 (中文舊名)'"
                rows="3"
                v-model="item.name_zh_hk"
                name="name_zh_hk"
                :disabled="true"
              /> -->
            </b-form-group>
          </b-col>

          <b-col cols="3">
            <b-form-group :label="'別名'" label-for="otherName-old">
              <div class="text-wrap:wrap input-box-style">
                {{ item.other_name }}
              </div>
              <!-- <b-form-input
                id="otherName-old"
                label="別名"
                rows="3"
                v-model="item.other_name"
                name="other_name"
                :disabled="true"
              /> -->
            </b-form-group>
          </b-col>
          <b-col cols="3" v-if="title === '演員'">
            <b-form-group :label="'性別'" label-for="sex-old">
              <b-form-select
                v-model="item.sex"
                :options="sexOptions"
                :disabled="true"
                selected
                style="color: black"
                rows="3"
                id="sex-old"
                name="sex"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group :label="'地區'" label-for="area-old">
              <b-form-input
                id="area-old"
                label="地區"
                rows="3"
                v-model="item.area"
                name="area"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="更改記錄日期" label-for="name-date">
              <datePickerCom
                :isDisabled="item.disabled"
                :pickTimeVal.sync="item.change_date"
                :inputWidth="'100%'"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3" class="margin-auto">
            <div
              style="margin-top: 27px"
              class="b-flex d-flex justify-content-center"
            >
              <feather-icon
                v-if="
                  pageType === 'edit' &&
                  !item.disabled
                "
                v-b-tooltip.hover
                title="Save"
                size="25"
                @click="historySave(item.id, index)"
                icon="SaveIcon"
                class="mr-3 cursor-pointer"
                color="#0e72bc"
              />
              <feather-icon
                v-if="
                  pageType === 'edit' &&
                  item.disabled
                "
                @click="editChange(index, 'edit')"
                size="25"
                v-b-tooltip.hover
                title="Edit"
                icon="EditIcon"
                class="mr-3 cursor-pointer"
                color="#0e72bc"
              />
              <feather-icon
                v-if="
                  pageType === 'edit' &&
                  !item.disabled
                "
                @click="deleteHistory(item.id)"
                size="25"
                v-b-tooltip.hover
                title="Delect"
                icon="Trash2Icon"
                color="red"
                class="mr-3 cursor-pointer"
              />
              <span
                v-if="!item.disabled && pageType === 'edit'"
                @click="editChange(index, 'cancel')"
                >取消</span
              >
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="pageType === 'edit'" class="line" style="width: 100%" />
      <div class="d-flex justify-content-center" v-if="pageType === 'edit'">
        <b-button
          v-if="!disableChange"
          variant="primary"
          @click="editChange('test', 'all-cancel')"
          >取消編輯</b-button
        >
      </div>
      <div class="d-flex justify-content-center" v-if="pageType === 'create'">
        <b-button
          @click="submit('noAddRecord')"
          variant="primary"
          :class="pageType === 'create' ? 'btn-create' : ''"
          >Save / 存檔</b-button
        >
      </div>
    </b-card>
  </form>
</template>

<script>
import { getSelfProfile } from "@/api/login";
import datePickerCom from "@/views/components/datePicker.vue";
import {
  changeOldRecord,
  deleteOldRecord
} from "@/api/film-material-management/oldRecord";

export default {
  components: { datePickerCom },
  props: {
    pageId: {
      type: [String, Number],
      default: ""
    },
    titleEn: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },

    selectOptions: {
      type: Array,
      required: true
    },
    titles: {
      type: Array,
      required: true
    },
    caseList: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    select: {
      type: Object,
      required: true
    },
    sex: {
      type: [String, Object],
      required: true
    },
    remarks: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    pageTypeVal: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      cardKey: 1,
      borderClass: "",
      datetime: null,
      pagePath: "",
      createStyle: {
        marginLeft: "25%"
      },
      sexOptions: ["F", "M"],
      disableChange: this.pageTypeVal !== "create"
    };
  },
  watch: {
    pageType(val) {
      console.log(val);
      if (val === "review") {
        this.disableChange = true;
      }
    }
  },
  computed: {
    pageType: {
      get() {
        return this.pageTypeVal;
      },
      set(value) {
        this.$emit("update:pageTypeVal", value);
      }
    },
    sexVal: {
      get() {
        return this.sex;
      },
      set(value) {
        this.$emit("update:sex", value);
      }
    },
    updateLoading: {
      get() {
        return this.loading;
      },
      set(value) {
        this.$emit("update:loading", value);
      }
    },
    infoPageTitle() {
      if (this.pageType === "review") {
        return `${this.title}記錄`;
      } else if (this.pageType === "create") {
        return `開立新${this.title}記錄`;
      } else {
        return `修訂${this.title}記錄`;
      }
    }
  },
  created() {
    console.log(this.caseList);
    // this.pageId = this.$route.query.id
    this.pagePath = this.$route.path;
    this.pageType = this.$route.query.type;
    // this.getSelfProfile();
    console.log(this.disableChange);
  },
  methods: {
    deleteHistory(id) {
      this.updateLoading = true;
      deleteOldRecord(this.name, id)
        .then((res) => {
          console.log(res);
          this.$emit("getDetail");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    historySave(id, index) {
      console.log(id, "is id");
      this.updateLoading = true;
      console.log(this.caseList[index]);
      const data = {
        name_en: this.caseList[index].name_en,
        name_zh_hk: this.caseList[index].name_zh_hk,
        change_date: this.caseList[index].change_date
      };
      changeOldRecord(this.name, id, data)
        .then((res) => {
          console.log(res);
          this.updateLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.updateLoading = false;
        });
    },
    editChange(index, type) {
      if (type === "edit") {
        this.caseList[index].disabled = false;
      } else if (type === "cancel") {
        this.caseList[index].disabled = true;
        this.$emit("getDetail");
      } else if (type === "all-cancel") {
        this.disableChange = true;
        this.caseList.map((el) => {
          el.disabled = true;
        });
        this.$emit("getDetail");
      }
    },
    // getSelfProfile() {
    //   getSelfProfile()
    //     .then((res) => {
    //       this.userInfo = res.data;
    //       console.log(this.userInfo);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    remarkShow(val) {
      if (val === "Others") {
        this.remarks.status = true;
      } else if (val === "Hong Kong") {
        this.remarks.status = false;
        this.titles.map((el) => {
          if (el.val.includes("*")) {
            el.val = el.val.replace(/\*/g, "");
          }
        });
      } else {
        this.remarks.status = false;
        this.titles.map((el) => {
          if (!el.val.includes("*")) {
            el.val = el.val + "*";
          }
        });
      }
    },
    saveInfo() {
      // this.titles.push(this.select)
      this.$emit(
        "saveInfo",
        this.titles,
        this.select,
        this.caseList,
        this.sexVal,
        this.remarks
      );
    },
    submit(type) {
      this.updateLoading = true;
      const revise = type === "noAddRecord" ? 0 : 1;
      this.$emit(
        "saveInfo",
        this.titles,
        this.select,
        this.caseList,
        this.sexVal,
        this.remarks,
        revise
      );
    }
  }
};
</script>
<style lang="scss"></style>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.sub-title {
  width: 100%;
  color: #0e72bc;
  font-size: 16px;
  font-weight: 500;
  margin: 20px 0;
}
.line {
  height: 1.5px;
  background: #0e72bc;
  width: 100%;
  margin: 20px 0;
}
.input-box {
  width: 27%;
}
.change-box {
  height: 80px;
  border-bottom: 2px solid #0e72bc;
  .change-btn {
    margin-top: 20px;
    float: right;
  }
}
.save {
  margin: auto;
  margin-top: 30px;
  width: 40%;
  border-bottom: 2px solid #c8cacc;
  text-align: center;
  cursor: pointer;
}
.save:hover {
  color: #0e72bc;
}
.btn-create {
  margin-left: -25%;
}
.label {
  font-size: 14px;
}
.case-tank {
  width: 100%;
}
.redborder {
  border-color: red;
}
</style>
