<template>
  <div>
    <b-card>
      <div class="title">
        <p>輸入/修改 票房記錄</p>
      </div>
      <div class="d-flex">
        <div class="mr-5">
          <p>
            戲院名稱 Theatre Name: <b>{{ theatreName }}</b>
          </p>
          <b-button
            size="sm"
            class="mr-2 mb-2"
            variant="outline-primary"
            @click="enterRecord"
          >
            輸入票房記錄
          </b-button>
        </div>
        <div class="">
          <p class="ml-1" style="color: red">所選日期前30日的記錄</p>
          <div>
            <datePickerCom
              :pickTimeVal.sync="datepickerValue"
              :inputWidth="'260px'"
              @getRecords="getRecords"
            />
          </div>
          <b-row>
            <b-col sm="3">
              <label></label>
            </b-col>
            <b-col sm="12"> </b-col>
          </b-row>
          <!-- <p class="sub-title">最近一個月的記錄</p> -->
        </div>
      </div>

      <b-table
        bordered
        v-loading="loading"
        striped
        hover
        responsive
        :items="items"
        :fields="
          $store.state.highlightCol.firTable
            ? $store.state.newTableFields
            : fields
        "
        @head-clicked="
          commonHighLightCol(
            $event,
            fields,
            $store.state.newTableFields,
            'firTable'
          )
        "
      >
        <template #cell(engagement_date)="data">
          <div class="text-nowrap">
            {{ $moment(data.item.engagement_date).format() }}
          </div>
        </template>
        <template #cell(show_time)="data">
          <div class="text-nowrap">
            {{ $moment(data.item.show_time).format("DD-MM-YYYY HH:mm:ss") }}
          </div>
        </template>
        <template #cell(admissions)="data">
          <div class="text-nowrap">
            {{ parseFloat(data.item.admissions).toLocaleString() }}
          </div>
        </template>
        <template #cell(gross)="data">
          <div class="text-nowrap">
            {{ parseFloat(data.item.gross).toLocaleString() }}
          </div>
        </template>
        <template #cell(action)="data">
          <b-button
            style="width: 90px"
            size="sm"
            class="mr-2"
            :variant="
              adminCant(data.item.show_time)
                ? 'outline-light'
                : 'outline-primary'
            "
            :disabled="adminCant(data.item.show_time)"
            @click="editRecord(data.item.id)"
          >
            修訂記錄
          </b-button>
        </template>
      </b-table>
      <p>紅色highligh代表午夜場</p>
    </b-card>
  </div>
</template>

<script>
import { getManualBoxoffice } from "@/api/boxoffice.js"
import { getTheatreDetail } from "@/api/theatre.js"
import datePickerCom from "@/views/components/datePicker.vue"

export default {
  components: { datePickerCom },
  data() {
    return {
      loading: false,
      rows: 5,
      perPage: 1,
      currentPage: 1,
      datepickerValue: new Date(),
      fields: [
        {
          key: "id",
          label: "E_ID",
          sortable: true,
        },
        {
          key: "engagement_date",
          label: "Engt.Date",
          sortable: true,
        },
        {
          key: "house_name",
          label: "Screen ID/ House ID",
          sortable: true,
        },
        {
          key: "show_time",
          label: "Show Time",
          sortable: true,
        },
        {
          key: "full_name",
          label: "Film Name",
          sortable: true,
        },
        {
          key: "admissions",
          label: "Total ADM.",
          sortable: true,
        },
        {
          key: "gross",
          label: "Total B.O.",
          sortable: true,
        },
        {
          key: "user",
          label: "User",
          sortable: true,
        },
        {
          key: "action",
          label: "",
        },
      ],
      items: [],
      theatreName: "",
      theatreId: null,
      listParams: {
        date: new Date().toISOString().split("T")[0],
      },
    }
  },
  computed: {
    adminCant() {
      return function (val) {
        const today = new Date()
        const showTime = new Date(val)
        const userRole = JSON.parse(window.localStorage.getItem("userData"))
        const diffDate = Math.ceil((today - showTime) / (1000 * 3600 * 24))
        if (userRole.role === "Admin Staff" && diffDate > 2) {
          return true
        } else {
          return false
        }
      }
    },
  },
  methods: {
    getRecords(val) {
      this.listParams.date = val
      this.getBoxOffice()
    },
    enterRecord() {
      this.$router.push({
        name: "boxoffice_record_detail",
        query: { theatreid: this.theatreId, type: "create", is_temp: 0 },
      })
    },
    editRecord(val) {
      this.$router.push({
        name: "boxoffice_record_detail",
        query: {
          theatreid: this.theatreId,
          id: val,
          type: "edit",
          prePage: "handType",
        },
      })
    },
    getBoxOffice() {
      this.loading = true
      const data = {
        date: this.listParams.date,
      }
      getManualBoxoffice(data, this.theatreId)
        .then((res) => {
          this.loading = false
          this.items = res.data
          this.items.map((el) => {
            const showTime = new Date(el.show_time)
            const version = el.film.remark_2
              ? `[${el.film.remark_1}/${el.film.remark_2}]`
              : `[${el.film.remark_1}]`
            el.full_name = `${el.film_name} (${el.film.language}) ${version} (${el.film.rating})`
            el._rowVariant =
              new Date(
                showTime.getFullYear(),
                showTime.getMonth(),
                showTime.getDate()
              ) > new Date(el.engagement_date)
                ? "danger"
                : ""
          })
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    getBoxOfficeName() {
      getTheatreDetail(this.theatreId).then((res) => {
        this.theatreName = res.data.full_name
        console.log(res)
      })
    },
  },
  created() {
    this.theatreId = this.$route.query.id
    this.getBoxOffice()
    this.getBoxOfficeName()
  },
}
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}
.sub-title {
  width: auto;
  color: red;
  font-size: 16px;
  font-weight: 500;
  margin: 20px 0;
}
</style>
