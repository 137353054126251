<template>
  <div>
    <b-card>
      <div class="title">
        <p>修改票房記錄</p>
      </div>
      <div>
        <b-col>
          <p class="sub-title">20.1 修改票房記錄</p>
          <div class="d-flex">
            <datePickerCom
              class="mr-1"
              :pickTimeVal.sync="date"
              :inputWidth="'220px'"
            />

            <b-button
              variant="primary"
              class="mr-2"
              @click="goReport('select-theatre')"
            >
              修改這天一天的記錄
            </b-button>
            <b-button
              variant="primary"
              @click="goReport('select-theatre', 'last-year')"
            >
              修改過去一年的記錄
            </b-button>
          </div>
        </b-col>
        <div class="line" />
        <b-col>
          <p class="sub-title">20.2 新增票房記錄</p>
          <div class="d-flex">
            <b-form-select
              v-model="periodSelect"
              :options="selectOptions"
              class="w300 per-page-selector mr-2 special-report-form"
            ></b-form-select>
            <b-button variant="primary" @click="goReport('add-record')">
              新增記錄
            </b-button>
          </div>
        </b-col>
      </div>
    </b-card>
  </div>
</template>
<script>
import { getBoxOfficesList } from "@/api/editBoxoffice.js"
import datePickerCom from "@/views/components/datePicker.vue"

export default {
  components: {
    datePickerCom,
  },
  data() {
    return {
      select: "",
      DatePickerFormat: "yyyy",
      Comparisoncheck: false,
      periodSelect: "",
      selectOptions: [],
      BoxOfficesList: [],
      boxId: "",
      date: "",
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      getBoxOfficesList().then((res) => {
        this.BoxOfficesList = res.data
        console.log(this.BoxOfficesList)
        this.BoxOfficesList.forEach((element) => {
          this.selectOptions.push({
            text: element.full_name,
            value: element.id,
          })
        })
      })
    },
    goReport(type, otherValue) {
      const query = {}
      if (type === "add-record") {
        // this.boxId = this.BoxOfficesList.filter(one => {
        //   return one.full_name === this.periodSelect
        // })[0].id
        // query.id = this.boxId
        if (this.periodSelect) {
          this.$router.push({
            name: "boxoffice_record_detail",
            query: {
              theatreid: this.periodSelect,
              type: "create",
              showPresale: "1",
              is_temp: 0,
            },
          })
        } else {
          this.$message({
            message: "請選擇戲院",
            type: "warning",
          })
        }
      }
      if (type === "select-theatre") {
        if (this.date) {
          if (otherValue) {
            query.date = this.$moment(this.date)
              .subtract(1, "years")
              .format("YYYY-MM-DD")
            this.$router.push({ name: type, query })
          } else {
            query.date = this.date
            this.$router.push({ name: type, query })
          }
        } else {
          if (otherValue) {
            query.date = this.$moment()
              .subtract(1, "years")
              .format("YYYY-MM-DD")
            this.$router.push({ name: type, query })
          } else {
            this.$message({
              message: "請選擇日期",
              type: "warning",
            })
          }
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.line {
  height: 1.5px;
  background: #0e72bc;
  width: 100%;
  margin: 20px 0;
}
.sub-title {
  width: 100%;
  color: #0e72bc;
  font-size: 16px;
  font-weight: 500;
  margin: 20px 0;
}
.special-report-form {
  width: 20%;
}
.text-red {
  color: red;
}
.w300 {
  width: 400px;
}
</style>
