<template>
  <div>
    <b-card>
      <div class="title">
        <p>分類查閱上載記錄</p>
      </div>
      <div
        v-for="(item, index) in categoryList"
        :key="index"
        class="category-tank mb-2 pb-1"
      >
        <p class="category-title">
          {{ item.title }}
        </p>
        <b-row class="my-1">
          <b-col sm="8">
            <b-form-input
              v-if="item.type !== 'date'"
              v-show="!item.noInput"
              v-model="item.val"
              :type="item.type"
              :tabindex="item.tabNum"
            />
            <datePickerCom
              v-else-if="(item.type === 'date') & (item.title === '16.9')"
              v-show="!item.noInput"
              :pickTimeVal.sync="item.val"
              :inputWidth="'100%'"
              :tabIndexNum="item.tabNum"
            />
            <!-- <datePickerCom
              v-else-if="(item.type === 'date') & (item.title === '16.9')"
              v-show="!item.noInput"
              :pickTimeVal.sync="item.val"
              :inputWidth="'100%'"
              :pickerOptions="{ disabledDate }"
            /> -->
            <datePickerCom
              v-else
              v-show="!item.noInput"
              :pickTimeVal.sync="item.val"
              :inputWidth="'100%'"
              :tabIndexNum="item.tabNum"
            />
            <!-- <b-form-datepicker
              v-else
              v-show="!item.noInput"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
              }"
              v-model="item.val"
            /> -->
            <b-form-input
              class="mt-1"
              v-if="
                (item.search_type == 'film' ||
                  item.search_type == 'film_master' ||
                  item.search_type == 'cinema') &
                (item.type !== 'date')
              "
              v-show="!item.noInput"
              v-model="item.val1"
              :type="item.type"
              :tabindex="item.tabNum2"
            />

            <datePickerCom
              class="mt-1"
              v-if="
                (item.search_type == 'film' ||
                  item.search_type == 'film_master' ||
                  item.search_type == 'cinema') &
                (item.type === 'date')
              "
              v-show="!item.noInput"
              :pickTimeVal.sync="item.val1"
              :inputWidth="'100%'"
              :tabIndexNum="item.tabNum2"
            />
            <el-date-picker
              value-format="yyyy"
              style="width: 100%"
              v-if="item.title === '16.10'"
              v-model="item.val"
              type="year"
              :tabindex="item.tabNum"
            >
            </el-date-picker>
            <b-form-checkbox
              class="mt-1"
              v-if="item.hasCheckBox"
              :id="`checkbox-${item.hasCheckBox}`"
              v-model="item.val1"
              :name="`checkbox-${item.hasCheckBox}`"
              :value="true"
              :unchecked-value="false"
            >
              {{ item.hasCheckBox }}
            </b-form-checkbox>
            <div
              v-html="tip"
              class="mt-1"
              v-for="tip in item.tips"
              :key="'tip' + tip"
            ></div>
          </b-col>
          <b-col sm="4">
            <b-button
              variant="outline-secondary"
              @click="checkDetail(item)"
              :tabindex="item.btnNum"
            >
              {{ item.btn }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import datePickerCom from "@/views/components/datePicker.vue"
export default {
  components: { datePickerCom },
  data() {
    return {
      date168: this.setDate(),
      yesterday: this.setDate(),
      defaultStartDate: this.setDate(),
      categoryList: [
        {
          title: "16.1 按日查閱上載記錄",
          val: `${this.yesterdayFormat}`,
          type: "date",
          btn: "查閱這天的記錄",
          search_type: "date",
          path: "/boxoffice/upload-record-detail-16.1",
          titleType: "list-of-day-final",
          title_num: "16.1",
          tabNum: 1,
          btnNum: 2,
        },
        {
          title: "16.1.R 查閱指定期間之記錄 (By Film_id)",
          val: `${this.defaultStartDate}`,
          val1: `${this.yesterdayFormat}`,
          type: "date",
          btn: "查閱這範圍內的記錄",
          search_type: "film",
          path: "/boxoffice/upload-record-detail-16.1.R",
          titleType: "list-of-day-final",
          title_num: "16.1.R",
          tabNum: 3,
          tabNum2: 4,
          btnNum: 5,
        },
        {
          title: "16.11.R 查閱指定期間之記錄 (By Film_Master_id)",
          val: `${this.defaultStartDate}`,
          val1: `${this.yesterdayFormat}`,
          type: "date",
          btn: "查閱這範圍內的記錄",
          search_type: "film_master",
          path: "/boxoffice/upload-record-detail-16.11.R",
          titleType: "list-of-day-final",
          title_num: "16.11.R",
          tabNum: 6,
          tabNum2: 7,
          btnNum: 8,
        },
        {
          title: "16.1.C 查閱指定期間之記錄 (By Cinema)",
          val: `${this.defaultStartDate}`,
          val1: `${this.yesterdayFormat}`,
          type: "date",
          btn: "查閱這範圍內的記錄",
          search_type: "cinema",
          path: "/boxoffice/upload-record-detail-16.1.C",
          showLocation: true,
          titleType: "list-of-day-final",
          title_num: "16.1.C",
          tabNum: 9,
          tabNum2: 10,
          btnNum: 11,
        },
        {
          title: "16.2 按電影查閱上載記錄",
          val: "",
          type: "text",
          btn: "搜查電影名稱",
          search_type: "film_name",
          path: "/boxoffice/upload-record-detail-16.2.1",
          title_num: "16.2.1",
          tabNum: 12,
          btnNum: 13,
        },
        {
          title: "16.3 查閱 調整及修改類記錄",
          noInput: true,
          btn: "查閱",
          tips: ["查閱 : 調整及修改類記錄"],
          path: "/boxoffice/upload-record-detail-16.3",
          titleType: "ticketRecord",
          title_num: "16.3",
          btnNum: 14,
        },
        {
          title: "16.8",
          // noInput: true,
          type: "date",
          btn: "查閱",
          tips: [
            `查閱 : ${this.$moment(this.date168).format()}之所有未確認總數`,
            `Sum of : All ${this.$moment(
              this.date168
            ).format()} + pre-sold tacket + Manual B.O. input.`,
          ],
          path: "/boxoffice/upload-record-detail-16.8",
          showLocation: true,
          //val: '2023-01-01', //2023-01-01
          val: `${this.date168}`,
          titleType: "list-theatre",
          title_num: "16.8",
          tabNum: 15,
          btnNum: 16,
        },
        {
          title: "16.8.23",
          // noInput: true,
          type: "date",
          btn: "查閱",
          tips: [
            `查閱 : ${this.$moment(
              this.date168
            ).format()} 23:00pm之前 之未確認總數`,
            `Sum 0f : <b style="color:red">${this.$moment(
              this.date168
            ).format()} 23:00</b> + pre-sold tacket + Manual B.O. input.`,
            '<span style="color:red">(不包括午夜場票房, No B.O. after 23:00)</span>',
          ],
          path: "/boxoffice/upload-record-detail-16.8.23",
          showLocation: true,
          //val: '2023-01-01', //2023-01-01
          val: `${this.date168}`,
          titleType: "list-theatre",
          title_num: "16.8.23",
          tabNum: 17,
          btnNum: 18,
        },
        {
          title: "16.9",
          type: "date",
          val: `${this.yesterdayFormat}`,
          btn: "查閱這天記錄",
          tips: [
            "比較某日之23:00(Hourly)數 與Day_Final數之分別(包括午夜場票房)",
            `(Max Report date:${this.$moment(this.yesterdayFormat).format()})`,
          ],
          path: "/boxoffice/upload-record-detail-16.9",
          showLocation: true,
          titleType: "list-theatre",
          title_num: "16.9",
          tabNum: 19,
          btnNum: 20,
        },
        {
          title: "16.9.23",
          type: "date",
          val: `${this.yesterdayFormat}`,
          btn: "查閱這天記錄",
          tips: [
            '比較某日之23:00(Hourly)數 與Day_Final數之分別<span style="color:red">(不包括午夜場票房)</span>',
            `(Max Report date:${this.$moment(this.yesterdayFormat).format()})`,
          ],
          path: "/boxoffice/upload-record-detail-16.9.23",
          titleType: "list-theatre",
          showLocation: true,
          title_num: "16.9.23",
          tabNum: 21,
          btnNum: 22,
        },
        {
          title: "16.10",
          noInput: true,
          val: this.$moment().subtract(1, "year").format("YYYY"),
          btn: "票房記錄",
          tips: ["「出產年份」查閱 "],
          hasCheckBox: "計至影片落畫/昨日",
          val1: true,
          path: "/boxoffice/upload-record-detail-16.10",
          showLocation: true,
          titleType: "report-period",
          title_num: "16.10",
          tabNum: 23,
          btnNum: 24,
        },
        {
          title: "16.12",
          noInput: true,
          btn: "查閱",
          tips: [
            "查閱 : 近一小時之所有未確認總數",
            'Sum 0f : <span style="color:red">Last Hour\'s</span> Hourly XML B.O. + pre-sold tackt + Manual B.O. input. ',
          ],
          path: "/boxoffice/upload-record-detail-16.12",
          titleType: "list-theatre",
          showLocation: true,
          title_num: "16.12",
          btnNum: 25,
        },
      ],
    }
  },
  methods: {
    setDate() {
      const hours = this.$moment().format("HH:mm")
      this.defaultStartDate = this.$moment()
        .startOf("month")
        .format("YYYY-MM-DD")
      this.yesterdayFormat = this.$moment()
        .subtract(1, "days")
        .format("YYYY-MM-DD")
      if (hours < "23:30") {
        this.date168 = this.yesterdayFormat
      } else {
        this.date168 = this.$moment().format("YYYY-MM-DD")
      }
    },
    disabledDate(time) {
      // time.getTime() < Date.now() - 8.64e7 禁用不包含當前日期
      // time.getTime() > Date.now() - 8.64e7 禁用包含當前日期

      return (
        time.getTime() < Date.now() - 8.64e7 * 14 ||
        time.getTime() > Date.now() - 8.64e7
      )
    },
    checkDetail(item) {
      const query = {
        type: item.search_type || "",
        val: item.val || "",
        val1: item.val1 || "",
        title: item.title,
        titleText: item.titleType || "",
        showLocation: item.showLocation || "",
        filmIDList: item.filmIDList || "",
        uploadDate: item.uploadDate || "",
        title_num: item.title_num,
      }
      console.log(query)
      const year = new Date().getFullYear()
      let month = new Date().getMonth() + 1
      month = month < 10 ? `0${month}` : month
      let day = new Date().getDate()
      day = day < 10 ? `0${day}` : day
      let hour = new Date().getHours()
      hour = hour < 10 ? `0${hour}` : hour
      let minute = new Date().getMinutes()
      minute = minute < 10 ? `0${minute}` : minute
      let second = new Date().getSeconds()
      second = second < 10 ? `0${second}` : second
      if (item.title === "16.12") {
        // const nowTime = `${year}-${month}-${day} ${hour}:${minute}:${second}`
        const nowDate = `${year}-${month}-${day}`
        let lastTime = `${year}-${month}-${day} ${hour - 1}:${minute}:${second}`
        if (hour - 1 < 0) {
          lastTime = `${year}-${month}-${day - 1} ${23}:${minute}:${second}`
        }
        query.val = lastTime
        query.val1 = nowDate
      } else if (item.title === "16.10" && item.val1) {
        let lastDay = new Date(parseInt(+new Date() - 3600 * 1000 * 24))
          .toLocaleDateString()
          .replace(/[/]+/g, "-")
        const newDay = lastDay.split("-")
        newDay[1] = newDay[1] < 10 ? `0${newDay[1]}` : newDay[1]
        newDay[2] = newDay[2] < 10 ? `0${newDay[2]}` : newDay[2]
        lastDay = newDay.join("-")
        console.log(lastDay)
        query.val1 = lastDay
      }

      this.$router.push({ path: "/boxoffice/upload-record-detail", query })
    },
  },
  created() {
    // this.setDate()
  },
}
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.category-tank {
  width: 60%;
  border-bottom: 2px solid #0e72bc;
  .category-title {
    color: #0e72bc;
    font-size: 16px;
  }
}
</style>
