import request from '@/utils/request'

// get filmList api
export function getFilmApi (data) {
  return request({
    url: `/api/film?${data}`,
    method: 'GET',
  })
}
// get filmList showing or to show api
export function getFilmListToShow (data) {
  return request({
    url: `/api/films/showing-pending?${data}`,
    method: 'GET',
  })
}
// get filmDetial
export function getFilmDetail (id) {
  return request({
    url: `/api/film/${id}`,
    method: 'GET',
  })
}
//create film
export function createFilm (data) {
  return request({
    url: '/api/film',
    method: 'POST',
    data
  })
}
//edit film
export function editFilm (id, data) {
  return request({
    url: `/api/film/${id}`,
    method: 'PUT',
    data
  })
}
//delect film
export function delectFilm (id) {
  return request({
    url: `/api/film/${id}`,
    method: 'DEL',
  })
}