<template>
  <TableDataListing
    :loading="loading"
    :type="type"
    :title="title"
    :fields="fields"
    :items="items"
    :showArea="false"
    :pageTotal="pageTotal"
    :add-button-title="addButtonTitle"
    :edit-button-title="editButtonTitle"
    :currentPage="currentPage"
    :rows="rows"
    @handleCurrentChange="handleCurrentChange"
    @handleSizeChange="handleSizeChange"
    @handleSearchChange="handleSearchChange"
    @handleSearch="handleSearch"
    :sort="sort"
    :direct="direct"
    @orderHead="orderHead"
  />
</template>
<script>
import TableDataListing from "../components/tableDataListing.vue";
import { getGenreApi } from "@/api/film-material-management/genre";

export default {
  name: "Genre",
  components: {
    TableDataListing
  },
  data() {
    return {
      sort: "id",
      direct: "desc",
      loading: false,
      type: "genre",
      title: "電影片種資料",
      addButtonTitle: "開立新片種記錄",
      editButtonTitle: "作出修訂",
      currentPage: 1,
      fields: [
        {
          key: "id",
          label: "Genre ID",
          sortKey: "id"
        },
        {
          key: "name_en",
          label: "Genre Name",
          sortKey: "filmcat_name_en"
        },
        {
          key: "name_ch",
          label: "片種名稱",
          sortKey: "filmcat_name_zh_hk"
        },
        {
          key: "actions",
          label: "",
          tdClass: "d-flex justify-content-center"
        }
      ],
      items: [],
      pageTotal: 10,
      rows: 20,
      search: ""
    };
  },
  methods: {
    orderHead(key, direct) {
      this.sort = key;
      this.direct = direct;
      this.getGenreList();
    },
    handleSizeChange(val) {
      this.rows = val;
      this.getGenreList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getGenreList();
    },
    handleSearchChange(val) {
      this.search = val;
    },
    handleSearch(val) {
      this.currentPage = 1;
      this.rows = val.rows;
      this.location = val.selectArea;
      this.search = val.search;
      this.sort = val.sort;
      this.direct = val.direct;
      this.getGenreList();
    },
    getGenreList() {
      this.loading = true;
      const data = {
        page: this.currentPage,
        count: this.rows,
        search: this.search,
        sort: this.sort,
        direct: this.direct
      };
      getGenreApi(data)
        .then((res) => {
          console.log(res);
          this.items = res.data;
          this.pageTotal = res.total;
          const arr = [];
          this.items.forEach((el) => {
            const obj = new Object();
            obj.id = el.id;
            obj.name_ch = el.filmcat_name_zh_hk;
            obj.name_en = el.filmcat_name_en;
            arr.push(obj);
          });
          this.items = arr;
          console.log(this.items);
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    }
  },
  mounted() {
    this.getGenreList();
  }
};
</script>
