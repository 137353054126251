var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[(_vm.items.length === 0)?_c('div',{staticClass:"no-data"},[_c('b',[_vm._v("No Data")])]):_vm._e(),(_vm.items.length > 0)?_c('b-table',{attrs:{"bordered":"","hover":"","responsive":"","items":_vm.items,"fields":_vm.$store.state.highlightCol.firTable
          ? _vm.$store.state.newTableFields
          : _vm.fields},on:{"head-clicked":function($event){return _vm.commonHighLightCol(
          $event,
          _vm.fields,
          _vm.$store.state.newTableFields,
          'firTable'
        )}},scopedSlots:_vm._u([{key:"cell(theatre_name)",fn:function(data){return [_c('div',{staticStyle:{"width":"100px"}},[_vm._v(" "+_vm._s(data.item.theatre.theatre_name)+" ")])]}},{key:"cell(title_name)",fn:function(data){return [_c('div',{staticStyle:{"width":"150px"},on:{"click":function($event){return _vm.goPage(data.item, 'checkXML')}}},[_vm._v(" "+_vm._s(data.item.title_name)+" ")])]}},{key:"cell(date_to_report)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(data.item.film.date_to_report)+" ")])]}}],null,false,2237348753)}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }