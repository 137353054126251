<template>
  <div>
    <div class="film-ver-record mt-1">
      <p class="sub-title">此影片之各種版本記錄：</p>
      <div class="line"></div>
      <div v-for="(verItem, verIndex) in versionInfo" :key="verIndex">
        <div class="ver-box">
          <div class="ver-content">
            <p class="sub-title">
              影片Film_ID：{{
                verItem.from_arch && verItem.from_arch === 1 ? "" : "F-"
              }}{{ verItem.id }}
            </p>
          </div>
          <div class="ver-content">
            <p class="sub-title">
              {{ verItem.language }} ({{ verItem.remark_1 }})
              {{
                !verItem.remark_2 || verItem.remark_2 === "[無影院特效]"
                  ? ""
                  : `[${verItem.remark_2}]`
              }}
            </p>
          </div>
          <div class="ver-content">
            <b style="color: red">{{ verItem.status }}</b>
          </div>
          <div
            class="ver-content"
            v-for="(item, index) in itemVerCat"
            :key="index"
          >
            <b-row>
              <b-col>
                <div class="d-flex">
                  <p class="mr-1" style="width: 30%">{{ item.label }} :</p>
                  <p class="film-ver-val" v-if="item.type === 'date'">
                    {{
                      isValidDate(verItem[item.key]) === "0000-00-00"
                        ? "00-00-0000"
                        : isValidDate(verItem[item.key])
                    }}
                  </p>
                  <p class="film-ver-val" v-else-if="item.type === 'date_off'">
                    {{
                      verItem.date_off === "0000-00-00"
                        ? "00-00-0000"
                        : $moment(verItem.date_off).format()
                    }}
                  </p>
                  <p class="film-ver-val" v-else>
                    {{ verItem[item.key] }}
                  </p>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="d-flex justify-content-end mt-1 mr-1">
          <b-button
            class="mr-1"
            @click="
              goEditVersion(verItem.id, masterId, 'edit', verItem.title_name)
            "
            size="sm"
            variant="outline-primary"
            >作出修訂</b-button
          >
          <b-button
            size="sm"
            @click="
              goPage(
                'revise-record-add',
                0,
                verItem.master_id,
                verItem,
                'search-not-film'
              )
            "
            variant="outline-primary"
            >爲此記錄搜尋未指派之電影</b-button
          >
        </div>
        <div class="line"></div>
      </div>
      <div class="d-flex justify-content-start mt-1 mr-1">
        <b-button
          @click="goEditVersion('', masterId, 'create', filmName)"
          size="sm"
          variant="outline-primary"
          >建立一個新影片輪次</b-button
        >
      </div>
      <CodeUseByCircuit :masterId="masterId" />
    </div>
  </div>
</template>

<script>
import CodeUseByCircuit from "./component/codeUseByCircuit.vue"
export default {
  components: { CodeUseByCircuit },
  props: {
    masterId: {
      type: String,
      default: "",
    },
    versionInfo: {
      type: Array,
    },
    filmName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      itemVerCat: [
        { label: "影片輪次*", key: "runVer" },
        { label: "視像效果*", key: "remark_1" },
        {
          label: "電影評級",
          key: "rating",
        },
        {
          label: "音軌語言",
          key: "language",
        },
        {
          label: "Theatre Effect",
          key: "remark_2",
        },
        {
          label: "片長(分鐘)",
          key: "film_duration",
        },
        {
          label: "字幕語言",
          key: "sub_title",
        },
        {
          label: "上映日期",
          key: "date_release",
          type: "date",
        },
        {
          label: "優先場日期",
          key: "date_on",
          type: "date",
        },
        {
          label: "影片格式",
          key: "format",
        },
        {
          label: "落畫日期",
          key: "date_off",
          type: "date_off",
        },
        {
          label: "顯示票房日期",
          key: "date_to_report",
          type: "date",
        },
        {
          label: "Status",
          key: "status",
        },
      ],
    }
  },
  mounted() {},
  methods: {
    goPage(info, pos_id, master_id, item, type) {
      console.log(item)
      if (info === "revise-record-add") {
        const search_split = this.getFilmSearchName(item)
        const filmInfo = {
          cinema_id: item.cinema_id,
          circuit: item.circuit_name,
          circuit_id: item.circuit_id,
          pos_id: item.pos_id || 0,
          title_name: item.title_name,
          search_name: search_split,
          film_circuit_id: item.film_circuit_id,
          id: item.id,
          goPage: this.$route.fullPath,
          film_id: item.film_id,
          type,
        }
        sessionStorage.setItem("filmInfo", JSON.stringify(filmInfo))
        this.$nextTick(() => {
          this.$router.push({
            name: info,
            query: {
              pos_id,
              master_id,
              search_name: search_split,
              circuit_id: item.circuit_id,
              id: item.id,
              cinema_id: item.cinema_id,
            },
          })
        })
      } else if (info === "revise-record-info") {
        this.$router.push({
          name: info,
        })
      }
    },
    goEditVersion(id, masterId, type, film_name) {
      this.$router.push({
        name: "edit-film-version-record",
        query: {
          id,
          master_id: masterId,
          type,
          film_name,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.film-ver-record {
  width: 100%;
  .ver-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    .ver-content {
      width: 31%;
      margin-left: 20px;
    }
  }
  .film-ver-val {
    background: #eee;
    width: 70%;
    padding: 3px;
  }
}
</style>
