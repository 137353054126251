<template>
  <section id="management-center">
    <b-card>
      <h2 class="app-color">
        訂正電影記錄
      </h2>
      <div  style="border-bottom: 2px solid #0e72bc; margin-bottom:20px;"></div>
     <div>
        <b-button
          size="sm"
          variant="outline-secondary"
          class="handle-button"
          @click="returnRecord()"
        >
          回上頁
        </b-button>
      
      </div>
      <hr class="line">
      <div>
        <b-row >
          <b-col cols="2">
            院線：
          </b-col>
          <b-col cols="10">xxxx</b-col>
          <b-col cols="2">
            Circuit POS ID:
          </b-col>
          <b-col cols="10" >01483CN200</b-col>
          <b-col cols="2">
            院線提供之電影名稱:
          </b-col>
          <b-col cols="10" >XXXXX</b-col>

 
        </b-row>
      </div>
      <hr class="line">
      <h3 class="app-color">
        沒有類似POS ID
      </h3>
      <hr class="line">
      <div>
        <b-button
          size="sm"
          variant="outline-secondary"
          class="handle-button mr-2"
          @click="goPage"
        >
          新增POS ID
        </b-button>
        <b-button  size="sm" variant="outline-primary">刪除此電影記錄</b-button>
      </div>
 
    </b-card>
  </section>
</template>
<script>
import {BTable, BButton, BCard, BPagination, BAvatar,
  BRow,
  BCol,BForm, BFormGroup, BFormInput,BFormDatepicker
} from 'bootstrap-vue'
import vSelect from 'vue-select'
export default {
  components: {BTable, BButton, BCard, BPagination, BAvatar,BFormDatepicker,
  BRow,
  BCol,BForm, BFormGroup, BFormInput,
  },
  data() {
    return{
     
    }
  },
  methods: {
    goPage() {

      this.$router.push({ name: 'revise-record-add' })

    },

  },
}
</script>
<style lang="scss" scope="scoped">
.app-color{
   font-weight: 500;
    color: #0e72bc;
    font-size: 20px;
}
.line{
    height: 3px;

}
.lh30{
  width: 250px;
}
.table-scroll{
    white-space: nowrap;
 }
 .font-color{
   color: blue;
 }
</style>
