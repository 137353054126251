export default [
  {
    path: '/record/version-film-record-by-date',
    name: 'version-film-record-by-date',
    component: () => import('@/views/recordManagement/versionFilmRecord/versionFilmRecordByDate.vue'),
  },
  {
    path: '/record/version-film-record-by-showTime',
    name: 'version-film-record-by-showTime',
    component: () => import('@/views/recordManagement/versionFilmRecord/versionFilmRecordByShowTime.vue'),
  },
  {
    path: '/record/version-film-record-by-xml',
    name: 'version-film-record-by-xml',
    component: () => import('@/views/recordManagement/versionFilmRecord/versionFilmRecordByXML.vue'),
  },
]