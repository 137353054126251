import request from '@/utils/request'

// get changeOldRecord api
export function changeOldRecord (type, id, data) {
  return request({
    url: `/api/${type}/record/${id}`,
    method: 'PUT',
    data
  })
}

// get deleteOldRecord api
export function deleteOldRecord (type, id) {
  return request({
    url: `/api/${type}/record/${id}`,
    method: 'DELETE',
  })
}