var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('div',{staticClass:"title"},[_c('p',[_vm._v("輸入/修改 票房記錄")])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-5"},[_c('p',[_vm._v(" 戲院名稱 Theatre Name: "),_c('b',[_vm._v(_vm._s(_vm.theatreName))])]),_c('b-button',{staticClass:"mr-2 mb-2",attrs:{"size":"sm","variant":"outline-primary"},on:{"click":_vm.enterRecord}},[_vm._v(" 輸入票房記錄 ")])],1),_c('div',{},[_c('p',{staticClass:"ml-1",staticStyle:{"color":"red"}},[_vm._v("所選日期前30日的記錄")]),_c('div',[_c('datePickerCom',{attrs:{"pickTimeVal":_vm.datepickerValue,"inputWidth":'260px'},on:{"update:pickTimeVal":function($event){_vm.datepickerValue=$event},"update:pick-time-val":function($event){_vm.datepickerValue=$event},"getRecords":_vm.getRecords}})],1),_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('label')]),_c('b-col',{attrs:{"sm":"12"}})],1)],1)]),_c('b-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"bordered":"","striped":"","hover":"","responsive":"","items":_vm.items,"fields":_vm.$store.state.highlightCol.firTable
          ? _vm.$store.state.newTableFields
          : _vm.fields},on:{"head-clicked":function($event){return _vm.commonHighLightCol(
          $event,
          _vm.fields,
          _vm.$store.state.newTableFields,
          'firTable'
        )}},scopedSlots:_vm._u([{key:"cell(engagement_date)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$moment(data.item.engagement_date).format())+" ")])]}},{key:"cell(show_time)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$moment(data.item.show_time).format("DD-MM-YYYY HH:mm:ss"))+" ")])]}},{key:"cell(admissions)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(parseFloat(data.item.admissions).toLocaleString())+" ")])]}},{key:"cell(gross)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(parseFloat(data.item.gross).toLocaleString())+" ")])]}},{key:"cell(action)",fn:function(data){return [_c('b-button',{staticClass:"mr-2",staticStyle:{"width":"90px"},attrs:{"size":"sm","variant":_vm.adminCant(data.item.show_time)
              ? 'outline-light'
              : 'outline-primary',"disabled":_vm.adminCant(data.item.show_time)},on:{"click":function($event){return _vm.editRecord(data.item.id)}}},[_vm._v(" 修訂記錄 ")])]}}])}),_c('p',[_vm._v("紅色highligh代表午夜場")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }