export default [
  {
    path: '/theatre/theatre',
    name: 'theatre',
    component: () => import('@/views/theatre/theatre.vue'),
  },
  {
    path: '/theatre/theatre-detail',
    name: 'theatre-detail',
    component: () => import('@/views/theatre/theatreDetail.vue'),
  },
  {
    path: '/theatre/theatre-detail-look',
    name: 'theatre-detail-look',
    component: () => import('@/views/theatre/theatreDetailForLook.vue'),
  },
  {
    path: '/theatre/edit-theatre-info',
    name: 'edit-theatre-info',
    component: () => import('@/views/theatre/editTheatreInfo.vue'),
  },
]