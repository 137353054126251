import request from '@/utils/request'

// country
export function country () {
  return request({
    url: '/api/film-master/countries',
    method: 'GET',
  })
}

// area
export function getArea () {
  return request({
    url: '/api/area',
    method: 'GET',
  })
}

// state
export function getState (country) {
  return request({
    url: `/api/state?country=${country}`,
    method: 'GET',
  })
}
// district
export function getDistrict (state) {
  return request({
    url: `/api/district?state=${state}`,
    method: 'GET',
  })
}


