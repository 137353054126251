<template>
  <div>
    <b-card>
      <div class="title">
        <p>請選擇電影版本</p>
      </div>
      <div>
        <b-button
          size="sm"
          variant="outline-secondary"
          class="handle-button mb-2"
          @click="returnRecord()"
        >
          回上頁
        </b-button>
      </div>
      <div class="mb-1">
        <b style="color: #0e72bc">已選擇的日期: </b>
        <b style="color: red">{{ $moment(date).format() }}</b>
      </div>
      <div class="mb-1">
        <b style="color: #0e72bc">已選擇的戲院: </b>
        <b style="color: red">{{ $route.query.theatre_name }}</b>
      </div>
      <div class="">
        <b style="color: #0e72bc">已選擇的電影：</b>
        <b style="color: red">{{ $route.query.film_name }}</b>
      </div>
      <div v-loading="loading">
        <b-table
          bordered
          class="mt-2"
          striped
          hover
          responsive
          :items="tableData"
          :fields="
            $store.state.highlightCol.firTable
              ? $store.state.newTableFields
              : tableFields
          "
          @head-clicked="
            commonHighLightCol(
              $event,
              tableFields,
              $store.state.newTableFields,
              'firTable'
            )
          "
        >
          <template v-slot:cell(action)="row">
            <b-button
              size="sm"
              class="mr-2"
              @click="goDetail('select-item', row.item)"
            >
              修訂此電影版本
            </b-button>
          </template>
        </b-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import { getMasterList } from "@/api/editBoxoffice.js";

export default {
  components: {
    // vSelect,
  },
  data() {
    return {
      loading: false,
      rows: 1,
      perPage: 1,
      currentPage: 1,
      select: "All locations",
      selectOptions: ["All locations", "Hong Kong", "Macau"],
      tableData: [],
      tableFields: [
        { key: "id", label: "Film ID ", sortable: true },
        { key: "eff", label: "Effect", sortable: true },
        // { key: 'xml_fid', label: 'XML ID' },
        { key: "run_ver.run_ver", label: "Ver.", sortable: true },
        { key: "language", label: "Lang.", sortable: true },
        { key: "sub_title", label: "Captioning lang.", sortable: true },
        { key: "action", label: "" }
      ],
      listParams: {
        page: 1,
        count: 10
      },
      master_id: "",
      theatre_id: "",
      date: ""
    };
  },
  methods: {
    returnRecord() {
      this.$router.go(-1);
    },
    getMasterList() {
      this.loading = true;
      const data = {
        date: this.date,
        theatre_id: this.theatre_id,
        master_id: this.master_id
      };
      getMasterList(data, this.listParams.page, this.listParams.count)
        .then((res) => {
          res.data.map((el) => {
            this.tableData.push(el.film);
          });
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    goDetail(name, item) {
      this.$router.push({
        name,
        query: {
          id: item.id,
          master_id: this.master_id,
          theatre_id: this.theatre_id,
          date: this.date,
          run: item.run_ver.run_ver,
          effect: item.eff,
          film_name: this.$route.query.film_name,
          theatre_name: this.$route.query.theatre_name
        }
      });
    }
  },
  created() {
    this.master_id = this.$route.query.master_id;
    this.theatre_id = this.$route.query.theatre_id;
    this.date = this.$route.query.date;
    this.getMasterList();
  }
};
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
</style>
