<template>
  <div v-loading="loading">
    <b-card>
      <div class="title">
        <p>{{ titles }}</p>
      </div>
      <div>
        <b-button
          size="sm"
          variant="outline-secondary"
          class="handle-button"
          @click="$router.push({ name: 'theater-data' })"
        >
          回目錄
        </b-button>
      </div>
      <h4 class="circuit_ID" v-if="isEdit">
        <span class="app-color">Circuit ID:{{ circuitId }}</span>
      </h4>
      <b-row v-for="(item, index) in info" :key="index" class="my-1">
        <b-col sm="2"> {{ item.label }} : </b-col>
        <b-col sm="6" v-if="item.type === 'select'">
          <b-form-select
            :disabled="!isEdit"
            v-model="theatreInfo.area"
            :options="areaList"
          ></b-form-select>
        </b-col>
        <b-col sm="6" v-else-if="item.type === 'date'">
          <datePickerCom
            :pickTimeVal.sync="theatreInfo[item.key]"
            :inputWidth="'100%'"
          />
        </b-col>
        <b-col sm="6" v-else>
          <b-form-input
            :disabled="!isEdit"
            :id="`circuit-${item}`"
            v-model="theatreInfo[item.key]"
          />
        </b-col>
      </b-row>
      <b-col offset-md="5">
        <b-button
          v-if="pageType === 'view'"
          type="submit"
          variant="primary"
          class="mr-1"
          @click="editCircuit()"
        >
          <span> 作出修訂</span>
        </b-button>
        <b-button
          v-else
          type="submit"
          variant="primary"
          class="mr-1"
          @click="addCircuit()"
        >
          <span>SAVE/存儲</span>
        </b-button>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import {
  addCircuit,
  getCircuitDetail,
  editCircuitDetail,
} from "@/api/circuit.js"
import datePickerCom from "@/views/components/datePicker.vue"
export default {
  components: { datePickerCom },
  data() {
    return {
      loading: false,
      info: [
        { label: "院線名稱 (Full Name)*", key: "full_name" },
        { label: "Circuit Name in XML*", key: "circuit_name" },
        { label: "Circuit ID in XML*", key: "circuit_key" },
        { label: "院線聯絡人", key: "contact_person" },
        { label: "職稱", key: "contact_post" },
        { label: "聯絡電話", key: "contact_phone" },
        { label: "聯絡電郵", key: "contact_mail" },
        { label: "地區", key: "area", type: "select" },
        { label: "開張日期", key: "open_date", type: "date" },
        { label: "關閉日期", key: "close_date", type: "date" },
        { label: "院線網頁", key: "web_page" },
        { label: "有XML上傳問題時之通報電郵", key: "mail_alert" },
        { label: "備註", key: "remarks" },
      ],
      areaList: [
        { value: "", text: "" },
        { value: "Hong Kong", text: "Hong Kong" },
        { value: "macau", text: "Macau" },
      ],
      theatreInfo: {
        full_name: "",
        circuit_name: "",
        circuit_key: "",
        contact_person: "",
        contact_post: "",
        contact_phone: "",
        contact_mail: "",
        web_page: "",
        remarks: "",
        area: "",
        open_date: "",
        close_date: "",
      },
      isEdit: true,
      pageType: "",
      circuitId: "",
    }
  },
  computed: {
    titles() {
      if (this.$route.query.type === "edit") {
        return "修訂院線記錄"
      } else if (this.$route.query.type === "create") {
        return "開立新院線記錄"
      } else {
        return "院線記錄"
      }
    },
  },
  mounted() {
    if (this.$route.query.type === "view") {
      this.isEdit = false
    } else {
      this.isEdit = true
    }
    this.pageType = this.$route.query.type
    this.circuitId = this.$route.query.id
    if (this.circuitId) {
      this.getCircuitDetail()
    }
  },
  methods: {
    editCircuit() {
      this.isEdit = true
      const query = {
        id: this.circuitId || null,
        type: "edit",
      }
      this.pageType = "edit"
      this.$router.push({ path: "/dashboard/circuit-detail", query })
    },
    getCircuitDetail() {
      this.loading = true
      getCircuitDetail(this.circuitId).then((res) => {
        this.theatreInfo = res.data
        this.loading = false
      })
    },
    addCircuit() {
      if (this.pageType === "edit") {
        this.loading = true
        editCircuitDetail(this.circuitId, this.theatreInfo)
          .then(() => {
            this.getCircuitDetail()
            this.loading = false
          })
          .catch((error) => {
            console.log(error)
            this.loading = false
          })
      } else {
        addCircuit(this.theatreInfo).then((res) => {
          this.circuitId = res.data.id
          this.pageType = "view"
          this.isEdit = false
          this.$router.push({
            path: "/dashboard/circuit-detail",
            query: { id: this.circuitId, type: this.pageType },
          })
          // this.$router.go(0)
          this.getCircuitDetail()
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.circuit_ID {
  margin-top: 20px;
}
</style>
