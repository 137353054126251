<template>
  <div>
    <b-card>
      <div class="title">
        <p>XML原始記錄檔</p>
      </div>
      <div v-loading="jsonInfoLoading">
        <p class="sec-title">
          Original XML Content [{{ xmlJsonInfo.file_name }}]-[XML_File_ID：{{
            $route.query.id
          }}]
        </p>
        <div style="font-size: 13px" v-if="xmlJsonInfo" class="mb-1">
          From <b>[{{ xmlJsonInfo.circuit_name }}]</b> <br />Time of this XML
          uploaded in FTP storage ：
          <span
            v-if="
              $moment(xmlJsonInfo.date_of_report).format('HH:mm') <=
              $moment('2021-01-28 07:30').format('HH:mm')
            "
          >
            {{ xmlJsonInfo.date_of_report }}
          </span>
          <span v-else style="color: red">
            {{ xmlJsonInfo.date_of_report }} (Submit after 07:30am.)</span
          >
          <div
            v-if="
              xmlJsonInfo.struct_info && xmlJsonInfo.struct_info.status === 'ok'
            "
          >
            There are
            <b>{{ xmlJsonInfo.struct_info.theatre_tags }}</b>
            set of
            {{ threatreText }}
            tag， and BoxOffice data on
            <b>{{ xmlJsonInfo.struct_info.engagement_tags }}</b>
            films in this XMLrecord. (Tags Count=
            <span>{{ xmlJsonInfo.struct_info.tags_count }}</span>
            )
          </div>
          <div
            v-else-if="
              xmlJsonInfo.struct_info && xmlJsonInfo.struct_info.status !== 'ok'
            "
          >
            {{ xmlJsonInfo.struct_info.status }}
          </div>
        </div>
      </div>

      <b-button
        size="sm"
        variant="outline-secondary"
        class="mb-1"
        @click="showDelModel()"
      >
        Delete XML：{{ $route.query.id }} and all Related records.
      </b-button>
      <div class="line" />
      <b-button
        size="sm"
        style="width: 194px"
        variant="outline-secondary"
        class="mb-1 mt-1"
        @click="checkRelated"
      >
        查閱此XML檔之資料庫記錄
      </b-button>
      <pre v-loading="xmlCodeLoading">
        {{ xml }}
      </pre>
      <b-modal
        centered
        hide-footer
        size="lg"
        id="modal-delete-xml-file"
        title="Delete XML"
      >
        <div v-loading="delLoading">
          <div>
            <p>
              Original XML Content [{{
                xmlJsonInfo.file_name
              }}]-[XML_File_ID：{{ $route.query.id }}]
            </p>
          </div>
          <b-row class="my-1">
            <b-col sm="12">
              <b-form-input
                trim
                v-model="confirmXMLid"
                type="number"
                placeholder="Enter XML ID"
              ></b-form-input>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-end copy-text-btn">
            <b-button
              size="sm"
              @click="$bvModal.hide('modal-delete-xml-file')"
              class="mr-1"
            >
              取消
            </b-button>
            <b-button @click="delXML(confirmXMLid)" variant="primary" size="sm">
              確定
            </b-button>
          </div>
        </div>
      </b-modal>
    </b-card>
  </div>
</template>
<script>
import {
  getXmlDetailOfJson,
  delXml,
  getXmlDetailOfXml
} from "@/api/checkXmlUpload.js";
import vkbeautify from "vkbeautify";

export default {
  data() {
    return {
      delLoading: false,
      confirmXMLid: null,
      xmlCodeLoading: false,
      jsonInfoLoading: false,
      threatreText: "<theatre>",
      xmlJsonInfo: {},
      xml: "" || "No XML file data"
    };
  },
  mounted() {
    this.getXmlDetailOfJson();
    this.getXmlDetailOfXml();
  },
  methods: {
    showDelModel() {
      this.$bvModal.show("modal-delete-xml-file");
      this.confirmXMLid = null;
    },
    delXML(isid) {
      const xmlId = Number(this.$route.query.id);
      const id = Number(isid);
      console.log(xmlId, id);
      if (id && id === xmlId) {
        this.delLoading = true;
        delXml(id)
          .then((res) => {
            this.delLoading = false;
            this.$bvModal.hide("modal-delete-xml-file");
            // this.$router.push({ name: "check-xml-upload" });
            this.$router.go(-1);
          })
          .catch((error) => {
            this.delLoading = false;
          });
      } else if (id && id !== xmlId) {
        this.$message({
          message: "請輸入一個正確的XML ID"
        });
      } else {
        this.$message({
          message: "請輸入一個XML ID"
        });
      }
    },
    getXmlDetailOfXml() {
      this.xmlCodeLoading = true;
      getXmlDetailOfXml(this.$route.query.id).then((res) => {
        this.xml = vkbeautify.xml(res);
        this.xmlCodeLoading = false;
      });
    },
    getXmlDetailOfJson() {
      this.jsonInfoLoading = true;
      getXmlDetailOfJson(this.$route.query.id).then((res) => {
        console.log(res);
        this.xmlJsonInfo = res.data;
        this.jsonInfoLoading = false;
      });
    },
    checkRelated() {
      this.$router.push({
        path: "/boxoffice/xml-related-record",
        query: {
          xml_id: this.xmlJsonInfo.xml_fid,
          file_id: this.$route.query.id
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.sec-title {
  color: #0e72bc;
  font-size: 16px;
}
.line {
  height: 1px;
  width: 100%;
  background: #ccc;
}
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
