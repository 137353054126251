<template>
  <div>
    <b-card>
      <div class="title">
        <p>特別電影記錄</p>
      </div>
      <div>
        <b-col>
          <p class="sub-title">
            22.1 B.O. Report For the year ( By Version )
            <span style="color: red">*</span>
          </p>
          <el-date-picker
            class="d-inline-block mr-1"
            v-model="reportYearVersion"
            @change="callDateFunctionVersion"
            type="year"
            placeholder="Year"
          >
          </el-date-picker>
          <b-button
            variant="primary"
            type="submit"
            @click="goReport('report-year-version', reportYearVersionValue)"
          >
            Show Report
          </b-button>
        </b-col>
        <div class="line" />
        <b-col>
          <p class="sub-title">
            22.2 B.O. Report For the year ( By Production Country )
            <span style="color: red">*</span>
          </p>
          <el-date-picker
            class="d-inline-block mr-1"
            v-model="reportYearProduction"
            @change="callDateFunctionProduction"
            type="year"
            placeholder="Year"
          >
          </el-date-picker>
          <b-button
            variant="primary"
            @click="
              goReport(
                'report-year-production-country',
                reportYearProductionValue
              )
            "
          >
            Show Report
          </b-button>
        </b-col>
        <div class="line" />
        <!-- special-period -->
        <b-col class="special-period">
          <p class="sub-title">22.3 B.O. Comp. (Special Period)</p>
          <!-- show special date -->
          <div class="d-flex">
            <b-form-checkbox
              name="check-button-Special"
              @change="specialCheckChange('specialDate')"
              v-model="period.showSpecial"
            />
            <!-- special date -->
            <div class="mr-2">
              <b-form-select
                style="width: 280px"
                :disabled="!period.showSpecial"
                v-model="period.selectStart"
                :options="selectFestivalOption.selectStart"
                class="mb-1"
              ></b-form-select>
              <el-date-picker
                style="width: 280px"
                :disabled="!period.showSpecial"
                class="d-block special-report-form my-1"
                v-model="period_dateStart"
                @change="callDateFunctionPeriodStart"
                type="year"
                placeholder="Year"
              >
              </el-date-picker>
              <!-- <datepicker
                style="width: 280px"
                :disabled="!period.showSpecial"
                :format="DatePickerFormat"
                :minimumView="'year'"
                :placeholder="'Year'"
                input-class="form-control bg-white"
                class="special-report-form my-1"
                v-model="period_dateStart"
                required
                @selected="callDateFunctionPeriodStart"
              >
              </datepicker> -->
            </div>
            <!-- special date end -->
            <b-form-checkbox
              name="check-button-Special1"
              @change="specialCheckChange('specialDatePick')"
              v-model="period.showSpecial1"
            />
            <!-- special-date-picker -->
            <el-date-picker
              format="dd-MM-yyyy"
              value-format="yyyy-MM-dd"
              :disabled="!period.showSpecial1"
              v-model="period.specialPickDate"
              type="daterange"
              range-separator="-"
              start-placeholder="DD-MM-YYYY"
              end-placeholder="DD-MM-YYYY"
            >
            </el-date-picker>
          </div>
          <!-- show special date -->
          <!-- show Comparison Period date -->
          <b-form-group>
            <b-form-checkbox class="my-1" v-model="period.compare">
              Comparison Period
            </b-form-checkbox>
          </b-form-group>
          <div class="d-flex" v-if="period.compare">
            <b-form-checkbox
              name="check-button-period"
              @change="specialCheckChange('periodDate')"
              v-model="period.showPeriod"
            />
            <!-- period date -->
            <div class="mr-2">
              <b-form-select
                style="width: 280px"
                :disabled="period.showPeriod1"
                v-model="period.selectEnd"
                :options="selectFestivalOption.selectEnd"
                class="mb-1"
              ></b-form-select>
              <el-date-picker
                style="width: 280px"
                :disabled="period.showPeriod1"
                class="d-block special-report-form my-1"
                v-model="period_dateEnd"
                @change="callDateFunctionPeriodEnd"
                type="year"
                placeholder="Year"
              >
              </el-date-picker>
              <!-- <datepicker
                :disabled="period.showPeriod1"
                style="width: 280px"
                :format="DatePickerFormat"
                :minimumView="'year'"
                :placeholder="'Year'"
                input-class="form-control bg-white"
                class="special-report-form my-1"
                v-model="period_dateEnd"
                required
                @selected="callDateFunctionPeriodEnd"
              >
              </datepicker> -->
            </div>
            <!-- period date end -->
            <b-form-checkbox
              name="check-button-period-1"
              @change="specialCheckChange('periodDatePick')"
              v-model="period.showPeriod1"
            />
            <!-- period-date-picker -->
            <el-date-picker
              format="dd-MM-yyyy"
              value-format="yyyy-MM-dd"
              :disabled="!period.showPeriod1"
              v-model="period.specialPickDate1"
              type="daterange"
              range-separator="-"
              start-placeholder="DD-MM-YYYY"
              end-placeholder="DD-MM-YYYY"
            >
            </el-date-picker>
          </div>
          <!-- show Comparison Period date -->
          <div class="d-flex justify-content-end">
            <b-button
              variant="primary"
              class="my-1"
              @click="goReport('report-year-period', period)"
            >
              Show Report
            </b-button>
          </div>
        </b-col>
        <div class="line" />
        <b-col>
          <p class="sub-title">22.4 B.O. for the week</p>
          <div class="d-flex">
            <datePickerCom
              class="mr-1"
              :pickTimeVal.sync="reportWeek"
              :inputWidth="'220px'"
            />
            <!-- <b-form-datepicker
              id="example-datepicker"
              style="width: 20%"
              class="mr-1"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
              }"
              v-model="reportWeek"
            /> -->
            <b-button
              variant="primary"
              @click="goReport('boxoffice-report-week', reportWeek)"
            >
              Show Report
            </b-button>
          </div>
        </b-col>
        <div class="line" />
        <b-col>
          <p class="sub-title">22.5 最高排名</p>

          <b-button
            variant="primary"
            @click="goReport('top-ranking', 'highest day')"
          >
            Show Report
          </b-button>
          <!-- <p class="my-1"><span class="text-red">*</span>表示包括舊電影紀錄</p> -->
        </b-col>
        <div class="line" />
        <b-col>
          <p class="sub-title">22.6 最高單日票房</p>

          <b-button
            variant="primary"
            @click="goReport('highest-single-day', 'highest')"
          >
            Show Report
          </b-button>
          <p class="my-1"><span class="text-red">*</span>表示包括舊電影紀錄</p>
        </b-col>
      </div>
    </b-card>
  </div>
</template>
<script>
import Datepicker from 'vuejs-datepicker'
import { isObject } from '@vueuse/shared'
import LunarCalendar from 'lunar-calendar'
import datePickerCom from '@/views/components/datePicker.vue'
export default {
  components: {
    Datepicker,
    datePickerCom
  },
  data() {
    return {
      DatePickerFormat: 'yyyy',
      reportYearVersion: `${new Date().getFullYear()}`,
      reportYearVersionValue: `${new Date().getFullYear()}`,
      reportYearProduction: `${new Date().getFullYear()}`,
      reportYearProductionValue: `${new Date().getFullYear()}`,
      reportWeek: '',
      selectFestivalOption: {
        selectStart: [
          { value: 'cny', text: '', date_start: '', date_end: '' },
          { value: 'easter', text: '', date_start: '', date_end: '' },
          { value: 'holiday', text: '', date_start: '', date_end: '' },
          { value: 'christmas', text: '', date_start: '', date_end: '' }
        ],
        selectEnd: [
          { value: 'cny', text: '', date_start: '', date_end: '' },
          { value: 'easter', text: '', date_start: '', date_end: '' },
          { value: 'holiday', text: '', date_start: '', date_end: '' },
          { value: 'christmas', text: '', date_start: '', date_end: '' }
        ]
      },
      period_dateStart: `${new Date().getFullYear()}-01-01`,
      period_dateEnd: `${new Date().getFullYear()}-12-01`,
      period: {
        selectStart: '',
        selectEnd: '',
        compare: false,
        dateStart: `${new Date().getFullYear()}`,
        dateEnd: `${new Date().getFullYear()}`,
        showSpecial: true,
        showSpecial1: false,
        showPeriod: true,
        showPeriod1: false,
        specialPickDate: '',
        specialPickDate1: ''
      }
    }
  },
  methods: {
    specialCheckChange(type) {
      if (type === 'specialDate') {
        this.period.showSpecial = true
        this.period.showSpecial1 = false
      } else if (type === 'specialDatePick') {
        this.period.showSpecial = false
        this.period.showSpecial1 = true
      } else if (type === 'periodDate') {
        this.period.showPeriod = true
        this.period.showPeriod1 = false
      } else {
        this.period.showPeriod = false
        this.period.showPeriod1 = true
      }
    },
    goReport(type, value) {
      let newSpecialVal = {}
      if (type === 'report-year-period') {
        let festivalDateStart = ''
        let festivalDateEnd = ''
        let festival = ''
        let festivalDateStartCom = ''
        let festivalDateEndCom = ''
        let festivalCom = ''
        const arr = ['selectStart', 'selectEnd']
        arr.map((item) => {
          this.selectFestivalOption[item].map((el) => {
            if (el.value === this.period[item]) {
              if (item === 'selectStart') {
                festivalDateStart = el.date_start
                festivalDateEnd = el.date_end
                festival = el.text
              } else {
                festivalDateStartCom = el.date_start
                festivalDateEndCom = el.date_end
                festivalCom = el.text
              }
            }
          })
        })
        if (!this.period.compare) {
          const specialVal = {
            selectText: this.period.showSpecial ? festival : '',
            dateStart: this.period.showSpecial
              ? festivalDateStart
              : this.period.specialPickDate[0],
            dateEnd: this.period.showSpecial
              ? festivalDateEnd
              : this.period.specialPickDate[1],
            compare: false
          }
          newSpecialVal = specialVal
        } else {
          console.log('showSpecial - compare')
          const specialVal = {
            selectText: this.period.showSpecial ? festival : '',
            selectTextCom: this.period.showPeriod ? festivalCom : '',
            compare: true,
            dateStart: this.period.showSpecial
              ? festivalDateStart
              : this.period.specialPickDate[0],
            dateEnd: this.period.showSpecial
              ? festivalDateEnd
              : this.period.specialPickDate[1],
            compareDateStart: this.period.showPeriod
              ? festivalDateStartCom
              : this.period.specialPickDate1[0],
            compareDateEnd: this.period.showPeriod
              ? festivalDateEndCom
              : this.period.specialPickDate1[1]
          }
          newSpecialVal = specialVal
        }
      }
      console.log(newSpecialVal)
      if (isObject(value)) {
        this.$router.push({ name: type, query: newSpecialVal })
      } else if (value) {
        this.$router.push({ name: type, query: { data: value } })
      }
    },
    callDateFunctionVersion(date) {
      if (date) {
        this.reportYearVersionValue = date.getFullYear()
        console.log(this.reportYearVersionValue)
      } else {
        console.log('null date')
      }
    },
    callDateFunctionProduction(date) {
      if (date) {
        this.reportYearProductionValue = date.getFullYear()
        console.log(this.reportYearProductionValue)
      } else {
        console.log('null date')
      }
    },
    callDateFunctionPeriodStart(date) {
      if (date) {
        this.period.dateStart = date.getFullYear()
        console.log(this.period.dateStart)
        this.getLunarDate(date.getFullYear(), 'selectStart')
        this.getEasterDate(date.getFullYear(), 'selectStart')
        this.selectFestivalOption.selectStart.map((el) => {
          if (el.value === 'holiday') {
            el.text = `Summer (${this.$moment(
              `${date.getFullYear()}-07-01`
            ).format()}~ ${this.$moment(
              `${date.getFullYear()}-08-31`
            ).format()})`
            el.date_start = `${date.getFullYear()}-07-01`
            el.date_end = `${date.getFullYear()}-08-31`
          }
          if (el.value === 'christmas') {
            el.text = `Christmas (${this.$moment(
              `${date.getFullYear()}-12-24`
            ).format()}~${this.$moment(
              `${date.getFullYear()}-12-26`
            ).format()})`
            el.date_start = `${date.getFullYear()}-12-24`
            el.date_end = `${date.getFullYear()}-12-26`
          }
        })
      } else {
        console.log('null date')
      }
    },
    callDateFunctionPeriodEnd(date) {
      if (date) {
        this.period.dateEnd = date.getFullYear()
        this.getLunarDate(date.getFullYear(), 'selectEnd')
        this.getEasterDate(date.getFullYear(), 'selectEnd')
        console.log(this.period.dateEnd)
        this.selectFestivalOption.selectEnd.map((el) => {
          if (el.value === 'holiday') {
            el.text = `Summer (${this.$moment(
              `${date.getFullYear()}-07-01`
            ).format()}~ ${this.$moment(
              `${date.getFullYear()}-08-31`
            ).format()})`
            el.date_start = `${date.getFullYear()}-07-01`
            el.date_end = `${date.getFullYear()}-08-31`
          }
          if (el.value === 'christmas') {
            el.text = `Christmas (${this.$moment(
              `${date.getFullYear()}-12-24`
            ).format()}~${this.$moment(
              `${date.getFullYear()}-12-26`
            ).format()})`
            el.date_start = `${date.getFullYear()}-12-24`
            el.date_end = `${date.getFullYear()}-12-26`
          }
        })
      } else {
        console.log('null date')
      }
    },
    getLunarDate(year, item) {
      const lunarDateStart = LunarCalendar.lunarToSolar(year, 1, 1)
      const lunarDateEnd = LunarCalendar.lunarToSolar(year, 1, 7)
      const isLunarDateStart = new Date(
        lunarDateStart.year,
        lunarDateStart.month - 1,
        lunarDateStart.day + 1
      )
        .toISOString()
        .split('T')[0]
      const isLunarDateEnd = new Date(
        lunarDateEnd.year,
        lunarDateEnd.month - 1,
        lunarDateEnd.day + 1
      )
        .toISOString()
        .split('T')[0]
      //date format

      this.selectFestivalOption[item].map((el) => {
        if (el.value === 'cny') {
          el.text = `CNY (${this.$moment(
            isLunarDateStart
          ).format()}~${this.$moment(isLunarDateEnd).format()})`
          el.date_start = isLunarDateStart
          el.date_end = isLunarDateEnd
        }
      })
    },
    getEasterDate(year, item) {
      const a = year % 19
      const b = Math.floor(year / 100)
      const c = year % 100
      const d = Math.floor(b / 4)
      const e = b % 4
      const f = Math.floor((b + 8) / 25)
      const g = Math.floor((b - f + 1) / 3)
      const h = (19 * a + b - d - g + 15) % 30
      const i = Math.floor(c / 4)
      const k = c % 4
      const l = (32 + 2 * e + 2 * i - h - k) % 7
      const m = Math.floor((a + 11 * h + 22 * l) / 451)
      const month = Math.floor((h + l - 7 * m + 114) / 31)
      const day = ((h + l - 7 * m + 114) % 31) + 1
      const easterDateStart = new Date(year, month - 1, day + 1)
        .toISOString()
        .split('T')[0]
      const easterDateEnd = new Date(year, month - 1, day + 3)
        .toISOString()
        .split('T')[0]
      this.selectFestivalOption[item].map((el) => {
        if (el.value === 'easter') {
          el.text = `Easter (${this.$moment(
            easterDateStart
          ).format()}~${this.$moment(easterDateEnd).format()})`
          el.date_start = easterDateStart
          el.date_end = easterDateEnd
        }
      })
    }
  },
  computed: {
    validationVersion() {
      return this.reportYearVersionValue.length > 1
    }
  },
  mounted() {
    const sevenDaysAgoDate = new Date()
    sevenDaysAgoDate.setDate(sevenDaysAgoDate.getDate() - 7)
    this.reportWeek = sevenDaysAgoDate.toISOString().slice(0, 10)
  },
  created() {
    //set 22.3 Festival Date
    const year = new Date().getFullYear()
    const arr = ['selectStart', 'selectEnd']
    arr.map((item) => {
      this.getLunarDate(year, item)
      this.getEasterDate(year, item)
      this.selectFestivalOption[item].map((el) => {
        if (el.value === 'holiday') {
          el.text = `Summer (01-07-${year}~31-08-${year})`
          el.date_start = `${year}-07-01`
          el.date_end = `${year}-08-31`
        }
        if (el.value === 'christmas') {
          el.text = `Christmas (24-12-${year}~26-12-${year})`
          el.date_start = `${year}-12-24`
          el.date_end = `${year}-12-26`
        }
      })
    })
    this.period.selectStart = 'cny'
    this.period.selectEnd = 'cny'
  }
}
</script>

<style lang="scss" scoped>
.line {
  height: 1.5px;
  background: #0e72bc;
  width: 100%;
  margin: 20px 0;
}
.sub-title {
  width: 100%;
  color: #0e72bc;
  font-size: 16px;
  font-weight: 500;
  margin: 20px 0;
}
.special-report-form {
  width: 20%;
}
.text-red {
  color: red;
}
</style>
