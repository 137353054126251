<template>
  <div>
    <b-card v-loading="loading">
      <div class="title">
        <p>{{ titleObj[type] }}</p>
      </div>
      <div>
        <b-button
          size="sm"
          variant="outline-secondary"
          class="handle-button"
          @click="returnBtn"
        >
          回目錄
        </b-button>
      </div>
      <h4 class="circuit_ID" v-if="type !== 'new'">
        <span class="app-color">User ID:{{ $route.query.id }}</span>
      </h4>
      <b-row v-for="(item, index) in info" :key="index" class="my-1">
        <b-col sm="2"> {{ item.label }} : </b-col>
        <b-col sm="6">
          <b-form-textarea
            v-if="item.key === 'remarks'"
            size="sm"
            v-model="userInfo[item.key]"
            placeholder="備註"
            :disabled="type === 'view'"
          ></b-form-textarea>
          <div v-else-if="item.key === 'Permissions'">
            <div v-if="userType === 'Pro/Normal'">
              <b-button
                :disabled="type === 'view' || !userInfo.level"
                variant="outline-secondary"
                @click="showModal"
              >
                Permissions
              </b-button>
              <b-modal
                id="modal-center"
                title="Permissions"
                size="xl"
                hide-footer
                centered
              >
                <permissionCom
                  :userInfo.sync="userInfo"
                  :permissionsData.sync="userInfo.Permissions"
                  @updatePermissionKey="updatePermissionKey"
                  @useXMLChange="useXMLChange"
                  :permissionKey="userInfo.level"
                />
              </b-modal>
            </div>
          </div>
          <div v-else-if="item.key === 'level'">
            <el-select
              placeholder=" "
              :disabled="type === 'view'"
              style="width: 100%"
              class="per-page-selector d-inline-block mr-1"
              v-model="userInfo[item.key]"
            >
              <el-option
                v-for="(item, index) in levelOptions"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <!-- <span
              style="color: red"
              v-if="
                userInfo.Permissions.showXML &&
                userInfo.level === 'Customize settings'
              "
              >* Api User</span
            > -->
          </div>
          <div v-else-if="item.key === 'theatres'">
            <el-select
              multiple
              placeholder=" "
              :disabled="type === 'view'"
              style="width: 100%"
              class="per-page-selector d-inline-block mr-1"
              v-model="userInfo[item.value]"
            >
              <el-option
                v-for="(item, index) in theatresList"
                :key="index"
                :label="item.full_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <el-select
            placeholder=" "
            clearable
            v-else-if="item.key === 'local'"
            :disabled="type === 'view'"
            style="width: 100%"
            class="per-page-selector d-inline-block mr-1"
            v-model="userInfo[item.key]"
          >
            <el-option
              v-for="(item, index) in localOptions"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <el-select
            placeholder=" "
            clearable
            v-else-if="item.key === 'Language'"
            :disabled="type === 'view'"
            style="width: 100%"
            class="per-page-selector d-inline-block mr-1"
            v-model="userInfo[item.key]"
          >
            <el-option
              v-for="(item, index) in langOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            placeholder=" "
            clearable
            v-else-if="item.key === 'Time'"
            :disabled="type === 'view'"
            style="width: 100%"
            class="per-page-selector d-inline-block mr-1"
            v-model="userInfo[item.key]"
          >
            <el-option
              v-for="(item, index) in timeOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            placeholder=" "
            clearable
            v-else-if="item.key === 'status'"
            :disabled="type === 'view'"
            style="width: 100%"
            class="per-page-selector d-inline-block mr-1"
            v-model="userInfo[item.key]"
          >
            <el-option
              v-for="(item, index) in statusOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <datePickerCom
            :disabled="type === 'view'"
            v-else-if="item.type === 'date'"
            :pickTimeVal.sync="userInfo[item.key]"
            :inputWidth="'100%'"
          />
          <b-form-input
            v-else-if="item.key === 'Password'"
            :disabled="disPassword"
            :id="`type-${index}`"
            v-model="userInfo[item.key]"
            :type="item.type || 'text'"
          />
          <b-form-input
            v-else
            :disabled="
              type === 'view' ||
              item.key === 'last' ||
              item.key === 'lastchange'
            "
            :id="`type-${index}`"
            v-model="userInfo[item.key]"
            :type="item.type || 'text'"
          />
        </b-col>
      </b-row>
      <b-col offset-md="5">
        <b-button
          type="submit"
          variant="primary"
          class="mr-1"
          @click="type === 'view' ? toEdit() : addUser()"
        >
          <span>{{ type !== "view" ? "SAVE/存儲" : "作出修訂" }}</span>
        </b-button>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import { createUsers, getUsersDetail, editUsers } from "@/api/user.js";
import permissionCom from "./permissionCom.vue";
import { permissionsList } from "./userPermissions";
import datePickerCom from "@/views/components/datePicker.vue";
import { getTheatreList } from "@/api/theatre.js";

export default {
  components: {
    permissionCom,
    datePickerCom
  },
  data() {
    return {
      theatresList: [],
      loading: false,
      levelOptions: [
        "Super Admin",
        "Admin Supervisor",
        "Admin Staff",
        "Pro. User",
        "Normal User",
        "Customize settings"
      ],
      langOptions: [
        { label: "Chinese[中文]", value: "hk" },
        { label: "English[英文]", value: "en" }
      ],
      timeOptions: [
        { label: "No[否]", value: 0 },
        { label: "Yes[是]", value: 1 }
      ],
      statusOptions: [
        { label: "In Use", value: "In Use" },
        { label: "Suspended(暫停)", value: "Suspended" },
        { label: "Delete(刪除)", value: "Delete" }
      ],
      localOptions: ["HongKong", "Macau"],
      titleObj: {
        new: "開立新使用者記錄",
        edit: "修訂使用者記錄",
        view: "檢閱使用者記錄"
      },
      info: [
        { label: "使用者名稱 (Full Name) *", key: "name" },
        { label: "Login ID *", key: "id" },
        { label: "Password *", key: "Password" },
        { label: "User Level *", key: "level" },
        { label: "Permissions", key: "Permissions" },
        { label: "關聯戲院", key: "theatres", value: "theatres" },
        { label: "所屬公司 *", key: "com" },
        { label: "職稱", key: "tit" },
        { label: "地區 *", key: "local" },
        { label: "E-Mail *", key: "email" },
        { label: "電話 *", key: "phone" },
        { label: "Language *", key: "Language" },
        { label: "Real Time *", key: "Time" },
        { label: "最後上線時間", key: "last" },
        { label: "Status *", key: "status" },
        { label: "Last Changes", key: "lastchange" },
        { label: "到期日", key: "date", type: "date" },
        { label: "備註", key: "remarks" }
      ],
      userInfo: {
        report_side_permissions_date_start: "",
        report_side_permissions_date_end: "",
        name: "",
        id: "",
        Password: "",
        level: "",
        Permissions: {
          boxofficeRecord: {
            bo: [],
            sh: [],
            rt: [],
            dr: [],
            al: []
          },
          dataSearch: {
            sc: []
          },
          filmSchedule: {
            sch: []
          },
          boxofficeReport: {
            bos: []
          },
          onShow: {
            shi: []
          },
          setTime: false,
          useXml: { xml: [] },
          showXML: false,
          setArea: {
            ar: []
          },
          dailyReport: {
            dar: []
          }
        },
        com: "",
        tit: "",
        local: "",
        email: "",
        phone: "",
        Language: "",
        Time: "",
        last: "",
        status: "",
        lastchange: "",
        date: "",
        remarks: ""
      },
      // userInfo: {
      //   report_side_permissions_date_start: '',
      //   report_side_permissions_date_end: '',
      //   name: 'super-admin-full',
      //   id: 'super-admin-log-1',
      //   Password: '123456',
      //   level: 'Super Admin',
      //   Permissions: {
      //     boxofficeRecord: {
      //       bo: [],
      //       sh: [],
      //       rt: [],
      //       dr: [],
      //       al: []
      //     },
      //     dataSearch: {
      //       sc: []
      //     },
      //     filmSchedule: {
      //       sch: []
      //     },
      //     boxofficeReport: {
      //       bos: []
      //     },
      //     onShow: {
      //       shi: []
      //     },
      //     setTime: false,
      //     useXml: { xml: [] },
      //     showXML: false,
      //     setArea: {
      //       ar: []
      //     },
      //     dailyReport: {
      //       dar: []
      //     }
      //   },
      //   com: '1111',
      //   tit: '2222',
      //   local: 'HongKong',
      //   email: '1324@qq.com',
      //   phone: '465123135',
      //   Language: 'hk',
      //   Time: 1,
      //   last: '',
      //   status: 'In Use',
      //   lastchange: '',
      //   date: '2025-10-23',
      //   remarks: 'test=rr',
      //   theatres: [],
      //   xml_gateway_ip: ''
      // },
      type: "",
      userType: ""
    };
  },
  watch: {
    $route(to, from) {
      if (to) {
        window.sessionStorage.removeItem("permissionList");
      }
    },
    "userInfo.level"(to) {
      if (to) {
        if (
          this.type === "new" &&
          this.userInfo.level === "Customize settings"
        ) {
          window.sessionStorage.setItem(
            "permissionList",
            JSON.stringify(this.userInfo.Permissions)
          );
        }
      }
    }
  },
  computed: {
    disPassword() {
      if (this.type === "edit" && this.userOrg.role === "Super Admin") {
        return false;
      } else if (
        this.type === "edit" &&
        this.userOrg.role === "Admin Supervisor"
      ) {
        return this.userInfo.level === "Super Admin";
      } else if (this.type === "new") {
        return false;
      } else {
        return true;
      }
    }
  },
  created() {
    this.type = this.$route.query.review;
    this.userType = this.$route.query.user_type;
    this.userOrg = JSON.parse(window.localStorage.getItem("userData"));
    console.log(this.userOrg.role);
    if (this.type !== "new") {
      this.getUsersDetail();
    }
    console.log(this.info);
    if (this.userType === "Pro/Normal") {
      this.getTheatreList();
      this.levelOptions = ["Pro. User", "Normal User", "Customize settings"];
    } else {
      this.levelOptions = ["Admin Supervisor", "Super Admin", "Admin Staff"];
      console.log(this.info);
      const del = ["Permissions", "theatres"];
      del.map((item) => {
        this.info.forEach((el, index) => {
          if (el.key === item) {
            this.info.splice(index, 1);
          }
        });
      });
      console.log(this.info);
    }
    if (this.type === "new") {
      const del = ["last", "lastchange"];
      del.map((item) => {
        this.info.map((el, index) => {
          if (el.key === item) {
            this.info.splice(index, 1);
          }
        });
      });
    }
  },
  mounted() {},
  methods: {
    getTheatreList() {
      const data = {
        page: 1,
        count: 1000
      };
      getTheatreList(data).then((res) => {
        console.log(res);
        this.theatresList = res.data;
      });
    },
    showModal() {
      if (this.userInfo.level === "Customize settings") {
        if (!window.sessionStorage.getItem("permissionList")) {
          console.log("oldPermissions");
          this.userInfo.Permissions = JSON.parse(
            window.sessionStorage.getItem("oldPermissions")
          );
        } else {
          console.log("permissionList");
          this.userInfo.Permissions = JSON.parse(
            window.sessionStorage.getItem("permissionList")
          );
        }
      }
      this.$bvModal.show("modal-center");
    },
    returnBtn() {
      this.$router.push({ name: "manage-users" });
      window.sessionStorage.removeItem("permissionList");
    },
    updatePermissionKey(type, key, val) {
      console.log(type, key, val);
      if (type === "cancel") {
        this.$bvModal.hide("modal-center");
      } else {
        this.userInfo.level = key;
        this.$bvModal.hide("modal-center");
      }
    },
    useXMLChange(val) {
      this.userInfo.Permissions.showXML = val;
    },
    toEdit() {
      this.type = "edit";
      const query = {
        id: this.$route.query.id || null,
        user_type: this.$route.query.user_type,
        review: this.type
      };
      this.$router.push({ path: "/dashboard/user-info", query });
    },
    getUsersDetail(mode) {
      this.loading = true;
      getUsersDetail(this.$route.query.id).then((res) => {
        const theatre_ids = [];
        res.data.theatres.map((el) => {
          theatre_ids.push(Number(el.id));
        });
        const info = {
          name: res.data.name,
          id: res.data.user_name,
          Password: res.data.password_plaintext,
          level: res.data.role,
          Permissions: res.data.permissions,
          com: res.data.info ? res.data.info.company : "",
          tit: res.data.info ? res.data.info.post : "",
          local: res.data.info ? res.data.info.area : "",
          email: res.data.info ? res.data.info.email : "",
          phone: res.data.info ? res.data.info.phone : "",
          Language: res.data.sys_language,
          Time: res.data.info ? res.data.info.real_time : "",
          last: res.data.last_used_date,
          status: res.data.status,
          lastchange: res.data.restrict_date,
          date: res.data.expire_date,
          remarks: res.data.info ? res.data.info.remarks : "",
          report_side_permissions_date_end:
            res.data.report_side_permissions_date_end,
          report_side_permissions_date_start:
            res.data.report_side_permissions_date_start,
          theatres: theatre_ids,
          xml_gateway_ip: res.data.xml_gateway_ip,
          xml_gateway_table_format: res.data.xml_gateway_table_format
        };
        this.userInfo = info;

        //setting permissions
        this.userInfo.Permissions = {
          boxofficeRecord: {
            bo:
              res.data.permissions.boxofficeRecord &&
              res.data.permissions.boxofficeRecord.bo
                ? res.data.permissions.boxofficeRecord.bo
                : [],
            sh:
              res.data.permissions.boxofficeRecord &&
              res.data.permissions.boxofficeRecord.sh
                ? res.data.permissions.boxofficeRecord.sh
                : [],
            rt:
              res.data.permissions.boxofficeRecord &&
              res.data.permissions.boxofficeRecord.rt
                ? res.data.permissions.boxofficeRecord.rt
                : [],
            dr:
              res.data.permissions.boxofficeRecord &&
              res.data.permissions.boxofficeRecord.dr
                ? res.data.permissions.boxofficeRecord.dr
                : [],
            al:
              res.data.permissions.boxofficeRecord &&
              res.data.permissions.boxofficeRecord.al
                ? res.data.permissions.boxofficeRecord.al
                : []
          },
          dataSearch: {
            sc:
              res.data.permissions.dataSearch &&
              res.data.permissions.dataSearch.sc
                ? res.data.permissions.dataSearch.sc
                : []
          },
          filmSchedule: {
            sch:
              res.data.permissions.filmSchedule &&
              res.data.permissions.filmSchedule.sch
                ? res.data.permissions.filmSchedule.sch
                : []
          },
          boxofficeReport: {
            bos:
              res.data.permissions.boxofficeReport &&
              res.data.permissions.boxofficeReport.bos
                ? res.data.permissions.boxofficeReport.bos
                : []
          },
          onShow: {
            shi:
              res.data.permissions.onShow && res.data.permissions.onShow.shi
                ? res.data.permissions.onShow.shi
                : []
          },
          setTime: !!(
            res.data.report_side_permissions_date_end &&
            res.data.report_side_permissions_date_start
          ),
          useXml: {
            xml:
              res.data.permissions.useXml &&
              res.data.permissions.useXml.xml.length > 0
                ? res.data.permissions.useXml.xml
                : []
          },
          showXML: !!(
            res.data.permissions.useXml &&
            res.data.permissions.useXml.xml.length
          ),
          setArea: {
            ar:
              res.data.permissions.setArea && res.data.permissions.setArea.ar
                ? res.data.permissions.setArea.ar
                : []
          },
          dailyReport: {
            dar:
              res.data.permissions.dailyReport &&
              res.data.permissions.dailyReport.dar
                ? res.data.permissions.dailyReport.dar
                : []
          }
        };
        this.loading = false;
        window.sessionStorage.setItem(
          "oldPermissions",
          JSON.stringify(this.userInfo.Permissions)
        );
        window.sessionStorage.setItem(
          "gatewayIp",
          this.userInfo.xml_gateway_ip
        );
        if (mode === "customizeShow") {
          this.$bvModal.show("modal-center");
        }
      });
    },
    addUser() {
      this.loading = true;
      let list = [];
      if (this.userInfo.level === "Customize settings") {
        window.sessionStorage.removeItem("permissionList");
        Object.keys(this.userInfo.Permissions).map((el) => {
          Object.keys(this.userInfo.Permissions[el]).map((item) => {
            if (this.userInfo.Permissions[el][item] === "xml_gateway") {
              list.push("xml_gateway");
            } else {
              this.userInfo.Permissions[el][item].map((val) => {
                list.push(val);
              });
            }
          });
        });
      } else if (this.userInfo.level === "Pro. User") {
        list = permissionsList.proUser;
      } else if (this.userInfo.level === "Normal User") {
        list = permissionsList.normalUser;
      } else if (this.userType === "Admin") {
        list = permissionsList.adminUser;
      }

      const data = {
        name: this.userInfo.name,
        user_name: this.userInfo.id,
        password_plaintext: this.userInfo.Password || null,
        role: this.userInfo.level,
        extra_permissions: list,
        sys_language: this.userInfo.Language,
        expire_date: this.userInfo.date,
        status: this.userInfo.status,
        report_side_permissions_date_end:
          this.userInfo.report_side_permissions_date_end,
        report_side_permissions_date_start:
          this.userInfo.report_side_permissions_date_start,
        info: {
          company: this.userInfo.com,
          post: this.userInfo.tit,
          email: this.userInfo.email,
          phone: this.userInfo.phone,
          remarks: this.userInfo.remarks,
          area: this.userInfo.local,
          real_time: this.userInfo.Time
        },
        theatres: this.userInfo.theatres,
        xml_gateway_ip: this.userInfo.xml_gateway_ip,
        xml_gateway_table_format:this.userInfo.xml_gateway_table_format
      };
      console.log(data);

      if (this.type === "edit") {
        editUsers(this.$route.query.id, data)
          .then(() => {
            this.getUsersDetail();
            // this.$router.push('/dashboard/manage-users')
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      } else if (this.type === "new") {
        createUsers(data)
          .then(() => {
            this.loading = false;
            this.$router.push("/dashboard/manage-users");
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.circuit_ID {
  margin-top: 20px;
}
</style>
