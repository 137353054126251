<template>
  <ProductionCompanyForm
    @getProductionDetail="getProductionDetail"
    :loading.sync="loading"
    :items="items"
    :title="title"
    :type="type"
  />
</template>
<script>
import ProductionCompanyForm from '../productioncompany/editProductionCompanyForm.vue'
import { getProductionDetail } from '@/api/film-material-management/productionCompany'

export default {
  components: {
    ProductionCompanyForm
  },
  data() {
    return {
      loading: false,
      title: '出品公司',
      type: 'edit',
      items: [
        {
          id: '',
          production_name_en: '',
          production_name_zh_hk: '',
          production_code: '',
          area: '',
          co_production: '',
          old_record: [],
          remark: ''
        }
      ]
    }
  },
  methods: {
    getProductionDetail() {
      console.log(this.pageId)
      this.loading = true
      getProductionDetail(this.pageId)
        .then((res) => {
          this.loading = false
          const date = new Date()
          const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
          const defaultDate = `${firstDay.getFullYear()}-${
            firstDay.getMonth() + 1
          }-${firstDay.getDate()}`
          this.items = [
            {
              id: this.pageId,
              production_name_en: res.data.production_name_en,
              production_name_zh_hk: res.data.production_name_zh_hk,
              production_code: res.data.production_code,
              area: res.data.area,
              co_production: res.data.is_jv,
              old_record: [],
              remark:res.data.remark
            }
          ]
          this.items[0].old_record = []
          res.data.old_record.forEach((item) => {
            this.items[0].old_record.push({
              id: item.id,
              name_en: item.name_en,
              name_zh_hk: item.name_zh_hk,
              change_date: item.change_date,
              code: item.code,
              disabled: true
            })
          })

          console.log(res.data)
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    }
  },
  mounted() {
    if (this.pageId) {
      this.getProductionDetail()
    }
  },
  created() {
    this.pageId = this.$route.query.id
  }
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.app-color {
  font-weight: bold;
  color: #0e72bc;
}
.line {
  height: 3px;
  color: #0e72bc;
}
</style>
