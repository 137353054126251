<template>
  <section>
    <b-card class="news">
      <div class="title">
        <p>{{ title }}</p>
      </div>
      <div>
        <b-button
          size="sm"
          @click="goPage(goPath, '', 'create')"
          class="mb-1"
          variant="outline-primary"
          >開立{{ title }}記錄</b-button
        >
      </div>
      <div class="mb-1">
        <b-row>
          <b-col>
            <el-date-picker
              value-format="yyyy"
              v-model="selectYear"
              type="year"
            >
            </el-date-picker>
            <b-button @click="search" variant="primary" class="ml-1"
              >Search</b-button
            >
          </b-col>
        </b-row>
      </div>
      <div>
        <b-table bordered hover responsive :fields="fields" :items="items">
          <template #cell(title)="data">
            <div
              class="table-title"
              @click="goPage(goPath, data.item.id, 'view')"
            >
              {{ data.item.title }}
            </div>
          </template>
          <template #cell(date)="data">
            <div>
              {{ $moment(data.item.date).format() }}
            </div>
          </template>
          <template #cell(action)="data">
            <b-button @click="goPage(goPath, data.item.id, 'edit')" size="sm"
              >作出修訂</b-button
            >
          </template>
        </b-table>
      </div>
    </b-card>
  </section>
</template>
<script>
export default {
  props: {
    items: {
      type: Array
    },
    title: {
      type: String,
      default: ''
    },
    goPath: {
      type: String,
      default: ''
    },
    fields: {
      type: Array
    }
  },
  data() {
    return {
      loading: false,
      selectYear: ''
    }
  },
  methods: {
    goPage(path, id, type) {
      this.$router.push({ name: path, query: { id, type, title: this.title } })
    },
    search() {
      this.$emit('search', this.selectYear)
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.table-title {
  cursor: pointer;
}
.table-title:hover {
  text-decoration: underline;
}
</style>
