<template>
  <ProductionCompanyForm
    :title="title"
    :type="type"
  />
</template>
<script>
import ProductionCompanyForm from '../components/productionCompanyForm.vue'

export default {
  components: {
    ProductionCompanyForm,
  },
  data() {
    return {
      title: '出品公司',
      type: 'create',
    }
  },
}
</script>
