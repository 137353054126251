<template>
  <section>
    <b-card class="news">
      <div class="title">
        <p>舊電影票房數據</p>
      </div>
      <div class="search-input">
        <div class="d-flex">
          <el-input
            clearable
            style="width: 60%"
            class="mr-2"
            size="small"
            v-model="searchVal"
            placeholder="Search Old Film Title"
            @change="Search"
          ></el-input>
          <el-button size="small" type="primary" @click="Search"
            >Search</el-button
          >
        </div>
        <div class="my-1" style="color: red; font-size: 12px">
          *此版面只能搜尋2012或之前的舊電影
        </div>
      </div>
      <div class="search-result" v-if="showSearchResult">
        <div style="color: #0e72bc">片名搜尋：{{ searchVal }}</div>
        <div>搜尋結果共：{{ tableData.length }}</div>
        <b-table
          v-loading="loading"
          :items="tableData"
          :fields="
            $store.state.highlightCol.firTable
              ? $store.state.newTableFields
              : tableFields
          "
          @head-clicked="
            commonHighLightCol(
              $event,
              tableFields,
              $store.state.newTableFields,
              'firTable'
            )
          "
        >
          <template #cell(name_english)="data">
            <div
              class="link-text"
              @click="goPage(data.item, data.item.name_english)"
            >
              {{ data.item.name_english }}
            </div>
          </template>
          <template #cell(name_chinese)="data">
            <div
              class="link-text"
              @click="goPage(data.item, data.item.name_chinese)"
            >
              {{ data.item.name_chinese }}
            </div>
          </template>
          <template #cell(date_release)="data">
            <div>{{ isValidDate(data.item.date_release) }}</div>
          </template>
        </b-table>
      </div>
    </b-card>
  </section>
</template>

<script>
import { getOldFilm } from "@/api/oldFilmRecord"
export default {
  name: "",
  data() {
    return {
      loading: false,
      showSearchResult: false,
      searchVal: "",
      tableData: [],
      tableFields: [
        {
          key: "name_english",
          sortable: true,
          label: "影片(英文)",
        },
        {
          key: "name_chinese",
          sortable: true,
          label: "影片(中文)",
        },
        {
          key: "country",
          label: "產地",
          sortable: true,
        },
        {
          key: "date_release",
          label: "開畫日期",
          sortable: true,
        },
        {
          key: "run_ver",
          label: "倫次",
          sortable: true,
        },
      ],
    }
  },
  components: {},
  created() {
    if (this.$route.query.search) {
      this.searchVal = this.$route.query.search
      this.showSearchResult = true
      this.getOldFilm()
    }
  },
  mounted() {},
  methods: {
    getOldFilm() {
      this.loading = true
      getOldFilm(this.searchVal)
        .then((res) => {
          this.tableData = res.data
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          console.log("error", error)
        })
    },
    goPage(item, filmName) {
      console.log(item, "item")
      this.$router.push({
        path: "/dashboard/searchFilmName/searchFilmResult",
        query: {
          film_id: item.film_id,
          search: filmName,
          full_name: `${item.name_english}*${item.name_chinese}`,
        },
      })
    },
    Search() {
      if (this.searchVal) {
        this.showSearchResult = true
        this.getOldFilm()
      }
      this.$router.push({
        path: "/dashboard/searchFilmName",
        query: { search: this.searchVal },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.link-text:hover {
  color: #0e72bc;
  text-decoration: underline;
  cursor: pointer;
}
</style>
