var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-card',[_c('div',{staticClass:"title"},[_c('p',[_vm._v("22.3 B.O. Comp. (Special Period)")])]),_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"outline-primary"},on:{"click":_vm.goBackReport}},[_vm._v(" 回上頁 ")]),_c('b-button',{staticClass:"mr-1",attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.exportToExcel(_vm.items, _vm.specialText, _vm.dataTotal)}}},[_vm._v(" "+_vm._s(_vm.specialText)+" Excel ")]),(_vm.comparison === 'true')?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.exportToExcel(_vm.itemsCompare, _vm.periodText, _vm.dataTotalCom)}}},[_vm._v(" "+_vm._s(_vm.periodText)+" Excel ")]):_vm._e()],1),_c('b-col',[_c('b-form-select',{staticStyle:{"width":"200px"},attrs:{"options":_vm.locationOptions},model:{value:(_vm.loactionVal),callback:function ($$v) {_vm.loactionVal=$$v},expression:"loactionVal"}}),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"primary"},on:{"click":_vm.searhChange}},[_vm._v(" Search ")])],1)],1),_c('div',{staticClass:"mt-1"},[[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoading),expression:"tableLoading"}]},[_c('b-table',{attrs:{"bordered":"","responsive":"","items":_vm.items,"fields":_vm.$store.state.highlightCol.firTable
                ? _vm.$store.state.newTableFields
                : _vm.fields},on:{"head-clicked":function($event){return _vm.commonHighLightCol(
                $event,
                _vm.fields,
                _vm.$store.state.newTableFields,
                'firTable'
              )}},scopedSlots:_vm._u([{key:"thead-top",fn:function(){return [_c('b-tr',[_c('b-th',{attrs:{"colspan":"8"}},[_vm._v(_vm._s(_vm.specialText)+" ")]),_c('b-th',{attrs:{"colspan":"3"}},[_vm._v("Period Total B.O. : "+_vm._s(parseFloat(_vm.dataTotal).toLocaleString()))])],1)]},proxy:true},{key:"cell()",fn:function(data){return [(data.field.numType)?_c('div',[_vm._v(" "+_vm._s(parseFloat(data.value).toLocaleString())+" ")]):(data.field.film)?_c('div',{staticStyle:{"width":"220px"}},[_vm._v(" "+_vm._s(data.value)+" ")]):(
                  data.field.key === 'release_date' ||
                  data.field.key === 'release_off'
                )?_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.isValidDate(data.value))+" ")]):_c('div',[_vm._v(" "+_vm._s(data.value)+" ")])]}}])})],1)]],2),(_vm.comparison === 'true')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.comparisonLoading),expression:"comparisonLoading"}],staticClass:"my-4"},[[_c('div',[_c('b-table',{attrs:{"bordered":"","responsive":"","items":_vm.itemsCompare,"fields":_vm.$store.state.highlightCol.secTable
                ? _vm.$store.state.newSecTableFields
                : _vm.fields},on:{"head-clicked":function($event){return _vm.commonHighLightCol(
                $event,
                _vm.fields,
                _vm.$store.state.newSecTableFields,
                'secTable'
              )}},scopedSlots:_vm._u([{key:"thead-top",fn:function(){return [_c('b-tr',[_c('b-th',{attrs:{"colspan":"8"}},[_vm._v(_vm._s(_vm.periodText))]),_c('b-th',{attrs:{"colspan":"3"}},[_vm._v("Period Total B.O. : "+_vm._s(parseFloat(_vm.dataTotalCom).toLocaleString()))])],1)]},proxy:true},{key:"cell()",fn:function(data){return [(data.field.numType)?_c('div',[_vm._v(" "+_vm._s(parseFloat(data.value).toLocaleString())+" ")]):(data.field.film)?_c('div',{staticStyle:{"width":"220px"}},[_vm._v(" "+_vm._s(data.value)+" ")]):(data.field.key === 'release_date')?_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.isValidDate(data.value))+" ")]):(data.field.key === 'release_off')?_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.isValidDate(data.value))+" ")]):_c('div',[_vm._v(" "+_vm._s(data.value)+" ")])]}}],null,false,3942211494)})],1)]],2):_vm._e(),_c('p',[_vm._v("紅色highligh代表有優先場")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }