import request from '@/utils/request'

// get Circuit
export function getCircuitList (params) {
  return request({
    url: '/api/circuit',
    method: 'GET',
    params
  })
}
// add Circuit
export function addCircuit (data) {
  return request({
    url: '/api/circuit',
    method: 'POST',
    data
  })
}

export function getCircuitDetail (id) {
  return request({
    url: `/api/circuit/${id}`,
    method: 'GET',
  })
}

export function editCircuitDetail (id, data) {
  return request({
    url: `/api/circuit/${id}`,
    method: 'PUT',
    data
  })
}