import request from '@/utils/request'

// get scriptwriterList api
export function getScriptwriterApi (params) {
  return request({
    url: '/api/scriptwriter',
    method: 'GET',
    params
  })
}
// get scriptwriterDetial
export function getScriptwriterDetial (id) {
  return request({
    url: `/api/scriptwriter/${id}`,
    method: 'GET',
  })
}
//create scriptwriter
export function createScriptwriter (data) {
  return request({
    url: '/api/scriptwriter',
    method: 'POST',
    data
  })
}
//edit scriptwriter
export function editScriptwriter (id, data) {
  return request({
    url: `/api/scriptwriter/${id}`,
    method: 'PUT',
    data
  })
}
//delect scriptwriter
export function delectScriptwriter (id) {
  return request({
    url: `/api/scriptwriter/${id}`,
    method: 'DEL',
  })
}