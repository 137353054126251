<template>
  <div>
    <b-card>
      <div class="title">
        <p>請選擇電影版本</p>
      </div>
      <div>
        <b-button
          size="sm"
          variant="outline-secondary"
          class="handle-button mb-2"
          @click="returnRecord()"
        >
          回上頁
        </b-button>
      </div>
      <b-row class="mb-1">
        <b-col sm="2"> <b style="color: #0e72bc">Film_Master_ID:</b> </b-col>
        <b-col sm="6">
          <b style="color: red">{{ $route.query.master_id }}</b>
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col sm="2"> <b style="color: #0e72bc">Film_ID:</b> </b-col>
        <b-col sm="6">
          <b style="color: red">{{ $route.query.id }}</b>
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col sm="2"> <b style="color: #0e72bc">輪次:</b> </b-col>
        <b-col sm="6">
          <b style="color: red">{{ $route.query.run }}</b></b-col
        >
      </b-row>
      <b-row class="mb-1">
        <b-col sm="2"> <b style="color: #0e72bc">Version:</b> </b-col>
        <b-col sm="6"
          ><b style="color: red">{{ $route.query.effect }}</b></b-col
        >
      </b-row>
      <b-row class="mb-1">
        <b-col sm="2"> <b style="color: #0e72bc">Film Name:</b> </b-col>
        <b-col sm="6"
          ><b style="color: red">{{ film_name_val[1] }}</b></b-col
        >
      </b-row>
      <b-row class="mb-1">
        <b-col sm="2"> <b style="color: #0e72bc">電影名稱:</b> </b-col>
        <b-col sm="6"
          ><b style="color: red">{{ film_name_val[0] }}</b></b-col
        >
      </b-row>
      <b-row class="mb-1">
        <b-col sm="2"> <b style="color: #0e72bc">已選擇的日期:</b> </b-col>
        <b-col sm="6"
          ><b style="color: red">{{ $moment(date).format() }}</b></b-col
        >
      </b-row>
      <div v-loading="loading">
        <b-table
          bordered
          class="mt-2"
          striped
          hover
          responsive
          :items="tableData"
          :fields="
            $store.state.highlightCol.firTable
              ? $store.state.newTableFields
              : tableFields
          "
          @head-clicked="
            commonHighLightCol(
              $event,
              tableFields,
              $store.state.newTableFields,
              'firTable'
            )
          "
        >
          <template v-slot:cell(date)="row">
            <div>
              {{
                $moment(`${row.item.date} ${row.item.time}`).format(
                  "DD-MM-YYYY HH:mm:ss"
                )
              }}
            </div>
          </template>
          <template v-slot:cell(action)="row">
            <b-button
              v-if="row.item.status !== 'deleted'"
              size="sm"
              class="mr-2"
              @click="goDetail('edit-record', row.item.id, row.item.theatre.id)"
            >
              修訂票房記錄
            </b-button>
            <b-button
              v-if="row.item.status !== 'deleted'"
              size="sm"
              class="mr-2"
              @click="deleteDetail(row.item.id)"
            >
              刪除這場次
            </b-button>
          </template>
        </b-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import { getDetail, getFilmList, deleteDetail } from "@/api/editBoxoffice.js"

export default {
  components: {
    // vSelect,
  },
  data() {
    return {
      loading: false,
      rows: 1,
      perPage: 1,
      currentPage: 1,
      select: "All locations",
      selectOptions: ["All locations", "Hong Kong", "Macau"],
      tableData: [],
      tableFields: [
        { key: "theatre.theatre_name", label: "Theatre Name", sortable: true },
        { key: "date", label: "Show Time", sortable: true },
        { key: "total_admissions", label: "Total ADM.", sortable: true },
        { key: "total_gross", label: "Total B.O.", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "action", label: "" },
        // { key: "action1", label: "" }
      ],
      listParams: {
        page: 1,
        count: 100,
      },
      form: {},
      master_id: "",
      theatre_id: "",
      date: "",
      film_id: "",
      film_name_val: [],
    }
  },
  methods: {
    deleteDetail(id) {
      deleteDetail(id)
        .then((res) => {
          console.log(res)
          this.getFilmList()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    returnRecord() {
      this.$router.go(-1)
    },
    getFilmList() {
      this.loading = true
      const data = {
        date: this.date,
        master_id: this.master_id,
        theatre_id: this.theatre_id,
        film_id: this.film_id,
        type: "date",
      }
      getFilmList(data, this.listParams.page, this.listParams.count)
        .then((res) => {
          this.tableData = res.data
          this.tableData.map((el) => {
            if (el.status === "deleted") {
              el._rowVariant = "danger"
            }
          })
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    goDetail(name, id, theatre_id) {
      this.$router.push({ name, query: { id, theatre_id } })
    },
  },
  created() {
    this.film_name_val = this.$route.query.film_name.split("*")
    this.master_id = this.$route.query.master_id
    this.theatre_id = this.$route.query.theatre_id
    this.date = this.$route.query.date
    this.film_id = this.$route.query.id
    this.getFilmList()
    // this.getDetail()
  },
}
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
</style>
