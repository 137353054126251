<template>
  <div class="record-subtitle">
    <div class="title">
      <p>{{ title }}</p>
    </div>
    <b style="color: #0e72bc"
      >List of Day Final Data as of
      <span style="color: red">{{ date ? $moment(date).format() : "" }}</span>
      <span v-if="type === 'xml'"
        >(Total on all record within in this period.)</span
      >
      <span v-else>(Total on that single Engament Date)</span>
    </b>
    <div class="mt-1">
      <b-button variant="outline-primary" size="sm" @click="$router.go(-1)"
        >上一頁</b-button
      >
    </div>
    <b-row v-for="(item, index) in info" :key="index" class="my-1">
      <b-col sm="2"> {{ item.label }} : </b-col>
      <b-col sm="6" v-if="item.label === 'Date'">
        {{ $moment(item.val).format() }}
      </b-col>
      <b-col sm="6" v-else-if="item.label === 'Show Time'">
        {{ $moment(item.val).format("DD-MM-YYYY HH:mm:ss") }}
      </b-col>
      <b-col sm="6" v-else>
        {{ item.val }}
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    info: {
      type: Array,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
</style>
