<template>
  <div>
    <b-card v-loading="isLoading" class="theatre-info">
      <div class="title">
        <p>戲院資料</p>
      </div>
      <div>
        <b-button
          size="sm"
          class="mr-2 mb-2"
          variant="outline-primary"
          @click="goDetail()"
        >
          開立新戲院記錄
        </b-button>
      </div>
      <div class="d-flex mb-1">
        <b-form-input
          style="width: 220px"
          class="mr-1"
          v-model="searchVal"
          placeholder="Enter Theatre Name"
        ></b-form-input>
        <b-form-select
          v-if="this.area"
          style="width: 220px"
          class="mr-1"
          v-model="selecteState"
          :options="optionState"
        ></b-form-select>
        <b-button @click="getTheatreList" variant="primary">Search</b-button>
      </div>
      <b-overlay rounded="sm">
        <b-table
          bordered
          v-show="!isLoading"
          striped
          hover
          responsive
          :items="tableData"
          :fields="
            $store.state.highlightCol.firTable
              ? $store.state.newTableFields
              : tableFields
          "
          @head-clicked="
            commonHighLightCol(
              $event,
              tableFields,
              $store.state.newTableFields,
              'firTable'
            )
          "
        >
          <template #head()="data">
            <div
              class="cursor-pointer"
              v-if="data.field.sortKey"
              @click="orderHead(data.field.sortKey)"
            >
              {{ data.field.label }}
            </div>
            <div v-else>{{ data.field.label }}</div>
          </template>
          <template #cell(full_name)="data">
            <div @click="goLook(data.item.id)">
              <span size="sm" class="mr-2 table-name">
                {{ data.item.full_name }}
              </span>
            </div>
          </template>
          <template v-slot:cell(action)="row">
            <div class="d-flex">
              <b-button
                style="width: 85px"
                size="sm"
                class="mr-2"
                @click="goDetail(row.item.id)"
              >
                作出修訂
              </b-button>
              <!-- <b-button
                style="width: 85px"
                variant="outline-danger"
                size="sm"
                class="mr-2"
                @click="deleteTheatre(row.item.id)"
              >
                刪除
              </b-button> -->
            </div>
          </template>
        </b-table>
        <div class="pagination mt-3">
          <!-- <p class="mt-0 mb-0">
            Displaying Page <span>{{ dataListStart }}</span> of
            <span>{{ dataListStop }}</span>
          </p> -->
          <el-pagination
            class=""
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[20, 50, 100]"
            :page-size="rows"
            layout=" sizes, prev, pager, next"
            :total="pageTotal"
          >
          </el-pagination>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { getTheatreList, delectTheatre } from '@/api/theatre.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getState } from '@/api/country.js'

export default {
  components: {},
  data() {
    return {
      selecteState: '',
      searchVal: '',
      pageTotal: 10,
      rows: 20,
      perPage: 1,
      currentPage: 1,
      select: '全部地區',
      sort: 'id',
      direct: 'desc', //asc
      selectOptions: [
        { label: '全部地區', id: '' },
        { label: '香港', id: 'Hong Kong' },
        { label: '澳門', id: 'Macau' }
      ],
      tableFields: [
        { key: 'id', label: 'Theatre ID', sortKey: 'id' },
        { key: 'full_name', label: 'Theatre Name', sortKey: 'full_name' },
        { key: 'xml_id', label: 'XML ID', sortKey: 'full_name' },
        { key: 'circuit_name', label: 'Circuit', sortKey: 'circuit_name' },
        {
          key: 'alert_mail_1',
          label: 'Email',
          sortKey: 'alert_mail_1'
        },
        {
          key: 'status',
          label: 'Status',
          sortKey:'status'
        },
        { key: 'action', label: '' }
      ],
      isLoading: false,
      tableData: [],
      optionState: []
    }
  },
  computed: {
    dataListStart() {
      if (this.rows === 5) {
        return (this.currentPage - 1) * 5 + 1
      } else {
        return (this.currentPage - 1) * 2 * (this.rows / 2) + 1
      }
    },
    dataListStop() {
      if (this.currentPage === Math.ceil(this.pageTotal / this.rows)) {
        return this.pageTotal
      } else {
        return this.rows * this.currentPage
      }
    }
  },
  methods: {
    getState() {
      let areaId = ''
      if (this.area === 'Hong Kong') {
        areaId = 1
      } else if (this.area === 'macau') {
        areaId = 2
      }
      this.optionState = [{ text: 'All state', value: '' }]
      getState(areaId).then((res) => {
        console.log(res)
        res.data.map((el) => {
          this.optionState.push({ text: el.name, value: el.id })
        })
      })
    },
    orderHead(key) {
      console.log(key)
      this.sort = key
      if (this.direct === 'desc') {
        this.direct = 'asc'
      } else {
        this.direct = 'desc'
      }
      this.getTheatreList()
    },

    handleSizeChange(val) {
      this.rows = val
      this.getTheatreList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getTheatreList()
    },
    getTheatreList() {
      this.isLoading = true
      if (this.searchVal) {
        this.currentPage = 1
      }
      const listParams = {
        page: this.currentPage,
        count: this.rows,
        location: this.selecteState,
        type: 'manual',
        area: window.sessionStorage.getItem('area') || '',
        search: this.searchVal,
        sort: this.sort,
        direct: this.direct
      }
      getTheatreList(listParams).then((res) => {
        res.data.map((el) => {
          el.xml_id = `${el.theatre_name}[${el.theatre_no}] `
          el.contact_phone = el.contact_phone || '-'
        })
        console.log(res.data)
        this.tableData = res.data
        this.pageTotal = res.total
        this.isLoading = false
      })
    },
    goDetail(id) {
      const query = {
        id: id || null
      }
      this.$router.push({ path: '/theatre/theatre-detail', query })
    },
    goLook(id) {
      const query = {
        id: id || null
      }
      this.$router.push({ path: '/theatre/theatre-detail-look', query })
    },
    deleteTheatre(id) {
      this.$swal({
        title: '',
        text: 'Are you sure to delete ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-primary mb-2',
          cancelButton: 'btn  btn-outline-secondary ml-1 mb-2'
        },
        showClass: {
          popup: 'animate__animated animate__fadeIn'
        },
        buttonsStyling: false
      }).then((res) => {
        if (res.value) {
          delectTheatre(id).then((res) => {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Delete successfully',
                  icon: 'InfoIcon',
                  variant: 'success'
                }
              },
              {
                position: 'top-center'
              }
            )
            this.getTheatreList()
          })
        }
      })
    }
  },
  created() {
    this.area = sessionStorage.getItem('area')
    if (sessionStorage.getItem('theatreInfo')) {
      sessionStorage.removeItem('theatreInfo')
    }
    if (sessionStorage.getItem('houseInfo')) {
      sessionStorage.removeItem('houseInfo')
    }
    this.getTheatreList()
    this.getState()
  }
}
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
</style>
