<template>
  <div class="boxofficeRecordDetail" v-loading="loading">
    <div class="d-flex justify-content-between my-1">
      <p>(有*號 為必須輸入項目)</p>
    </div>
    <b-row v-for="(item, index) in types" :key="index" class="1">
      <b-col
        sm="3"
        class="d-flex align-items-center my-1"
        v-if="
          (!isEdit &&
            item.label !== 'Date of Engagement*' &&
            item.label !== 'Screen ID/ House ID *') ||
          isEdit
        "
      >
        <label class="label">{{ item.label }} :</label>
      </b-col>
      <b-col sm="7" v-if="item.type === 'text'">
        <b-form-input
          :id="`type-${index}`"
          v-model="item.val"
          :readonly="item.disabled"
        />
      </b-col>
      <b-col sm="7" v-if="item.type === 'selectfilm'">
        <b-form-select
          @change="selectFilmChange"
          v-model="item.val"
          :options="selectFilm"
        ></b-form-select>
      </b-col>
      <b-col sm="7" v-if="item.type === 'searchFilm'">
        <el-input
          :disabled="item.disabled"
          v-model="item.val"
          @change="autoInputFilmInfo"
          placeholder=""
        ></el-input>
      </b-col>
      <b-col
        sm="7"
        v-if="
          item.type === 'date' &&
          ((item.label === 'Date of Engagement*' && pageType === 'edit') ||
            item.label !== 'Date of Engagement*')
        "
      >
        <datePickerCom
          v-if="showDatePickerCom"
          :id="`type-${index}`"
          :pickTimeVal.sync="item.val"
          :inputWidth="'100%'"
        />
      </b-col>
      <p
        v-if="
          !isEdit &&
          item.label !== 'Date of Engagement*' &&
          item.label !== 'Screen ID/ House ID *'
        "
        :style="[{ color: invalidColor }, { 'font-size': '10px' }]"
      >
        <span v-show="invalid(item.val)">此項内容為空</span>
      </p>
    </b-row>
    <div class="case-tank mt-3 pt-2">
      <div
        v-for="(item, index) in caseList"
        :key="index"
        class="d-flex align-items-center justify-content-between"
      >
        <div class="input-box" style="width: 5%">
          <label class="label" for="">Show</label>
          <div style="height: 40px; padding: 7px 0">{{ index + 1 }}</div>
          <p style="height: 12px; width: 100%"></p>
        </div>
        <div class="input-box">
          <label class="label" for="">放映時間 Show Time *</label>
          <div class="d-flex align-items-center">
            <!-- <span class="mr-1">{{ $moment(types[7].val).format() }}</span> -->
            <el-input
              :tabindex="String(index * 4 + 1)"
              :ref="'myShowTimeInput'"
              @change="timeInputChange(item.input_time_val, index)"
              v-model="item.input_time_val"
              style=""
            ></el-input>
          </div>
          <p
            :style="[
              { color: invalidColor },
              { 'font-size': '10px' },
              { height: '12px' },
            ]"
          >
            <span v-show="invalid(item.show_time)">此項内容為空</span>
          </p>
        </div>
        <div class="input-box" v-if="!isEdit">
          <label class="label" for="">Screen ID/ House ID *</label>
          <el-autocomplete
            class="inline-input"
            v-model="item.house_name"
            @change="autoFillName(item.house_name, index)"
            :fetch-suggestions="querySearch"
            placeholder=" "
            @select="handleSelect(index)"
            :tabindex="String(index * 4 + 2)"
          ></el-autocomplete>
          <p
            :style="[
              { color: invalidColor },
              { 'font-size': '10px' },
              { height: '12px' },
            ]"
          >
            <span v-show="invalid(item.house_id)">此項内容為空</span>
          </p>
        </div>
        <div class="input-box">
          <label class="label" for="">入場人數 Admissions *</label>
          <b-form-input
            :tabindex="String(index * 4 + 3)"
            @change="inputChange"
            type="number"
            v-model="item.admissions"
            ref="focusInput"
            :autofocus="isFocus"
          />
          <p
            :style="[
              { color: invalidColor },
              { 'font-size': '10px' },
              { height: '12px' },
            ]"
          >
            <!-- <span v-show="invalid(item.admissions)">此項内容為空</span> -->
          </p>
        </div>
        <div class="input-box">
          <label class="label" for="">收入 Total Gross (HKD) *</label>
          <b-form-input
            :tabindex="String(index * 4 + 4)"
            @change="inputChange"
            type="number"
            v-model="item.gross"
          />
          <p
            :style="[
              { color: invalidColor },
              { 'font-size': '10px' },
              { height: '12px' },
            ]"
          >
            <!-- <span v-show="invalid(item.gross)">此項内容為空</span> -->
          </p>
        </div>
        <b-button
          v-show="caseList.length > 1"
          variant="danger"
          pill
          size="sm"
          class="mb-1"
          @click="delCase(index)"
        >
          X
        </b-button>
      </div>
    </div>

    <div class="d-flex justify-content-end mt-2" style="width: 100%">
      <b-button
        :tabindex="String(caseList.length * 4 + 1)"
        v-if="!isEdit"
        variant="outline-primary"
        size="sm"
        @click="addCase"
      >
        增加一個輸入列
      </b-button>
    </div>

    <div class="d-flex justify-content-center">
      <b-button
        :tabindex="String(caseList.length * 4 + 2)"
        :disabled="showModal"
        style="padding: 0; width: 75px; height: 38px"
        type="submit"
        variant="primary"
        @click="saveChange"
      >
        <p style="margin: 0; width: 72px; height: 36px; line-height: 36px">
          Save
        </p>
      </b-button>
    </div>
    <el-dialog
      :destroy-on-close="true"
      title="請核實以下輸入及確定儲存為正式記錄"
      :visible.sync="dialogVisible"
      width="80%"
      modal-append-to-body
      append-to-body
      :before-close="handleClose"
    >
      <div v-loading="loadingBox">
        <b-row
          v-for="(item, index) in types"
          :key="`main-${index}`"
          class="my-1"
        >
          <b-col
            sm="3"
            v-if="
              item.label !== 'Date of Engagement*' &&
              pageType === 'create' &&
              item.label !== 'Screen ID/ House ID *'
            "
          >
            {{ item.label }} :
          </b-col>
          <b-col sm="3" v-else-if="pageType === 'edit'">
            {{ item.label }} :
          </b-col>
          <b-col v-if="item.type === 'selectfilm'">
            <b-form-select
              disabled
              v-model="item.val"
              :options="selectFilm"
            ></b-form-select
          ></b-col>
          <b-col v-else-if="item.type === 'selecthouse' && isEdit"
            ><el-input
              disabled
              v-model="item.house_name"
              placeholder=""
            ></el-input
          ></b-col>

          <b-col v-else-if="item.label === '放映日期 Show Date *'" sm="6">
            {{ $moment(item.val).format() }}
          </b-col>
          <b-col v-else-if="item.type !== 'selecthouse'" sm="6">
            {{ item.val }}
          </b-col>
        </b-row>
        <div class="case-tank"></div>
        <div
          v-for="(item, index) in caseList"
          :key="`caselist-${index}`"
          class="dorest d-flex align-items-center justify-content-between"
          style="width: 100%"
        >
          <div style="width: 5%">
            <p>Show</p>
            <div style="height: 30px">
              {{ index + 1 }}
            </div>
          </div>
          <div v-if="!isEdit">
            <p>Engagement Date*</p>
            <div class="d-flex align-items-center" style="height: 30px">
              <span class="eng">{{
                $moment(item.date_of_engagement).format()
              }}</span>
            </div>
          </div>
          <div>
            <p>放映時間 Show Time</p>
            <div class="d-flex align-items-center">
              <!-- <span class="mr-1">{{ $moment(types[7].val).format() }}</span> -->
              <el-input
                @change="timeInputChange(item.input_time_val, index)"
                v-model="item.input_time_val"
                style="width: 180px"
                class="eng"
              ></el-input>
              <b
                v-if="item.timeType === '午夜場'"
                style="color: red"
                class="ml-1"
                >{{ item.timeType }}</b
              >
              <b
                v-if="item.timeType === '白天場'"
                style="color: #fff"
                class="ml-1"
                >{{ item.timeType }}</b
              >
            </div>
          </div>
          <div v-if="!isEdit">
            <p>Screen ID/ House ID *</p>
            <el-autocomplete
              class="eng inline-input"
              v-model="item.house_name"
              :fetch-suggestions="querySearch"
              placeholder=""
              @select="handleSelect(index)"
              :tabindex="String(index * 4 + 2)"
            ></el-autocomplete>
          </div>
          <div>
            <p>入場人數 Admissions</p>
            <b-form-input
              @change="inputChange"
              type="number"
              v-model="item.admissions"
              class="eng"
            />
            <!-- <p>{{ item.admissions }}</p> -->
          </div>
          <div>
            <p>收入 Total Gross (HKD)</p>
            <b-form-input
              @change="inputChange"
              type="number"
              v-model="item.gross"
              class="eng"
            />
            <!-- <p>{{ item.gross }}</p> -->
          </div>
        </div>
        <div
          class="total d-flex align-items-center justify-content-between pt-1"
          v-if="pageType === 'create'"
        >
          <p>Sum of the above inputs:</p>
          <b></b>
          <b></b>
          <b>{{ parseFloat(is_all_admissions).toLocaleString() }}</b>
          <b>$ {{ parseFloat(is_all_gross).toLocaleString() }}</b>
        </div>
        <p v-if="pageType === 'create'">
          <b>Remark:</b> If the "<span style="color: red">Engagement Date*</span
          >" field are left empty,the system will not add the record to the
          database.
        </p>
        <div class="d-flex justify-content-center mt-1 confirmBtn">
          <button
            :disabled="canFinalSave"
            ref="focusButton"
            @click="confirmSave"
            class="focusButton"
          >
            Confirm Save
          </button>
          <!-- <el-button
            :disabled="canFinalSave"
            @click="addManualBoxoffice"
            :autofocus="true"
            :type="'success'"
            >Confirm Save</el-button
          > -->
        </div>
      </div>
    </el-dialog>
    <b-modal ref="my-check-treatre-modal" id="check-treatre-modal" hide-footer>
      <p class="my-2">修改成功!</p>
      <div style="float: right">
        <b-button size="sm" @click="hideModal">confirm</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { addManualBoxoffice } from "@/api/boxoffice.js"
import {
  getFilmListToShow,
  getFilmDetail,
} from "@/api/film-material-management/film"
import { getTheatreDetail } from "@/api/theatre.js"
import { getTheatreHouseList } from "@/api/theatrehouse.js"
import datePickerCom from "@/views/components/datePicker.vue"

export default {
  components: { datePickerCom },
  props: {},
  data() {
    return {
      isFocus: false,
      restaurants: [],
      houseList: [],
      dialogVisible: false,
      loading: false,
      showDatePickerCom: true,
      timeInputValue: "",
      invalidColor: "#fff",
      loadingBox: false,
      pageId: "",
      pageType: "",
      theatreId: "",
      selectedHouse: "",
      selectedFilm: "",
      selectHouse: [],
      selectFilm: [],
      searchHouse: "",
      searchFilm: "",
      types: [
        {
          label: "戲院名稱 Theatre Name",
          val: "",
          disabled: true,
          type: "text",
        },
        {
          label: "電影名稱 Film Name",
          val: "",
          type: "text",
          disabled: true,
        },
        {
          label: "Film Master ID",
          val: "",
          type: "text",
          disabled: true,
        },
        {
          label: "Film ID *",
          val: "",
          type: "searchFilm",
          disabled: true,
        },
        {
          label: "上映中或即將上映之電影",
          val: "",
          type: "selectfilm",
        },
        {
          label: "Screen ID/ House ID *",
          val: "",
          type: "selecthouse",
          key: "",
          house_name: "",
        },
        {
          label: "Date of Engagement*",
          val: "",
          type: "date",
        },
        {
          label: "放映日期 Show Date *",
          val: "",
          type: "date",
        },
      ],
      caseList: [
        {
          date_of_engagement: "",
          input_time_val: "",
          show_time: "",
          admissions: 0,
          gross: 0,
          timeType: "",
          house_id: "",
          house_name: "",
        },
      ],
      timeType: "",
      all_admissions: "",
      all_gross: "",
      items: [],
      canFinalSave: true,
      isTemp: "",
    }
  },
  computed: {
    invalid() {
      return function (val) {
        if (val) {
          return false
        } else {
          return true
        }
      }
    },
    is_all_admissions: {
      get() {
        let data = ""
        this.caseList.map((el) => {
          data = Number(el.admissions) + Number(data)
        })
        return data
      },
      set(val) {
        this.all_admissions = val
      },
    },
    is_all_gross: {
      get() {
        let data = ""
        this.caseList.map((el) => {
          data = Number(el.gross) + Number(data)
        })
        return data
      },
      set(val) {
        this.all_gross = val
      },
    },
    isEdit() {
      return !!this.$route.query.id
    },
    showModal() {
      let filmInfo = true
      let caseInfo = true
      this.types.map((el) => {
        if (this.pageType === "create") {
          if (
            !el.val &&
            el.label !== "Date of Engagement*" &&
            el.label !== "Screen ID/ House ID *"
          ) {
            filmInfo = false
          }
        } else if (this.pageType === "edit" && !el.val) {
          filmInfo = false
        }
      })

      this.caseList.map((el) => {
        if (
          !el.show_time ||
          (!this.isEdit && !el.date_of_engagement) ||
          (!this.isEdit && !el.house_id)
        ) {
          caseInfo = false
        }
      })
      console.log(filmInfo)
      console.log(caseInfo)
      if (filmInfo && caseInfo) {
        return false
      } else {
        return true
      }
    },
  },
  methods: {
    confirmSave() {
      console.log("confirm save")
      this.addManualBoxoffice()
    },
    handleSelect(item) {
      console.log(item, "this.houseListitem")
      this.houseList.map((el) => {
        if (
          this.pageType === "create" &&
          this.caseList[item].house_name === el.value
        ) {
          console.log(el, "el")

          this.caseList[item].house_id = el.label
        }
      })
      console.log(this.caseList, "this.caseList")
      if (this.pageType === "create") {
        this.$nextTick(() => {
          console.log()
          // 在DOM更新后聚焦到新增的输入框
          this.$refs.focusInput[item].focus()
          // this.$refs.myShowTimeInput[this.caseList.length - 1].focus();
        })
      }
    },
    autoFillName(val, index) {
      console.log(val, index, "auto fill name")
      this.houseList.map((el) => {
        if (el.value.includes(val) && val) {
          console.log(el.screen_id, val, "house list")
          if (this.pageType === "create") {
            console.log(el.value, "el.value")

            this.caseList[index].house_name = el.value
            this.caseList[index].house_id = el.label
          }
        }
      })
    },
    querySearch(queryString, cb) {
      const restaurants = this.restaurants
      const results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants
      cb(results)
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          // restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          // 0
          restaurant.value.includes(queryString)
        )
      }
    },
    handleClose(done) {
      this.canFinalSave = true
      done()
    },
    autoInputFilmInfo(val) {
      console.log(val)
      const data = {
        id: val,
      }
      this.updateFilmandMaster(data)
    },
    hideModal() {
      this.$refs["my-check-treatre-modal"].hide()
    },
    getHouseList() {
      const data = ""
      getTheatreHouseList(this.theatreId, data).then((res) => {
        console.log(res)
        this.houseList = []
        res.data.map((el) => {
          this.houseList.push({
            value: el.house_name,
            label: el.id,
            screen_id: el.screen_id,
          })
          if (this.pageType === "edit" && this.types[5].val === el.screen_id) {
            this.types[5].house_name = el.house_name
          }
        })
        console.log(this.houseList, "this.houseList")
        this.restaurants = this.houseList
      })
    },
    inputHouseNum(val) {
      console.log(val)
      const data = "" //`search=${val}`
      getTheatreHouseList(this.theatreId, data).then((res) => {
        console.log(res)
        if (res.data.length > 0) {
          this.caseList[this.caseList.length - 1].house_name =
            res.data[0].house_name
          this.caseList[this.caseList.length - 1].house_id = res.data[0].id
          this.types[5].house_name = res.data[0].house_name
          this.types[5].val = res.data[0].id
        } else {
          this.$message.error("House Name Not Found")
          this.caseList[this.caseList.length - 1].house_name = ""
          this.caseList[this.caseList.length - 1].house_id = ""
          this.types[5].house_name = ""
          this.types[5].val = ""
        }
      })
    },
    timeInputChange(val, index) {
      console.log(val)
      if (val.length === 4 || val.length === 5) {
        const timePattern = /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/
        if (val.length === 5) {
          const isValidTime = timePattern.test(val)
          if (isValidTime) {
            this.caseList[index].show_time = val
            this.timePickerChange("showTime", index, val)
          } else {
            this.$message.error("請輸入正確的時間格式")
            this.caseList[index].input_time_val = ""
          }
        }
        if (val.length === 4) {
          const before = val.slice(0, 2)
          const after = val.slice(2)
          const time = `${before}:${after}`
          const isValidTime = timePattern.test(time)
          if (isValidTime) {
            this.caseList[index].show_time = time
            this.caseList[index].input_time_val = time
            this.timePickerChange("showTime", index, time)
          } else {
            this.$message.error("請輸入正確的時間格式")
            this.caseList[index].input_time_val = ""
          }
          console.log(`${before}:${after}`)
        }
      } else {
        this.$message.error("請輸入正確的時間格式")
        this.caseList[index].input_time_val = ""
      }
    },
    timePickerChange(type, index, time) {
      if (type === "showTime") {
        const hours = new Date(`${this.types[7].val} ${time}`).getHours()
        console.log(this.types[7].val)
        if (this.types[7].val) {
          const currentDate = new Date(this.types[7].val)
          console.log(hours)
          if (hours <= 5) {
            // 获取当前日期和时间
            currentDate.setDate(currentDate.getDate() - 1) // 计算前一天的日期

            const year = currentDate.getFullYear()
            const month = String(currentDate.getMonth() + 1).padStart(2, "0")
            const day = String(currentDate.getDate()).padStart(2, "0")

            if (this.pageType === "create") {
              this.caseList[
                index
              ].date_of_engagement = `${year}-${month}-${day}`
            } else if (this.pageType === "edit") {
              this.types[6].val = `${year}-${month}-${day}`
              console.log(this.types)
            }
            console.log(this.caseList[index].date_of_engagement)
          } else if (this.pageType === "create") {
            this.caseList[index].date_of_engagement = this.types[7].val
          } else if (this.pageType === "edit") {
            this.types[6].val = this.types[7].val
          }
        }
        this.caseList[index].input_time_val = time
      }
      this.caseList.map((el) => {
        const hours = new Date(`'2021-12-13' ${el.show_time}`).getHours()
        if (hours === 23 || hours <= 5) {
          el.timeType = "午夜場"
        } else if (hours > 5) {
          el.timeType = "白天場"
        }
      })
    },
    inputChange() {
      console.log(this.showModal)
      this.all_admissions = ""
      this.all_gross = ""
      this.caseList.map((el) => {
        this.all_admissions =
          Number(el.admissions) + Number(this.all_admissions)
        this.all_gross = Number(el.gross) + Number(this.all_gross)
      })
    },
    saveChange() {
      this.caseList.map((el) => {
        if (el.gross == "") {
          el.gross = 0
        }
        if (el.admissions == "") {
          el.admissions = 0
        }
      })
      this.invalidColor = "red"

      if (this.pageType === "edit") {
        this.addManualBoxoffice()
      } else if (this.canFinalSave === true) {
        this.dialogVisible = true
        setTimeout(() => {
          this.canFinalSave = false
          setTimeout(() => {
            this.$refs.focusButton.focus()
          }, 300)
        }, 5000)
      } else {
        this.addManualBoxoffice()
      }
    },
    selectFilmChange(val) {
      this.selectFilm.map((el) => {
        if (val === el.value) {
          this.types[1].val = el.film_name
          this.types[2].val = el.master_id
          this.types[3].val = el.value
        }
      })
    },
    onContext(ctx) {
      console.log(ctx)
      this.inputValue = ctx.selectedFormatted
    },
    addCase() {
      this.caseList.push({
        date_of_engagement: "",
        house_id: "",
        show_time: "",
        admissions: 0,
        gross: 0,
        timeType: "",
      })
      this.$nextTick(() => {
        // 在DOM更新后聚焦到新增的输入框
        this.$refs.myShowTimeInput[this.caseList.length - 1].focus()
      })
    },
    delCase(index) {
      this.caseList.splice(index, 1)
    },
    addManualBoxoffice() {
      this.loadingBox = true
      const data = {
        theatre_id: this.theatreId,
        // house_id: this.types[5].key,
        film_id: this.types[3].val,
        show_date: this.types[7].val,
        engagements: [],
        is_temp: this.isTemp,
      }
      if (this.caseList.length > 0) {
        this.caseList.forEach((item) => {
          data.engagements.push({
            show_time: item.show_time,
            admissions: item.admissions,
            total_gross: item.gross,
            date_of_engagement: item.date_of_engagement,
            house_id: item.house_id,
          })
        })
      }
      console.log(data)
      addManualBoxoffice(data, this.theatreId)
        .then((res) => {
          console.log(res)
          this.loadingBox = false
          this.$router.go(0)
          // this.$router.push('/boxoffice/edit_boxoffice_record')
        })
        .catch((error) => {
          console.log(error)
          this.loadingBox = false
        })
    },
    getTheatre() {
      getTheatreDetail(this.theatreId).then((res) => {
        this.types[0].val = res.data.theatre_name
      })
    },
    getFilmList(search) {
      const data = `count=1000&search=${search}`
      getFilmListToShow(data).then((res) => {
        const arr = []
        console.log(res)
        res.data.forEach((el) => {
          const obj = new Object()
          obj.value = el.id
          obj.text = el.name
          obj.master_id = el.master_id
          obj.film_name = el.title_name
          arr.push(obj)
        })
        this.selectFilm = arr
      })
    },
    updateFilmandMaster(val) {
      getFilmDetail(val.id).then((res) => {
        console.log(res)
        this.types[1].val = res.data.title_name
        this.types[2].val = res.data.master_id
        this.types[3].val = res.data.id
        this.types[4].val = res.data.id
      })
    },
  },
  mounted() {
    this.getTheatre()
    this.getFilmList("", "")
    this.getHouseList()
  },
  created() {
    this.pageId = this.$route.query.id
    this.theatreId = this.$route.query.theatreid
    this.theatreId = this.$route.query.theatreid
    this.pageType = this.$route.query.type
    this.queryDate = this.$route.query.date || ""
    this.isTemp = Number(this.$route.query.is_temp)
    if (this.pageType !== "edit") {
      if (this.queryDate !== "") {
        this.types[7].val = this.$moment(this.queryDate).format("YYYY-MM-DD")
      } else {
        this.types[7].val = this.$moment().format("YYYY-MM-DD")
      }
    }
  },
}
</script>
<style lang="scss">
.boxofficeRecordDetail,
#modal-1 {
  #datetime-1,
  #datetime-2 {
    padding-left: 19px;
    padding-right: 15px;
  }
}
</style>
<style lang="scss" scoped>
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.label {
  font-size: 14px;
}
.case-tank {
  width: 100%;
  border-top: 2px solid #0e72bc;
}
.input-box {
  width: 20%;
}
.change-box {
  height: 80px;
  border-bottom: 2px solid #0e72bc;
  .change-btn {
    margin-top: 20px;
    float: right;
  }
}
.redborder {
  border-color: red;
}
.dorest {
  // margin-top: -15px;
}
.eng {
  margin-top: -10px;
}

.confirmBtn {
  .focusButton {
    background: #28c76f;
    color: #ffffff;
    padding: 7px;
    border-radius: 3px;
  }
  .focusButton[disabled] {
    background: #a1f8c8;
    color: #9f9e9e;
    padding: 7px;
    border-radius: 3px;
  }
}
</style>

