import Cookies from 'js-cookie'

const TokenKey = 'hk_box_key'

const S3Key = 's3_key'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, { expires: 1 })
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getS3Key() {
  return Cookies.get(S3Key)
}

export function setS3Key(data) {
  const InFourteenMinutes = new Date((new Date().getTime() + 14) * 60 * 1000)
  return Cookies.set(S3Key, data, { expires: InFourteenMinutes })
}
