import request from '@/utils/request'

export function getEngByDate (date, area) {
  return request({
    url: `records/eng-by-date?date=${date}&area=${area}`,
    method: 'GET',
  })
}

export function getEngByDuration(str, end, type, area) {
  return request({
    url: `records/eng-by-date-duration?date_start=${str}&date_end=${end}&type=${type}&area=${area}`,
    method: 'GET',
  })
}

export function getEngByID(date, type, id) {
  return request({
    url: `records/get-Eng-by-date-by-id?date=${date}&type=${type}&id=${id}`,
    method: 'GET',
  })
}

export function getEngBySearch(search) {
  return request({
    url: `records/master-film-by-id?count=1000&page=1&search=${search}`,
    method: 'GET',
  })
}
//16.1~  all
export function getEngByDateRecord(data) {
  return request({
    url: '/api/records/eng-by-date',
    method: 'POST',
    data
  })
}
//16.1 所有版本
export function getAllversionByFilm(params) {
  return request({
    url: '/api/records/eng-by-date-by-id',
    method: 'GET',
    params
  })
}
//16.1 按放映時間
export function getEngByIDByTime(params) {
  return request({
    url: '/api/records/eng-by-date-by-id-by-theatre',
    method: 'GET',
    params
  })
}
//16.1 列出相關的xml儅
export function getEngByTheareByXML(params) {
  return request({
    url: '/api/records/eng-by-id-by-theatre-by-time',
    method: 'GET',
    params
  })
}
//16.2.1
export function getCheckFilmUploadRecord(count, page, search) {
  return request({
    url: `/api/records/master-film?count=${count}&page=${page}&search=${search}`,
    method: 'GET'
  })
}
//16.2.2 - run
export function getEngByMasterId(id, params) {
  return request({
    url: `/api/records/eng-by-master-id/${id}`,
    method: 'GET',
    params
  })
}
//16.2.5
export function getEngByFilmRecords(params) {
  return request({
    url: '/api/records/film',
    method: 'GET',
    params
  })
}
//16.2.5 run
export function getEngByFilmId(id) {
  return request({
    url: `/api/records/eng-by-film-id/${id}`,
    method: 'GET'
  })
}
//16.3
export function getModifyRecord(page, count) {
  return request({
    url: `/api/records/eng-by-negative?page=${page}&count=${count}`,
    method: 'GET'
  })
}
export function getBeforeEng(id, page, count) {
  return request({
    url: `/api/records/get-before-eng/${id}?page=${page}&count=${count}`,
    method: 'GET'
  })
}
//16.8
export function getUncertainRecord(data) {
  return request({
    url: '/api/records/uncertain-list',
    method: 'POST',
    data
  })
}
//16.9
export function getNightAndFinalCompare(data) {
  return request({
    url: '/api/records/compare-night-and-final',
    method: 'POST',
    data
  })
}
//16.10
export function getRecordByYear(data) {
  return request({
    url: '/api/records/get-record-by-year',
    method: 'POST',
    data
  })
}
//16.12
export function getLastHourUncertainRecord(params) {
  return request({
    url: '/api/records/get-last-hour-uncertain-record',
    method: 'GET',
    params
  })
}

//excel export
export function excelExport(data) {
  return request({
    url: '/api/records/eng-by-date-export',
    method: 'POST',
    data
  })
}