export default [
  {
    path: "/dashboard/analytics",
    name: "dashboard-analytics",
    component: () => import("@/views/dashboard/ecommerce/Ecommerce.vue")
  },
  {
    path: "/dashboard/ecommerce",
    name: "dashboard-ecommerce",
    component: () => import("@/views/dashboard/ecommerce/Ecommerce.vue")
  },
  {
    path: "/dashboard/management-center",
    name: "management-center",
    component: () => import("@/views/management/managementCenter.vue")
  },
  {
    path: "/dashboard/management-center/add-boxoffice-record",
    name: "add-boxoffice-record",
    component: () => import("@/views/management/addBoxofficeRecordDetail.vue")
  },
  {
    path: "/dashboard/not-found-records",
    name: "not-found-records",
    component: () => import("@/views/management/notFoundRecords.vue")
  },
  {
    path: "/dashboard/abnormal-records",
    name: "abnormal-records",
    component: () => import("@/views/management/abnormalRecords.vue")
  },
  {
    path: "/dashboard/data-management",
    name: "data-management",
    component: () => import("@/views/management/dataManagement.vue")
  },
  {
    path: "/dashboard/dayend-file",
    name: "dayend-file",
    component: () => import("@/views/management/dayendFile.vue")
  },
  {
    path: "/dashboard/add-xml-record",
    name: "add-xml-record",
    component: () => import("@/views/management/addXmlRecord.vue")
  },
  {
    path: "/dashboard/no-discipline",
    name: "no-discipline",
    component: () => import("@/views/management/noDiscipline.vue")
  },
  {
    path: "/dashboard/film-distributor",
    name: "film-distributor",
    component: () => import("@/views/dashboard/distributor/filmDistributor.vue")
  },
  {
    path: "/dashboard/edit-film-distributor/",
    name: "edit-film-distributor",
    component: () =>
      import("@/views/dashboard/distributor/editFilmDistributor.vue")
  },
  {
    path: "/dashboard/add-film-distributor/",
    name: "add-film-distributor",
    component: () =>
      import("@/views/dashboard/distributor/addFilmDistributor.vue")
  },
  {
    path: "/dashboard/master-record",
    name: "master-record",
    component: () => import("@/views/filmrecords/masteRecord.vue")
  },
  {
    path: "/dashboard/revise-record",
    name: "revise-record",
    component: () => import("@/views/filmrecords/reviseRecord.vue")
  },
  {
    path: "/dashboard/revise-record-detail",
    name: "revise-record-detail",
    component: () => import("@/views/filmrecords/reviseRecordDetail.vue")
  },
  {
    path: "/dashboard/revise-record-info",
    name: "revise-record-info",
    component: () => import("@/views/filmrecords/reviseRecordInfo.vue")
  },
  {
    path: "/dashboard/no-record",
    name: "no-record",
    component: () => import("@/views/filmrecords/noRecord.vue")
  },
  {
    path: "/dashboard/revise-record-add",
    name: "revise-record-add",
    component: () => import("@/views/filmrecords/reviseRecordAdd.vue")
  },
  {
    path: "/dashboard/revise-record-save",
    name: "revise-record-save",
    component: () => import("@/views/filmrecords/reviseRecordSave.vue")
  },
  {
    path: "/dashboard/film-record",
    name: "film-record",
    component: () => import("@/views/filmprofiles/film/film.vue")
  },
  {
    path: "/dashboard/film-record-detail",
    name: "film-record-detail",
    component: () => import("@/views/filmrecords/masteRecordDetail.vue")
  },
  {
    path: "/dashboard/edit-film-version-record",
    name: "edit-film-version-record",
    component: () => import("@/views/filmrecords/editFilmVersionRecord.vue")
  },
  {
    path: "/dashboard/actor",
    name: "actor",
    component: () => import("@/views/filmprofiles/actor/actor.vue")
  },
  {
    path: "/dashboard/actor-info",
    name: "actor-info",
    component: () => import("@/views/filmprofiles/actor/actorInfo.vue")
  },
  {
    path: "/dashboard/director",
    name: "director",
    component: () => import("@/views/filmprofiles/director/director.vue")
  },
  {
    path: "/dashboard/director-info",
    name: "director-info",
    component: () => import("@/views/filmprofiles/director/directorInfo.vue")
  },
  {
    path: "/dashboard/producer",
    name: "producer",
    component: () =>
      import("@/views/filmprofiles/filmproducer/filmproducer.vue")
  },
  {
    path: "/dashboard/producer-info",
    name: "producer-info",
    component: () =>
      import("@/views/filmprofiles/filmproducer/filmproducerInfo.vue")
  },
  {
    path: "/dashboard/scriptwriter",
    name: "scriptwriter",
    component: () =>
      import("@/views/filmprofiles/scriptwriter/scriptwriter.vue")
  },
  {
    path: "/dashboard/scriptwriter-info",
    name: "scriptwriter-info",
    component: () =>
      import("@/views/filmprofiles/scriptwriter/scriptwriterInfo.vue")
  },
  {
    path: "/dashboard/production-company/",
    name: "production-company",
    component: () =>
      import("@/views/dashboard/productioncompany/productionCompany.vue")
  },
  {
    path: "/dashboard/edit-production-company/",
    name: "edit-production-company",
    component: () =>
      import("@/views/dashboard/productioncompany/editProductionCompany.vue")
  },
  {
    path: "/dashboard/add-production-company/",
    name: "add-production-company",
    component: () =>
      import("@/views/dashboard/productioncompany/addProductionCompany.vue")
  },
  {
    path: "/dashboard/admin-properties-options",
    name: "admin-properties-options",
    component: () => import("@/views/admin-options/adminPropertiesPage.vue")
  },
  {
    path: "/dashboard/edit-admin-properties-options",
    name: "edit-admin-properties-options",
    component: () => import("@/views/admin-options/adminEditOptions.vue")
  },
  {
    path: "/dashboard/create-admin-properties-options",
    name: "create-admin-properties-options",
    component: () => import("@/views/admin-options/adminEditOptions.vue")
  },
  {
    path: "/dashboard/manage-users",
    name: "manage-users",
    component: () => import("@/views/manage-users/manageUsers.vue")
  },
  {
    path: "/dashboard/edit-film-record",
    name: "edit-film-record",
    component: () => import("@/views/edit-film-record/editFilmRecord.vue")
  },
  {
    path: "/dashboard/select-theatre",
    name: "select-theatre",
    component: () => import("@/views/edit-film-record/selectTheatre.vue")
  },
  {
    path: "/dashboard/select-film",
    name: "select-film",
    component: () => import("@/views/edit-film-record/selectFilm.vue")
  },
  {
    path: "/dashboard/select-vison",
    name: "select-vison",
    component: () => import("@/views/edit-film-record/selectVison.vue")
  },
  {
    path: "/dashboard/select-item",
    name: "select-item",
    component: () => import("@/views/edit-film-record/selectItem.vue")
  },
  {
    path: "/dashboard/edit-record",
    name: "edit-record",
    component: () => import("@/views/edit-film-record/editRecord.vue")
  },
  {
    path: "/dashboard/add-record",
    name: "add-record",
    component: () => import("@/views/edit-film-record/addRecord.vue")
  },
  {
    path: "/dashboard/add-film-record",
    name: "add-film-record",
    component: () => import("@/views/edit-film-record/addFilmRecord.vue")
  },
  {
    path: "/dashboard/user-info",
    name: "user-info",
    component: () => import("@/views/manage-users/userInfo.vue")
  },
  {
    path: "/dashboard/genre",
    name: "genre",
    component: () => import("@/views/dashboard/genre/movieGenre.vue")
  },
  {
    path: "/dashboard/editgenre",
    name: "edit-genre",
    component: () => import("@/views/dashboard/genre/editMovieGenre.vue")
  },
  {
    path: "/dashboard/add-genre",
    name: "add-genre",
    component: () => import("@/views/dashboard/genre/addMovieGenre.vue")
  },
  {
    path: "/dashboard/theater-management",
    name: "theater-management",
    component: () => import("@/views/theaterManagement/theaterManagement.vue")
  },
  // {
  //   path: '/dashboard/cinemas-data',
  //   name: 'cinemas-data',
  //   component: () => import('@/views/theaterManagement/cinemasData.vue'),
  // },
  {
    path: "/dashboard/circuit-detail",
    name: "circuit-detail",
    component: () => import("@/views/theaterManagement/circuitDetail.vue")
  },
  {
    path: "/dashboard/theater-data",
    name: "theater-data",
    component: () => import("@/views/theaterManagement/theaterData.vue")
  },
  {
    path: "/dashboard/attendance-data",
    name: "attendance-data",
    component: () => import("@/views/theaterManagement/attendanceData.vue")
  },
  {
    path: "/dashboard/attendance-data/details",
    name: "attendance-data-details",
    component: () => import("@/views/theaterManagement/attendanceDetails.vue")
  },
  {
    path: "/dashboard/special-film-record",
    name: "special-film-record",
    component: () => import("@/views/report/specialFilmRecord.vue")
  },
  {
    path: "/report/report-year-version",
    name: "report-year-version",
    component: () => import("@/views/report/yearReportVersion.vue")
  },
  {
    path: "/report/report-year-version-detail",
    name: "report-year-version-detail",
    component: () => import("@/views/report/yearReportVersionDetails.vue")
  },
  {
    path: "/report/report-year-production-country",
    name: "report-year-production-country",
    component: () => import("@/views/report/yearReportProductionCountry.vue")
  },
  {
    path: "/report/report-year-period",
    name: "report-year-period",
    component: () => import("@/views/report/yearReportSpecialPeriod.vue")
  },
  {
    path: "/report/boxoffice-report-week",
    name: "boxoffice-report-week",
    component: () => import("@/views/report/boxOfficeReportWeek.vue")
  },
  {
    path: "/report/top-ranking",
    name: "top-ranking",
    component: () => import("@/views/report/topRanking.vue")
  },
  {
    path: "/report/highest-single-day",
    name: "highest-single-day",
    component: () => import("@/views/report/highestSingleDay.vue")
  },
  {
    path: "/dashboard/pre-sale",
    name: "pre-sale",
    component: () => import("@/views/boxoffice/pre-sale.vue")
  },
  {
    path: "/dashboard/unusual-film-record",
    name: "unusual-film-record",
    component: () => import("@/views/unusualFilmRecord/unusualFilmRecord.vue")
  },
  {
    path: "/dashboard/latestNews",
    name: "latestNews",
    component: () => import("@/views/news/latestNews.vue")
  },
  {
    path: "/dashboard/latestNews/latestNews-detail",
    name: "latestNews-detail",
    component: () => import("@/views/news/latestNewsDetail.vue"),
    meta: {
      parent: "/dashboard/latestNews"
    }
  },
  {
    path: "/dashboard/pressRelease",
    name: "pressRelease",
    component: () => import("@/views/news/pressRelease.vue")
  },
  {
    path: "/dashboard/pressRelease/pressRelease-detail",
    name: "pressRelease-detail",
    component: () => import("@/views/news/pressReleaseDetail.vue"),
    meta: {
      parent: "/dashboard/pressRelease"
    }
  },
  {
    path: "/dashboard/searchFilmName",
    name: "searchFilmName",
    component: () => import("@/views/oldFilmSearch/searchFilmName.vue")
  },
  {
    path: "/dashboard/searchFilmName/searchFilmResult",
    name: "searchFilmResult",
    component: () => import("@/views/oldFilmSearch/searchFilmResult.vue")
  }
];
