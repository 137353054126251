var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-card',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('div',{staticClass:"title"},[_c('p',[_vm._v(_vm._s(_vm.title))])]),_c('div',[_c('b-row',[_c('b-col',[_c('b',{staticStyle:{"color":"red"}},[_vm._v("過往負值/0元人次票房紀錄")]),_c('p',[_vm._v("顯示最近500條記錄")])])],1)],1),_c('div',[_c('b-table',{attrs:{"bordered":"","hover":"","responsive":"","items":_vm.items,"fields":_vm.$store.state.highlightCol.firTable
            ? _vm.$store.state.newTableFields
            : _vm.fields},on:{"head-clicked":function($event){return _vm.commonHighLightCol(
            $event,
            _vm.fields,
            _vm.$store.state.newTableFields,
            'firTable'
          )}},scopedSlots:_vm._u([{key:"cell(date_of_engagement)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$moment(data.item.date_of_engagement).format())+" ")])]}},{key:"cell(show_time)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$moment(data.item.show_time).format("DD-MM-YYYY HH:mm:ss"))+" ")])]}},{key:"cell(total_gross)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(parseFloat(data.item.total_gross).toLocaleString())+" ")])]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }