import request from '@/utils/request'

// get Circuit
export function getBoxOfficesList (params) {
  return request({
    url: '/api/box-offices/theatres',
    method: 'GET',
    params
  })
}

export function addBoxOffices (id, data) {
  return request({
    url: `/api/box-office/${id}`,
    method: 'POST',
    data
  })
}

export function getList (params, page, count) {
  return request({
    url: `api/box-offices/films-by-theatre?date=${params.date}&theatre_id=${params.id}&page=${page}&count=${count}&search=${params.search}`,
    method: 'GET',
  })
}
export function getMasterList (params, page, count) {
  return request({
    url: `api/box-offices/films-by-master?date=${params.date}&theatre_id=${params.theatre_id}&page=${page}&count=${count}&master_id=${params.master_id}`,
    method: 'GET',
  })
}

export function getFilmList (params, page, count) {
  return request({
    url: `api/box-offices/films-by-film?date=${params.date}&theatre_id=${params.theatre_id}&page=${page}&count=${count}&film_id=${params.film_id}&type=${params.type}`,
    method: 'GET',
  })
}

export function getDetail (id) {
  return request({
    url: `api/box-office/${id}`,
    method: 'GET',
  })
}

export function editDetail (id, data) {
  return request({
    url: `api/box-office/${id}`,
    method: 'PUT',
    data
  })
}

export function deleteDetail (id) {
  return request({
    url: `api/box-office/${id}`,
    method: 'DELETE'
  })
}