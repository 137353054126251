<template>
  <TableDataListing
    :loading="loading"
    :type="type"
    :title="title"
    :fields="fields"
    :items="items"
    :showArea="false"
    :add-button-title="addButtonTitle"
    :edit-button-title="editButtonTitle"
    :pageTotal="pageTotal"
    :currentPage="currentPage"
    :rows="rows"
    :sort="sort"
    :direct="direct"
    :placeHolder="placeHolder"
    @handleCurrentChange="handleCurrentChange"
    @handleSizeChange="handleSizeChange"
    @handleSearch="handleSearch"
    @orderHead="orderHead"
    @selectFilmType="selectFilmType"
  />
</template>
<script>
import TableDataListing from "../components/tableDataListing.vue"
import { getDistributorApi } from "@/api/film-material-management/distributor"

export default {
  name: "FilmDistributor",
  components: {
    TableDataListing,
  },
  data() {
    return {
      placeHolder: "Search Distributor",
      loading: false,
      type: "film-distributor",
      title: "電影發行商",
      addButtonTitle: "開立新發行商記錄",
      editButtonTitle: "作出修訂",
      sort: "id",
      direct: "desc",
      fields: [
        {
          key: "id",
          label: "Distributor ID",
          sortKey: "id",
        },
        {
          key: "name_en",
          label: "Distributor Name",
          sortKey: "distributor_name_en",
        },
        {
          key: "name_ch",
          label: "發行商名稱",
          sortKey: "distributor_name_zh_hk",
        },
        {
          key: "short_name",
          label: "簡稱",
          sortKey: "distributor_code",
        },
        {
          key: "actions",
          label: "",
          tdClass: "d-flex justify-content-center",
        },
      ],
      items: [],
      currentPage: 1,
      pageTotal: 10,
      rows: 20,
      search: "",
      isOld: 0,
    }
  },
  methods: {
    selectFilmType(val) {
      this.isOld = val
      this.getDistributorList()
    },
    orderHead(key, direct) {
      this.sort = key
      this.direct = direct
      this.getDistributorList()
    },
    handleSizeChange(val) {
      this.rows = val
      this.getDistributorList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getDistributorList()
    },
    handleSearch(val) {
      this.currentPage = 1
      this.rows = val.rows
      this.search = val.search
      this.sort = val.sort
      this.direct = val.direct
      this.getDistributorList()
    },
    getDistributorList() {
      this.loading = true
      const data = {
        page: this.currentPage,
        count: this.rows,
        search: this.search,
        sort: this.sort,
        direct: this.direct,
        arch: this.isOld,
      }
      getDistributorApi(data)
        .then((res) => {
          console.log(res)
          this.pageTotal = res.total
          const arr = []
          this.items = arr
          res.data.forEach((el) => {
            const obj = new Object()
            obj.id = el.id
            obj.name_ch = el.distributor_name_zh_hk
            obj.name_en = el.distributor_name_en
            obj.short_name = el.distributor_code
            arr.push(obj)
          })
          this.items = arr
          console.log(this.items)
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    },
  },
  mounted() {
    this.getDistributorList()
  },
}
</script>
