<template>
  <section id="management-center">
    <b-card>
      <div class="title d-flex">
        <p>異常電影記錄</p>
        <p style="color: red" v-show="showPresale">(有電影預售超過14天)</p>
      </div>
      <div>
        <b-button
          size="sm"
          variant="outline-secondary"
          class="handle-button"
          @click="returnRecord()"
          >回目錄</b-button
        >
      </div>
      <div class="tit">
        <span>在資料庫內異常之電影</span>
        <span>共 {{ total }} 項</span>
      </div>
      <div v-loading="loading">
        <b-table
          bordered
          striped
          hover
          responsive
          :items="tableData"
          :fields="
            $store.state.highlightCol.firTable
              ? $store.state.newTableFields
              : tableFields
          "
          @head-clicked="
            commonHighLightCol(
              $event,
              tableFields,
              $store.state.newTableFields,
              'firTable'
            )
          "
        >
          <template #head(title_name)>
            <div style="width: 132px; text-wrap: wrap">
              Film Name By Circuit Prov.
            </div>
          </template>
          <template #cell(title_name)="row">
            <div style="width: 150px">{{ row.item.title_name }}</div>
          </template>

          <template #cell(circuit)="row">
            <div style="width: 100px">{{ row.item.circuit }}</div>
          </template>
          <template #cell(full_name)="row">
            <div style="width: 132px">{{ row.item.full_name }}</div>
          </template>
          <template #cell(house)="row">
            <div class="text-nowrap">{{ row.item.house }}</div>
          </template>
          <template #cell(show_time)="row">
            <div class="text-nowrap">{{ row.item.show_time }}</div>
          </template>
          <template v-slot:cell(action1)="row">
            <div
              class="d-flex flex-wrap justify-content-center"
              style="width: 280px"
            >
              <b-button
                style="width: 132px; margin: 5px"
                variant="outline-info"
                class="handle-button"
                @click="setOvertime(row.item.film_id)"
                size="sm"
              >
                延長落畫時間
              </b-button>
              <b-button
                size="sm"
                variant="outline-secondary"
                class="handle-button"
                style="width: 132px; margin: 5px"
                @click="
                  goPage(
                    'revise-record-add',
                    row.item.pos_id,
                    row.item.film_master_id,
                    row.item
                  )
                "
              >
                指派到新Film ID
              </b-button>

              <b-button
                size="sm"
                style="width: 132px; margin: 5px"
                variant="outline-danger"
                class="handle-button"
                v-b-modal.modal-delete-status
                @click="actionChanges('delete', row.item.id)"
              >
                刪除這項記錄
              </b-button>
              <b-button
                size="sm"
                style="width: 132px; margin: 5px"
                variant="outline-success"
                class="handle-button"
                v-b-modal.modal-delete-status
                @click="actionChanges('status', row.item.id)"
              >
                已完成處理
              </b-button>
            </div>
          </template>
          <template #head(action1)="row">
            <div
              class="d-flex flex-wrap justify-content-start"
              style="width: 280px"
            >
              <b-button
                :disabled="checkItemList.length === 0"
                size="sm"
                style="width: 132px; margin: 5px"
                variant="info"
                class="handle-button"
                @click="multiAction('延長落畫時間')"
              >
                延長落畫時間
              </b-button>

              <b-button
                :disabled="checkItemList.length === 0"
                size="sm"
                style="width: 132px; margin: 5px"
                variant="success"
                class="handle-button"
                @click="multiAction('已完成處理')"
              >
                已完成處理
              </b-button>
              <b-button
                :disabled="checkItemList.length === 0"
                size="sm"
                style="width: 132px; margin: 5px"
                variant="danger"
                class="handle-button"
                @click="multiAction('刪除記錄')"
              >
                刪除這項記錄
              </b-button>
            </div>
          </template>
          <template #cell(checkbox)="rowSelect">
            <b-form-checkbox
              @change="selectChange(rowSelect.item)"
              v-model="tableData[rowSelect.index].isSelect"
              :value="true"
              :unchecked-value="false"
            />
          </template>
        </b-table>
        <div class="">
          <b-button
            class="d-block"
            variant="link"
            style="text-decoration: underline"
            v-for="(item, index) in linkToPage"
            :key="index"
            @click="$router.push({ name: item.link })"
          >
            <i class="el-icon-caret-right"></i> {{ item.name }}</b-button
          >
        </div>
      </div>
    </b-card>
    <b-modal id="modal-delete-status" centered hide-footer>
      <p>是否確認{{ actionStatus }}此條記錄？</p>
      <div class="float-right">
        <b-button
          class="mr-1"
          @click="$bvModal.hide('modal-delete-status')"
          size="sm"
          >取消</b-button
        >
        <b-button size="sm" @click="deletFilmRecord(itemId, actionStatusVal)"
          >確認</b-button
        >
      </div>
    </b-modal>
    <b-modal id="modal-all-complete" hide-footer centered title="全部完成">
      <p class="my-3">是否確認全部完成處理？</p>
      <div class="float-right">
        <b-button
          class="mr-1"
          @click="$bvModal.hide('modal-all-complete')"
          size="sm"
          >取消</b-button
        >
        <b-button size="sm" @click="setAllStatus">確認</b-button>
      </div>
    </b-modal>
    <b-modal
      id="modal-select-multi"
      hide-footer
      centered
      size="lg"
      :title="`${actionTitle}-多選`"
    >
      <p>
        是否確認以下電影<span style="color: red">{{ actionTitle }}</span
        >？
      </p>
      <div v-loading="multiLoading">
        <p v-for="(con, conIndex) in multiContent" :key="conIndex">
          {{ con.text }}
        </p>
      </div>
      <div class="float-right">
        <b-button
          class="mr-1"
          @click="$bvModal.hide('modal-select-multi')"
          size="sm"
          >取消</b-button
        >
        <b-button size="sm" @click="setMultis">確認</b-button>
      </div>
    </b-modal>
  </section>
</template>
<script>
import {
  getAbnormalRecord,
  setOvertime,
  setOvertimeMulti,
  deletFilmRecord,
  setAllStatus,
  setStatusMulti,
} from "@/api/management.js"

export default {
  components: {},
  data() {
    return {
      multiLoading: "",
      multiContent: [],
      actionTitle: "",
      showPresale: false,
      linkToPage: [
        { name: "新增/修改 電影記錄 記錄", link: "film-record" },
        { name: "訂正票房資料庫記錄", link: "revise-record" },
        { name: "新增/修改 電影發行商 記錄", link: "film-distributor" },
      ],
      loading: false,
      itemId: "",
      actionStatus: "",
      actionStatusVal: "",
      updateOverTime: "",
      total: 0,
      tableData: [],
      tableFields: [
        { key: "id", label: "EID", sortable: true },
        { key: "film_master_id", label: "FMID", sortable: true },
        { key: "film_id", label: "FID", sortable: true },
        { key: "pos_id", label: "POS", sortable: true },
        {
          key: "title_name",
          label: "Film Name By Circuit Prov.",
          sortable: true,
        },
        { key: "circuit_name", label: "Circuit", sortable: true },
        { key: "full_name", label: "Theatre Name", sortable: true },
        { key: "house", label: "House", sortable: true },
        { key: "show_time", label: "Show Time", sortable: true },
        { key: "action1", label: "" },
        { key: "checkbox", label: "" },
      ],
      paramsVal: {
        abPage: 1,
        abCount: 10000,
      },
      checkItemList: [],
    }
  },
  created() {
    this.getAbnormalRecord()
  },
  methods: {
    multiAction(title) {
      this.actionTitle = title
      this.$bvModal.show("modal-select-multi")
    },
    setMultis() {
      console.log(this.checkItemList)
      const timeList = []
      const statusList = []
      this.checkItemList.map((el) => {
        timeList.push(el.film_id)
        statusList.push(el.id)
      })
      if (this.actionTitle === "延長落畫時間") {
        this.setOvertimeMulti(timeList)
      } else {
        this.setStatusMulti(statusList)
      }
    },
    setStatusMulti(list) {
      this.multiLoading = true
      const data = {
        ids: list,
        status: this.actionTitle === "已完成處理" ? "N" : "D",
      }
      setStatusMulti(data).then((res) => {
        console.log(res)
        this.multiLoading = false
        this.$bvModal.hide("modal-select-multi")
        this.checkItemList = []
        this.getAbnormalRecord()
      })
    },
    setOvertimeMulti(list) {
      this.multiLoading = true
      const data = {
        ids: list,
      }
      setOvertimeMulti(data).then((res) => {
        console.log(res)
        this.multiLoading = false
        this.$bvModal.hide("modal-select-multi")
        this.checkItemList = []
        this.getAbnormalRecord()
      })
    },
    allComplete() {
      this.$bvModal.show("modal-all-complete")
    },
    selectChange(item) {
      console.log(item)
      const multiList = []
      const multiCon = []
      if (item.isSelect) {
        this.checkItemList.push({ id: item.id, film_id: item.film_id })
        this.multiContent.push({
          text: `[E_id:${item.id}, F_id:${item.film_id}] ${item.title_name}`,
          id: item.id,
        })
      } else {
        this.checkItemList.map((el, index) => {
          if (el.id === item.id) {
            this.checkItemList.splice(index, 1)
          }
        })
        this.multiContent.map((el, index) => {
          if (el.id === item.id) {
            this.multiContent.splice(index, 1)
          }
        })
      }
      console.log(this.checkItemList)
    },
    handleCurrentChange(val) {
      console.log(val)
      this.paramsVal.abPage = val
      this.getAbnormalRecord()
    },
    handleCurrentChangeNot(val) {
      this.paramsVal.notPage = val
      this.getUnfoundRecord()
    },
    setAllStatus() {
      setAllStatus()
        .then((res) => {
          this.$bvModal.hide("modal-all-complete")
          this.getAbnormalRecord()
        })
        .catch((error) => {
          console.log(error)
          this.$bvModal.hide("modal-all-complete")
        })
    },
    actionChanges(type, id) {
      this.itemId = id
      if (type === "delete") {
        this.actionStatus = "刪除"
        this.actionStatusVal = "D"
      } else {
        this.actionStatus = "更改"
        this.actionStatusVal = "N"
      }
    },
    deletFilmRecord(id, status) {
      const data = {
        status,
      }
      deletFilmRecord(id, data)
        .then((res) => {
          this.$bvModal.hide("modal-delete-status")
          this.getAbnormalRecord()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    setOvertime(id) {
      console.log(id)
      setOvertime(id)
        .then((res) => {
          console.log(res)
          this.getAbnormalRecord()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    returnRecord() {
      this.$router.push({ name: "management-center" })
    },
    getAbnormalRecord() {
      this.loading = true
      const data = {
        page: this.paramsVal.abPage,
        count: this.paramsVal.abCount,
      }
      getAbnormalRecord(data).then((res) => {
        this.total = res.total
        this.tableData = res.data
        this.tableData.map((el) => {
          if (this.$moment(el.show_time).diff(this.$moment(), "days") > 14) {
            this.showPresale = true
          }
          el.isSelect = false
        })
        this.loading = false
      })
    },
    goPage(info, pos_id, master_id, item) {
      const search_split = this.getFilmSearchName(item)
      const filmInfo = {
        cinema_id: item.cinema_id,
        circuit: item.circuit_name,
        circuit_id: item.circuit_id,
        pos_id: item.pos_id,
        title_name: item.title_name,
        search_name: search_split,
        film_circuit_id: item.film_circuit_id,
        id: item.id,
        goPage: this.$route.fullPath,
        film_id: item.film_id,
        type: "abnormal",
      }
      sessionStorage.setItem("filmInfo", JSON.stringify(filmInfo))
      this.$router.push({
        name: info,
        query: {
          pos_id,
          master_id,
          search_name: search_split,
          circuit_id: item.circuit_id,
          id: item.id,
          cinema_id: item.cinema_id,
        },
      })
    },
  },
}
</script>
<style lang="scss" scope="scoped">
.tit {
  width: 100%;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin: 20px 0;
  justify-content: flex-start;
}
.table-scroll {
  overflow: scroll;
}
</style>
