<template>
  <div>
    <b-card>
      <div class="title">
        <p>入座率資料（By theater）</p>
      </div>
      <v-select
        v-model="select"
        :options="selectOptions"
        :clearable="false"
        style="width: 200px"
        class="per-page-selector d-inline-block mr-1 mb-1"
      />
      <b-table
        :sticky-header="true"
        striped
        hover
        responsive
        :items="tableData"
        :fields="tableFields"
      >
        <template v-slot:cell(action)="row">
          <b-button size="sm" class="mr-2" @click="goDetail(row.item.id)">
            作出修訂
          </b-button>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        first-number
        last-number
        class="mb-0 mt-3"
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { getCircuitList } from "@/api/circuit.js";

export default {
  components: {
    vSelect
  },
  data() {
    return {
      rows: 1,
      perPage: 1,
      currentPage: 1,
      select: "All locations",
      selectOptions: ["All locations", "Hong Kong", "Macau"],
      tableData: [],
      tableFields: [
        { key: "id", label: "Circuit ID " },
        { key: "circuit_name", label: "院線名稱" },
        { key: "remarks", label: "XML標記" },
        { key: "xml_fid", label: "XML ID" },
        { key: "action", label: "" }
      ],
      listParams: {
        page: 1,
        count: 10
      }
    };
  },
  methods: {
    getCircuitList() {
      getCircuitList(this.listParams).then((res) => {
        this.tableData = res.data.data;
      });
    },
    goDetail(id) {
      const query = {
        id: id || null
      };
      this.$router.push({ path: "/dashboard/circuit-detail", query });
    }
  },
  created() {
    this.getCircuitList();
  }
};
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
</style>
