<template>
  <div>
    <b-card v-loading="loading">
      <div class="title">
        <p>{{ title }}</p>
      </div>
      <div class="tip">
        <b-button
          size="sm"
          class="mr-2"
          @click="newFilm"
          variant="outline-primary"
        >
          {{ createBtn }}
        </b-button>
        <b-button-group size="sm" class="mr-2">
          <b-button
            class="mt-0"
            @click="selectFilmType('')"
            :variant="isOldFilm === '' ? 'primary' : ''"
            >所有電影</b-button
          >
          <b-button
            class="mt-0"
            @click="selectFilmType(0)"
            :variant="isOldFilm === 0 ? 'primary' : ''"
            >新電影記錄</b-button
          >
          <b-button
            class="mt-0"
            @click="selectFilmType(1)"
            :variant="isOldFilm === 1 ? 'primary' : ''"
            >舊電影記錄</b-button
          >
        </b-button-group>
        <b-button size="sm" variant="success" @click="openPosid"
          >搜尋 POS ID</b-button
        >
      </div>
      <div class="search">
        <div style="width: 40%">
          <b-form-input
            style="width: 100%"
            type="text"
            placeholder="Search Film (e.g.: M-film master id ,F-film id,M-O-film master id)"
            v-model="searchQuery.search"
            @input="handleSearchChange"
            @change="searchFilmMaster"
          />
        </div>

        <b-col>
          <el-select
            ref="blurSelect"
            v-model="searchQuery.location"
            clearable
            placeholder="All locations"
            @change="enterSearch"
          >
            <el-option
              v-for="item in selectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
              <div>{{ item.label }}</div>
            </el-option>
          </el-select>
        </b-col>
        <b-col>
          <el-date-picker
            style="width: 200px; height: 36px"
            v-model="searchQuery.pickYear"
            type="year"
            placeholder="Year"
            format="yyyy"
            value-format="yyyy"
            class="datePick"
            @change="enterSearch"
          >
          </el-date-picker>
        </b-col>
        <b-col
          ><b-button
            ref="enterBtn"
            :autofocus="autofocus"
            variant="primary"
            @click="searchFilmMaster"
          >
            Search
          </b-button></b-col
        >
      </div>
      <div style="color: red">
        （資料搜尋:
        <span v-if="isOldFilm">舊電影記錄</span>
        <span v-else-if="isOldFilm === 0">新電影記錄</span>
        <span v-else>所有電影</span>
        <span v-if="searchQuery.search"> / {{ searchQuery.search }} </span>
        <span v-if="searchQuery.location"> / {{ searchQuery.location }} </span>
        <span v-if="searchQuery.pickYear"> / {{ searchQuery.pickYear }} </span>
        ）
      </div>
      <b-table
        bordered
        striped
        responsive
        hover
        :items="items"
        :fields="
          $store.state.highlightCol.firTable
            ? $store.state.newTableFields
            : fields
        "
        @head-clicked="
          commonHighLightCol(
            $event,
            fields,
            $store.state.newTableFields,
            'firTable'
          )
        "
      >
        <template #head()="data">
          <div
            v-if="data.field.sortKey"
            class="cursor-pointer"
            @click="orderHead(data.field.sortKey)"
          >
            {{ data.field.label }}
          </div>
          <div v-else>{{ data.field.label }}</div>
        </template>
        <template #cell(id)="data">
          <div @click="editRecord(data.item, 'review')">
            {{ data.item.id + "(" + data.item.run_count + ")" }}
          </div>
        </template>
        <template #cell(name_en)="data">
          <div style="width: 220px" @click="editRecord(data.item, 'review')">
            <span size="sm" class="mr-2 table-name">
              {{ data.item.name_en }}
            </span>
          </div>
        </template>
        <template #cell(name_cn)="data">
          <div style="width: 220px" @click="editRecord(data.item, 'review')">
            <span size="sm" class="mr-2 table-name">
              {{ data.item.name_cn }}
            </span>
          </div>
        </template>
        <template #cell(release_date)="data">
          <div
            v-if="
              data.item.release_date !== '0000-00-00' && data.item.release_date
            "
          >
            {{ isValidDate(data.item.release_date) }}
          </div>
          <div v-else>-</div>
        </template>
        <template #cell(paint_date)="data">
          <div
            v-if="data.item.paint_date !== '0000-00-00' && data.item.paint_date"
          >
            {{
              isValidDate(
                dateOffFormat(data.item.paint_date, data.item.release_date)
              )
            }}
          </div>
          <div v-else>-</div>
        </template>
        <template #cell(action)="data">
          <b-button
            size="sm"
            class="mr-2"
            @click="editRecord(data.item, 'view')"
          >
            檢視及修訂
          </b-button>
          {{ "(" + data.item.film_count + ")" }}
        </template>
      </b-table>
      <div class="pagination mt-3">
        <el-pagination
          class=""
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[20, 50, 100]"
          :page-size="rows"
          layout=" sizes, prev, pager, next"
          :total="pageTotal"
        >
        </el-pagination>
      </div>
      <p>紅色highligh代表此電影為刪除狀態</p>
      <b-modal
        size="lg"
        id="add-pos-id"
        hide-footer
        centered
        title="搜尋 POS ID"
      >
        <div class="my-2" v-loading="loadingAddPosid">
          <div class="d-flex mb-1">
            <el-input
              size="small"
              autocomplete
              v-model="add_pos_id"
              @change="checkPosid"
              placeholder="Enter POS ID"
            ></el-input>
            <b-button
              class="ml-1"
              @click="checkPosid"
              variant="primary"
              size="sm"
            >
              Search
            </b-button>
          </div>
          <div v-if="usedPosidItems && usedPosidItems.length > 0">
            <b-table
              striped
              hover
              :items="usedPosidItems"
              :fields="usedPosidFields"
            >
              <template #cell(action)="data">
                <div>
                  <b-button
                    @click="goChangePage(data.item)"
                    variant="light"
                    size="sm"
                    v-b-tooltip.hover
                    title="修改POS ID"
                  >
                    <b-icon font-scale="1" icon="chevron-right"></b-icon>
                  </b-button>
                </div>
              </template>
            </b-table>
          </div>
        </div>
        <div class="float-right">
          <b-button @click="cancelPosid" size="sm">取消</b-button>
        </div>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import { getFilmMasterApi } from "@/api/film-material-management/filmMaster"
import { getManageProperties } from "@/api/properties"
import { checkPosid } from "@/api/management.js"
import { getCircuitList } from "@/api/circuit"
export default {
  components: {},
  data() {
    return {
      isOldFilm: "",
      autofocus: false,
      loading: false,
      title: "電影記錄",
      name: "Film Master",
      createBtn: "開立新電影記錄",
      select: "",
      selectOptions: [],
      currentPage: 1,
      fields: [
        {
          key: "id",
          label: "Film Master ID (RUN)",
          sortKey: "id",
        },
        {
          key: "name_en",
          label: "Film Name",
          sortKey: "name_english",
        },
        {
          key: "name_cn",
          label: "電影名稱",
          sortKey: "name_chinese",
        },
        {
          key: "origin",
          label: "Country",
          sortKey: "country",
        },
        {
          key: "release_date",
          label: "date release",
          sortKey: "film.0.date_release",
        },
        {
          key: "paint_date",
          label: "date off",
          sortKey: "film.0.date_off",
        },
        {
          key: "action",
          label: "",
        },
      ],
      items: [],
      pageTotal: 10,
      rows: 20,
      DatePickerFormat: "yyyy",
      searchQuery: {
        search: "",
        location: "",
        pickYear: "",
        sort: "id",
        direct: "desc", //asc
      },
      loadingAddPosid: false,
      circuits: [],
      add_pos_id: "",
      usedPosidItems: [],
      usedPosidFields: [
        { key: "film_id", label: "Film ID" },
        { key: "system_name", label: "在系統中的電影名字" },
        { key: "title_name", label: "Film Name" },
        { key: "film.ver_name", label: "Version" },
        { key: "circuit_name", label: "Circuit Name" },
        { key: "action", label: "Action" },
      ],
    }
  },
  methods: {
    goChangePage(item) {
      const search_split = this.getFilmSearchName(item)
      const filmInfo = {
        circuit: item.circuit_name,
        circuit_id: item.circuit_id,
        pos_id: item.pos_id,
        title_name: item.title_name,
        search_name: search_split,
        id: item.id,
        goPage: this.$route.fullPath,
        film_id: item.film_id,
        type: "notfound",
      }
      sessionStorage.setItem("filmInfo", JSON.stringify(filmInfo))
      this.$nextTick(() => {
        this.$router.push({
          name: "revise-record-add",
          query: {
            pos_id: item.pos_id,
            master_id: item.master_id,
            search_name: search_split,
            circuit_id: item.circuit_id,
            id: item.id,
          },
        })
      })
    },
    cancelPosid() {
      this.add_pos_id = ""
      this.$bvModal.hide("add-pos-id")
      this.loadingAddPosid = false
    },
    getCircuitList() {
      const data = {
        page: 1,
        count: 10000,
      }
      getCircuitList(data)
        .then((res) => {
          const list = []
          res.data.map((el) => {
            list.push({ text: el.full_name, value: el.circuit_key, id: el.id })
          })
          this.circuits = list
        })
        .catch((error) => {
          console.log(error)
        })
    },
    openPosid() {
      this.$bvModal.show("add-pos-id")
      this.add_pos_id = ""
      this.usedPosidItems = []
    },
    checkPosid() {
      this.loadingAddPosid = true
      const data = {
        pos_id: this.add_pos_id,
      }
      console.log(data)
      checkPosid(data).then((res) => {
        this.usedPosidItems = res.data.list
        this.usedPosidItems.map((item) => {
          item.system_name =
            item.film && item.film.title_name ? item.film.title_name : "-"
        })
        this.loadingAddPosid = false
      })
    },
    selectFilmType(val) {
      this.isOldFilm = val
      this.getFilMasterList()
    },
    getManageProperties() {
      getManageProperties("film_country")
        .then((res) => {
          const list = []
          console.log(res, "res")
          res.data.forEach((item) => {
            list.push({
              value: item.name_en,
              label: `${item.name_en}[${item.name_zh_hk}]`,
            })
          })
          list.push(
            { value: "No Record", label: "No Record" },
            { value: "Unknown", label: "Unknown" }
          )
          this.selectOptions = list
        })
        .catch((error) => {
          console.log("error")
        })
    },
    enterSearch() {
      this.$nextTick(() => {
        this.$refs.blurSelect.blur()
        this.$refs.enterBtn.focus()
      })

      // this.getFilMasterList()
    },
    orderHead(key) {
      console.log(key)
      this.searchQuery.sort = key
      if (this.searchQuery.direct === "desc") {
        this.searchQuery.direct = "asc"
      } else {
        this.searchQuery.direct = "desc"
      }
      this.getFilMasterList()
    },
    searchFilmMaster() {
      this.currentPage = 1
      this.getFilMasterList()
    },
    datePickerChange(value) {
      console.log(value)
      if (!value) {
        this.getFilMasterList()
      }
    },
    handleSizeChange(val) {
      this.rows = val
      this.getFilMasterList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getFilMasterList()
    },
    handleSearchChange(val) {
      this.searchQuery.search = val
    },
    handleLocationChange(val) {
      console.log(val)
      // z
    },
    handleSearch() {
      this.currentPage = 1
      this.getFilMasterList()
    },
    newFilm() {
      this.$router.push({ path: "master-record", query: { type: "create" } })
    },
    getFilMasterList() {
      this.loading = true
      const val =
        this.searchQuery.location === "All locations"
          ? ""
          : this.searchQuery.location
      this.searchQuery.location = val
      this.searchQuery.pickYear =
        this.searchQuery.pickYear == null ? "" : this.searchQuery.pickYear
      const data = {
        page: this.currentPage,
        count: this.rows,
        country: this.searchQuery.location,
        search: this.searchQuery.search,
        year: this.searchQuery.pickYear,
        sort: this.searchQuery.sort,
        direct: this.searchQuery.direct,
        arch: this.isOldFilm,
      }
      getFilmMasterApi(data)
        .then((res) => {
          console.log(res)
          this.items = res.data.data
          this.pageTotal = res.data.total
          const arr = []
          this.items.forEach((el) => {
            el.date_release =
              el.film && el.film.length > 0 ? el.film[0].date_release : ""
            el.date_off =
              el.film && el.film.length > 0 ? el.film[0].date_off : ""
            // const date_off = el.date_off
            //   ? this.dateOffFormat(el.date_off, el.date_release)
            //   : ''

            const obj = new Object()
            obj.id = el.id
            obj.name_cn = el.name_chinese
            obj.name_en = el.name_english
            obj.origin = el.country
            obj.film_count = el.film_count
            obj.run_count = el.run_count
            obj.status = el.status
            obj.release_date = el.date_release
            obj.release_date_cal =
              el.film && el.film.length > 0 ? el.date_release : ""
            obj.paint_date = el.date_off
            if (
              el.status.includes("Delete") ||
              el.status.includes("delete") ||
              el.status.includes("del") ||
              el.status.includes("Del")
            ) {
              obj._rowVariant = "danger"
            }
            arr.push(obj)
          })
          this.items = arr
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    },
    editRecord(item, istype) {
      this.isId = item.id
      if (istype === "edit") {
        this.$router.push({
          path: "film-record-detail",
          query: { type: "edit", id: this.isId },
        })
      } else {
        this.$router.push({
          path: "film-record-detail",
          query: { type: "review", id: this.isId },
        })
      }
    },
    search() {
      this.$emit("handleSearch")
    },
  },
  mounted() {
    this.getManageProperties()
    this.getFilMasterList()
    this.getCircuitList()
    this.searchQuery.location = this.select
  },
  computed: {
    dataListStart() {
      if (this.rows === 5) {
        return (this.currentPage - 1) * 5 + 1
      } else {
        return (this.currentPage - 1) * 2 * (this.rows / 2) + 1
      }
    },
    dataListStop() {
      if (this.currentPage === Math.ceil(this.pageTotal / this.rows)) {
        return this.pageTotal
      } else {
        return this.rows * this.currentPage
      }
    },
  },
}
</script>
<style lang="scss">
.datePick {
  .el-input__inner {
    height: 38px;
  }
}
</style>
<style lang="scss" scoped>
.tip {
  color: #0e72bc;
}
.search {
  display: flex;
  margin: 15px 0;
}
.table-name:hover {
  text-decoration: underline;
}
</style>
