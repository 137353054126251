var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"xml-related-record"},[_c('div',{staticClass:"title"},[_c('p',[_vm._v("XML檔在資料庫的相關記錄")])]),_c('b-button',{attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("上一頁")]),_c('div',{staticClass:"sec-title"},[_vm._v(" 包括在 "+_vm._s(_vm.XMLDetails.file_name)+" (XML檔案篇號："+_vm._s(_vm.$route.query.file_id)+")之記錄 ")]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.headertLoading),expression:"headertLoading"}]},_vm._l((_vm.items),function(item,index){return _c('b-row',{key:index,staticClass:"my-1"},[_c('b-col',{staticClass:"d-flex justify-content-end bold",attrs:{"sm":"3"}},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.val !== 'number_of_record' && item.type === 'date')?_c('b-col',{attrs:{"sm":"3"}},[_vm._v(" "+_vm._s(_vm.$moment(_vm.XMLDetails[item.val]).format("DD-MM-YYYY HH:mm:ss"))+" ")]):(item.val !== 'number_of_record' && item.type !== 'date')?_c('b-col',{attrs:{"sm":"3"}},[_vm._v(" "+_vm._s(_vm.XMLDetails[item.val])+" ")]):_c('b-col',{attrs:{"sm":"3"}},[(_vm.total)?_c('span',[_vm._v(_vm._s(_vm.total || 0))]):_vm._e()])],1)}),1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoading),expression:"tableLoading"}]},[_c('b-table',{attrs:{"bordered":"","tbody-tr-class":_vm.rowClass,"striped":"","hover":"","responsive":"","items":_vm.tableData,"fields":_vm.$store.state.highlightCol.firTable
            ? _vm.$store.state.newTableFields
            : _vm.tableFields},on:{"head-clicked":function($event){return _vm.commonHighLightCol(
            $event,
            _vm.tableFields,
            _vm.$store.state.newTableFields,
            'firTable'
          )}},scopedSlots:_vm._u([{key:"thead-top",fn:function(data){return [_c('b-tr',[_c('b-th',{attrs:{"colspan":"9"}}),_c('b-th',[_vm._v(" total ADM :"+_vm._s(_vm.totalVal("total_admissions")))]),_c('b-th',[_vm._v(" total B.O. :"+_vm._s(_vm.totalVal("total_gross")))])],1)]}},{key:"cell(circuit)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(data.item.circuit_name)+" ")])]}},{key:"cell(date_of_engagement)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$moment(data.item.date_of_engagement).format())+" ")])]}},{key:"cell(show_time)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$moment(data.item.show_time).format("DD-MM-YYYY HH:mm:ss"))+" ")])]}},{key:"cell(total_admissions)",fn:function(data){return [_c('div',[_vm._v(" "+_vm._s(parseFloat(data.item.total_admissions).toLocaleString())+" ")])]}},{key:"cell(total_gross)",fn:function(data){return [_c('div',[_vm._v(" "+_vm._s(parseFloat(data.item.total_gross).toLocaleString())+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }