<template>
  <div class="uncertan-page">
    <div class="title d-flex">
      <p>{{ title }}</p>
      <p>查閲：最近一小時之所有未確認總數</p>
    </div>
    <b-button
      size="sm"
      @click="$router.push({ name: 'check-upload-record' })"
      class="mb-1"
      style="margin-left: 8px"
      variant="outline-dark"
      >回目錄</b-button
    >

    <div style="display: flex; align-items: center">
      <b-form-checkbox
        id="checkbox-showHiddenMovie-16.12"
        v-model="showHiddenMovie"
        @change="getLastHourUncertainRecord(showHiddenMovie)"
      >
        不顯示隱藏戲院記錄
      </b-form-checkbox>
    </div>
    <div class="mt-1 mb-1" v-loading="loading">
      <b style="color: #0e72bc" class="mt-1">
        List Theatre B.O. Records in
        <span style="color: red">{{ $moment(val).format() }}</span>
        <span style="color: red"
          >(Engt.Date:{{ $moment(engt_date).format() }})</span
        >
        <span
          >(UnOfficial Total on all records received within in this day,until
          the last hour.)</span
        >
      </b>
      <b-table
        bordered
        striped
        hover
        responsive
        :items="tableData"
        :fields="
          $store.state.highlightCol.firTable
            ? $store.state.newTableFields
            : tableFields
        "
        @head-clicked="
          commonHighLightCol(
            $event,
            tableFields,
            $store.state.newTableFields,
            'firTable'
          )
        "
      >
        <template #cell()="data">
          <div v-if="data.field.numType" class="text-nowrap">
            {{ parseFloat(Number(data.value)).toLocaleString() }}
          </div>
          <div v-else>{{ data.value }}</div>
        </template>
        <template #cell(theatre)="data">
          <div style="width: 220px">
            {{ data.item.theatre }}
          </div>
        </template>
        <template #cell(date_upload_ref)="data">
          <div class="text-nowrap">
            {{
              $moment(data.item.date_upload_ref).format(
                "DD-MM-YYYY HH:mm:ss"
              ) === "Invalid date"
                ? "-"
                : $moment(data.item.date_upload_ref).format(
                    "DD-MM-YYYY HH:mm:ss"
                  )
            }}
          </div>
        </template>
      </b-table>
    </div>
    <div class="mt-3" v-loading="loading">
      <b style="color: #0e72bc" class="mt-1">
        List Movie B.O. Records in
        <span style="color: red">{{ $moment(val).format() }}</span>
        <span style="color: red"
          >(Engt.Date:{{ $moment(engt_date).format() }})</span
        >
        <span
          >(UnOfficial Total on all records received within in this day,until
          the last hour.)</span
        >
      </b>
      <b-table
        bordered
        class="mt-1"
        striped
        hover
        responsive
        :items="movieData"
        :fields="
          $store.state.highlightCol.secTable
            ? $store.state.newSecTableFields
            : movieFields
        "
        @head-clicked="
          commonHighLightCol(
            $event,
            movieFields,
            $store.state.newSecTableFields,
            'secTable'
          )
        "
      >
        <template #head()="data">
          <div v-html="data.label"></div>
        </template>
        <template #cell()="data">
          <div v-if="data.field.numType" class="text-nowrap">
            {{ parseFloat(Number(data.value)).toLocaleString() }}
          </div>
          <div v-else>{{ data.value }}</div>
        </template>
        <template #cell(film_title_name)="data">
          <div style="width: 250px" v-html="data.item.film_title_name"></div>
        </template>
        <template #cell(film_effect)="data">
          <div class="text-nowrap">
            {{ data.item.film_effect }}
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { getLastHourUncertainRecord } from "@/api/record.js"

export default {
  props: {
    title: {
      type: String,
    },
    title_num: {
      type: String,
    },
    val: {
      type: String,
    },
    val1: {
      type: String,
    },
    titleText: {
      type: String,
    },
    uploadDate: {
      type: String,
    },
    filmIDList: {
      type: String,
    },
    searchType: {
      type: String,
    },
  },
  data() {
    return {
      movieData: [],
      tableData: [],
      loading: false,
      select: "All Location",
      selectOptions: [
        { value: "All Location", text: "全部地區" },
        { value: "Hong Kong", text: "香港" },
        { value: "Macau", text: "澳門" },
      ],
      showHiddenMovie: true,
    }
  },
  computed: {
    engt_date() {
      const d = new Date(this.val)
      if (d.getHours() > 5 && d.getHours() <= 23) {
        return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
      } else if (d.getHours() <= 5) {
        return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate() - 1}`
      } else return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
    },
    movieFields() {
      return [
        { key: "film_title_name", label: "Movie Name" },
        // { key: "film_effect", label: "" },
        {
          key: "up_to_date_bo",
          label: `Up to Date B.O. Until ${this.$moment(
            this.val
          ).format()} <span style="color:red">(UnOfficial !!)</span>`,
          numType: true,
        },
        {
          key: "pre_adm_day_final",
          label: "Pre-Sale Adm. In Day Final",
          numType: true,
        },
        {
          key: "pre_bo_day_final",
          label: "Pre-Sale B.O. In Day Final",
          numType: true,
        },
        {
          key: "last_adm_hourly_xml",
          label: "Last Adm. In hourly XML",
          numType: true,
        },
        {
          key: "last_bo_hourly_xml",
          label: "Last B.O. In hourly XML",
          numType: true,
        },
        {
          key: "daily_total_adm",
          label: `Daily Total Adm.<span style="color:red">(UnOfficial !)</span>`,
          numType: true,
        },
        {
          key: "daily_total_bo",
          label: `Daily Total B.O.<span style="color:red">(UnOfficial !)</span>`,
          numType: true,
        },
        { key: "last_theatre", label: "H23 Theatre", numType: true },
        { key: "last_screens", label: "H23 House", numType: true },
        { key: "last_shows", label: "H23 Shows", numType: true },
      ]
    },
    tableFields() {
      return [
        { key: "theatre", label: "Theatre Name", sortable: true },
        { key: "date_upload_ref", label: "Date Upload Ref.", sortable: true },
        {
          key: "pre_adm_day_final",
          label: "Pre-Sale Adm. In Day Final",
          sortable: true,
          numType: true,
        },
        {
          key: "pre_bo_day_final",
          label: "Pre-Sale B.O. In Day Final",
          sortable: true,
          numType: true,
        },
        {
          key: "last_adm_hourly_xml",
          label: "Last Adm. In hourly XML",
          sortable: true,
          numType: true,
        },
        {
          key: "last_bo_hourly_xml",
          label: "Last B.O. In hourly XML",
          sortable: true,
          numType: true,
        },
        {
          key: "adm_final_xml",
          label: "Total Adm.",
          sortable: true,
          numType: true,
        },
        {
          key: "bo_final_xml",
          label: "Total B.O.",
          sortable: true,
          numType: true,
        },
      ]
    },
  },
  methods: {
    //get 16.12 data
    getLastHourUncertainRecord(showHiddenMovie) {
      console.log(this.showHiddenMovie, "this.showHiddenMovie")

      this.loading = true
      const data = {
        area: sessionStorage.getItem("area"),
        without_hidden: this.showHiddenMovie,
      }
      getLastHourUncertainRecord(data)
        .then((res) => {
          res.data.theatreTable.map((el) => {
            el.adm_final_xml =
              Number(el.last_adm_hourly_xml) + Number(el.pre_adm_day_final)
            el.bo_final_xml =
              Number(el.last_bo_hourly_xml) + Number(el.pre_bo_day_final)
          })
          this.tableData = res.data.theatreTable
          const list = []
          const movieList = res.data.movieTable
          console.log(movieList)
          movieList.map((el) => {
            el.film_effect = ""
            if (el.length === 1) {
              console.log(el[0])
              const preshow =
                this.$moment() < this.$moment(el[0].film_date_release)
                  ? "（優先場）"
                  : ""
              const remark = el[0].film_remark_2
                ? `${el[0].film_remark_1} (${el[0].film_remark_2})`
                : el[0].film_remark_1
              el[0]._rowVariant = "warning"
              el[0].film_title_name = `${el[0].film_title_name} <span style="color:red">${preshow}</span> <br/> <div style="font-size:10px">${remark} ${el[0].film_language} [${el[0].film_run_ver}]</div>`
              list.push(el[0])
            } else {
              const preshow =
                this.$moment() < this.$moment(el[0].film_date_release)
                  ? "（優先場）"
                  : ""
              const totalList = new Object()
              totalList.up_to_date_bo = el.reduce(
                (sum, item) => sum + item.up_to_date_bo,
                0
              )
              totalList.daily_total_adm = el.reduce(
                (sum, item) => sum + item.daily_total_adm,
                0
              )
              totalList.daily_total_bo = el.reduce(
                (sum, item) => sum + item.daily_total_bo,
                0
              )
              totalList.last_shows = el.reduce(
                (sum, item) => sum + item.last_shows,
                0
              )
              totalList.last_theatre = el[0].total_theatre
              totalList.last_screens = el[0].total_screens
              totalList.film_title_name = `${el[0].film_title_name} <span style="color:red">${preshow}</span>`
              totalList.film_date_release = el[0].film_date_release
              totalList._rowVariant = "warning"
              totalList.pre_adm_day_final = el.reduce(
                (sum, item) => sum + item.pre_adm_day_final,
                0
              )
              totalList.pre_bo_day_final = el.reduce(
                (sum, item) => sum + item.pre_bo_day_final,
                0
              )
              totalList.last_adm_hourly_xml = el.reduce(
                (sum, item) => sum + item.last_adm_hourly_xml,
                0
              )
              totalList.last_bo_hourly_xml = el.reduce(
                (sum, item) => sum + item.last_bo_hourly_xml,
                0
              )
              totalList.adm_final_xml = el.reduce(
                (sum, item) => sum + item.adm_final_xml,
                0
              )
              totalList.bo_final_xml = el.reduce(
                (sum, item) => sum + item.bo_final_xml,
                0
              )
              list.push(totalList)
              el.map((li) => {
                const effect = li.film_remark_2
                  ? `${li.film_remark_1} (${li.film_remark_2})`
                  : li.film_remark_1
                // li.film_title_name = "";
                li.film_title_name = `${effect} ${li.film_language} [${li.film_run_ver}]`

                list.push(li)
              })
            }
          })
          console.log(list, "list--------------")
          this.movieData = list
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
  mounted() {
    this.getLastHourUncertainRecord()
  },
}
</script>
<style lang="scss">
.uncertan-page {
  .b-table {
    thead {
      tr {
        th {
          div {
            //  white-space: normal;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.sec-title {
  color: #0e72bc;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid #0e72bc;
}
.w300 {
  width: 250px;
}
</style>
