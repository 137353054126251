var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('div',{staticClass:"title"},[_c('p',[_vm._v("人手鍵入票房記錄")])]),_c('p',{staticClass:"tip"},[_vm._v("未能由系統輸入票房記錄之戲院")]),_c('b-table',{attrs:{"bordered":"","striped":"","hover":"","items":_vm.theatreList,"fields":_vm.$store.state.highlightCol.firTable
          ? _vm.$store.state.newTableFields
          : _vm.fields},on:{"head-clicked":function($event){return _vm.commonHighLightCol(
          $event,
          _vm.fields,
          _vm.$store.state.newTableFields,
          'firTable'
        )}},scopedSlots:_vm._u([{key:"cell(theatre_name)",fn:function(row){return [_c('b',{staticClass:"theatre-name",on:{"click":function($event){return _vm.editRecord(row.item)}}},[_vm._v(_vm._s(row.item.theatre_name))])]}},{key:"cell(action)",fn:function(data){return [_c('b-button',{staticClass:"mr-2",staticStyle:{"width":"125px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.addRecord(data.item.id)}}},[_vm._v(" 輸入票房記錄 ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }