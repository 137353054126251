<template>
  <div>
    <b-card>
      <div class="attendance-header">
        <div class="title">
          <p>查閱XML上傳檔 (By theatre)</p>
        </div>
        <div>
          <b-form-select
            style="width: 220px"
            class="mb-1"
            v-model="selectTheatres"
            :options="optionTheatres"
          ></b-form-select>
        </div>
      </div>
      <div class="date-pick">
        <div class="d-flex date-box">
          <div class="from-date">
            <label for="example-datepicker">From</label>
            <datePickerCom
              class="mb-2"
              :pickTimeVal.sync="fromDate"
              :inputWidth="'200px'"
              @getRecords="getRecords"
            />
          </div>
          <div class="to-date">
            <label for="example-datepicker">To</label>
            <datePickerCom
              v-if="showToDate"
              class="mb-2"
              :pickTimeVal.sync="toDate"
              :pickerOptions="{ disabledDate }"
              :inputWidth="'200px'"
            />
          </div>
          <b-button class="ml-2" variant="primary" @click="getXmlByTheatre">
            Search
          </b-button>
        </div>
        <b-button
          variant="outline-secondary"
          class="deleteBtn"
          @click="deleteRecord()"
        >
          過往刪除記錄
        </b-button>
        <!-- <b-button variant="outline-secondary" class="btn">
          觀看XML原檔記錄
        </b-button> -->
      </div>

      <b-table
        v-if="showTable"
        bordered
        striped
        hover
        responsive
        :items="tableData"
        :fields="
          $store.state.highlightCol.firTable
            ? $store.state.newTableFields
            : tableFields
        "
        @head-clicked="
          commonHighLightCol(
            $event,
            tableFields,
            $store.state.newTableFields,
            'firTable'
          )
        "
      >
        <template #head(action)>
          <div class="d-flex align-items-center">
            <div>
              <b-form-checkbox @change="selectAllChange" v-model="selectAll"
                >全選</b-form-checkbox
              >
            </div>
            <b-button v-b-modal.check-treatre-modal size="sm" class="ml-2">
              刪除
            </b-button>
          </div>
        </template>

        <template #cell(action)="rowSelect">
          <b-form-checkbox
            @change="selectChange(rowSelect.item)"
            v-model="tableData[rowSelect.index].isSelect"
            :value="true"
            :unchecked-value="false"
          />
        </template>
        <template v-slot:cell(view)="row">
          <b-button size="sm" class="mr-2" @click="checkOrigin(row.item.id)">
            觀看XML原檔
          </b-button>
        </template>
      </b-table>
      <b-modal
        ref="my-check-treatre-modal"
        id="check-treatre-modal"
        hide-footer
      >
        <p class="my-2">是否確定多項刪除?</p>
        <div style="float: right">
          <b-button class="mr-1" size="sm" @click="toggleModal">確定</b-button>
          <b-button size="sm" @click="hideModal">取消</b-button>
        </div>
      </b-modal>
      <div class="pagination mt-3">
        <el-pagination
          class=""
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[20, 50, 100]"
          :page-size="rows"
          layout=" sizes, prev, pager, next"
          :total="pageTotal"
        >
        </el-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import { getXmlByTheatre, DeleteXML } from '@/api/checkXmlUpload.js'
import { getTheatreList } from '@/api/theatre.js'
import datePickerCom from '@/views/components/datePicker.vue'

export default {
  components: { datePickerCom },
  data() {
    return {
      rows: 20,
      page: 1,
      pageTotal: 0,
      showToDate: true,
      showTable: true,
      delectItemList: [],
      selectAll: false,
      toDate: '',
      fromDate: '',
      selectTheatres: '',
      optionTheatres: [{ text: '全部戲院', value: '' }],
      tableData: [],
      tableFields: [
        { key: 'file_name', label: 'XML' },
        { key: 'time', label: 'File Date' },
        { key: 'view', label: '' },
        { key: 'action', label: '刪除' }
      ]
    }
  },
  watch: {
    fromDate(val) {
      if (val) {
        const maxDate = new Date(val)
        maxDate.setDate(maxDate.getDate() + 20)
        this.toDate = maxDate
        console.log(this.toDate)
      }
    }
  },
  computed: {
    searchName() {
      if (this.selectAttendance === '入座率資料(By film)') {
        return 'Search for film'
      } else if (this.selectAttendance === '入座率資料(By house)') {
        return 'Search for house'
      } else {
        return false
      }
    }
  },
  methods: {
    handleSizeChange(val) {
      this.rows = val
      this.getActorList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getActorList()
    },
    getRecords(val, type) {
      let isDate = val
      if (type === 'changeDate' && val) {
        const year = val.slice(6, 10)
        const month = val.slice(3, 5)
        const date = val.slice(0, 2)
        isDate = `${year}-${month}-${date}`
      }
      if (val) {
        this.fromDate = isDate
        this.showToDate = false
        this.$nextTick(() => {
          this.showToDate = true
        })
      }
    },
    disabledDate(time) {
      // time.getTime() < Date.now() - 8.64e7 禁用不包含當前日期
      // time.getTime() > Date.now() - 8.64e7 禁用包含當前日期
      return (
        time.getTime() < new Date(this.fromDate).getTime() ||
        time.getTime() > new Date(this.toDate).getTime()
      )
    },
    getTheatreList() {
      const data = {
        page: 1,
        count: 300
      }
      getTheatreList(data).then((res) => {
        res.data.map((el) => {
          this.optionTheatres.push({ text: el.full_name, value: el.id })
        })
        console.log(res)
      })
    },
    checkOrigin(id) {
      this.$router.push({
        path: '/boxoffice/original-record-file',
        query: { id }
      })
    },
    setDate() {
      //set date
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const minDate = new Date(today)
      minDate.setDate(minDate.getDate())
      const maxDate = new Date(today)
      maxDate.setDate(maxDate.getDate() + 20)
      const fromDate = new Date(today)
      fromDate.setDate(fromDate.getDate() - 20)
      this.fromDate = fromDate
      this.toDate = maxDate
    },
    hideModal() {
      this.$refs['my-check-treatre-modal'].hide()
    },
    toggleModal() {
      console.log('確定')
      this.delectXML()
    },
    selectChange(item) {
      console.log(item)
      if (item.isSelect) {
        this.delectItemList.push(item.id)
      } else {
        this.delectItemList.map((el, index) => {
          if (el === item.id) {
            this.delectItemList.splice(index, 1)
          }
        })
      }
      if (this.delectItemList.length === this.tableData.length) {
        this.selectAll = true
      } else {
        this.selectAll = false
      }
    },
    selectAllChange(checked) {
      this.showTable = false
      this.selectAll = checked
      if (this.selectAll) {
        this.tableData.map((el) => {
          el.isSelect = true
          this.delectItemList.push(el.id)
        })
      } else {
        this.tableData.map((el) => {
          this.delectItemList = []
          el.isSelect = false
        })
      }
      this.$nextTick(() => {
        this.showTable = true
      })
    },
    getDateVal(dateVal) {
      const newDate = new Date(dateVal)
      const year = newDate.getFullYear()
      let month = newDate.getMonth() + 1
      if (month < 10) {
        month = `0${month}`
      }
      let date = newDate.getDate()
      if (date < 10) {
        date = `0${date}`
      }
      const isDate = `${year}-${month}-${date}`
      return isDate
    },
    getXmlByTheatre() {
      const data = {
        date_start: this.getDateVal(this.fromDate),
        date_end: this.getDateVal(this.toDate),
        theatre_id: this.selectTheatres,
        page: this.page,
        count: this.rows
      }
      getXmlByTheatre(data).then((res) => {
        this.tableData = res.data.data
        this.tableData.map((el) => {
          el.isSelect = false
        })
        this.pageTotal = res.data.total
      })
    },
    delectXML() {
      console.log(this.delectItemList)
      const list = []
      this.delectItemList.map((el) => {
        list.push('el')
      })
      const data = { ids: this.delectItemList }
      console.log(data)
      DeleteXML(data)
        .then((res) => {
          this.$router.go(0)
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    },
    goDetail(id) {
      const query = {
        id: id || null
      }
      this.$router.push({ path: '/dashboard/circuit-detail', query })
    },
    deleteRecord() {
      this.$router.push({
        name: 'check-xml-delete',
        query: { theatre_id: this.selectTheatres }
      })
    }
  },
  created() {
    this.setDate()
    this.getTheatreList()
    this.getXmlByTheatre()
  }
}
</script>

<style lang="scss" scoped>
.date-pick {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .date-box {
    justify-content: flex-start;
    align-items: center;
  }
  .from-date {
    margin-right: 20px;
  }
}
.search {
  display: flex;
  margin-bottom: 20px;
}
.deleteBtn {
  margin-top: 20px;
  margin-right: 50px;
  float: right;
}
</style>
