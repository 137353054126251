<template>
  <div class="attendanceDetails">
    <b-card v-loading="loading">
      <div class="title">入座率詳情</div>
      <div class="mt-1 mb-1">
        <b-button size="sm" variant="outline-secondary" @click="$router.go(-1)"
          >返回上一頁</b-button
        >
      </div>
      <p>
        <b>{{ queryVal.dateStart }} ~ {{ queryVal.dateEnd }}</b>
      </p>
      <p>
        <b>{{ queryVal.titleName }}</b>
      </p>
      <b-table striped hover :items="items" :fields="fields">
        <template #cell(show)="data">
          <div class="text-nowrap">
            {{ parseFloat(Number(data.value)).toLocaleString() }}
          </div>
        </template>
        <template #cell(ta)="data">
          <div class="text-nowrap">
            {{ parseFloat(Number(data.value)).toLocaleString() }}
          </div>
        </template>
        <template #cell(tg)="data">
          <div class="text-nowrap">
            {{ parseFloat(Number(data.value)).toLocaleString() }}
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { getOccupancyByFilm, getOccupancyByTheatre } from "@/api/occupancy.js";
export default {
  name: "",
  data() {
    return {
      loading: false,
      queryVal: "",
      items: [],
      fields: [
        { label: "Title Name", key: "title_name" },
        { label: "戲院-no. of show", key: "show" },
        { label: "戲院-期間人次", key: "ta" },
        { label: "戲院-期間票房", key: "tg" },
        { label: "戲院-入座率", key: "occupancy" }
      ]
    };
  },
  components: {},
  created() {
    this.queryVal = this.$route.query;
    if (this.queryVal.type === "theatre") {
      this.getOccupancyByTheatre();
    } else {
      this.getOccupancyByFilm();
    }
  },
  mounted() {},
  methods: {
    getOccupancyByTheatre() {
      this.loading = true;
      const data = {
        date_start: this.queryVal.dateStart,
        date_end: this.queryVal.dateEnd,
        search: "",
        area: "",
        film_id: "",
        film_master_id: this.queryVal.id,
        page: 1,
        count: 10000
      };
      getOccupancyByTheatre(data)
        .then((res) => {
          const list = [];
          res.data.map((el) => {
            list.push({
              title_name: el.theatre.full_name,
              show: el.num_show,
              ta: el.admissions_total,
              tg: el.gross,
              occupancy: el.occupancy
            });
          });
          this.items = list;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    getOccupancyByFilm() {
      this.loading = true;
      const data = {
        date_start: this.queryVal.dateStart,
        date_end: this.queryVal.dateEnd,
        search: "",
        theatre: this.queryVal.id,
        house: this.queryVal.houseId,
        page: 1,
        count: 1000
      };
      getOccupancyByFilm(data)
        .then((res) => {
          const dataList = [];
          res.data.forEach((el) => {
            dataList.push({
              title_name: `${el.film_master.name_chinese} * ${el.film_master.name_english}`,
              tg: el.gross,
              show: el.num_shows,
              occupancy: el.occupancy,
              ta: el.admissions_total
            });
          });
          this.items = dataList;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
