<template>
  <div>
    <b-card v-loading="tableLoading" class="attendance-data">
      <div class="attendance-header">
        <div class="title">
          <p>{{ selectAttendance }}</p>
        </div>
        <div class="attendance-select">
          <b-form-select
            style="width: 285px; margin-bottom: 10px"
            @change="changeAttendance"
            v-model="selectAttendance"
            :options="optionAttendance"
          ></b-form-select>
        </div>
      </div>
      <div class="date-pick">
        <div class="from-date">
          <label for="example-datepicker">From</label>
          <datePickerCom
            class="mb-2"
            :isDisabled="!hideBox"
            :pickTimeVal.sync="fromDate"
            :inputWidth="'200px'"
          />
        </div>
        <div class="to-date">
          <label for="example-datepicker">to</label>
          <datePickerCom
            class="mb-2"
            :pickerOptions="{ disabledDate }"
            :isDisabled="!hideBox"
            :pickTimeVal.sync="toDate"
            :inputWidth="'200px'"
          />
        </div>
        <div class="hide-checkbox">
          <b-form-checkbox
            id="hide-checkbox-1"
            v-model="hideBox"
            name="checkbox-1"
            :value="true"
            :unchecked-value="false"
          >
          </b-form-checkbox>
        </div>
      </div>
      <div class="search">
        <!-- 地區 -->
        <el-select
          v-model="areaSelect"
          :loading="areaLoading"
          loading-text="數據加載中..."
          popper-append-to-body
          popper-class="option-group"
          v-if="selectAttendance !== '入座率資料(By house)'"
          class="mr-1 search-select"
          style="width: 25%"
          placeholder="Select districts"
        >
          <el-option
            v-for="(item, index) in areaList"
            :key="index"
            :label="item.name"
            :value="item.id"
            @click.native="selectAreaChange(item.id)"
          >
          </el-option>
        </el-select>
        <!-- 戲院 -->
        <el-select
          v-if="selectAttendance !== '入座率資料(By theatre)'"
          v-model="selectCircuit"
          loading-text="數據加載中..."
          :loading="theatreLoading"
          popper-append-to-body
          popper-class="option-group"
          class="mr-1 search-select"
          style="width: 25%"
          :placeholder="selectForFilm"
        >
          <el-option
            v-for="(item, index) in newFilmList"
            :key="index"
            @click.native="selectCircuitOption(item)"
            :label="item.full_name"
            :value="
              item.circuit_key
                ? `${item.full_name}:${item.circuit_key}`
                : `${item.full_name}:${item.circuit_id}`
            "
          >
          </el-option>
        </el-select>
        <!-- 上映中或即將上映的電影(By theatre) -->
        <el-select
          v-if="selectAttendance === '入座率資料(By theatre)'"
          v-model="selectPengdingFilm"
          clearable
          loading-text="數據加載中..."
          :loading="theatreLoading"
          popper-append-to-body
          popper-class="option-group-pending"
          @change="selectPengdingChange"
          class="mr-1 search-select"
          style="width: 45%"
          :placeholder="selectForFilm"
        >
          <el-option
            v-for="(item, index) in pengdingFilmList"
            :key="index"
            @click.native="selectPengdingOption(item)"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <!-- 所有電影(By theatre && By film) -->
        <!-- <el-autocomplete
          :key="inputSelectKey"
          v-if="selectAttendance !== '入座率資料(By house)'"
          class="inline-input mr-1 all-film-list"
          v-model="inputForFilm"
          @change="inputSearchFilmChange"
          @input="inputSearchFilm"
          :fetch-suggestions="querySearch"
          placeholder="搜尋所有電影"
          style="width: 100%"
          @select="searchFilmInput"
          clearable
        ></el-autocomplete> -->
        <el-select
          v-if="selectAttendance !== '入座率資料(By house)'"
          v-model="inputForFilm"
          filterable
          clearable
          class="inline-input mr-1 all-film-list"
          style="width: 100%"
          placeholder="搜尋所有電影"
          :remote="true"
          :remote-method="remoteMethod"
          :loading="searchFilmLoading"
          @clear="clearSearchFilm"
        >
          <el-option
            v-for="option in filmList"
            :key="'film' + option.id"
            popper-append-to-body
            :label="option.value"
            :value="option.id"
          >
            <div @click="searchFilmInput(option)">{{ option.value }}</div>
          </el-option>
        </el-select>
        <b-button
          :disabled="canSearch"
          variant="primary"
          @click="searchInfo(selectAttendance)"
        >
          Search
        </b-button>
      </div>

      <b-table
        bordered
        v-show="showTabel"
        striped
        hover
        responsive
        :items="attendanceData"
        :fields="
          $store.state.highlightCol.firTable
            ? $store.state.newTableFields
            : tableFields
        "
        @head-clicked="
          commonHighLightCol(
            $event,
            tableFields,
            $store.state.newTableFields,
            'firTable'
          )
        "
      >
        <template #cell()="data">
          <div v-if="data.field.numType" class="text-nowrap">
            {{ parseFloat(Number(data.value)).toLocaleString() }}
          </div>
          <div v-else>{{ data.value }}</div>
        </template>
        <template #cell(film_name_cn)="data">
          <div style="width: 130px">
            {{ data.item.film_name_cn }}
          </div>
        </template>
        <template #cell(occupancy)="data">
          <div>
            {{ data.item.occupancy }}
          </div>
        </template>
        <template #cell(film_name_en)="data">
          <div style="width: 150px">
            {{ data.item.film_name_en }}
          </div>
        </template>
        <template #cell(up_date)="data">
          <div
            class="text-nowrap"
            :style="
              data.item.up_date === '0001-01-01' ||
              data.item.up_date === '0000-00-00'
                ? 'color:red'
                : ''
            "
          >
            {{ isValidDate(data.item.up_date) }}
          </div>
        </template>
        <template #cell(down_date)="data">
          <div class="text-nowrap">
            {{
              isValidDate(dateOffFormat(data.item.down_date, data.item.up_date))
            }}
          </div>
        </template>
        <template #cell(theatre_highest)="data">
          <div
            class="text-nowrap link-color"
            @click="goDetails(data.item, 'theatre')"
          >
            {{ parseFloat(Number(data.value)).toLocaleString() }}
          </div>
        </template>
        <template #cell(num_of_film)="data">
          <div
            class="text-nowrap link-color"
            @click="goDetails(data.item, 'film')"
          >
            {{ parseFloat(Number(data.value)).toLocaleString() }}
          </div>
        </template>
      </b-table>
      <p>紅色highligh代表有優先場</p>
    </b-card>
  </div>
</template>

<script>
import {
  getOccupancyByFilm,
  getOccupancyByHouse,
  getOccupancyByTheatre,
  selectShowFilms,
  getAreas,
  getCircuitTheatres,
  getFilmPengding,
  getFilmListOfFilm,
} from "@/api/occupancy.js"
import datePickerCom from "@/views/components/datePicker.vue"
import store from "@/store"
export default {
  components: { datePickerCom },
  data() {
    return {
      inputSelectKey: 1,
      inputForFilm: "",
      selectPengdingFilm: "",
      searchForFilm: "",
      showTabel: false,
      areaSelect: "",
      timeNow: "",
      areaLoading: false,
      searchFilmLoading: false,
      theatreLoading: false,
      searchSelectVal: "",
      hideBox: false,
      searchData: "",
      tableLoading: false,
      min: "",
      max: "",
      toDate: "",
      fromDate: "",
      pageTotal: 10,
      perPage: 5,
      rows: 10000,
      currentPage: 1,
      selectAttendance: "入座率資料(By film)",
      optionAttendance: [
        "入座率資料(By film)",
        "入座率資料(By theatre)",
        "入座率資料(By house)",
      ],
      selectCircuit: "",
      attendanceData: [],
      listParams: {
        page: 1,
        count: 10,
      },
      searchFilmList: [],
      newSearchFilmList: [],
      areaList: [],
      newFilmList: [],
      film_id: "",
      master_id: "",
      circuit_id: "",
      theatre_id: "",
      pengdingFilmList: [],
      filmListData: [],
      filmList: [],
      timer: null,
    }
  },
  computed: {
    tableFields() {
      if (this.selectAttendance === "入座率資料(By film)") {
        return [
          { key: "film_name_cn", label: "電影名稱", sortable: true },
          { key: "film_name_en", label: "Film name", sortable: true },
          { key: "up_date", label: "上映日期", sortable: true },
          { key: "down_date", label: "落畫日期", sortable: true },
          { key: "country", label: "Country", sortable: true },
          {
            key: "theatre_highest",
            label: "最高戲院數目",
            sortable: true,
            numType: true,
          },
          {
            key: "screen_highest",
            label: "最高銀幕數目",
            sortable: true,
            numType: true,
          },
          { key: "ta", label: "期間人次", sortable: true, numType: true },
          { key: "tg", label: "期間票房", sortable: true, numType: true },
          { key: "occupancy", label: "入座率", sortable: true },
        ]
      } else if (this.selectAttendance === "入座率資料(By theatre)") {
        return [
          { key: "circuit_name", label: "Circuit Name", sortable: true },
          { key: "theatre_name", label: "Theatre Name", sortable: true },
          {
            key: "num_of_film",
            label: "No. of film",
            sortable: true,
            numType: true,
          },
          {
            key: "num_of_show",
            label: "No. of show",
            sortable: true,
            numType: true,
          },
          { key: "ta", label: "期間人次", sortable: true, numType: true },
          { key: "tg", label: "期間票房", sortable: true, numType: true },
          { key: "occupancy", label: "入座率", sortable: true },
        ]
      } else {
        return [
          { key: "circuit_name", label: "Circuit Name", sortable: true },
          { key: "theatre_name", label: "Theatre Name", sortable: true },
          { key: "house_id", label: "House No.", sortable: true },
          {
            key: "num_of_film",
            label: "No. of film",
            sortable: true,
            numType: true,
          },
          {
            key: "num_of_show",
            label: "No. of show",
            sortable: true,
            numType: true,
          },
          { key: "ta", label: "期間人次", sortable: true, numType: true },
          { key: "tg", label: "期間票房", sortable: true, numType: true },
          { key: "occupancy", label: "入座率", sortable: true },
        ]
      }
    },
    canSearch() {
      if (this.selectAttendance === "入座率資料(By film)") {
        if (
          this.selectCircuit ||
          this.selectPengdingFilm ||
          this.searchForFilm ||
          this.inputForFilm
        ) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    },
    searchFilmOption() {
      return function (key, item) {
        const newlist = []
        console.log(key, item)
        key.map((el) => {
          newlist.push({
            label: `[${el}]`,
            id: item.films[el][0].master_id,
            type: "master",
          })
          item.films[el].map((child) => {
            newlist.push({
              label: ` -- (${child.remark_1}版${
                child.remark_2 ? `, ${child.remark_2}版` : ""
              }) ${child.date_release}`,
              id: child.id,
              type: "film",
            })
          })
        })
        return newlist
      }
    },
    searchName() {
      if (this.selectAttendance === "入座率資料(By film)") {
        return "Search for film"
      } else if (this.selectAttendance === "入座率資料(By theatre)") {
        return "Search for theatre"
      } else {
        return false
      }
    },
    selectForFilm() {
      if (this.selectAttendance === "入座率資料(By film)") {
        return "選擇戲院/院線"
      } else if (this.selectAttendance === "入座率資料(By theatre)") {
        return "上映或者即將上映的電影"
      } else {
        return "選擇戲院"
      }
    },
  },
  watch: {
    selectAttendance(val) {
      this.searchData = ""
      this.selectCircuit = ""
      this.film_id = ""
      this.master_id = ""
      this.circuit_id = ""
      this.theatre_id = ""
      this.areaSelect = ""
      this.selectPengdingFilm = ""
      this.searchForFilm = ""
      this.showTabel = false
      this.hideBox = false
      this.inputForFilm = ""
      this.setDate()
      if (val) {
        store.commit("updateStateData")
      }
    },
  },
  methods: {
    clearSearchFilm() {
      this.master_id = ""
      this.film_id = ""
    },
    changeAttendance(val) {
      if (val === "入座率資料(By film)") {
        this.getCircuitTheatres()
        this.getAreas()
      } else if (val === "入座率資料(By house)") {
        this.getCircuitTheatres()
      } else {
        this.getFilmPengding()
        this.getAreas()
      }
      this.$router.push({
        name: "attendance-data",
        query: { page: val },
      })
    },
    goDetails(value, type) {
      this.$router.push({
        name: "attendance-data-details",
        query: {
          id: value.id,
          houseId: value.screen_id || "",
          type,
          dateStart: this.fromDate,
          dateEnd: this.toDate,
          titleName:
            type === "theatre"
              ? `${value.film_name_cn} * ${value.film_name_en}`
              : `${value.theatre_name}`,
        },
      })
    },
    querySearch(queryString, cb) {
      const restaurants = this.filmListData
      const results = queryString
        ? this.createFilter(queryString, restaurants)
        : restaurants
      cb(results)
    },
    createFilter(queryString, restaurants) {
      return restaurants.filter((item) =>
        item.value.toLowerCase().includes(queryString.toLowerCase())
      )
    },
    disabledDate(time) {
      // time.getTime() < Date.now() - 8.64e7 禁用不包含當前日期
      // time.getTime() > Date.now() - 8.64e7 禁用包含當前日期
      const nextYearDate = this.$moment(this.fromDate)
        .add(1, "years")
        .add(1, "days")
        .format("YYYY-MM-DD")
      return (
        time.getTime() < new Date(this.fromDate).getTime() - 8.64e7 ||
        time.getTime() > new Date(nextYearDate).getTime()
      )
    },
    selectAreaChange(val) {
      // if (val === '入座率資料(By film)') {
      //   // this.selectCircuit = ''
      //   // this.getCircuitTheatres()
      //   // this.inputForFilm = ''
      // } else {
      //   // this.selectPengdingFilm = ''
      //   // this.inputForFilm = ''
      // }
    },
    inputSearchFilm() {
      //輸入下拉 input事件
      //篩選后清空其他搜索框内容
      this.selectPengdingFilm = ""
      // this.getFilmListOfFilm(this.inputForFilm)
    },
    inputSearchFilmChange(val) {
      //輸入下拉 change事件
      console.log(val, "val")
      // this.inputSelectKey++;
      if (!val) {
        this.film_id = ""
        this.master_id = ""
      }
      this.selectPengdingFilm = ""
      this.setDate()
    },
    selectPengdingChange(val) {
      if (!val) {
        this.film_id = ""
      }
    },
    selectPengdingOption(val) {
      //上映中電影 select事件
      console.log(val)
      if (val) {
        this.film_id = val.id
        //篩選后清空其他搜索框内容
        this.searchForFilm = ""
        this.inputForFilm = ""
        this.setDate()
      }
      // this.areaSelect = ''
    },
    getFilmPengding() {
      //獲取上映中電影數據
      getFilmPengding()
        .then((res) => {
          console.log(res, "pending")
          this.pengdingFilmList = res.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    selectCircuitOption(value) {
      console.log(value, "housevalue")
      if (value.circuit_key) {
        this.circuit_id = value.id
        this.theatre_id = ""
      } else {
        this.circuit_id = ""
        this.theatre_id = value.id
      }
    },
    searchFilmInput(value) {
      console.log(value)
      const todate = this.$moment().subtract(1, "days").format("YYYY-MM-DD")
      const defaultDate = this.$moment()
        .subtract(7, "days")
        .format("YYYY-MM-DD")
      let startDate = ""
      let endDate = ""
      if (value.type === "film") {
        startDate =
          value.date_release !== "0001-01-01" ? value.date_release : defaultDate
        endDate =
          value.date_release >= todate
            ? this.$moment(value.date_release)
                .add(6, "days")
                .format("YYYY-MM-DD")
            : value.date_off !== "0000-00-00"
            ? value.date_off
            : todate
      } else {
        startDate = defaultDate
        endDate = todate
      }
      this.fromDate = startDate
      this.toDate = endDate
      this.min = this.fromDate
      this.max = this.toDate
      if (this.hideBox) {
        console.log(this.min, this.max)
      }
      // this.inputForFilm = value.value
      this.selectPengdingFilm = ""
      // this.areaSelect = ''
      if (value.type === "master") {
        this.master_id = value.id
        this.film_id = ""
      } else {
        this.master_id = ""
        this.film_id = value.id
      }
      // this.master_id = value.id
    },
    remoteMethod(query) {
      this.filmList = []
      if (query !== "") {
        clearTimeout(this.timer)
        this.timer = setTimeout(async () => {
          this.filmList = await this.getFilmListOfFilm(query)
        }, 100)
        return query
      }
      this.filmList = []
      return query
    },
    setDate() {
      //set date
      const todate = this.$moment().subtract(1, "days").format("YYYY-MM-DD")
      const defaultDate = this.$moment()
        .subtract(7, "days")
        .format("YYYY-MM-DD")
      const minDate = this.$moment().subtract(1, "years").format("YYYY-MM-DD")
      this.toDate = todate
      this.fromDate = defaultDate
      this.min = minDate
      this.max = todate
      console.log(this.min, this.max)
    },
    getCircuitTheatres() {
      this.theatreLoading = true
      const data = {
        area: this.areaSelect,
      }
      getCircuitTheatres(data)
        .then((res) => {
          const newFilmList = []
          res.data.map((el) => {
            if (this.selectAttendance === "入座率資料(By film)") {
              newFilmList.push(el)
            }
            if (el.theatres.length > 0) {
              el.theatres.map((child) => {
                child.full_name = `- 戲院： ${child.full_name}`
                newFilmList.push(child)
              })
            }
          })
          const all = {
            full_name:
              this.selectAttendance === "入座率資料(By film)"
                ? "所有院線和戲院"
                : "所有戲院",
            circuit_key: "",
            id: "",
          }
          newFilmList.unshift(all)
          this.newFilmList = newFilmList
          this.theatreLoading = false
        })
        .catch((error) => {
          console.log(error)
          this.theatreLoading = false
        })
    },
    getAreas() {
      this.areaLoading = true
      getAreas()
        .then((res) => {
          const newList = []
          res.data.map((el) => {
            el.states.map((child) => {
              newList.push(child)
              child.districts.map((kid) => {
                // console.log(kid)
                kid.name = `- ${kid.name}`
                newList.push(kid)
              })
            })
          })
          const all = {
            name: "All districts",
            id: "",
          }
          newList.unshift(all)
          this.areaList = newList
          this.areaLoading = false
        })
        .catch((error) => {
          console.log(error)
          this.areaLoading = false
        })
    },
    selectShowFilms(val) {
      this.searchSelectVal = val ? val : ""
      const data = {
        search: this.searchSelectVal,
      }
      selectShowFilms(data)
        .then((res) => {
          this.searchFilmList = res.data
          console.log(this.searchFilmList)
          const newList = []
          this.searchFilmList.map((el) => {
            el.film_name = `${el.name_chinese} * ${el.name_english}`
          })
          // this.newSearchFilmList = newList
          console.log(this.newSearchFilmList)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    searchInfo(type) {
      this.showTabel = true
      if (!this.searchForFilm && type === "入座率資料(By film)") {
        // this.hideBox = true
      }

      if (type === "入座率資料(By film)") {
        this.searchSelectVal = ""
        // this.getFilmMasterApi()
        this.getOccupancyByFilm()
      } else if (type === "入座率資料(By house)") {
        this.getOccupancyByHouse()
      } else {
        this.getOccupancyByTheatre()
      }
      store.commit("updateStateData")
    },
    getOccupancyByFilm() {
      this.tableLoading = true
      const data = {
        date_start: this.fromDate,
        date_end: this.toDate,
        search: this.film_id || this.master_id ? "" : this.inputForFilm,
        hide: this.hideBox,
        area: this.areaSelect,
        film_master_id: this.master_id,
        film_id: this.film_id,
        circuit: this.circuit_id,
        theatre: this.theatre_id,
        page: this.currentPage,
        count: this.rows,
      }
      if (this.circuit_id) {
        data.theatre = ""
      } else {
        data.circuit = ""
      }
      getOccupancyByFilm(data)
        .then((res) => {
          this.attendanceData = res.data

          const dataList = []

          res.data.forEach((el) => {
            el.date_release = el.run_ver_info.date_release
            el.date_on = el.run_ver_info.date_on
            el.date_off = el.run_ver_info.date_off
            el.date_to_report = el.run_ver_info.date_to_report
            dataList.push({
              id: el.film_master.id,
              film_name_cn: el.film_master.name_chinese,
              film_name_en: el.film_master.name_english,
              country: el.film_master.country,
              _rowVariant:
                el.date_on < el.date_release && el.date_release !== "0001-01-01"
                  ? "danger"
                  : "",
              theatre_highest: el.num_theatre,
              tg: el.gross,
              occupancy: el.occupancy,
              ta: el.admissions_total,
              screen_highest: el.num_screen,
              up_date: el.run_ver_info.date_release,
              down_date: el.date_off,
            })
          })
          this.attendanceData = dataList
          this.pageTotal = this.attendanceData.length
          console.log(this.attendanceData)
          this.tableLoading = false
        })
        .catch((error) => {
          console.log(error)
          this.tableLoading = false
        })
    },
    getOccupancyByHouse() {
      this.tableLoading = true
      const data = {
        date_start: this.fromDate,
        date_end: this.toDate,
        theatre: this.theatre_id,
        page: 1,
        count: 1000,
      }
      getOccupancyByHouse(data)
        .then((res) => {
          const list = []
          res.data.map((el) => {
            list.push({
              id: el.theatre ? el.theatre.id : "",
              screen_id:
                el.house && el.house.house_name ? el.house.screen_id : "",
              circuit_name:
                el.theatre && el.theatre.circuit ? el.theatre.circuit : "-",
              theatre_name:
                el.theatre && el.theatre.full_name ? el.theatre.full_name : "-",
              house_id:
                el.house && el.house.house_name ? el.house.house_name : "-",
              num_of_film: el.num_film,
              num_of_show: el.num_show,
              ta: el.admissions_total,
              tg: el.gross,
              occupancy: el.occupancy,
            })
          })
          this.attendanceData = list
          this.pageTotal = res.data.total
          this.tableLoading = false
        })
        .catch((error) => {
          console.log(error)
          this.tableLoading = false
        })
    },
    getOccupancyByTheatre() {
      this.tableLoading = true
      const data = {
        date_start: this.fromDate,
        date_end: this.toDate,
        search: this.film_id || this.master_id ? "" : this.inputForFilm,
        area: this.areaSelect,
        film_id: this.film_id,
        film_master_id: this.master_id,
        page: 1,
        count: 10000,
      }
      getOccupancyByTheatre(data)
        .then((res) => {
          const list = []
          res.data.map((el) => {
            list.push({
              id: el.theatre.id,
              circuit_name: el.theatre.circuit,
              theatre_name: el.theatre.full_name,
              num_of_film: el.num_film,
              num_of_show: el.num_show,
              ta: el.admissions_total,
              tg: el.gross,
              occupancy: el.occupancy,
            })
          })
          this.attendanceData = list
          this.attendanceData.map((el) => {
            console.log(res)
          })
          this.pageTotal = res.data.total
          this.tableLoading = false
        })
        .catch((error) => {
          console.log(error)
          this.tableLoading = false
        })
    },
    getFilmListOfFilm(val) {
      this.searchFilmLoading = true
      let searchVal = val
      if (typeof val === "string") {
        searchVal = val
      } else {
        searchVal = ""
        this.searchForFilm = ""
      }
      const data = {
        search: searchVal,
      }
      const list = []
      this.filmList = []
      getFilmListOfFilm(data).then((res) => {
        res.data.map((item) => {
          list.push({
            id: item.id,
            value: `${item.name_chinese}*${item.name_english}`,
            type: "master",
          })
          if (item.film && item.film.length > 0) {
            item.film.map((el) => {
              const remark = el.remark_2
                ? `${el.remark_1}[${el.remark_2}]`
                : el.remark_1
              const date_release =
                this.isValidDate(el.date_release) === "0000-00-00"
                  ? "00-00-0000"
                  : this.isValidDate(el.date_release)
              const date_off = this.isValidDate(
                this.dateOffFormat(el.date_off, el.date_release)
              )
              list.push({
                id: el.id,
                master_id: el.master_id,
                type: "film",
                value: ` - ${el.title_name}-(${el.run_ver.run_ver}, ${el.language}, ${remark} ) - (${date_release} to ${date_off})`,
                date_release: el.date_release,
                date_off: el.date_off,
              })
            })
          }
        })
        this.searchFilmLoading = false
      })
      return list
    },
  },
  created() {
    console.log(Date())
    if (!this.hideBox) {
      this.fromDate = ""
      this.toDate = ""
    }
    this.selectAttendance = this.$route.query.page || "入座率資料(By film)"
    this.setDate()
    this.getAreas()
    this.getCircuitTheatres()
    this.changeAttendance(this.selectAttendance)
  },
}
</script>

<style lang="scss">
.el-autocomplete-suggestion {
  li {
    white-space: normal;
  }
}
</style>
<style lang="scss" scoped>
.date-pick {
  display: flex;
  .from-date,
  .to-date {
    margin-right: 20px;
  }
  .hide-checkbox {
    position: relative;
    top: 35px;
  }
  .select-film-name {
    position: relative;
    top: 25px;
  }
}
.search {
  display: flex;
  margin-bottom: 20px;
}
.link-color {
  color: #0e72bc;
}
.all-film-list {
}
</style>
