import request from '@/utils/request'

// create manage-properties
export function creatManageProperties (data) {
  return request({
    url: '/api/manage-properties',
    method: 'POST',
    data
  })
}

// get manage-properties
export function getManageProperties (type) {
  return request({
    url: `/api/manage-properties?type=${type}`,
    method: 'GET'
  })
}

// delect manage-properties
export function delectManageProperties (id) {
  return request({
    url: `/api/manage-properties/${id}`,
    method: 'DEL'
  })
}

// eidt manage-properties
export function editManageProperties (id, data) {
  return request({
    url: `/api/manage-properties/${id}`,
    method: 'PUT',
    data
  })
}

// change sort
export function sortManageProperties (id, data) {
  return request({
    url: `/api/manage-properties/${id}/change-sort`,
    method: 'PUT',
    data
  })
}
