<template>
  <div>
    <profilesInfo
      :title="title"
      :title-en="titleEn"
      :select-options="selectOptions"
      :titles="infoList"
      :type="type"
      :pageId="pageId"
      :caseList="caseList"
      @saveInfo="saveInfo"
      :name="name"
      :select="select"
      :sex="sex"
      :remarks="remarks"
      :loading.sync="loading"
      :pageTypeVal.sync="pageType"
      @getDetail="getDirectorDetial"
    />
  </div>
</template>

<script>
import profilesInfo from '../profilespage/profilesInfo'
import {
  getDirectorDetial,
  editDirector,
  createDirector
} from '@/api/film-material-management/director'

export default {
  components: {
    profilesInfo
  },
  data() {
    return {
      loading: false,
      pageType: '',
      type: 'newInfo',
      title: '導演',
      name: 'director',
      titleEn: 'Director',
      select: {
        name: ''
      },
      sex: {
        type: null
      },
      remarks: {
        name: '',
        status: false
      },
      selectOptions: [
        { text: '香港', value: 'Hong Kong' },
        { text: '非香港', value: 'Non Hong Kong' }
      ],
      pageId: '',
      caseList: [],
      infoList: [
        { title_name: 'Director Name', val: '' },
        { title_name: '導演名稱', val: '' },
        { title_name: '別名', val: '' }
      ],
      createInfo: '',
      area: ''
    }
  },
  methods: {
    getDirectorDetial() {
      this.loading = true
      getDirectorDetial(this.pageId)
        .then((res) => {
          this.caseList = []
          res.data.old_record.forEach((item) => {
            this.caseList.push({
              id: item.id,
              name_en: item.name_en,
              name_zh_hk: item.name_zh_hk,
              change_date: item.change_date,
              area: item.area,
              other_name: item.other_name,
              disabled: true
            })
          })
          this.caseList.map((el)=>{
            if(el.area === "Hong Kong"){
              el.area = "香港"
            }else if(el.area === "Non Hong Kong"){
              el.area = "非香港"
            }
          })
          console.log(this.caseList);
          this.infoList = [
            { title_name: 'Director Name', val: res.data.director_name_en },
            { title_name: '導演名稱', val: res.data.director_name_zh_hk },
            { title_name: '別名', val: res.data.director_name_other }
          ]
          this.select.name = res.data.area
          this.remarks.name = res.data.remarks
          if (this.select.name === 'Others') {
            this.remarks.status = true
          }
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    saveInfo(value, select, iscase, issex, remarks, revise) {
      console.log('this should be working')
      this.createInfo = value
      this.area = select.name
      this.caseLists = iscase
      this.sex = issex
      this.remarks = remarks

      if (this.pageId) {
        this.editDirector(revise)
        // this.$router.push({path:'/dashboard/director'})
      } else {
        this.createDirector(revise)
        // this.$router.push({ path: '/dashboard/director' })
      }
    },
    editDirector(revise) {
      console.log(this.createInfo)

      const data = {
        director_name_en: this.createInfo[0].val,
        director_name_zh_hk: this.createInfo[1].val,
        director_name_other: this.createInfo[2].val,
        remarks: this.remarks.name,
        area: this.area,
        revise
      }
      editDirector(this.pageId, data)
        .then((res) => {
          console.log(res)
          this.caseList = res.data.old_record
          this.getDirectorDetial()
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    createDirector(revise) {
      const data = {
        director_name_en: this.createInfo[0].val,
        director_name_zh_hk: this.createInfo[1].val,
        director_name_other: this.createInfo[2].val,
        remarks: this.remarks.name,
        area: this.area,
        revise
      }
      createDirector(data)
        .then((res) => {
          console.log(res)
          this.$router.push({
            name: 'director-info',
            query: {
              type: 'review',
              id: res.data.id
            }
          })
          this.pageId = res.data.id
          this.pageType = 'review'
          this.getDirectorDetial()
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  mounted() {
    if (this.pageId) {
      this.getDirectorDetial()
    }
  },
  created() {
    this.pageId = this.$route.query.id
    this.pageType = this.$route.query.type
  }
}
</script>

<style lang="scss" scoped>
.title-top {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.title {
  width: 100%;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin: 20px 0;
}
</style>
