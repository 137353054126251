import request from '@/utils/request'

// get actorList api
export function getActorApi (params) {
  return request({
    url: '/api/actor',
    method: 'GET',
    params
  })
}
// get actorDetial
export function getActorDetial (id) {
  return request({
    url: `/api/actor/${id}`,
    method: 'GET',
  })
}
//create actor
export function createActor (data) {
  return request({
    url: '/api/actor',
    method: 'POST',
    data
  })
}
//edit actor
export function editActor (id, data) {
  return request({
    url: `/api/actor/${id}`,
    method: 'PUT',
    data
  })
}
//delect actor
export function delectActor (id) {
  return request({
    url: `/api/actor/${id}`,
    method: 'DEL',
  })
}