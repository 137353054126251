import request from '@/utils/request'

// get ProductionList API
export function getProductionApi (params) {
  return request({
    url: '/api/production',
    method: 'GET',
    params
  })
}
//get Production Detail
export function getProductionDetail(id) {
  return request({
    url: `/api/production/${id}`,
    method: 'GET',
  })
}
//create Production
export function createProduction(data) {
  return request({
    url: '/api/production',
    method: 'POST',
    data
  })
}
//edit Production
export function editProduction(id, data) {
  return request({
    url: `/api/production/${id}`,
    method: 'PUT',
    data
  })
}
//delete Production
export function deleteProduction(id) {
  return request({
    url: `/api/production/${id}`,
    method: 'DELETE',
  })
}