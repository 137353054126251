<template>
  <div class="boxofficeRecordDetail" v-loading="loading">
    <div v-for="(record, reindex) in boxofficeList" :key="reindex">
      <div class="d-flex justify-content-between my-1">
        <p>(有*號 為必須輸入項目)</p>
        <b-button
          size="sm"
          variant="outline-danger"
          @click="deleteManual(record.id)"
        >
          刪除此記錄
        </b-button>
      </div>
      <b-row v-for="(item, index) in types" :key="index" class="1">
        <b-col sm="3" class="d-flex align-items-center my-1">
          <label class="label">{{ item.label }} :</label>
        </b-col>
        <b-col sm="7">
          <b-form-input
            :id="`type-${index}`"
            v-model="record[item.val]"
            disabled
          />
        </b-col>
      </b-row>
      <div class="case-tank mt-3 pt-2">
        <div
          v-for="(item, index) in record.caseList"
          :key="index"
          class="d-flex align-items-center justify-content-between"
        >
          <p style="width: 20px">
            {{ index + 1 }}
          </p>
          <div class="input-box">
            <label class="label" for="">放映時間 Show Time *</label>
            <div class="d-flex align-items-center">
              <el-input
                :ref="'myShowTimeInput'"
                v-model="item.input_time_val"
                style=""
                disabled
              ></el-input>
            </div>
          </div>
          <div class="input-box" v-if="!isEdit">
            <label class="label" for="">Screen ID/ House ID *</label>
            <el-input
              :ref="'myShowTimeInput'"
              v-model="item.house_name"
              style=""
              disabled
            ></el-input>
          </div>
          <div class="input-box">
            <label class="label" for="">入場人數 Admissions *</label>
            <b-form-input
              type="number"
              v-model="item.admissions"
              ref="focusInput"
              :autofocus="isFocus"
              disabled
            />
          </div>
          <div class="input-box">
            <label class="label" for="">收入 Total Gross (HKD) *</label>
            <b-form-input type="number" v-model="item.gross" disabled />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getManualBoxoffice, delectManualBoxoffice } from "@/api/boxoffice.js";
import { getTheatreHouseList } from "@/api/theatrehouse.js";
import datePickerCom from "@/views/components/datePicker.vue";

export default {
  components: { datePickerCom },
  props: {},
  data() {
    return {
      isFocus: false,
      restaurants: [],
      houseList: [],
      dialogVisible: false,
      loading: false,
      showDatePickerCom: true,
      timeInputValue: "",
      invalidColor: "#fff",
      loadingBox: false,
      pageId: "",
      pageType: "",
      theatreId: "",
      selectedHouse: "",
      selectedFilm: "",
      selectHouse: [],
      selectFilm: [],
      searchHouse: "",
      searchFilm: "",
      types: [
        {
          label: "戲院名稱 Theatre Name",
          val: "theatre_name",
          disabled: true,
          type: "text"
        },
        {
          label: "電影名稱 Film Name",
          val: "film_name",
          type: "text"
        },
        {
          label: "Film Master ID",
          val: "film_master_id",
          type: "text"
        },
        {
          label: "Film ID *",
          val: "film_id",
          type: "text"
        },
        {
          label: "上映中或即將上映之電影",
          val: "film_on_showing",
          type: "text"
        },
        {
          label: "Date of Engagement*",
          val: "engagement_date",
          type: "text"
        },
        {
          label: "放映日期 Show Date *",
          val: "show_time",
          type: "text"
        }
      ],
      timeType: "",
      all_admissions: "",
      all_gross: "",
      items: [],
      canFinalSave: true,
      isTemp: "",
      boxofficeList: [],
      theatre_name: ""
    };
  },
  computed: {
    invalid() {
      return function (val) {
        if (val) {
          return false;
        } else {
          return true;
        }
      };
    },
    is_all_admissions: {
      get() {
        let data = "";
        this.caseList.map((el) => {
          data = Number(el.admissions) + Number(data);
        });
        return data;
      },
      set(val) {
        this.all_admissions = val;
      }
    },
    is_all_gross: {
      get() {
        let data = "";
        this.caseList.map((el) => {
          data = Number(el.gross) + Number(data);
        });
        return data;
      },
      set(val) {
        this.all_gross = val;
      }
    },
    isEdit() {
      return !!this.$route.query.id;
    },
    showModal() {
      let filmInfo = true;
      let caseInfo = true;
      this.types.map((el) => {
        if (this.pageType === "create") {
          if (
            !el.val &&
            el.label !== "Date of Engagement*" &&
            el.label !== "Screen ID/ House ID *"
          ) {
            filmInfo = false;
          }
        } else if (this.pageType === "edit" && !el.val) {
          filmInfo = false;
        }
      });

      this.caseList.map((el) => {
        if (
          !el.show_time ||
          (!this.isEdit && !el.date_of_engagement) ||
          (!this.isEdit && !el.house_id)
        ) {
          caseInfo = false;
        }
      });
      console.log(filmInfo);
      console.log(caseInfo);
      if (filmInfo && caseInfo) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    getManualBoxoffice() {
      this.loading = true;
      const data = {
        single_date: this.queryDate
      };
      getManualBoxoffice(data, this.theatreId)
        .then((res) => {
          res.data.map((el) => {});
          this.boxofficeList = res.data;
          if (this.boxofficeList && this.boxofficeList.length > 0) {
            this.boxofficeList.map((el) => {
              let house_name = "";
              this.houseList.map((house) => {
                if (el.screen_id === house.label) {
                  house_name = house.value;
                }
              });
              const caseList = [
                {
                  input_time_val: this.$moment(el.show_time).format("HH:mm"),
                  admissions: el.admissions,
                  gross: el.gross,
                  house_name: house_name
                }
              ];

              const remark = el.film.remark_2
                ? `[${el.film.remark_1}] (${el.film.remark_2})`
                : `[${el.film.remark_1}]`;
              el.theatre_name = this.theatre_name;
              el.film_master_id = el.film.master_id;
              el.film_id = el.film.id;
              el.film_on_showing = `${el.film.title_name} (${el.film.language}) ${remark}`;
              el.engagement_date = this.$moment(el.engagement_date).format(
                "DD-MM-YYYY"
              );
              el.show_time = this.$moment(el.show_time).format("DD-MM-YYYY");
              el.caseList = caseList;
            });
          }

          console.log(res.data, "res.data");
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    getHouseList() {
      const data = "";
      getTheatreHouseList(this.theatreId, data).then((res) => {
        console.log(res);
        res.data.map((el) => {
          this.houseList.push({ value: el.house_name, label: el.screen_id });
        });
        this.getManualBoxoffice();
        console.log(this.houseList);
      });
      this.restaurants = this.houseList;
    },
    deleteManual(id) {
      delectManualBoxoffice(this.theatreId, id).then((res) => {
        console.log(res);
        if (res.result === "success") {
          this.getManualBoxoffice();
        }
      });
    }
  },
  mounted() {
    this.getHouseList();
  },
  created() {
    this.pageId = this.$route.query.id;
    this.theatreId = this.$route.query.theatreid;
    this.pageType = this.$route.query.type;
    this.queryDate = this.$route.query.date || "";
    this.isTemp = Number(this.$route.query.is_temp);
    this.theatre_name = this.$route.query.theatre_name;
  }
};
</script>
<style lang="scss">
.boxofficeRecordDetail,
#modal-1 {
  #datetime-1,
  #datetime-2 {
    padding-left: 19px;
    padding-right: 15px;
  }
}
</style>
<style lang="scss" scoped>
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.label {
  font-size: 14px;
}
.case-tank {
  width: 100%;
  padding: 20px 0;
  border-top: 2px solid #d7d9da;
  border-bottom: 2px solid red;
}
.input-box {
  width: 20%;
}
.change-box {
  height: 80px;
  border-bottom: 2px solid #0e72bc;
  .change-btn {
    margin-top: 20px;
    float: right;
  }
}
.redborder {
  border-color: red;
}
.dorest {
  margin-top: -15px;
}
.eng {
  margin-top: -10px;
}
</style>
