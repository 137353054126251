<template>
  <div>
    <b-card class="xml-related-record">
      <div class="title">
        <p>XML檔在資料庫的相關記錄</p>
      </div>
      <b-button variant="outline-primary" size="sm" @click="$router.go(-1)"
        >上一頁</b-button
      >
      <div class="sec-title">
        包括在 {{ XMLDetails.file_name }} (XML檔案篇號：{{
          $route.query.file_id
        }})之記錄
      </div>
      <div v-loading="headertLoading">
        <b-row v-for="(item, index) in items" :key="index" class="my-1">
          <b-col sm="3" class="d-flex justify-content-end bold">
            {{ item.name }}
          </b-col>
          <!-- <b-col sm="3" v-if="item.val !== 'number_of_record'">
            {{ XMLDetails[item.val] }}
          </b-col> -->
          <b-col
            sm="3"
            v-if="item.val !== 'number_of_record' && item.type === 'date'"
          >
            {{ $moment(XMLDetails[item.val]).format("DD-MM-YYYY HH:mm:ss") }}
          </b-col>
          <b-col
            sm="3"
            v-else-if="item.val !== 'number_of_record' && item.type !== 'date'"
          >
            {{ XMLDetails[item.val] }}
          </b-col>
          <b-col sm="3" v-else>
            <span v-if="total">{{ total || 0 }}</span>
          </b-col>
        </b-row>
      </div>
      <div v-loading="tableLoading">
        <b-table
          bordered
          :tbody-tr-class="rowClass"
          striped
          hover
          responsive
          :items="tableData"
          :fields="
            $store.state.highlightCol.firTable
              ? $store.state.newTableFields
              : tableFields
          "
          @head-clicked="
            commonHighLightCol(
              $event,
              tableFields,
              $store.state.newTableFields,
              'firTable'
            )
          "
        >
          <template #thead-top="data">
            <b-tr>
              <b-th colspan="9"></b-th>
              <b-th> total ADM :{{ totalVal("total_admissions") }}</b-th>
              <b-th> total B.O. :{{ totalVal("total_gross") }}</b-th>
            </b-tr>
          </template>
          <template #cell(circuit)="data">
            <div class="text-nowrap">
              {{ data.item.circuit_name }}
            </div>
          </template>
          <template #cell(date_of_engagement)="data">
            <div class="text-nowrap">
              {{ $moment(data.item.date_of_engagement).format() }}
            </div>
          </template>
          <template #cell(show_time)="data">
            <div class="text-nowrap">
              {{ $moment(data.item.show_time).format("DD-MM-YYYY HH:mm:ss") }}
            </div>
          </template>

          <template #cell(total_admissions)="data">
            <div>
              {{ parseFloat(data.item.total_admissions).toLocaleString() }}
            </div>
          </template>
          <template #cell(total_gross)="data">
            <div>
              {{ parseFloat(data.item.total_gross).toLocaleString() }}
            </div>
          </template>
        </b-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import { getXmlRecord, getXmlDetailOfJson } from "@/api/checkXmlUpload.js";

export default {
  props: {
    hasItem: {
      type: String,
      default: null
    }
  },
  components: {},
  data() {
    return {
      total: 0,
      headertLoading: false,
      tableLoading: false,
      rows: 1000,
      perPage: 1,
      currentPage: 1,
      items: [
        {
          name: "date_of_report (Receive Time) :",
          val: "date_of_report",
          type: "date"
        },
        {
          name: "circuit_name :",
          val: "circuit_name"
        },
        {
          name: "src_type :",
          val: "src_type"
        },
        {
          name: "report_start :",
          val: "report_start",
          type: "date"
        },
        {
          name: "report_end :",
          val: "report_end",
          type: "date"
        },
        {
          name: "Number of related Records :",
          val: "number_of_record"
        }
      ],
      tableData: [],
      tableFields: [
        { key: "id", label: "E_id", sortable: true },
        { key: "theatre.full_name", label: "Theatre Name", sortable: true },
        { key: "screen_id", label: "House", sortable: true },
        { key: "film_master_id", label: "F_master_id", sortable: true },
        { key: "film_id", label: "Film_id", sortable: true },
        { key: "title_key", label: "POS_id", sortable: true },
        { key: "title_name", label: "Film Name", sortable: true },
        { key: "date_of_engagement", label: "Date_of_engt", sortable: true },
        { key: "show_time", label: "Show_time", sortable: true },
        { key: "total_admissions", label: "Total ADM.", sortable: true },
        { key: "total_gross", label: "Total B.O.", sortable: true }
      ],
      XMLDetails: {}
    };
  },
  computed: {
    totalVal() {
      return function (val, hasItem) {
        if (hasItem) {
          return parseFloat(
            this.tableData[hasItem].reduce(
              (sum, item) => sum + Number(item[val]),
              0
            )
          ).toLocaleString();
        } else {
          return parseFloat(
            this.tableData.reduce((sum, item) => sum + Number(item[val]), 0)
          ).toLocaleString();
        }
      };
    }
  },
  mounted() {
    this.getXmlRecord();
    this.getXmlDetailOfJson();
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.id === Number(this.$route.query.e_id)) return "table-success";
    },
    getXmlDetailOfJson() {
      this.headertLoading = true;
      getXmlDetailOfJson(this.$route.query.file_id)
        .then((res) => {
          this.XMLDetails = res.data;
          this.headertLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.headertLoading = false;
        });
    },
    getXmlRecord() {
      this.tableLoading = true;
      getXmlRecord(this.$route.query.file_id, this.currentPage, this.rows)
        .then((res) => {
          this.tableData = res.data.data;
          this.total = res.data.total;
          this.tableLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.tableLoading = false;
        });
    }
  }
};
</script>
<style lang="scss"></style>
<style lang="scss" scoped>
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.sec-title {
  color: #0e72bc;
  font-size: 16px;
}
.bold {
  font-weight: 500;
}
</style>
