import request from '@/utils/request'

// get producerList api
export function getProducerApi (params) {
  return request({
    url: '/api/producer',
    method: 'GET',
    params
  })
}
// get producerDetial
export function getProducerDetial (id) {
  return request({
    url: `/api/producer/${id}`,
    method: 'GET',
  })
}
//create producer
export function createProducer (data) {
  return request({
    url: '/api/producer',
    method: 'POST',
    data
  })
}
//edit producer
export function editProducer (id, data) {
  return request({
    url: `/api/producer/${id}`,
    method: 'PUT',
    data
  })
}
//delect producer
export function delectProducer (id) {
  return request({
    url: `/api/producer/${id}`,
    method: 'DEL',
  })
}