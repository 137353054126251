<template>
  <MovieGenreForm
    v-loading="loading"
    @getGenreDetail="getGenreDetail"
    :items="items"
    :title="title"
    :type="type"
  />
</template>
<script>
import MovieGenreForm from "../components/GernreForm.vue";
import { getGenreDetail } from "@/api/film-material-management/genre";

export default {
  components: {
    MovieGenreForm
  },
  data() {
    return {
      loading: false,
      title: "片種",
      type: "edit",
      pageId: "",
      items: [
        {
          id: 0,
          filmcat_name_en: "",
          filmcat_name_zh_hk: "",
          old_record: []
        }
      ]
    };
  },
  methods: {
    getGenreDetail() {
      console.log(this.pageId);
      this.loading = true;
      getGenreDetail(this.pageId)
        .then((res) => {
          const date = new Date();
          const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
          const defaultDate = `${firstDay.getFullYear()}-${
            firstDay.getMonth() + 1
          }-${firstDay.getDate()}`;
          this.items = [
            {
              id: this.pageId,
              filmcat_name_en: res.data.filmcat_name_en,
              filmcat_name_zh_hk: res.data.filmcat_name_zh_hk,
              old_record: []
            }
          ];
          this.items[0].old_record = [];
          res.data.old_record.forEach((item) => {
            console.log("222222");
            this.items[0].old_record.push({
              id: item.id,
              name_en: item.name_en,
              name_zh_hk: item.name_zh_hk,
              change_date: item.change_date,
              disabled: true
            });
          });
          console.log(res.data);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    }
  },
  mounted() {
    if (this.pageId) {
      this.getGenreDetail();
    }
  },
  created() {
    this.pageId = this.$route.query.id;
  }
};
</script>
