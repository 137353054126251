var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('div',{staticClass:"attendance-header"},[_c('div',{staticClass:"title"},[_c('p',[_vm._v("查閱XML上傳檔 (By theatre)")])]),_c('div',[_c('b-form-select',{staticClass:"mb-1",staticStyle:{"width":"220px"},attrs:{"options":_vm.optionTheatres},model:{value:(_vm.selectTheatres),callback:function ($$v) {_vm.selectTheatres=$$v},expression:"selectTheatres"}})],1)]),_c('div',{staticClass:"date-pick"},[_c('div',{staticClass:"d-flex date-box"},[_c('div',{staticClass:"from-date"},[_c('label',{attrs:{"for":"example-datepicker"}},[_vm._v("From")]),_c('datePickerCom',{staticClass:"mb-2",attrs:{"pickTimeVal":_vm.fromDate,"inputWidth":'200px'},on:{"update:pickTimeVal":function($event){_vm.fromDate=$event},"update:pick-time-val":function($event){_vm.fromDate=$event},"getRecords":_vm.getRecords}})],1),_c('div',{staticClass:"to-date"},[_c('label',{attrs:{"for":"example-datepicker"}},[_vm._v("To")]),(_vm.showToDate)?_c('datePickerCom',{staticClass:"mb-2",attrs:{"pickTimeVal":_vm.toDate,"pickerOptions":{ disabledDate: _vm.disabledDate },"inputWidth":'200px'},on:{"update:pickTimeVal":function($event){_vm.toDate=$event},"update:pick-time-val":function($event){_vm.toDate=$event}}}):_vm._e()],1),_c('b-button',{staticClass:"ml-2",attrs:{"variant":"primary"},on:{"click":_vm.getXmlByTheatre}},[_vm._v(" Search ")])],1),_c('b-button',{staticClass:"deleteBtn",attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.deleteRecord()}}},[_vm._v(" 過往刪除記錄 ")])],1),(_vm.showTable)?_c('b-table',{attrs:{"bordered":"","striped":"","hover":"","responsive":"","items":_vm.tableData,"fields":_vm.$store.state.highlightCol.firTable
          ? _vm.$store.state.newTableFields
          : _vm.tableFields},on:{"head-clicked":function($event){return _vm.commonHighLightCol(
          $event,
          _vm.tableFields,
          _vm.$store.state.newTableFields,
          'firTable'
        )}},scopedSlots:_vm._u([{key:"head(action)",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_c('b-form-checkbox',{on:{"change":_vm.selectAllChange},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}},[_vm._v("全選")])],1),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.check-treatre-modal",modifiers:{"check-treatre-modal":true}}],staticClass:"ml-2",attrs:{"size":"sm"}},[_vm._v(" 刪除 ")])],1)]},proxy:true},{key:"cell(action)",fn:function(rowSelect){return [_c('b-form-checkbox',{attrs:{"value":true,"unchecked-value":false},on:{"change":function($event){return _vm.selectChange(rowSelect.item)}},model:{value:(_vm.tableData[rowSelect.index].isSelect),callback:function ($$v) {_vm.$set(_vm.tableData[rowSelect.index], "isSelect", $$v)},expression:"tableData[rowSelect.index].isSelect"}})]}},{key:"cell(view)",fn:function(row){return [_c('b-button',{staticClass:"mr-2",attrs:{"size":"sm"},on:{"click":function($event){return _vm.checkOrigin(row.item.id)}}},[_vm._v(" 觀看XML原檔 ")])]}}],null,false,2846302113)}):_vm._e(),_c('b-modal',{ref:"my-check-treatre-modal",attrs:{"id":"check-treatre-modal","hide-footer":""}},[_c('p',{staticClass:"my-2"},[_vm._v("是否確定多項刪除?")]),_c('div',{staticStyle:{"float":"right"}},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm"},on:{"click":_vm.toggleModal}},[_vm._v("確定")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":_vm.hideModal}},[_vm._v("取消")])],1)]),_c('div',{staticClass:"pagination mt-3"},[_c('el-pagination',{attrs:{"background":"","current-page":_vm.page,"page-sizes":[20, 50, 100],"page-size":_vm.rows,"layout":" sizes, prev, pager, next","total":_vm.pageTotal},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }