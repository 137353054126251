export default [
  {
    path: '/dashboard/hand-type',
    name: 'hand-type',
    component: () => import('@/views/boxoffice/handType.vue'),
  },
  {
    path: '/boxoffice/edit_boxoffice_record',
    name: 'edit_boxoffice_record',
    component: () => import('@/views/boxoffice/editBoxofficeRecord.vue'),
  },
  {
    path: '/boxoffice/boxoffice_record_detail',
    name: 'boxoffice_record_detail',
    component: () => import('@/views/boxoffice/boxofficeRecordDetail.vue'),
  },
  {
    path: '/boxoffice/check-upload-record',
    name: 'check-upload-record',
    component: () => import('@/views/boxoffice/checkUploadRecord.vue'),
  },
  {
    path: '/boxoffice/check-xml-theatre',
    name: 'check-xml-theatre',
    component: () => import('@/views/boxoffice/checkXMLTheatre.vue'),
  },
  {
    path: '/boxoffice/check-xml-delete',
    name: 'check-xml-delete',
    component: () => import('@/views/boxoffice/checkXMLDelete.vue'),
  },
  {
    path: '/boxoffice/check-xml-upload',
    name: 'check-xml-upload',
    component: () => import('@/views/boxoffice/checkXMLUpload.vue'),
  },
  {
    path: '/boxoffice/original-record-file',
    name: 'original-record-file',
    component: () => import('@/views/boxoffice/originalRecordFile.vue'),
  },
  {
    path: '/boxoffice/xml-related-record',
    name: 'xml-related-record',
    component: () => import('@/views/boxoffice/xmlRelatedRecord.vue'),
  },
  {
    path: '/boxoffice/upload-record-detail',
    name: 'upload-record-detail',
    component: () => import('@/views/boxoffice/uploadRecordDetail.vue'),
  },
  {
    path: '/boxoffice/film-box-record',
    name: 'film-box-record',
    component: () => import('@/views/boxoffice/filmBoxoffice.vue'),
  },
  {
    path: '/boxoffice/upload-old-record',
    name: 'upload-old-record',
    component: () => import('@/views/boxoffice/checkOldRecord.vue'),
  },
  {
    path: '/boxoffice/search-film-upload-record',
    name: 'search-film-upload-record',
    component: () => import('@/views/boxoffice/uploadRecordsByCategory/searchFilmRecords(16.2.2&16.2.5).vue'),
  },
]
