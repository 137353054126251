var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('div',{staticClass:"title"},[_c('p',[_vm._v("請選擇電影")])]),_c('div',[_c('b-button',{staticClass:"handle-button mb-2",attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){return _vm.returnRecord()}}},[_vm._v(" 回上頁 ")])],1),_c('div',{staticClass:"search-group"},[_c('b-input-group',{staticClass:"input-group-merge search"},[_c('b-form-input',{staticClass:"search-product",attrs:{"placeholder":"Search Film (Film_ID or Title_key e.g. : F-123 / T-123)"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"text-muted",attrs:{"icon":"SearchIcon"}})],1)],1),_c('b-button',{staticClass:"mr-2 mb-2 add-user",attrs:{"size":"sm","variant":"outline-primary"},on:{"click":function($event){return _vm.getBoxOfficesList()}}},[_vm._v(" Search ")])],1),_c('div',{staticClass:"mb-1"},[_c('b',{staticStyle:{"color":"#0e72bc"}},[_vm._v("已選擇的日期: ")]),_c('b',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.$moment(_vm.$route.query.date).format()))])]),_c('div',{},[_c('b',{staticStyle:{"color":"#0e72bc"}},[_vm._v("已選擇的戲院:")]),_c('b',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.$route.query.name))])]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('b-table',{staticClass:"mt-2",attrs:{"bordered":"","striped":"","hover":"","responsive":"","items":_vm.tableData,"fields":_vm.$store.state.highlightCol.firTable
            ? _vm.$store.state.newTableFields
            : _vm.tableFields},on:{"head-clicked":function($event){return _vm.commonHighLightCol(
            $event,
            _vm.tableFields,
            _vm.$store.state.newTableFields,
            'firTable'
          )}},scopedSlots:_vm._u([{key:"cell(date_release)",fn:function(rows){return [_c('div',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.isValidDate(rows.value)))])]}},{key:"cell(date_off)",fn:function(rows){return [_c('div',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.isValidDate(rows.value)))])]}},{key:"cell(action)",fn:function(rows){return [_c('b-button',{staticClass:"mr-2",attrs:{"size":"sm"},on:{"click":function($event){return _vm.goDetail(
                'select-vison',
                rows.item.film_master.id,
                rows.item.film.title_name
              )}}},[_vm._v(" 修訂此電影 ")])]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }