<template>
  <section id="management-center">
    <b-card v-loading="loading">
      <div class="title">
        <p>檢視電影記錄</p>
      </div>
      <div class="mb-1">
        <b-button
          size="sm"
          variant="outline-secondary"
          class="handle-button"
          @click="$router.push({ name: 'film-record' })"
        >
          回目錄
        </b-button>
      </div>
      <p class="sub-title">電影記錄</p>
      <div
        class="header-title d-flex justify-content-between align-items-center mb-1"
      >
        <p style="width: 80%">Film_Master_ID: {{ master_id }}</p>
        <b-button
          size="sm"
          v-if="filmDetail.last_id"
          @click="nextFilm('last')"
          variant="outline-primary"
          >上一套影片</b-button
        >
        <b-button
          size="sm"
          v-if="filmDetail.next_id"
          @click="nextFilm('next')"
          variant="outline-primary"
          >下一套影片</b-button
        >
      </div>
      <div class="film-detail">
        <div class="film-content">
          <div class="content-left">
            <b-form-group
              label-cols="4"
              label-cols-lg="2"
              :label="item.label"
              label-for="input-default"
              v-for="(item, index) in itemLeft"
              :key="index"
            >
              <b-form-input
                v-if="item.type === 'input'"
                v-model="filmDetail[item.key]"
                disabled
                :id="'input-default' + item.label"
              ></b-form-input>
              <b-form-textarea
                v-else
                disabled
                :id="'textarea' + item.label"
                v-model="info[item.key]"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </div>
          <div class="content-right">
            <b-form-group
              label-cols="4"
              label-cols-lg="2"
              :label="item.label"
              label-for="input-default"
              v-for="(item, index) in itemRight"
              :key="index"
            >
              <b-form-textarea
                disabled
                :id="'textarea' + item.label"
                v-model="info[item.key]"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </div>
        </div>
        <div class="film-remarks">
          <div style="width: 49%">
            <b-form-group
              label-cols="2"
              label-cols-lg="2"
              label="預告"
              label-for="input-default-trailer"
            >
              <div class="link-box">
                <a
                  target="_blank"
                  v-if="filmDetail.film_extra_info"
                  :href="filmDetail.film_extra_info.trailer_url"
                  >{{ filmDetail.film_extra_info.trailer_url }}</a
                >
              </div>
            </b-form-group>
            <b-form-group
              label-cols="2"
              label-cols-lg="2"
              label="電影描述"
              label-for="input-default-description"
            >
              <b-form-textarea
                v-if="filmDetail.film_extra_info"
                id="remarks-textarea"
                disabled
                rows="3"
                v-model="filmDetail.film_extra_info.description"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
            <b-form-group
              label-cols="2"
              label-cols-lg="2"
              label="備註欄"
              label-for="input-default"
            >
              <b-form-textarea
                v-if="filmDetail.film_extra_info"
                id="remarks-textarea"
                disabled
                rows="3"
                v-model="filmDetail.film_extra_info.remark"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </div>
          <div class="poster-box" style="width: 49%">
            <b-form-group
              label-cols="2"
              label-cols-lg="2"
              label="海報"
              label-for="input-default-poster"
            >
              <UploadImgCom
                v-if="filmDetail.film_extra_info"
                :pageType="pageType"
                :bannerPath.sync="filmDetail.film_extra_info.poster_path"
              />
            </b-form-group>
          </div>
        </div>
        <div class="search-to-web">
          <div>
            <a target="_blank" :href="googleSearchUrl"
              >Search Film Info in Google.</a
            >
          </div>
          <div>
            <a :href="IMDBSearchUrl" target="_blank"
              >Search Film Info in IMDB.</a
            >
          </div>
        </div>
        <div class="d-flex justify-content-end mt-1 mr-1">
          <b-button @click="goEditPage" size="sm" variant="outline-primary"
            >修訂以上資料</b-button
          >
        </div>
      </div>
      <filmVersionRecord
        ref="filmVersionRecord"
        :master-id="String(master_id)"
        :version-info="filmDetail.film"
        :film-name="`${filmDetail.name_chinese}*${filmDetail.name_english}`"
      />
    </b-card>
  </section>
</template>
<script>
import filmVersionRecord from "./filmVersionRecord.vue";
import { getFilmMasterDetail } from "@/api/film-material-management/filmMaster";
import UploadImgCom from "@/views/components/uploadImg.vue";

export default {
  components: { filmVersionRecord, UploadImgCom },

  data() {
    return {
      imgLoading: false,
      filmDetail: {},
      loading: false,
      master_id: "",
      itemLeft: [
        { label: "Film Name (in English) *", type: "input", key: "name_english" },
        { label: "電影名稱 (中文名稱) *", type: "input", key: "name_chinese" },
        {
          label: `電影原名 (以該國語言記錄)
Original Film name in it Own Language`,
          type: "input",
          key: "name_org"
        },
        {
          label: `電影其他譯名
Other Names`,
          type: "input",
          key: "name_others"
        },
        {
          label: `產地
Production Country`,
          type: "input",
          key: "country"
        },
        {
          label: `發行商
Distributor`,
          type: "textarea",
          key: "distributor"
        },
        {
          label: "出產年份 (Year)*",
          type: "input",
          key: "ver_year"
        },
        {
          label: `狀態
Status`,
          type: "input",
          key: "status"
        }
      ],
      itemRight: [
        {
          label: `導演
Director`,
          type: "textarea",
          key: "director"
        },
        {
          label: `編劇
Scriptwriter`,
          type: "textarea",
          key: "scriptwriter"
        },
        {
          label: `演員
Actor`,
          type: "textarea",
          key: "actor"
        },
        {
          label: `片種
Genre`,
          type: "textarea",
          key: "film_cat"
        },
        {
          label: `出品公司
Production company`,
          type: "textarea",
          key: "production"
        },
        {
          label: `監制
Producer`,
          type: "textarea",
          key: "producer"
        }
      ],
      info: {
        distributor: "",
        director: "",
        scriptwriter: "",
        actor: "",
        film_cat: "",
        production: "",
        producer: ""
      }
    };
  },
  computed: {
    googleSearchUrl() {
      const baseUrl = "https://www.google.com.hk/search?q=";
      return (
        baseUrl +
        encodeURIComponent(this.filmDetail.name_english) +
        encodeURIComponent(this.filmDetail.name_chinese)
      );
    },
    IMDBSearchUrl() {
      const baseUrl = "https://www.imdb.com/find/?s=all&q=";
      return (
        baseUrl +
        encodeURIComponent(this.filmDetail.name_english) +
        encodeURIComponent(this.filmDetail.name_chinese)
      );
    }
  },

  mounted() {},
  methods: {
    nextFilm(val) {
      if (val === "next") {
        this.master_id = this.filmDetail.next_id;
      } else {
        this.master_id = this.filmDetail.last_id;
      }
      this.$router.push({
        name: "film-record-detail",
        query: { type: this.pageType, id: this.master_id }
      });
      this.getFilmMasterDetail();
      this.$refs.filmVersionRecord.getCircuitFilms();
    },
    goEditPage() {
      this.$router.push({
        path: "master-record",
        query: { type: "edit", id: this.master_id }
      });
    },
    getFilmMasterDetail() {
      this.loading = true;

      getFilmMasterDetail(this.master_id).then((res) => {
        this.filmDetail = res.data;
        const newObj = new Object();
        Object.keys(this.info).map((key) => {
          newObj[key] = [];
          if (key !== "distributor") {
            if (res.data.film_extra_info) {
              res.data.film_extra_info[`${key}_detail`].map((item) => {
                const sex = item.sex ? `-${item.sex}` : "";
                const name_en = item[`${key}_name_en`];
                const name_cn = item[`${key}_name_zh_hk`];
                newObj[key].push(`${name_en}(${name_cn})${sex}\n`);
              });
            }
          } else {
            res.data.distributor_detail.map((item) => {
              const name_en = item[`${key}_name_en`];
              const name_cn = item[`${key}_name_zh_hk`];
              newObj[key].push(`${name_en}(${name_cn})\n`);
            });
          }
          this.info[key] = newObj[key].join("");
        });
        this.filmDetail.film.map((el) => {
          el.runVer = el.run_ver.run_ver;
          el.remark_2 =
            el.remark_2 === "" || !el.remark_2 ? "[無影院特效]" : el.remark_2;
        });
        this.loading = false;
      });
    },
    returnRecord() {
      // this.$router.push({ name: 'add-xml-record' })
      this.$router.go(-1);
    }
  },
  created() {
    this.pageType = this.$route.query.type;
    this.master_id = this.$route.query.id;
    if (this.pageType === "edit" || this.pageType === "review") {
      this.getFilmMasterDetail();
    }
  }
};
</script>
<style lang="scss" scope="scoped">
.line {
  width: 100%;
  height: 1px;
  background: #0e72bc;
  margin: 10px 0;
}
.lh30 {
  width: 250px;
}
.sub-title {
  width: 90%;
  color: #0e72bc;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}
.film-detail {
  width: 100%;
  border: 1px solid rgb(235, 234, 234);
  padding: 10px 5px;
  border-radius: 5px;
  .film-content {
    width: 100%;
    display: flex;
    justify-content: space-around;
    .content-left,
    .content-right {
      width: 49%;
      border: 1px solid rgb(235, 234, 234);
      padding: 10px;
      border-radius: 5px;
    }
  }
  .film-remarks {
    width: 99%;
    padding: 10px;
    border: 1px solid rgb(235, 234, 234);
    border-radius: 5px;
    margin: 10px 10px 10px 5px;
    display: flex;
    justify-content: space-between;
    .poster-box {
      border: 1px solid rgb(235, 234, 234);
      border-radius: 5px;
      padding: 10px;
    }
  }
}
.link-box {
  width: 100%;
  height: 80px;
  border: 1px solid rgb(235, 234, 234);
  border-radius: 5px;
  word-wrap: break-word;
  text-align: left;
  padding: 15px;
}
.search-to-web {
  width: 100%;
  height: auto;
  border: 1px solid rgb(235, 234, 234);
  border-radius: 5px;
  text-align: right;
  padding: 10px;
}
</style>
