<template>
  <div>
    <b-card>
      <div class="attendance-header" v-loading="loading">
        <div class="title">
          <p>查閱XML上傳檔 (By theatre)</p>
        </div>

        <b-table
          :sticky-header="true"
          striped
          hover
          responsive
          :items="items"
          :fields="tableFields"
        >
          <template #cell(action)="row">
            <b-button size="sm" class="mr-2" @click="RestorXML(row.item.id)"> 恢復 </b-button>
          </template>
        </b-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import { getXmlByDelete ,restorXML} from '@/api/checkXmlUpload'
export default {
  data() {
    return {
      loading: false,
      tableData: [],
      tableFields: [
        { key: 'file_name', label: 'XML' },
        { key: 'time', label: 'File Date' },
        { key: 'deletle_date', label: 'Delete Date' },
        { key: 'action', label: '' }
      ],
      items: [],
    }
  },
  computed: {},
  methods: {
    delete() {},
    getXmlByDelete() {
      this.loading = true
      const data = {
        theatre_id: this.$route.query.theatre_id
      }
      getXmlByDelete(data).then((res) => {
        this.items = res.data.data
        this.loading = false
      })
    },
    RestorXML(id) {

      this.loading = true
      const data = {
        ids: [id]
      }
      console.log(id);
      restorXML(data).then((res) => {
        this.$router.go(0)
      })
    },
  },
  created() {
    this.getXmlByDelete()
  }
}
</script>

<style lang="scss" scoped>
.date-pick {
  display: flex;
  position: relative;
  .from-date {
    margin-right: 20px;
  }
  .btn {
    position: absolute;
    right: 50px;
    top: 25px;
    height: 40px;
  }
}
.search {
  display: flex;
  margin-bottom: 20px;
}
.deleteBtn {
  margin-top: 20px;
  margin-right: 50px;
  float: right;
}
</style>
