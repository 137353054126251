import request from '@/utils/request'

// get directorList api
export function getDirectorApi (params) {
  return request({
    url: '/api/director',
    method: 'GET',
    params
  })
}
// get directorDetial
export function getDirectorDetial (id) {
  return request({
    url: `/api/director/${id}`,
    method: 'GET',
  })
}
//create director
export function createDirector (data) {
  return request({
    url: '/api/director',
    method: 'POST',
    data
  })
}
//edit director
export function editDirector (id, data) {
  return request({
    url: `/api/director/${id}`,
    method: 'PUT',
    data
  })
}
//delect director
export function delectDirector (id) {
  return request({
    url: `/api/director/${id}`,
    method: 'DEL',
  })
}