<template>
  <div>
    <b-card v-loading="loading">
      <div class="title">
        <p>人手鍵入票房記錄</p>
      </div>
      <p class="tip">未能由系統輸入票房記錄之戲院</p>
      <b-table
        bordered
        striped
        hover
        :items="theatreList"
        :fields="
          $store.state.highlightCol.firTable
            ? $store.state.newTableFields
            : fields
        "
        @head-clicked="
          commonHighLightCol(
            $event,
            fields,
            $store.state.newTableFields,
            'firTable'
          )
        "
      >
        <template v-slot:cell(theatre_name)="row">
          <b class="theatre-name" @click="editRecord(row.item)">{{
            row.item.theatre_name
          }}</b>
        </template>
        <template #cell(action)="data">
          <b-button
            size="sm"
            style="width: 125px"
            class="mr-2"
            @click="addRecord(data.item.id)"
          >
            輸入票房記錄
          </b-button>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { getTheatreList } from "@/api/theatre.js"

export default {
  data() {
    return {
      loading: false,
      rows: 1,
      perPage: 1,
      currentPage: 1,
      fields: [
        {
          key: "circuit",
          label: "Circuit",
          sortable: true,
        },
        {
          key: "theatre_no",
          label: "Theatre ID",
          sortable: true,
        },
        {
          key: "theatre_name",
          label: "Theatre Name",
          sortable: true,
        },
        {
          key: "box_office_records",
          label: "BoxOffice Records",
          sortable: true,
        },
        {
          key: "action",
          label: "",
        },
      ],
      theatreList: [],
      listParams: {
        page: 1,
        count: 10000,
        status: "manual",
        area: window.sessionStorage.getItem("area") || "",
      },
    }
  },
  methods: {
    addRecord(id) {
      this.$router.push({
        name: "boxoffice_record_detail",
        query: { theatreid: id, type: "create", is_temp: 0 },
      })
    },
    editRecord(item) {
      this.$router.push({
        path: "/boxoffice/edit_boxoffice_record",
        query: { id: item.id },
      })
    },
    getTheatreList() {
      this.loading = true
      getTheatreList(this.listParams)
        .then((res) => {
          console.log(res)
          this.items = res.data.filter((item) => !item.is_closed)
          this.pageTotal = res.total
          const arr = []
          this.items.forEach((el) => {
            const obj = new Object()
            obj.id = el.id
            obj.circuit = el.circuit_name
            obj.theatre_no = el.theatre_no
            obj.theatre_name = el.full_name
            obj.box_office_records =
              el.box_office_records === 0 ? "Manual" : el.box_office_records
            arr.push(obj)
          })
          console.log(this.theatreList)
          this.loading = false
          this.theatreList = arr
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
  },
  created() {
    this.getTheatreList()
  },
}
</script>

<style lang="scss" scoped>
.tip {
  color: #0e72bc;
}
.theatre-name {
  color: #0e72bc;
}
</style>
