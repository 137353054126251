<template>
  <div>
    <profilesInfo
      :title="title"
      :title-en="titleEn"
      :select-options="selectOptions"
      :titles="infoList"
      :type="type"
      :pageId="pageId"
      :caseList="caseList"
      @saveInfo="saveInfo"
      :name="name"
      :select="select"
      :sex.sync="sex"
      :remarks="remarks"
      :pageTypeVal.sync="pageType"
      :loading.sync="loading"
      @getDetail="getActorDetial"
    />
  </div>
</template>

<script>
import profilesInfo from '../profilespage/profilesInfo'
import {
  getActorDetial,
  editActor,
  createActor
} from '@/api/film-material-management/actor'

export default {
  components: {
    profilesInfo
  },
  data() {
    return {
      loading: false,
      type: 'newInfo',
      title: '演員',
      name: 'actor',
      titleEn: 'Actor',
      select: {
        name: ''
      },
      sex: '',
      remarks: {
        name: '',
        status: false
      },
      selectOptions: [
        { text: '香港', value: 'Hong Kong' },
        { text: '非香港', value: 'Non Hong Kong' }
      ],
      pageId: '',
      caseList: [],
      infoList: [
        { title_name: 'Actor Name', val: '' },
        { title_name: '演員名稱', val: '' },
        { title_name: '別名', val: '' }
      ],
      createInfo: '',
      area: ''
    }
  },
  methods: {
    getActorDetial() {
      this.loading = true
      getActorDetial(this.pageId)
        .then((res) => {
          this.actorDetail = res.data
          console.log(this.actorDetail)
          this.caseList = []
          res.data.old_record.forEach((item) => {
            this.caseList.push({
              id: item.id,
              name_en: item.name_en,
              name_zh_hk: item.name_zh_hk,
              change_date: item.change_date,
              sex: item.sex,
              area: item.area,
              other_name: item.other_name,
              disabled: true
            })
          })
          this.caseList.map((el)=>{
            if(el.area === "Hong Kong"){
              el.area = "香港"
            }else if(el.area === "Non Hong Kong"){
              el.area = "非香港"
            }
          })
          this.infoList = [
            { title_name: 'Actor Name', val: res.data.actor_name_en },
            { title_name: '演員名稱', val: res.data.actor_name_zh_hk },
            { title_name: '別名', val: res.data.actor_name_other }
          ]
          this.sex = res.data.sex
          this.select.name = res.data.area
          this.remarks.name = ''
          if (this.select.name === 'Others') {
            this.remarks.status = true
          }
          console.log(this.caseList)
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    },
    saveInfo(value, select, iscase, issex, remarks, revise) {
      console.log(issex)
      this.createInfo = value
      console.log(this.createInfo)
      this.area = select.name
      this.caseLists = iscase
      this.sex = issex
      this.remarks = remarks

      if (this.pageId) {
        this.editActor(revise)
        // this.$router.push({ path: '/dashboard/actor' })
      } else {
        this.createActor(revise)
        // this.$router.push({ path: '/dashboard/actor' })
      }
    },
    editActor(revise) {
      const data = {
        actor_name_en: this.createInfo[0].val,
        actor_name_zh_hk: this.createInfo[1].val,
        sex: this.sex,
        actor_name_other: this.createInfo[2].val,
        area: this.area,
        remarks: this.remarks.name,
        revise
      }
      console.log(data)
      editActor(this.pageId, data)
        .then((res) => {
          console.log(res)
          this.caseList = res.data.old_record
          this.getActorDetial()
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    createActor(revise) {
      const data = {
        actor_name_en: this.createInfo[0].val,
        actor_name_zh_hk: this.createInfo[1].val,
        sex: this.sex,
        actor_name_other: this.createInfo[2].val,
        area: this.area,
        remarks: this.remarks.name,
        revise
      }
      console.log(data)
      createActor(data)
        .then((res) => {
          console.log(res)
          this.$router.push({
            name: 'actor-info',
            query: {
              type: 'review',
              id: res.data.id
            }
          })
          this.pageId = res.data.id
          this.pageType = 'review'
          this.getActorDetial()
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  mounted() {
    if (this.pageId) {
      this.getActorDetial()
    }
  },
  created() {
    this.pageId = this.$route.query.id
    this.pageType = this.$route.query.type
  }
}
</script>

<style lang="scss" scoped>
.title-top {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.title {
  width: 100%;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin: 20px 0;
}
</style>
