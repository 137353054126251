<template>
  <DistributorForm
  :loading.sync="loading"
    :title="title"
    :items="items"
    :type="type"
    @reLoading="getDistributorDetail"
  />
</template>
<script>
import DistributorForm from '../components/filmDistributorForm.vue'
import {getDistributorDetail} from '@/api/film-material-management/distributor'

export default {
  components: {
    DistributorForm,
  },
  data() {
    return {
      loading:false,
      title: '發行商',
      type:'edit',
      items: [
        {
          distributor_name_en: '',
          distributor_name_zh_hk: '',
          distributor_code: '',
          remarks: '',
          old_record: []
        },
      ],
    }
  },
  methods: {
    getDistributorDetail() {
      this.loading = true

      getDistributorDetail(this.pageId).then(res => {
        console.log(res)
        const date = new Date()
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
        const defaultDate = `${firstDay.getFullYear()}-${firstDay.getMonth() + 1}-${firstDay.getDate()}`
        this.items = [
          {
            id: this.pageId,
            distributor_name_en:  res.data.distributor_name_en,
            distributor_name_zh_hk: res.data.distributor_name_zh_hk,
            distributor_code: res.data.distributor_code,
            remarks: res.data.remarks,
            old_record: []
          },
        ]
        if (!((res.data.old_record).length)) {
          this.items[0].old_record = []
        } else { 
          res.data.old_record.forEach(item => {
            this.items[0].old_record.push(
              { 
                id:item.id,
                code:item.code,
                name_en:item.name_en,
                name_zh_hk:item.name_zh_hk,
                change_date:item.change_date,
                disabled:true
              }
            )
          })
        }
        this.loading = false
        console.log(this.items)
      }).catch(error => {
        this.loading = false
        console.log(error)
      })
    },
  },
  mounted() {
    if (this.pageId) {
      this.getDistributorDetail()
    }
    
  },
  created() {
    this.pageId = this.$route.query.id
  }
}
</script>
