import Vue from "vue";
import {
  ToastPlugin,
  ModalPlugin,
  BootstrapVue,
  BootstrapVueIcons
} from "bootstrap-vue";
// import { Pagination, Loading, Select, Option, OptionGroup, Tabs} from 'element-ui'
import ElementUI, { Loading } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/scss/element-variables.scss";
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";
import VueCompositionAPI from "@vue/composition-api";
import CommonPlugin from "@/utils/common-methods"; // 假设 common-methods.js 放在项目根目录下
import moment from "moment";

// 全局引入vue-codemirror
import VueCodemirror from "vue-codemirror";
import "codemirror/lib/codemirror.css";
import i18n from "@/libs/i18n";
import router from "./router";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/acl";
import "@/libs/portal-vue";
import "@/libs/clipboard";
import "@/libs/toastification";
import "@/libs/sweet-alerts";
import "@/libs/vue-select";
import "@/libs/tour";

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require("@core/assets/fonts/feather/iconfont.css"); // For form-wizard

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
moment.defaultFormat = "DD-MM-YYYY";
Vue.prototype.$moment = moment;
Vue.use(ElementUI);
Vue.use(Loading.directive);
Vue.prototype.$loading = Loading.service;
locale.use(lang);
Vue.use(VueCodemirror);
Vue.config.productionTip = false;
Vue.use(
  VueCodemirror /* { 
  options: { theme: 'base16-dark', ... },
  events: ['scroll', ...]
} */
);
Vue.use(CommonPlugin);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount("#app");
