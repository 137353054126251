import request from '@/utils/request'

// get genreList API
export function getGenreApi (params) {
  return request({
    url: '/api/filmCat',
    method: 'GET',
    params
  })
}
//get Genre Detail
export function getGenreDetail(id) {
  return request({
    url: `/api/filmCat/${id}`,
    method: 'GET',
  })
}
//create Genre
export function createGenre(data) {
  return request({
    url: '/api/filmCat',
    method: 'POST',
    data
  })
}
//edit Genre
export function editGenre(id, data) {
  return request({
    url: `/api/filmCat/${id}`,
    method: 'PUT',
    data
  })
}
//delete Genre
export function deleteGenre(id) {
  return request({
    url: `/api/filmCat/${id}`,
    method: 'DELETE',
  })
}