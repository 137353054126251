var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"management-center"}},[_c('b-card',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('div',{staticClass:"title"},[_c('p',[_vm._v("在資料庫內未有記錄之電影")])]),_c('div',[_c('b-button',{staticClass:"handle-button",attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){return _vm.returnRecord()}}},[_vm._v("回目錄")])],1),_c('div',{staticClass:"tit"},[_c('span',[_vm._v("在資料庫內未有記錄之電影")]),_c('span',[_vm._v("共 "+_vm._s(_vm.tableData.length)+" 項")])]),_c('b-table',{attrs:{"bordered":"","striped":"","hover":"","responsive":"","items":_vm.tableData,"fields":_vm.$store.state.highlightCol.firTable
          ? _vm.$store.state.newTableFields
          : _vm.tableFields},on:{"head-clicked":function($event){return _vm.commonHighLightCol(
          $event,
          _vm.tableFields,
          _vm.$store.state.newTableFields,
          'firTable'
        )}},scopedSlots:_vm._u([{key:"cell(action)",fn:function(row){return [_c('b-button',{staticClass:"handle-button",staticStyle:{"width":"84px"},attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){return _vm.goPage(
              'revise-record-add',
              row.item.pos_id,
              row.item.master_id,
              row.item
            )}}},[_vm._v(" 作出修訂 ")])]}}])}),_c('div',{},_vm._l((_vm.linkToPage),function(item,index){return _c('b-button',{key:index,staticClass:"d-block",staticStyle:{"text-decoration":"underline"},attrs:{"variant":"link"},on:{"click":function($event){return _vm.$router.push({ name: item.link })}}},[_c('i',{staticClass:"el-icon-caret-right"}),_vm._v(" "+_vm._s(item.name))])}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }