import request from '@/utils/request'

// get film-masterList api
export function getFilmMasterApi (params) {
  return request({
    url: '/api/film-master',
    method: 'GET',
    params
  })
}
// get film-masterDetial
export function getFilmMasterDetail (id) {
  return request({
    url: `/api/film-master/${id}`,
    method: 'GET',
  })
}
//create film-master
export function createFilmMaster (data) {
  return request({
    url: '/api/film-master',
    method: 'POST',
    data
  })
}
//edit film-master
export function editFilmMaster (id, data) {
  return request({
    url: `/api/film-master/${id}`,
    method: 'PUT',
    data
  })
}
export function creatFilmMaster (data) {
  return request({
    url: '/api/film-master',
    method: 'POST',
    data
  })
}
//delect film-master
export function deleteFilmMaster (id) {
  return request({
    url: `/api/film-master/${id}`,
    method: 'DEL',
  })
}

//get circuit-films
export function getCircuitFilms (id,params) {
  return request({
    url: `/api/${id}/circuit-films`,
    method: 'GET',
    params
  })
}

export function nextMasterId () {
  return request({
    url: '/api/film-master-next-estimate-id',
    method: 'GET',
  })
}
