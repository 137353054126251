import {
  excelExport
} from '@/api/record.js'
import XLSX from "xlsx-js-style";
import {
  getYearExport
} from '@/api/specialRecord'
const commonMixin = {
  methods: {
    commonHighLightCol(event, tableFields, newTableFields, TaNo) {
      this.$store.state.highlightCol[TaNo] = true //此表格是否高亮
      tableFields.map((item) => {
        if (item.key) {
          if (event === item.key && (event !== 'Action' || event !== 'action') && (item.sortable || item.sortKey)) {
            item.variant = 'info'
          } else {
            item.variant = ''
          }
        }
        newTableFields.push(item)
      })
    },
    excelExport(type, value, value2) {
      if (window.innerWidth > 990) {
        this.$alert('數據下載中，請勿離開...', 'Excel Export', {
          showClose: false,
          showConfirmButton: false,
          closeOnClickModal: false,
          closeOnPressEscape: false
        })
      } else {
        this.$message({
          message: '數據下載中，請勿離開...',
          type: 'warning'
        });
        this.showExportText = true
      }
      if (this.$route.path === '/report/report-year-version-detail' || this.$route.path === '/report/report-year-production-country') {
        const val = {
          year: this.year,
          search: this.searchVal,
          country: this.countryVal,
          area: this.area
        }
        getYearExport(val).then((res) => {
          // console.log(res)
          console.log(res)
          this.exportData = res.data
          if (window.innerWidth < 990) {
            this.$message({
              message: '數據已加載完成',
              type: 'success'
            });
            this.showExportText = false
          }
          const info = this.exportExcel()
          this.excelFun(info)
        })
      } else {
        console.log(this.title_num);
        const data = {
          day: '',
          month: '',
          film_master_id: '',
          area: sessionStorage.getItem('area') || "",
          date_start: '',
          date_end: '',
          not_match_ok: this.title_num === '16.1.C' ? 1 : "",
          region: this.areaSelect || "",
          circuit: this.circuitVal || "",
          theatre: this.theatreVal || "",
          show_all_film: this.isShowAllFilm || ""
        }
        if (type === 'day') {
          data.day = value
        } else if (type === 'month') {
          console.log();
          if (this.$moment(value).format('MM') === this.$moment(value2).format('MM')) {
            data.date_start = value
            data.date_end = value2
          } else {
            data.month = this.$moment(value).format('YYYY-MM')
          }
        } else {
          data.film_master_id = value
        }
        excelExport(data).then(res => {
          this.excelData = res
          if (window.innerWidth < 990) {
            this.$message({
              message: '數據已加載完成',
              type: 'success'
            });
            this.showExportText = false
          }
          const info = this.exportExcel()
          this.excelFun(info)
        })
      }

    },
    excelFun(info, dataType) {
      const worksheet = XLSX.utils.aoa_to_sheet(info.excelData);
      const width = [];
      for (let index = 0; index < info.header.length; index++) {
        width.push({
          width: 18
        }); //設置單元格寬度
      }
      worksheet["!cols"] = width;
      if (info.merge) {
        const merges = [
          //合并單元格
          {
            s: {
              r: info.data.length,
              c: 0
            },
            e: {
              r: info.data.length,
              c: 1
            }
          }
        ];
        worksheet[!"merges"] = merges;
      }
      if (info.setDateValue) {
        info.data.map((item, index) => {
          info.dateCol.map(col => {
            worksheet[`${col.title}${ index + col.index}`].z = "yyyy/m/d h:mm";
          })
        });
      }
      if (info.numFmt) {
        info.numFmtList.map(num => {
          info.data.map((el, index) => {
            if (el.show !== 0 || el.TA !== 0 || el.TG !== 0) {
              worksheet[`${num.title}${index + num.index}`].s = {
                numFmt: "#,##"
              };
            }
          })

          //total 千分位
          if (info.hasTotal) {
            worksheet[`${num.title}${info.data.length + num.index}`].s = {
              numFmt: "#,##"
            };
          }
        })
      }
      // 創建一個工作簿對象
      const workbook = XLSX.utils.book_new();
      // 將工作表添加到工作簿中
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      // 將工作簿保存爲XLSX文件
      const binaryData = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "binary"
      });
      const blob = new Blob([this.s2ab(binaryData)], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", info.fileName);
      document.body.appendChild(link);
      link.click();
      if (window.innerWidth > 990 && !dataType) {
        this.$router.go(0)
      }
    },
    s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buf;
    },
    excelDateFormat(date) {
      let isDate = ''
      if (date === '0001-01-01' || date === '0000-00-00') {
        isDate = '-'
      } else if (!isNaN(new Date(date).getTime())) {
        isDate = new Date(date)
      } else {
        isDate = date
      }
      return isDate
    },
    dateOffFormat(date_off, date_release) {
      let dateOff = ''
      if (date_off < '1900-01-01' && date_release < '1900-01-01') {
        return ""
      }
      if (
        (date_off === '0001-01-01' || date_off === '0000-00-00') &&
        this.$moment(date_release, 'YYYY-MM-DD') <=
        this.$moment(new Date(), 'YYYY-MM-DD')
      ) {
        dateOff = '上映中'
      } else if (
        (date_off === '0001-01-01' || date_off === '0000-00-00') &&
        this.$moment(date_release, 'YYYY-MM-DD') >
        this.$moment(new Date(), 'YYYY-MM-DD')
      ) {
        dateOff = '未上映'
      } else if (isNaN(new Date(date_off))) {
        dateOff = date_off
      } else {
        dateOff = new Date(date_off)
      }
      return dateOff
    },
    isValidDate(date) {
      return this.$moment(date).isValid() ? this.$moment(date).format() : date
    },
    getAlphabetLetter(index) {
      // 将索引转换为对应的英文字母
      const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      return alphabet[index]
    },
    getFilmSearchName(item) {
      let search_split = ''
      if (item.title_name.includes('*')) {
        const search_en = item.title_name.split('*')[1]
        if (search_en.includes('(')) {
          search_split = search_en.split('(').slice(0, -1).join('(')
        } else {
          search_split = search_en
        }
      } else {
        search_split = item.title_name
      }
      return search_split
    },
    getCookie(name) {
      const cookies = document.cookie.split(";");
      for (const cookie of cookies) {
        const [cookieName, cookieValue] = cookie
          .split("=")
          .map((c) => c.trim());
        if (cookieName === name) {
          return cookieValue;
        }
      }
      return null;
    },
  }
}
// 创建一个Vue插件
const CommonPlugin = {
  install(Vue) {
    Vue.mixin(commonMixin)
  }
}

export default CommonPlugin