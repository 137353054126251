import { render, staticRenderFns } from "./16.10Record.vue?vue&type=template&id=14afbd9f&scoped=true&"
import script from "./16.10Record.vue?vue&type=script&lang=js&"
export * from "./16.10Record.vue?vue&type=script&lang=js&"
import style0 from "./16.10Record.vue?vue&type=style&index=0&id=14afbd9f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14afbd9f",
  null
  
)

export default component.exports