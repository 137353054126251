<template>
  <div>
    <b-card v-loading="optionsLoding">
      <div class="title">
        <p>
          <span>{{
            pagePath == "/dashboard/create-admin-properties-options"
              ? "創立"
              : "修訂"
          }}</span
          >屬性選項
        </p>
      </div>
      <div v-if="pagePath == '/dashboard/edit-admin-properties-options'">
        <p class="sub-title">已在案之選項</p>
        <div class="mt-1">
          <table width="100%">
            <thead>
              <tr>
                <th style="width: 150px"></th>
                <th>排列次序</th>
                <th>Selection</th>
                <th>選項名稱（中文説明）</th>
                <th>默認選項</th>
              </tr>
            </thead>
            <tbody>
              <tr class="sub-title sub-bg">
                <td colspan="5">
                  <div v-for="(item, index) in options" :key="index">
                    <span v-if="optionType == item.value">{{ item.text }}</span>
                  </div>
                </td>
              </tr>
              <tr v-for="(cateItem, cateIndex) in selectList" :key="cateIndex">
                <td>
                  <span v-if="pageId == cateItem.id" class="edit-select"
                    >（修訂之中的項目）</span
                  >
                </td>
                <td>{{ cateIndex + 1 }}</td>
                <td :style="pageId == cateItem.id ? nameColor : ''">
                  {{ cateItem.name_en }}
                </td>
                <td :style="pageId == cateItem.id ? nameColor : ''">
                  {{ cateItem.name_zh_hk }}
                </td>
                <td>
                  <div
                    v-if="
                      adminStaff.setDefault == false && cateItem.is_default == 1
                    "
                  >
                    <feather-icon
                      style="color: #000"
                      icon="ArrowLeftIcon"
                    ></feather-icon>
                    默認選項
                  </div>
                  <div
                    v-if="
                      adminStaff.setDefault == true && pageId == cateItem.id
                        ? true
                        : false
                    "
                  >
                    <feather-icon
                      style="color: #000"
                      icon="ArrowLeftIcon"
                    ></feather-icon>
                    默認選項
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="line"></div>
      </div>
      <div class="edit-font">
        <p class="sub-title" v-for="(item, index) in options" :key="index">
          <span v-if="optionType == item.value">{{
            pagePath == "/dashboard/create-admin-properties-options"
              ? "創立"
              : "修訂"
          }}</span
          ><span v-if="optionType == item.value">
            {{ item.text }} 選項(Admin Staff)</span
          >
        </p>
        <div class="d-flex align-items-center mt-1 pt-1 case-tank">
          <b-col sm="2">
            <label for="">Selection(XML Value) :</label>
          </b-col>
          <b-col sm="8">
            <b-form-input type="text" v-model="adminStaff.selection" />
          </b-col>
        </div>
        <div class="d-flex align-items-center mt-1 pt-1 case-tank">
          <b-col sm="2">
            <label for="">選項名稱(中文説明) :</label>
          </b-col>
          <b-col sm="8">
            <b-form-input type="text" v-model="adminStaff.name_cn" />
          </b-col>
        </div>
        <div class="d-flex align-items-center mt-1 pt-1 case-tank">
          <b-col sm="2">
            <label for="">預定為默認選項(Set"Selected") :</label>
          </b-col>
          <b-col sm="8">
            <b-form-checkbox
              id="checkbox-1"
              @change="checkChange"
              v-model="adminStaff.setDefault"
              name="checkbox-1"
            >
            </b-form-checkbox>
          </b-col>
        </div>
        <div class="d-flex align-items-center mt-1 pt-1 case-tank">
          <b-col sm="2">
            <label for="">備注(Remarkes) :</label>
          </b-col>
          <b-col sm="8">
            <b-form-textarea
              id="textarea-small"
              size="sm"
              v-model="adminStaff.remarks"
              placeholder="Remarks"
            ></b-form-textarea>
          </b-col>
        </div>
        <div class="d-flex align-items-center mt-1 pt-1 case-tank">
          <b-col sm="2"> </b-col>
          <b-col sm="8">
            <b-button
              size="sm"
              class="mr-2"
              style="width: 100%"
              @click="saveInfo"
              variant="outline-primary"
            >
              Save / 存檔
            </b-button>
          </b-col>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  getManageProperties,
  editManageProperties,
  creatManageProperties
} from "@/api/properties";

export default {
  data() {
    return {
      optionsLoding: false,
      nameColor: {
        color: "#fa0606"
      },
      options: [
        { text: "使用者類別", value: "user_type" },
        { text: "影片格式", value: "film_format" },
        { text: "音軌語言", value: "film_language" },
        { text: "月份", value: "month" },
        { text: "開拍國家", value: "film_country" },
        { text: "電影評級", value: "film_rating" },
        { text: "視像效果", value: "film_visual_effect" },
        { text: "分區", value: "film_region" },
        { text: "字幕語言", value: "film_title_language" },
        { text: "系統語言", value: "manage-properties" },
        { text: "影片輪次", value: "film_version" }
      ],
      adminStaff: {
        selection: "",
        name_cn: "",
        setDefault: "",
        remarks: ""
      },
      pageId: "",
      optionType: "",
      pagePath: "",
      selectList: [],
      pageIndex: ""
    };
  },
  computed: {},
  methods: {
    saveInfo() {
      if (this.pagePath === "/dashboard/edit-admin-properties-options") {
        this.editManageProperties();
      } else {
        this.creatManageProperties();
      }
      this.$router.go(-1);
    },
    checkChange() {
      if (this.adminStaff.setDefault === false) {
        this.selectList[this.pageIndex].is_default = 0;
      }
    },
    getManageProperties() {
      this.optionsLoding = true;
      getManageProperties(this.optionType)
        .then((res) => {
          console.log(res.data);
          this.selectList = [];
          res.data.map((el) => {
            if (el.status !== "1") {
              this.selectList.push(el);
            }
          });
          const adminStaff = {
            selection: this.selectList[this.pageIndex].name_en,
            name_cn: this.selectList[this.pageIndex].name_zh_hk,
            setDefault: this.selectList[this.pageIndex].is_default !== 0,
            remarks: this.selectList[this.pageIndex].remarks
          };
          this.adminStaff = adminStaff;
          this.optionsLoding = false;
        })
        .catch((error) => {
          console.log(error);
          this.optionsLoding = false;
        });
    },
    editManageProperties() {
      const data = {
        name_en: this.adminStaff.selection,
        name_zh_hk: this.adminStaff.name_cn,
        remarks: this.adminStaff.remarks,
        is_default: this.adminStaff.setDefault === false ? 0 : 1
      };
      editManageProperties(this.pageId, data)
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    creatManageProperties() {
      const data = {
        name_en: this.adminStaff.selection,
        name_zh_hk: this.adminStaff.name_cn,
        remarks: this.adminStaff.remarks,
        is_default:
          this.adminStaff.setDefault === false ||
          this.adminStaff.setDefault === ""
            ? 0
            : 1,
        type: this.optionType
      };
      creatManageProperties(data)
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  created() {
    this.pageId = this.$route.query.id;
    this.optionType = this.$route.query.type;
    this.pageIndex = this.$route.query.index;
    this.pagePath = this.$route.path;
    if (this.pagePath === "/dashboard/edit-admin-properties-options") {
      this.getManageProperties();
    }
  }
};
</script>

<style lang="scss" scoped>
.sub-title {
  width: 100%;
  color: #0e72bc;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}
.sub-bg {
  background: #f1feff;
}
.edit-select {
  text-align: right;
}
.line {
  height: 1.5px;
  background: #0e72bc;
  width: 100%;
  margin: 20px 0;
}
</style>
