<template>
  <section id="management-center" v-loading="loading">
    <b-card>
      <div class="title">
        <p>訂正票房資料庫記錄</p>
      </div>
      <div class="d-flex mb-1">
        <b-button-group size="sm" class="mr-2">
          <b-button
            @click="selectFilmType(0)"
            :variant="isOldFilm ? '' : 'primary'"
            >新電影記錄</b-button
          >
          <b-button
            @click="selectFilmType(1)"
            :variant="isOldFilm ? 'primary' : ''"
            >舊電影記錄</b-button
          >
        </b-button-group>
        <b-form-input
          style="width: 35%"
          v-model="searchFilm"
          type="text"
          class=""
          @change="getFilmMasterApi()"
          placeholder="Search Film (e.g.: M-film master id ,F-film id)"
        ></b-form-input>
        <span class="ml-2">
          <b-button variant="primary" @click="getFilmMasterApi()">
            Search
          </b-button>
        </span>
      </div>
      <b-table
        bordered
        striped
        hover
        :items="items"
        :fields="
          $store.state.highlightCol.firTable
            ? $store.state.newTableFields
            : fields
        "
        @head-clicked="
          commonHighLightCol(
            $event,
            fields,
            $store.state.newTableFields,
            'firTable'
          )
        "
      >
        <template #head()="data">
          <div
            v-if="data.field.sortKey"
            class="cursor-pointer"
            @click="orderHead(data.field.sortKey)"
          >
            {{ data.field.label }}
          </div>
          <div v-else>{{ data.field.label }}</div>
        </template>
        <template #cell(name_en)="data">
          <div @click="editRecord(data.item, 'review')">
            <span size="sm" class="mr-2 table-name">
              {{ data.item.name_en }}
            </span>
          </div>
        </template>
        <template #cell(name_cn)="data">
          <div @click="editRecord(data.item, 'review')">
            <span size="sm" class="mr-2 table-name">
              {{ data.item.name_cn }}
            </span>
          </div>
        </template>
        <template #cell(action)="data">
          <b-button
            size="sm"
            class="mr-2"
            @click="editRecord(data.item, 'edit')"
          >
            作出修訂
          </b-button>
          ({{ data.item.film_count }})
        </template>
      </b-table>
      <div class="pagination mt-3">
        <el-pagination
          class=""
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[20, 50, 100]"
          :page-size="rows"
          layout=" sizes, prev, pager, next"
          :total="pageTotal"
        >
        </el-pagination>
      </div>
      <p>紅色highligh代表此電影為刪除狀態</p>
    </b-card>
  </section>
</template>
<script>
import { getFilmMasterApi } from "@/api/film-material-management/filmMaster"
export default {
  data() {
    return {
      isOldFilm: 0,
      loading: false,
      searchFilm: "",
      sort: "id",
      direct: "desc",
      rows: 20,
      pageTotal: 0,
      currentPage: 1,
      path: "revise-record-detail",
      fields: [
        {
          key: "Master_Id",
          label: "Film Master ID (RUN)",
          sortKey: "id",
        },
        {
          key: "name_en",
          label: " Film Name",
          sortKey: "name_english",
        },
        {
          key: "name_cn",
          label: " 電影名稱",
          sortKey: "name_chinese",
        },
        {
          key: "action",
          label: "",
        },
      ],

      items: [],
    }
  },
  created() {
    this.getFilmMasterApi()
  },
  methods: {
    selectFilmType(val) {
      this.isOldFilm = val
      this.getFilmMasterApi()
    },
    orderHead(key) {
      console.log(key)
      this.sort = key
      if (this.direct === "desc") {
        this.direct = "asc"
      } else {
        this.direct = "desc"
      }
      this.getFilmMasterApi()
    },
    getFilmMasterApi() {
      this.loading = true
      if (this.searchFilm) {
        this.currentPage = 1
      }
      const data = {
        search: this.searchFilm,
        page: this.currentPage,
        count: this.rows,
        sort: this.sort,
        direct: this.direct,
        arch: this.isOldFilm,
      }
      getFilmMasterApi(data)
        .then((res) => {
          console.log(res)
          this.items = res.data.data
          this.pageTotal = res.data.total
          const arr = []
          this.items.forEach((el) => {
            const obj = new Object()
            obj.id = el.id
            obj.Master_Id = `${el.id} (${el.run_count})`
            obj.name_en = el.name_english
            obj.name_cn = el.name_chinese
            obj.film_count = el.film_count
            if (
              el.status.includes("Delete") ||
              el.status.includes("delete") ||
              el.status.includes("del") ||
              el.status.includes("Del")
            ) {
              obj._rowVariant = "danger"
            }
            arr.push(obj)
          })
          this.items = arr
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    goPage() {
      this.$router.push({ name: "revise-record-detail" })
    },
    handleSizeChange(val) {
      this.rows = val
      this.getFilmMasterApi()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getFilmMasterApi()
    },
    editRecord(item, istype) {
      console.log(item)
      this.isId = item.id
      if (istype === "edit") {
        this.$router.push({
          path: this.path,
          query: { type: "edit", id: this.isId },
        })
      } else {
        this.$router.push({
          path: this.path,
          query: { type: "review", id: this.isId },
        })
      }
    },
  },
  computed: {
    dataListStart() {
      if (this.rows === 5) {
        return (this.currentPage - 1) * 5 + 1
      } else {
        return (this.currentPage - 1) * 2 * (this.rows / 2) + 1
      }
    },
    dataListStop() {
      if (this.currentPage === Math.ceil(this.pageTotal / this.rows)) {
        return this.pageTotal
      } else {
        return this.rows * this.currentPage
      }
    },
  },
}
</script>
<style lang="scss" scope="scoped">
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
</style>
