<template>
  <section>
    <b-card>
      <div class="title">
        <p>22.4 B.O. for the week</p>
      </div>
      <b-row>
        <b-col
          cols="12"
          class="d-flex align-items-center justify-content-start"
        >
          <b-button
            size="sm"
            variant="outline-primary"
            class="mr-1"
            @click="goBackReport"
          >
            回上頁
          </b-button>
          <b-button
            @click="exportToExcel()"
            size="sm"
            variant="outline-primary"
            class="mr-1"
          >
            Excel
          </b-button>
        </b-col>
        <b-col
          cols="12"
          class="d-flex align-items-center justify-content-start"
        >
          <p class="sub-title" style="width: 30%">
            已選擇的日子:<span class="text-red">
              {{ startDate }} 至 {{ endDate }}
            </span>
          </p>
        </b-col>
      </b-row>
      <div>
        <template>
          <div v-loading="tableLoading">
            <b-table
              bordered
              :items="items"
              :fields="
                $store.state.highlightCol.firTable
                  ? $store.state.newTableFields
                  : fields
              "
              @head-clicked="
                commonHighLightCol(
                  $event,
                  fields,
                  $store.state.newTableFields,
                  'firTable'
                )
              "
              :responsive="true"
            >
              <template #thead-top="">
                <b-tr>
                  <b-th colspan="2">Total Receipt for the week</b-th>
                  <b-th colspan="6"
                    >Overall B.O. for the week：{{
                      parseFloat(Number(dataInfo.overall_bo)).toLocaleString()
                    }}</b-th
                  >
                  <b-th colspan="6"
                    >Overall Accum. B.O. for the week：{{
                      parseFloat(
                        Number(dataInfo.overall_accum_bo)
                      ).toLocaleString()
                    }}</b-th
                  >
                </b-tr>
              </template>
              <!-- table head width start -->
              <template #head(showsno)>
                <div style="width: 70px; text-wrap: wrap">No. of Shows</div>
              </template>
              <template #head(theatreno)>
                <div style="width: 70px; text-wrap: wrap">No. of Theatres</div>
              </template>
              <template #head(average)>
                <div style="width: 70px; text-wrap: wrap">Show Aver.</div>
              </template>
              <template #head(week_bo)>
                <div style="width: 100px; text-wrap: wrap">
                  Total B.O. this week
                  {{
                    isArea === "Hong Kong"
                      ? "(HKD)"
                      : isArea === "macau"
                      ? "(MOP)"
                      : ""
                  }}
                </div>
              </template>
              <template #head(total_bo)>
                <div style="width: 100px; text-wrap: wrap">
                  Total B.O. to End date
                  {{
                    isArea === "Hong Kong"
                      ? "(HKD)"
                      : isArea === "macau"
                      ? "(MOP)"
                      : ""
                  }}
                </div>
              </template>
              <!-- table head width end -->
              <template #cell()="data">
                <div v-if="data.field.numType">
                  {{ parseFloat(Number(data.value)).toLocaleString() }}
                </div>
                <div v-else>{{ data.value }}</div>
              </template>
              <template #cell(release_date)="data">
                <div>
                  {{ $moment(data.value).format() }}
                </div>
              </template>
              <template #cell(days)="data">
                <div
                  :style="`color:${data.item.days === 'No_Show' ? 'red' : ''}`"
                >
                  {{
                    data.value !== "No_Show"
                      ? parseFloat(Number(data.value)).toLocaleString()
                      : data.value
                  }}
                </div>
              </template>
              <template #cell(distributor)="data">
                <div v-if="data.value && data.value.length > 0">
                  <div v-for="(item, index) in data.value" :key="index">
                    {{ item.distributor_name_en }}-{{
                      item.distributor_name_zh_hk
                    }}
                  </div>
                </div>
                <div v-else>-</div>
              </template>
              <template #cell(company)="data">
                <div v-if="data.value && data.value.length > 0">
                  <div v-for="(item, index) in data.value" :key="index">
                    {{ item.production_name_en }}-{{
                      item.production_name_zh_hk
                    }}
                  </div>
                </div>
                <div v-else>-</div>
              </template>
            </b-table>
          </div>
        </template>
      </div>
    </b-card>
  </section>
</template>
<script>
import { getReportWeek } from "@/api/specialRecord";
// import XLSX from 'xlsx'
import XLSX from "xlsx-js-style";
export default {
  components: {},
  data() {
    return {
      isArea: window.sessionStorage.getItem("area") || "",
      parseFloatList: ["total_bo", "week_bo", "peopleavg"],
      tableLoading: false,
      selectDropdown: true,
      tabletop: true,
      path: "special-film-record",
      startDate: "",
      endDate: "",
      location: "",
      selectValue: "All locations",
      selectOptions: ["All locations", "Hong Kong", "Non Hong Kong"],
      fields: [
        {
          key: "film",
          label: "Film Title",
          sortable: true
        },
        {
          key: "distributor",
          label: "Distributor",
          sortable: true
        },
        {
          key: "source",
          label: "Production Country",
          sortable: true
        },
        {
          key: "company",
          label: "Production Company",
          sortable: true
        },
        {
          key: "release_date",
          label: "Release Date",
          sortable: true
        },
        {
          key: "days",
          label: "Days",
          sortable: true,
          numType: true
        },
        {
          key: "showsno",
          label: "No. of Shows",
          sortable: true,
          numType: true
        },
        {
          key: "theatreno",
          label: "No. of Theatres",
          sortable: true,
          numType: true
        },
        {
          key: "average",
          label: "Show Aver.",
          sortable: true,
          numType: true
        },
        {
          key: "theatreavg",
          label: "Theatre Aver.",
          sortable: true,
          numType: true
        },
        {
          key: "visits",
          label: "Adm.",
          sortable: true,
          numType: true
        },
        {
          key: "peopleavg",
          label: "Aver Adm.",
          sortable: true,
          numType: true
        },
        {
          key: "week_bo",
          label: "Total B.O. this week",
          sortable: true,
          numType: true
        },
        {
          key: "total_bo",
          label: "Total B.O. to End date",
          sortable: true,
          numType: true
        }
      ],
      items: [],
      dataInfo: {}
    };
  },
  methods: {
    exportToExcel() {
      console.log(this.exportData);
      //tabel list
      const header = [];
      this.fields.map((el) => {
        header.push(el.label);
      });
      const dataList = [];
      this.items.map((item) => {
        const distributors = [];
        if (item.distributor && item.distributor.length > 0) {
          item.distributor.map((distr) => {
            distributors.push(
              `${distr.distributor_name_zh_hk} ${distr.distributor_name_en}`
            );
          });
        }
        const company = [];
        item.company.map((com) => {
          company.push(
            `${com.production_name_en}-${com.production_name_zh_hk}`
          );
        });
        const list = [];
        list.push(
          item.film,
          distributors.length > 0 ? distributors.join("/") : "-",
          item.source,
          company.join(" / ") || "-",
          this.excelDateFormat(item.release_date),
          item.days,
          Number(item.showsno),
          Number(item.theatreno),
          Number(item.average),
          Number(item.theatreavg),
          Number(item.visits),
          Number(item.peopleavg),
          Number(item.week_bo),
          Number(item.total_bo)
        );
        dataList.push(list);
      });
      dataList.unshift(header);
      dataList.push([
        `Report Date/Time: ${this.$moment().format("YYYY-MM-DD/HH:mm")}`
      ]);
      const numFmt = header.slice(6, 14);
      const numList = [];
      numFmt.map((key) => {
        numList.push({
          title: this.getAlphabetLetter(header.indexOf(key)),
          index: 2
        });
      });
      const excelInfo = {
        type: "22.4",
        merge: true,
        setDateValue: false,
        data: this.items,
        dateCol: [],
        fileName: `Week B.O. Report (${this.startDate} to ${this.endDate}).xlsx`,
        header,
        excelData: dataList,
        numFmt: true,
        numFmtList: numList,
        hasTotal: false
      };
      this.excelFun(excelInfo, "noapi");
    },
    handleLocationChange() {
      this.location =
        this.selectValue === "All locations" ? "" : this.selectValue;
    },
    search() {
      this.getReport();
    },
    getReport() {
      this.tableLoading = true;
      const data = `date_start=${this.startDate}&date_end=${this.endDate}&area=${this.isArea}`;
      getReportWeek(data)
        .then((res) => {
          console.log(res);
          this.dataInfo = res;
          const arr = [];
          res.data.forEach((el) => {
            const obj = new Object();
            obj.film = el.film ? el.film.title_name : "-";
            obj.days = el.days === "-" ? "No_Show" : el.days;
            obj.source = el.film_master ? el.film_master.country : "-";
            obj.release_date = el.film.date_release;
            obj.showsno = el.num_show;
            obj.average = el.show_average;
            obj.theatreno = el.num_cinema;
            obj.theatreavg = el.theatre_average;
            obj.visits = el.ta;
            obj.peopleavg = el.admission_average;
            obj.week_bo = el.tg;
            obj.total_bo = el.total_bo_to_end;
            obj.distributor = el.film_master
              ? el.film_master.distributor_code_and_detail
              : [];
            obj.company = el.film_master
              ? el.film_master.film_extra_info.production_detail
              : [];

            arr.push(obj);
          });
          this.items = arr;
          this.tableLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.tableLoading = false;
        });
    },
    goBackReport() {
      this.$router.push({ name: this.path });
    }
  },
  mounted() {
    console.log(this.startDate);
    if (this.startDate) {
      this.getReport();
    }
  },
  created() {
    const currentDate = new Date(this.$route.query.data);
    this.startDate = this.$route.query.data;
    this.endDate = new Date(currentDate.setDate(currentDate.getDate() + 6))
      .toISOString()
      .split("T")[0];
    console.log(this.startDate, this.endDate);
  }
};
</script>
<style lang="scss">
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.table-name:hover {
  text-decoration: underline;
}
.line {
  height: 1.5px;
  background: #0e72bc;
  width: 100%;
  margin: 20px 0;
}
.sub-title {
  width: 100%;
  color: #0e72bc;
  font-size: 16px;
  font-weight: 500;
  margin: 20px 0;
}
.text-red {
  color: red;
}
</style>
