import request from "@/utils/request";

// get report for year
export function getReportYear(data) {
  return request({
    url: `/api/special-records/year?${data}`,
    method: "GET"
  });
}
// get report for year version detail
export function getReportYearVersionDetail(data) {
  return request({
    url: `/api/special-records/year-detail?${data}`,
    method: "GET"
  });
}
// get report for period
export function getReportPeriod(data) {
  return request({
    url: `/api/special-records/period?${data}`,
    method: "GET"
  });
}
// get report for period
export function getReportWeek(data) {
  return request({
    url: `/api/special-records/week?${data}`,
    method: "GET"
  });
}
// get report for highest
export function getHighest(data) {
  return request({
    url: `/api/special-records/highest?${data}`,
    method: "GET"
  });
}
// get report for highest day
export function getHighestDay(data) {
  return request({
    url: `/api/special-records/highest-day?${data}`,
    method: "GET"
  });
}

//export Excel 22.1 & 22.2
export function getYearExport(params) {
  return request({
    url: "/api/special-records/year-export",
    method: "GET",
    params
  });
}

//export Excel 22.5
export function getHighestExport(num) {
  return request({
    url: `/api/special-records/export/${num}`,
    method: "GET",
    responseType: "blob"
  });
}
