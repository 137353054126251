<template>
  <div>
    <b-card>
      <subTitle
        :info="info"
        :title="title"
        :date="queryVal.date"
        :type="routeType"
      />
      <div v-loading="loading">
        <b-table
          bordered
          striped
          hover
          responsive
          :items="tableData"
          :fields="
            $store.state.highlightCol.firTable
              ? $store.state.newTableFields
              : tableFields
          "
          @head-clicked="
            commonHighLightCol(
              $event,
              tableFields,
              $store.state.newTableFields,
              'firTable'
            )
          "
        >
          <template #thead-top="data">
            <b-tr>
              <b-th colspan="5"></b-th>
              <b-th> total ADM :{{ parseFloat(tableTotal["TA"]).toLocaleString()  }}</b-th>
              <b-th> total B.O. :{{ parseFloat(tableTotal["TG"]).toLocaleString() }}</b-th>
              <b-th></b-th>
            </b-tr>
          </template>
          <template #cell(show_time)="data">
            <div>
              {{ $moment(data.item.show_time).format("DD-MM-YYYY HH:mm:ss") }}
            </div>
          </template>
          <template #cell(TA)="data">
            <div>
              {{ parseFloat(data.item.TA).toLocaleString() }}
            </div>
          </template>
          <template #cell(TG)="data">
            <div>
              {{ parseFloat(data.item.TG).toLocaleString() }}
            </div>
          </template>
          <template v-slot:cell(Action)="data">
            <b-button
              style="width: 145px"
              v-if="data.item.show_time !== 'Total:'"
              size="sm"
              @click="checkxml(data.item)"
            >
              列出相關的xml記錄
            </b-button>
          </template>
        </b-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import { getEngByIDByTime } from "@/api/record.js";
import subTitle from "./component/subTitles.vue";

export default {
  components: { subTitle },
  data() {
    return {
      title: "按戲院顯示播放時間記錄",
      loading: false,
      queryVal: "",
      tableData: [],
      info: [
        { label: "Film Title", val: "" },
        { label: "Film ID Listed", val: "" },
        { label: "Date", val: "" },
        { label: "Theatre", val: "" }
      ],
      routeType: "",
      tableFields: [
        { key: "house_id", label: "House ID", sortable: true },
        { key: "effect", label: "Effect", sortable: true },
        { key: "run_ver", label: "Ver.", sortable: true },
        { key: "show_time", label: "Show Time", sortable: true },
        { key: "language", label: "Lang.", sortable: true },
        { key: "TA", label: "Total Adm.", sortable: true },
        { key: "TG", label: "Total B.O.", sortable: true },
        { key: "Action", label: "Action" }
      ],
      tableTotal: {}
    };
  },
  methods: {
    getEngByIDByTime() {
      this.loading = true;
      const data = {
        film_id: this.queryVal.film_id,
        date: this.queryVal.date,
        theatre_id: this.queryVal.theatre_id
      };
      getEngByIDByTime(data).then((res) => {
        this.loading = false;
        this.tableTotal = res;
        res.data.map((el) => {
          if (el.film) {
            this.tableData.push({
              film_id: el.film.id,
              house_id: el.house.house_name,
              screen_id: el.house.screen_id,
              effect: el.film.remark_2
                ? `${el.film.remark_1}[${el.film.remark_2}]`
                : el.film.remark_1,
              run_ver: el.film.run_ver.run_ver,
              show_time: el.show_time,
              theatre_name: el.theatre.full_name,
              language: el.film.language,
              showings: el.showings,
              TA: el.TA,
              TG: el.TG,
              title_name: el.film.title_name,
              theatre_id: el.theatre.id
            });
          }
        });
        this.info = [
          { label: "Film Title", val: this.tableData[0].title_name },
          { label: "Film ID Listed", val: this.tableData[0].film_id },
          { label: "Date", val: this.queryVal.date },
          { label: "Theatre", val: this.tableData[0].theatre_name }
        ];
      });
    },
    checkxml(item) {
      const query = {
        type: "xml",
        time: item.show_time,
        film_id: item.film_id,
        theatre_id: item.theatre_id,
        screen_id: item.screen_id,
        film_name: item.title_name,
        theatre_name: `${item.theatre_name}(${item.house_id})`,
        film_effect: item.effect
      };
      this.$router.push({
        path: "/record/version-film-record-by-xml",
        query
      });
    }
  },
  created() {
    this.queryVal = this.$route.query;
    this.routeType = this.queryVal.type;
    this.getEngByIDByTime();
  }
};
</script>

<style lang="scss" scoped></style>
