<template>
  <section>
    <b-card>
      <div class="title">
        <p>{{ title }}</p>
      </div>
      <div>
        <b-button size="sm" variant="outline-secondary" @click="back()">
          回目錄
        </b-button>
      </div>
      <div>
        <b-form ref="form">
          <b-row ref="row">
            <b-col cols="12" class="mt-1">
              <p class="sub-title">
                ID : {{ pageType == "create" ? "Create New ID" : id }}
              </p>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Title*" label-for="title" label-cols-md="3">
                <b-form-input
                  id="title"
                  label="Title"
                  style="width: 80%"
                  v-model="info.title"
                  name="info-title"
                  :disabled="disableChange"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Date*" label-for="date" label-cols-md="3">
                <datePickerCom
                  :pickTimeVal.sync="info.date"
                  :inputWidth="'80%'"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Banner*" label-for="banner" label-cols-md="3">
                <UploadImgCom
                  :pageType="pageType"
                  :bannerPath.sync="info.banner_path"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Content"
                label-for="content"
                label-cols-md="3"
              >
                <b-form-textarea
                  id="Content"
                  style="width: 80%; height: 200px"
                  v-model="info.paragraph"
                  :disabled="disableChange"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col cols="12" v-if="name === '新聞發佈'">
              <b-form-group
                label="PDF"
                label-for="uploadPDF"
                label-cols-md="3"
              >
                <el-upload
                  :file-list="fileListVal"
                  class="upload-demo"
                  :limit="1"
                  :action="`${env}/api/news/upload-news-file`"
                  :on-exceed="handleExceed"
                  :headers="{
                    Authorization: 'Bearer ' + loginCode,
                    Accept: 'application/json'
                  }"
                  :show-file-list="false"
                  name="file"
                  :on-error="uploadError"
                  :on-success="uploadFileSuccess"
                  :before-upload="beforeUpload"
                >
                  <el-button
                    class="upload-btn"
                    size="small"
                    round
                    icon="el-icon-plus"
                    >CHOOSE FILE</el-button
                  >
                </el-upload>
                <p class="mt-1" v-if="info.news_file && info.news_file.file_name">
                  <el-link
                    v-loading="fileLoading"
                    type="primary"
                    icon="el-icon-document"
                    :href="info.news_file.path"
                    target="_blank"
                    >{{ info.news_file.file_name }}</el-link
                  >
                </p>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col offset-md="6">
              <b-button
                variant="primary"
                class="mr-1"
                @click="changeType"
                v-if="pageType === 'view'"
              >
                Edit
              </b-button>
              <b-button
                v-if="pageType !== 'view'"
                variant="primary"
                class="mr-1"
                @click="submit"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-card>
  </section>
</template>
<script>
import UploadImgCom from "@/views/components/uploadImg.vue";
import datePickerCom from "@/views/components/datePicker.vue";
export default {
  props: {
    name: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    infoVal: {
      type: Object
    },
    id: {
      type: [Number, String]
    },
    bannerPath: {
      type: String,
      default: ""
    },
    fileList: {
      type: Array,
      default: []
    }
  },
  components: { UploadImgCom, datePickerCom },
  data() {
    return {
      fileLoading: false,
      pageType: "",
      date: "",
      loginCode: "",
      env: process.env.VUE_APP_BASE_API,
      showFile: true
    };
  },
  mounted() {
    this.pageType = this.$route.query.type;
    this.loginCode = this.getCookie("hk_box_key");
    if (this.pageType === "create") {
      this.showFile = false;
    }
  },
  watch: {},
  computed: {
    fileListVal: {
      get() {
        return this.fileList;
      },
      set(val) {
        this.$emit("update:fileList", val);
      }
    },
    disableChange() {
      if (this.pageType === "view") {
        return true;
      } else {
        return false;
      }
    },
    info: {
      get() {
        return this.infoVal;
      },
      set(val) {
        this.$emit("update:infoVal", val);
      }
    }
  },
  methods: {
    beforeUpload(file) {
      this.fileLoading = true;
    },
    handleExceed(files, fileList) {
      this.$message.warning(`只能上傳一個文件`);
    },
    uploadFileSuccess(response) {
      console.log(response);
      if (response) {
        this.$message({
          message: `上傳成功`,
          type: "success"
        });
        this.showFile = false;
        this.info.news_file_id = response.id;
        const name = response.path.split("?")[0].split("/");
        this.info.news_file.file_name = decodeURIComponent(
          name[name.length - 1]
        );
        this.fileListVal = [];
        this.fileLoading = false;
      }
    },
    uploadError(error) {
      const { message } = error;
      const errorM = JSON.parse(message);
      console.log(error.exception, "response");
      if (error) {
        this.$message.error(errorM.message);
      }
    },
    back() {
      const path = this.$route.meta.parent;
      this.$router.push({ path });
    },
    submit() {
      this.$emit("submit", this.info);
    },
    changeType() {
      this.$router.push({
        path: this.$route.path,
        query: { id: this.id, type: "edit", title: this.title }
      });
      this.pageType = "edit";
    }
  }
};
</script>
<style lang="scss">
.sub-title {
  width: 100%;
  color: #0e72bc;
  font-size: 16px;
  font-weight: 500;
  margin: 20px 0;
}
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.line {
  height: 3px;
  color: #0e72bc;
}
</style>
