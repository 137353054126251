import request from '@/utils/request'

// get manual box office
export function getUsers (params) {
  return request({
    url: `api/users`,
    method: "GET",
    params
  });
}

export function createUsers (data) {
  return request({
    url: 'api/users',
    method: 'POST',
    data
  })
}

export function deleteUser (id) {
  return request({
    url: `api/users/${id}`,
    method: 'DELETE'
  })
}

export function getUsersDetail (id) {
  return request({
    url: `api/users/${id}`,
    method: 'GET',
  })
}

export function editUsers (id, data) {
  return request({
    url: `api/users/${id}`,
    method: 'PUT',
    data
  })
}

export function getPermissionsList () {
  return request({
    url: 'api/role-permissions',
    method: 'GET'
  })
}
export function getPermissionsType (type) {
  return request({
    url: `api/role-permissions/${type}`,
    method: 'GET'
  })
}
export function checkRole (data) {
  return request({
    url: 'api/users-check-role',
    method: 'POST',
    data
  })
}