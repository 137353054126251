<template>
  <div>
    <b-card>
      <UploadOneRecord
        :title="title"
        :title_num="title_num"
        :val="val || ''"
        :val1="val1 || ''"
        :searchType="searchType"
        :titleText="titleText || ''"
        :showLocation="showLocation"
        :uploadDate="uploadDate"
        :filmIDList="filmIDList"
        v-if="
          title_num === '16.1' ||
          title_num === '16.1.R' ||
          title_num === '16.11.R' ||
          title_num === '16.1.C'
        "
      />
      <UploadTwoRecord
        :title="title"
        :title_num="title_num"
        :val="val || ''"
        :val1="val1 || ''"
        :searchType="searchType"
        :titleText="titleText || ''"
        :showLocation="showLocation"
        :uploadDate="uploadDate"
        :filmIDList="filmIDList"
        v-if="
          title_num === '16.2.1' ||
          title_num === '16.2.2' ||
          title_num === '16.2.3' ||
          title_num === '16.2.4' ||
          title_num === '16.2.5'
        "
      />
      <UploadThreeRecord
        :title="title"
        :title_num="title_num"
        :val="val || ''"
        :val1="val1 || ''"
        :searchType="searchType"
        :titleText="titleText || ''"
        :showLocation="showLocation"
        :uploadDate="uploadDate"
        :filmIDList="filmIDList"
        v-if="title_num === '16.3'"
      />
      <UploadEightRecord
        :title="title"
        :title_num="title_num"
        :val="val || ''"
        :val1="val1 || ''"
        :searchType="searchType"
        :titleText="titleText || ''"
        :showLocation="showLocation"
        :uploadDate="uploadDate"
        :filmIDList="filmIDList"
        v-if="title_num === '16.8' || title_num === '16.8.23'"
      />
      <UploadNineRecord
        :title="title"
        :title_num="title_num"
        :val="val || ''"
        :val1="val1 || ''"
        :searchType="searchType"
        :titleText="titleText || ''"
        :showLocation="showLocation"
        :uploadDate="uploadDate"
        :filmIDList="filmIDList"
        v-if="title_num === '16.9' || title_num === '16.9.23' || title_num === '16.9.23.N'"
      />
      <UploadTenRecord
        :title="title"
        :title_num="title_num"
        :val="val || ''"
        :val1="val1 || ''"
        :searchType="searchType"
        :titleText="titleText || ''"
        :showLocation="showLocation"
        :uploadDate="uploadDate"
        :filmIDList="filmIDList"
        v-if="title_num === '16.10'"
      />
      <UploadTwelveRecord
        :title="title"
        :title_num="title_num"
        :val="val || ''"
        :val1="val1 || ''"
        :searchType="searchType"
        :titleText="titleText || ''"
        :showLocation="showLocation"
        :uploadDate="uploadDate"
        :filmIDList="filmIDList"
        v-if="title_num === '16.12'"
      />
    </b-card>
  </div>
</template>

<script>
import UploadOneRecord from './uploadRecordsByCategory/16.1Record.vue'
import UploadTwoRecord from './uploadRecordsByCategory/16.2Record.vue'
import UploadThreeRecord from './uploadRecordsByCategory/16.3Record.vue'
import UploadEightRecord from './uploadRecordsByCategory/16.8Record.vue'
import UploadNineRecord from './uploadRecordsByCategory/16.9Record.vue'
import UploadTenRecord from './uploadRecordsByCategory/16.10Record.vue'
import UploadTwelveRecord from './uploadRecordsByCategory/16.12Record.vue'


export default {
  components: {
  
    UploadOneRecord,
    UploadTwoRecord,
    UploadThreeRecord,
    UploadEightRecord,
    UploadNineRecord,
    UploadTenRecord,
    UploadTwelveRecord
  },
  data() {
    return {
      pageTotal: 0,
      rows: 5,
      perPage: 1,
      currentPage: 1,
      searchType: null,
      val: null,
      val1: null,
      titleText: '',
      showLocation: false,
      uploadDate: '',
      filmIDList: '',
      title: '',
      isShowAllFilm: false,
    }
  },
  watch: {
    // $route(to) {
    //   if (to) {
    //     this.$router.go(0)
    //   }
    // }
  },
  computed: {
    dataListStart() {
      if (this.rows === 5) {
        return (this.currentPage - 1) * 5 + 1
      } else {
        return (this.currentPage - 1) * 2 * (this.rows / 2) + 1
      }
    },
    dataListStop() {
      if (this.currentPage === Math.ceil(this.pageTotal / this.rows)) {
        return this.pageTotal
      } else {
        return this.rows * this.currentPage
      }
    }
  },
  methods: {
    handleSizeChange(val) {
      this.rows = val
      this.getDataApi()
      console.log(val, 'handleSizeChange')
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getDataApi()
      console.log(val, 'handleCurrentChange')
    },
    
  },
  created() {
    this.searchType = this.$route.query.type
    this.val = this.$route.query.val
    this.val1 = this.$route.query.val1
    this.titleText = this.$route.query.titleText
    this.showLocation = this.$route.query.showLocation
    this.uploadDate = this.$route.query.uploadDate
    this.filmIDList = this.$route.query.filmIDList
    this.title = this.$route.query.title
    this.title_num = this.$route.query.title_num
  }
}
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.sec-title {
  color: #0e72bc;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid #0e72bc;
}
.w300 {
  width: 250px;
}
</style>
