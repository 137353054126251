<template>
  <div>
    <b-card>
      <div class="title">
        <p>請選擇電影</p>
      </div>
      <div>
        <b-button
          size="sm"
          variant="outline-secondary"
          class="handle-button mb-2"
          @click="returnRecord()"
        >
          回上頁
        </b-button>
      </div>
      <div class="search-group">
        <b-input-group class="input-group-merge search">
          <b-form-input
            v-model="search"
            placeholder="Search Film (Film_ID or Title_key e.g. : F-123 / T-123)"
            class="search-product"
          />
          <b-input-group-append is-text>
            <feather-icon icon="SearchIcon" class="text-muted" />
          </b-input-group-append>
        </b-input-group>
        <b-button
          size="sm"
          class="mr-2 mb-2 add-user"
          variant="outline-primary"
          @click="getBoxOfficesList()"
        >
          Search
        </b-button>
      </div>
      <div class="mb-1">
        <b style="color: #0e72bc">已選擇的日期: </b>
        <b style="color: red">{{ $moment($route.query.date).format() }}</b>
      </div>
      <div class="">
        <b style="color: #0e72bc">已選擇的戲院:</b>
        <b style="color: red">{{ $route.query.name }}</b>
      </div>
      <div v-loading="loading">
        <b-table
          bordered
          class="mt-2"
          striped
          hover
          responsive
          :items="tableData"
          :fields="
            $store.state.highlightCol.firTable
              ? $store.state.newTableFields
              : tableFields
          "
          @head-clicked="
            commonHighLightCol(
              $event,
              tableFields,
              $store.state.newTableFields,
              'firTable'
            )
          "
        >
          <template v-slot:cell(date_release)="rows">
            <div class="text-nowrap">{{ isValidDate(rows.value) }}</div>
          </template>
          <template v-slot:cell(date_off)="rows">
            <div class="text-nowrap">{{ isValidDate(rows.value) }}</div>
          </template>
          <template v-slot:cell(action)="rows">
            <b-button
              size="sm"
              class="mr-2"
              @click="
                goDetail(
                  'select-vison',
                  rows.item.film_master.id,
                  rows.item.film.title_name
                )
              "
            >
              修訂此電影
            </b-button>
          </template>
        </b-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import { getList } from "@/api/editBoxoffice.js"

export default {
  components: {},
  data() {
    return {
      loading: false,
      search: "",
      rows: 1,
      perPage: 1,
      currentPage: 1,
      tableData: [],
      tableFields: [
        { key: "film_master_id", label: "Film Master ID ", sortable: true },
        { key: "name_english", label: "Film Name", sortable: true },
        { key: "name_chinese", label: "電影名稱", sortable: true },
        { key: "date_release", label: "Date Release", sortable: true },
        { key: "date_off", label: "Date Off", sortable: true },
        { key: "action", label: "" },
      ],
      listParams: {
        page: 1,
        count: 10,
      },
      theatre_id: "",
      date: "",
    }
  },
  methods: {
    returnRecord() {
      this.$router.go(-1)
    },
    getBoxOfficesList() {
      this.loading = true
      const params = {
        date: this.$route.query.date,
        id: this.$route.query.id,
        search: this.search,
      }
      getList(params, this.listParams.page, this.listParams.count)
        .then((res) => {
          this.loading = false
          this.tableData = res.data
          res.data.map((el, index) => {
            this.tableData[index].name_chinese = el.film_master.name_chinese
            this.tableData[index].name_english = el.film_master.name_english
            this.tableData[index].date_release = el.film.date_release
            this.tableData[index].date_off = this.dateOffFormat(
              el.film.date_off,
              el.film.date_release
            )
          })
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    goDetail(name, master_id, film_name) {
      this.$router.push({
        name,
        query: {
          master_id,
          theatre_id: this.theatre_id,
          date: this.date,
          film_name,
          theatre_name: this.$route.query.name,
        },
      })
    },
  },
  created() {
    this.theatre_id = this.$route.query.id
    this.date = this.$route.query.date
    this.getBoxOfficesList()
  },
}
</script>

<style lang="scss" scoped>
.search-group {
  margin-bottom: 10px;
  position: relative;
  .search {
    width: 500px;
  }
  .add-user {
    position: absolute;
    top: 5px;
    left: 600px;
  }
}
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
</style>
