<template>
  <section id="film-distributor">
    <b-card>
      <div class="title">
        <p>{{ infoPageTitle }}</p>
      </div>

      <div>
        <b-button variant="outline-secondary" @click="goBackInvestInfo()">
          回目錄
        </b-button>
      </div>
      <div>
        <b-form>
          <div v-for="item in items" :id="item.id" :key="item.id">
            <b-row :id="item.id" :key="item.id" ref="row">
              <b-col cols="12" class="mt-2">
                <p class="sub-title">
                  Genre ID :
                  {{ pageIdVal == "edit" ? item.id : "Create New ID" }}
                </p>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Genre Name"
                  label-for="invest-name-code"
                  label-cols-md="3"
                >
                  <b-form-input
                    id="invest-name-code"
                    label="Genre Name"
                    v-model="item.filmcat_name_en"
                    name="filmcat_name_en"
                    :disabled="pageType === 'review'"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="片種名稱"
                  label-for="invest-name-en"
                  label-cols-md="3"
                >
                  <b-form-input
                    id="invest-name-en"
                    label="片種名稱"
                    v-model="item.filmcat_name_zh_hk"
                    name="filmcat_name_zh_hk"
                    :disabled="pageType === 'review'"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <hr />
              </b-col>
              <b-col offset-md="5" v-if="pageType === 'review'">
                <b-button variant="primary" class="mr-1" @click="toEdit()">
                  Edit
                </b-button>
              </b-col>
              <b-col offset-md="5" v-if="pageType === 'edit'">
                <b-button
                  variant="primary"
                  class="mr-1"
                  @click="submitForm('edit')"
                >
                  Save
                </b-button>
              </b-col>
              <b-col offset-md="5" v-if="pageType === 'create'">
                <b-button
                  variant="primary"
                  class="mr-1"
                  @click="submitForm('create')"
                  :disabled="disableChange"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </div>
    </b-card>
  </section>
</template>
<script>
import { createGenre, editGenre } from "@/api/film-material-management/genre";
import { getSelfProfile } from "@/api/login";

export default {
  name: "ProductionCompanyForm",
  components: {},
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    },
    items: {
      type: Array,
      default: () => [
        {
          id: "",
          filmcat_name_en: "",
          filmcat_name_zh_hk: "",
          old_record: []
        }
      ]
    }
  },
  data() {
    return {
      dateFormat: "d-m-Y",
      userInfo: "",
      disableChange: true,
      borderClass: "",
      pageId: "",
      pagePath: "",
      pageType: "",
      select: "",
      selectOptions: [
        { text: "香港", value: "Hong Kong" },
        { text: "非香港", value: "Non Hong Kong" }
      ],
      // dateDefault: 'today',
      formdata: {
        id: "",
        filmcat_name_en: "",
        filmcat_name_zh_hk: "",
        old_record: []
      }
    };
  },
  created() {
    this.pageType = this.$route.query.type;
    this.pageId = this.$route.query.id;
    this.pagePath = this.$route.path;
    console.log(this.pageType);
    if (this.pageType === "review") {
      // this.disableChange = false
    } else if (this.pageType === "edit") {
      // this.dateDefault = this.items[0].old_record[0].change_date
      this.borderClass = "redborder";
      this.getSelfProfile();
    } else {
      this.disableChange = false;
    }
  },
  computed: {
    infoPageTitle() {
      if (this.pageType === "review") {
        return `${this.title}記錄`;
      } else if (this.pageType === "edit") {
        return `修訂${this.title}記錄`;
      } else {
        return `開立新${this.title}記錄`;
      }
    },
    pageIdVal() {
      if (this.pageType === "review") {
        return "edit";
      } else if (this.pageType === "edit") {
        return "edit";
      } else {
        return "Create New ID";
      }
    },
  },
  methods: {
    getSelfProfile() {
      getSelfProfile()
        .then((res) => {
          this.userInfo = res.data;
          console.log(this.userInfo);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goBackInvestInfo() {
      this.$router.push({ name: "genre" });
    },
    toEdit() {
      this.$router.push({
        path: this.pagePath,
        query: { type: "edit", id: this.pageId }
      });
      this.pageType = "edit";
      this.disableChange = false;
      this.borderClass = "redborder";
    },
    submitForm(type) {
      const as = this.items[0].filmcat_name_en;
      const data = {
        filmcat_name_en: this.items[0].filmcat_name_en,
        filmcat_name_zh_hk: this.items[0].filmcat_name_zh_hk,
        revise: 0
      };
      console.log(type, data);
      if (type === "create") {
        createGenre(data)
          .then((res) => {
            this.formdata.filmcat_name_en = res.data.filmcat_name_en;
            this.formdata.filmcat_name_zh_hk = res.data.filmcat_name_zh_hk;
            console.log(res);
            this.$router.push({
              name: "editgenre",
              query: {
                type: "review",
                id: res.data.id
              }
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (type === "edit") {
        editGenre(this.pageId, data).then((res) => {
          this.$emit("getGenreDetail");
        });
      }
      // this.$router.push({ name: 'production-company' })
    }
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.sub-title {
  width: 100%;
  color: #0e72bc;
  font-size: 16px;
  font-weight: 500;
  margin: 20px 0;
}
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.line {
  height: 3px;
  color: #0e72bc;
}
.redborder {
  border-color: red;
}
</style>
