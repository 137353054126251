import Vue from "vue";
import VueRouter from "vue-router";
import { getToken } from "@/utils/auth";

// Routes
import { canNavigate } from "@/libs/acl/routeProtection";
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser
} from "@/auth/utils";
import dashboard from "./routes/dashboard";
import boxoffice from "./routes/boxoffice";
import pages from "./routes/pages";
import theatre from "./routes/theatre";
import store from "@/store";
import recordManagement from "./routes/recordManagement";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: "/", redirect: { name: "management-center" } },
    ...dashboard,
    ...boxoffice,
    ...theatre,
    ...pages,
    ...recordManagement,
    {
      path: "*",
      redirect: "error-404"
    }
  ]
});

router.beforeEach((to, _, next) => {
  store.commit("updateStateData");
  // const isLoggedIn = isUserLoggedIn()

  // if (!canNavigate(to)) {
  //   // Redirect to login if not logged in
  //   if (!isLoggedIn) return next({ name: 'auth-login' })

  //   // If logged in => not authorized
  //   return next({ name: 'misc-not-authorized' })
  // }

  // // Redirect if logged in
  // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
  //   const userData = getUserData()
  //   next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  // }
  // return next()
  if (to.path !== "/login") {
    if (getToken() && sessionStorage.getItem("isLogin")) {
      // console.log(getToken());
      next();
    } else {
      localStorage.clear();
      sessionStorage.clear();
      // 清除cookie
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
      next("/login");
    }
  } else {
    next();
  }
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to, from) => {
  //Logout Refresh the page
  if (to.path === "/login" && from.path !== "/") {
    location.reload();
  }
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
